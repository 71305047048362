import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getTaxonomyClassPropTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import { configurationServiceInstance } from "services/ConfigurationService";

import { buildJsxComponentsByTemplate } from "modules/GenerateByUIConfigurationModule/buildJsxComponentsByTemplate";
import { UIConfigurationTemplates, UIConfigurationType } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { RelationsList } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/RelationsList";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";

import { Preloader, PreloaderType } from "ui/Preloader";

import { TaxonomyItemReadSectionProps } from "../TaxonomyItemReadSections.types";

const getFieldsForRenderBy = (taxonomyClass: string, UIConfiguration: UIConfigurationType) => {
  const config = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  return Object.keys({ ...(config?.properties || {}), ...(UIConfiguration.read[taxonomyClass] || {}) });
};

export const CommonTaxonomyItemReadSection: React.FC<TaxonomyItemReadSectionProps> = React.memo(
  ({ taxonomyClass, taxonomyItem, fieldsToHide = [], nestingDepth = 0 }) => {
    const fieldsToRender = React.useMemo(() => {
      return taxonomyItem
        ? getFieldsForRenderBy(taxonomyClass, UIConfigurationTemplates).filter((key) => !fieldsToHide.includes(key))
        : [];
    }, [fieldsToHide, taxonomyClass, taxonomyItem]);

    if (!taxonomyItem) {
      return <Preloader type={PreloaderType.SKELETON} />;
    }

    return (
      <div>
        {fieldsToRender.map((key) => {
          const template = UIConfigurationTemplates.read[taxonomyClass]?.[key];

          const renderValue = () => {
            return template ? (
              <div className="flex flex-row flex-wrap">
                {buildJsxComponentsByTemplate(template, taxonomyItem as TaxonomyItem)}
              </div>
            ) : (
              taxonomyItem[key]
            );
          };

          return <TaxonomyItemReadRow key={key} title={getTaxonomyClassPropTitle(taxonomyClass, key)} value={renderValue()} />;
        })}
        <RelationsList relations={taxonomyItem.relations} nestingDepth={nestingDepth} taxonomyClass={taxonomyClass} />
      </div>
    );
  }
);
