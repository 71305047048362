import { TaxonomyItem } from "types/entities/Taxonomy";
import { FormTaxonomyData } from "types/formDataTypes";

import { relationFieldName } from "modules/TaxonomyModule/CommonTaxonomyFormModule/const";

export const createTaxonomyItemFromFormData = (formData: FormTaxonomyData): TaxonomyItem => {
  return {
    ...formData,
    ...(
      formData[relationFieldName] && {
        [relationFieldName]: Object.entries(formData[relationFieldName]).reduce(
          (acc, [key, values]) => {
            return { ...acc, [key]: values.map((v) => ({ id: v })) };
          },
          {}
        ),
      }
    )
  }
}
