import { ItemID } from "types/common";

import { useParams } from "react-router-dom";
import { ReadTaxonomyParamsType } from "router/navigationTypes";
import { catalogName } from "router/path";

export const useItemMetaFromParams = (): {
  itemID: ItemID;
  taxonomyClass: string;
  IDNumber: string;
} => {
  const { taxonomyClass = "", id = "" } = useParams<ReadTaxonomyParamsType>();

  return {
    itemID: `${catalogName}/${taxonomyClass}/${id}`,
    taxonomyClass,
    IDNumber: id,
  };
};
