import { MatchGroup } from "types/entities/Match";

import React from "react";
import { useLocation } from "react-router-dom";
import { Radio, RadioChangeEvent } from "antd";
import { useTranslations } from "translations/useTranslations";

import { CATALOG_PATH_SEPARATOR } from "modules/MatchingModule/consts/paths";
import { MATCHING_TARGETS_TABS } from "modules/MatchingModule/MatchingListModule/consts/matchingTargetsTabs";
import {
  useHandleMatchingTargetsTabChange
} from "modules/MatchingModule/MatchingListModule/hooks/useHandleMatchingTargetsTabChange";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import {
  useMatchingTargetsClassTabsContext
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";

const getActiveKey = (pathname: string) => {
  return pathname.includes(CATALOG_PATH_SEPARATOR) ? MATCHING_TARGETS_TABS.CATALOG : MATCHING_TARGETS_TABS.SUGGESTIONS;
}

export const MatchingTargetsTabs = React.memo(() => {
  const { pathname } = useLocation();
  const translations = useTranslations();
  const { selectedGroupIds, matchGroupsList } = useMatchingContext();
  const { isDiagnosticTabSelected } = useMatchingTargetsClassTabsContext();

  const currentActiveGroupRef = React.useRef<MatchGroup>();

  const activeKey = React.useMemo(() => getActiveKey(pathname), [pathname]);

  const handleMatchingTargetsTabChange = useHandleMatchingTargetsTabChange();

  const handleRadioChange = React.useCallback((e: RadioChangeEvent) => {
    handleMatchingTargetsTabChange(e.target.value);
  }, [handleMatchingTargetsTabChange]);

  // Switch to suggestions when current active group is changed
  React.useEffect(() => {
    const selectedGroup = matchGroupsList.find(({ group_id }) => group_id === selectedGroupIds[0]);

    if (
      selectedGroup?.group_id === currentActiveGroupRef.current?.group_id &&
      selectedGroup?.metadata_.match.status === currentActiveGroupRef.current?.metadata_.match.status
    ) return;

    currentActiveGroupRef.current = selectedGroup;

    handleMatchingTargetsTabChange(MATCHING_TARGETS_TABS.SUGGESTIONS);
  }, [handleMatchingTargetsTabChange, matchGroupsList, selectedGroupIds]);

  React.useEffect(() => {
    if (activeKey !== MATCHING_TARGETS_TABS.SUGGESTIONS && isDiagnosticTabSelected) handleMatchingTargetsTabChange(MATCHING_TARGETS_TABS.SUGGESTIONS);
  }, [activeKey, handleMatchingTargetsTabChange, isDiagnosticTabSelected]);

  if (isDiagnosticTabSelected) return null;

  return (
    <Radio.Group value={activeKey} onChange={handleRadioChange} className="flex">
      <Radio.Button value={MATCHING_TARGETS_TABS.SUGGESTIONS} className="grow text-center">
        {translations["matching.target.tabs.suggestions"]}
      </Radio.Button>
      <Radio.Button value={MATCHING_TARGETS_TABS.CATALOG} className="grow text-center">
        {translations["matching.target.tabs.catalog"]}
      </Radio.Button>
    </Radio.Group>
  )
});
