export type Code = {
  id: string;
  code: string;
  type: CodeType;
  created_by: string;
  created_dts: string;
  modified_by: string | null;
  modified_dts: string | null;
};

export type TrimmedCode = Pick<Code, "code" | "type">;

export enum CodeType {
  CLINIC = "CLINIC",
  SKU = "SKU",
  DIN = "DIN",
  REFID = "REFID",
  EAN = "EAN",
  BARSOI = "BARSOI",
  GTIN = "GTIN",
}

export type ClinicCode = {
  code: string | null;
  code_classification: string | null;
};
