import React from "react";
import { Input } from "antd";
import { useSearch } from "hooks/useSearch";
import { useTranslations } from "translations/useTranslations";

import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";

export const SEARCH_KEY = "description__contains";

export const MatchingSearch = React.memo(() => {
  const translations = useTranslations();

  const urlQueryService = useMatchingUrlQueryService();
  const {
    inputValue,
    queryValue,
    handleChange
  } = useSearch(urlQueryService.getValueByKey(SEARCH_KEY) as string || "", 0);

  React.useEffect(() => {
    urlQueryService.setSearchParam(SEARCH_KEY, queryValue);
  }, [urlQueryService, queryValue]);

  return (
    <Input.Search
      placeholder={translations["matching.table.search"]}
      value={inputValue}
      onChange={handleChange}
    />
  );
});
