import React from "react";
import { ApiOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useMatchItem } from "modules/MatchingModule/MatchingTargetsModule/hooks/useMatchItem";

import { MatchingMatchButtonProps } from "./MatchingMatchButton.types";

export const MatchingMatchButton = React.memo<MatchingMatchButtonProps>((props) => {
  const { itemID, taxonomyItem, iconOnly, className } = props;
  const translations = useTranslations();
  const { match, isMatchGroupsUpdating } = useMatchItem();
  const enabled = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_MATCH);

  const handleMatch = React.useCallback(() => match(itemID, taxonomyItem), [itemID, taxonomyItem, match]);

  if (!enabled) return null;

  return (
    <Button
      type="primary"
      className={className}
      icon={<ApiOutlined />}
      onClick={handleMatch}
      loading={isMatchGroupsUpdating}
    >
      {iconOnly ? null : translations["matching.target.buttons.match"]}
    </Button>
  );
});
