const breakpoints = {
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
}

const queries = {
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
  xl: `(min-width: ${breakpoints.xl})`,
  xxl: `(min-width: ${breakpoints.xxl})`,
}

const defaultTimeToLive = {
  staleTime: 1000 * 60 * 5,
  cacheTime: 1000 * 60 * 10,
};

module.exports = {
  breakpoints,
  queries,
  defaultTimeToLive,
}
