import { TaxonomyItem } from "types/entities/Taxonomy";

import {
  EnabledClassesAndItemsForDiagnosticsMatching
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/MatchingTargetsDiagnosticsModule.types";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

const REQUIREMENT_PROP_PREFIX = "enable";
const REQUIREMENT_REL_REGX = new RegExp("limit(.*)Values");

// TODO: maybe move to backend? (ask Nikita) https://kynetec.atlassian.net/browse/PTKA-1483
export const getEnabledClassesAndItemsForDiagnosticMatching = (
  providerRequirements?: TaxonomyItem
): EnabledClassesAndItemsForDiagnosticsMatching => {
  if (!providerRequirements) return {};

  const enabledClassesAndItems: EnabledClassesAndItemsForDiagnosticsMatching = {};

  Object.entries(providerRequirements).forEach(([key, value]) => {
    if (key.startsWith(REQUIREMENT_PROP_PREFIX)) {
      const taxonomyClass = key.replace(REQUIREMENT_PROP_PREFIX, "") as TaxonomyClass;
      enabledClassesAndItems[taxonomyClass] = value as boolean;
    }
  });

  Object.entries(providerRequirements.relations || {}).forEach(([key, value]) => {
    const taxonomyClass = key.match(REQUIREMENT_REL_REGX)?.[1];
    if (taxonomyClass) enabledClassesAndItems[taxonomyClass as TaxonomyClass] = value.map((item: TaxonomyItem) => item.id);
  });

  return enabledClassesAndItems;
}
