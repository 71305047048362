import { TaxonomyItem } from "types/entities/Taxonomy";

import { findAndRemoveRegExp } from "./helpers/findAndRemoveRegExp";

import { valueAndUnitPairs } from "./UIConfiguration/UIConfigurationPairs";
import { generateInstructionsByUIConfiguration } from "./generateByUIConfiguration";
import { defaultComponentsConfiguration, valueBetweenBracesRegExp } from "./generateByUIConfiguration.consts";
import {
  ComponentsConfigurationType,
  ContentType,
  InstructionResultForm,
  InstructionResultPartialType,
} from "./generateByUIConfiguration.types";

// TODO: refactoring this file

const getSeparator = (template: string, startStr: string, endString: string) => {
  if (!template || !endString) return "";

  const separator = template.slice(
    startStr ? template.indexOf(startStr) + startStr.length : 0,
    template.indexOf(endString)
  );

  return separator || "";
};

export const buildJsxComponentWithInstructions = (
  template: string,
  instructions: InstructionResultPartialType[],
  componentsConfiguration: ComponentsConfigurationType = defaultComponentsConfiguration
): JSX.Element[] => {
  const { getJsxByType, Separator } = componentsConfiguration;

  return instructions
    .map((instructionEl, index) => {
      const isFirstInstruction = index === 0;
      const separator = getSeparator(
        template,
        instructionEl.template.withBrackets,
        instructions[index + 1]?.template?.withBrackets
      );

      if (instructionEl.form === InstructionResultForm.ARRAY) {
        const generateValueForArray = instructionEl.content as ContentType[][];

        const generatedJsxForArray = generateValueForArray
          .map((valuesArray) => valuesArray.sort(
            (a, b) => a.value.localeCompare(b.value))
          )
          .sort((a, b) => a[0].value.localeCompare(b[0].value))
          .map((valuesArray, generateValueIndex) => {
            const isLastAllArrayList = generateValueForArray.length - 1 === generateValueIndex;

            const [, findings] = findAndRemoveRegExp(instructionEl.template.withBrackets, valueBetweenBracesRegExp);
            const separatorsList = findings.map(([regExpResult], findingIndex) => {
              return findings.length - 1 === findingIndex
                ? getSeparator(template, regExpResult, "]")
                : getSeparator(template, regExpResult, findings[findingIndex + 1][0]);
            }) as string[];

            return valuesArray.reduce<JSX.Element[]>((acc, content, valuesArrayIndex) => {
              const isLast = valuesArray.length - 1 === valuesArrayIndex;
              const isNotNeedSeparatorInEnd = isLastAllArrayList && isLast;
              const separatorJsx = isNotNeedSeparatorInEnd
                ? []
                : [
                  <Separator
                    key={`${content.to}-${generateValueIndex}-separator`}
                    separator={separatorsList[valuesArrayIndex]}
                  />,
                ];
              const netJsxElements = content.value ? [getJsxByType(content), ...separatorJsx] : separatorJsx;
              return [...acc, ...netJsxElements];
            }, []);
          })
          .flat(1);

        return [...generatedJsxForArray, <Separator key={`${index}-value-separator`} separator={separator} />];
      }

      if (instructionEl.form === InstructionResultForm.PAIR) {
        const { withBrackets } = instructionEl.template;
        const templateConfig = Object.values(valueAndUnitPairs).find(
          (valueAndUnitPairConfig) => valueAndUnitPairConfig.template === withBrackets
        );
        if (!templateConfig) return [];

        return templateConfig.decider(instructionEl.content)?.map((contentEl) => getJsxByType(contentEl));
      }

      return [
        ...(isFirstInstruction
          ? [
            <Separator
              key={`${index}-value-separator`}
              separator={getSeparator(template, "", instructionEl.template.withBrackets)}
            />,
          ]
          : []),
        getJsxByType(instructionEl.content as ContentType),
        <Separator key={`${index}-value-separator`} separator={separator} />,
      ];
    })
    .reduce((acc, jsxElements) => [...acc, ...jsxElements], []);
};

export const buildJsxComponentsByTemplate = (
  template: string,
  item: Partial<TaxonomyItem>,
  componentsConfiguration: ComponentsConfigurationType = defaultComponentsConfiguration
): JSX.Element[] => {
  const instructions = generateInstructionsByUIConfiguration(template, item);
  return buildJsxComponentWithInstructions(template, instructions, componentsConfiguration);
};
