import { UNKNOWN_PREMATCH_CLASS } from "types/entities/Prematch";
import { TaxonomyItem, TaxonomyStatus } from "types/entities/Taxonomy";

import { useMemo } from "react";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import { buildTree } from "helpers/treeHelpers/buildTree";
import { getChildrenOfTreeNode } from "helpers/treeHelpers/getChildrenOfNodeTree";
import { useTranslations } from "translations/useTranslations";

import {
  formatterFacetsValuesFromBEToFEFormat
} from "modules/FacetsModule/helpers/formatterFacetsValuesFromBEToFEFormat";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { LabelWithIgnore } from "./LabelWithIgnore";

export const useCascadeOptions = (
  drugClasses: TaxonomyItem[],
  foodClasses: TaxonomyItem[],
  retailClasses: TaxonomyItem[],
) => {
  const translations = useTranslations();

  const cascadeOptions = useMemo(() => {
    const filteredDrugClasses = drugClasses.filter((el) => el.status !== TaxonomyStatus.deactivated);
    const filteredFoodClasses = foodClasses.filter((el) => el.status !== TaxonomyStatus.deactivated);
    const filteredRetailClasses = retailClasses.filter((el) => el.status !== TaxonomyStatus.deactivated);

    const { tree: drugClassesTree } = buildTree(formatterFacetsValuesFromBEToFEFormat(filteredDrugClasses) || []);
    const { tree: foodClassesTree } = buildTree(formatterFacetsValuesFromBEToFEFormat(filteredFoodClasses) || []);
    const { tree: retailClassesTree } = buildTree(formatterFacetsValuesFromBEToFEFormat(filteredRetailClasses) || []);

    return [
      {
        value: TaxonomyClass.MEDICAL_PRODUCT,
        label: getTaxonomyClassTitle(TaxonomyClass.MEDICAL_PRODUCT),
        children: Object.values(drugClassesTree).map((nodeTree) => {
          const drugClass = drugClasses.find((el) => el.id === nodeTree.key) as TaxonomyItem;
          return {
            value: nodeTree.key,
            label: <LabelWithIgnore taxonomyItem={drugClass} />,
            taxonomyItem: drugClass,
            children: getChildrenOfTreeNode(nodeTree).map((thirdLvl) => {
              const thirdLvlDrugClass = drugClasses.find((el) => el.id === thirdLvl.key) as TaxonomyItem;
              return {
                value: thirdLvl.key,
                taxonomyItem: thirdLvlDrugClass,
                label: <LabelWithIgnore taxonomyItem={thirdLvlDrugClass} />,
              };
            }),
          };
        }),
      },
      {
        value: TaxonomyClass.FOOD_PRODUCT,
        label: getTaxonomyClassTitle(TaxonomyClass.FOOD_PRODUCT),
        children: Object.values(foodClassesTree).map((nodeTree) => {
          const foodClass = foodClasses.find((el) => el.id === nodeTree.key) as TaxonomyItem;
          return {
            value: nodeTree.key,
            label: <LabelWithIgnore taxonomyItem={foodClass}/>,
            taxonomyItem: foodClass,
            children: getChildrenOfTreeNode(nodeTree).map((thirdLvl) => {
              const thirdLvlFoodClass = drugClasses.find((el) => el.id === thirdLvl.key) as TaxonomyItem;
              return {
                value: thirdLvl.key,
                taxonomyItem: thirdLvlFoodClass,
                label: <LabelWithIgnore taxonomyItem={thirdLvlFoodClass} />,
              };
            }),
          };
        }),
      },
      {
        value: TaxonomyClass.RETAIL_PRODUCT,
        label: getTaxonomyClassTitle(TaxonomyClass.RETAIL_PRODUCT),
        children: Object.values(retailClassesTree).map((nodeTree) => {
          const retailClass = retailClasses.find((el) => el.id === nodeTree.key) as TaxonomyItem;
          return {
            value: nodeTree.key,
            label: <LabelWithIgnore taxonomyItem={retailClass}/>,
            taxonomyItem: retailClass,
            children: getChildrenOfTreeNode(nodeTree).map((thirdLvl) => {
              const thirdLvlRetailClass = drugClasses.find((el) => el.id === thirdLvl.key) as TaxonomyItem;
              return {
                value: thirdLvl.key,
                taxonomyItem: thirdLvlRetailClass,
                label: <LabelWithIgnore taxonomyItem={thirdLvlRetailClass} />,
              };
            }),
          };
        })
      },
      {
        value: TaxonomyClass.DIAGNOSTIC_TEST,
        label: getTaxonomyClassTitle(TaxonomyClass.DIAGNOSTIC_TEST),
      },
      {
        value: UNKNOWN_PREMATCH_CLASS,
        label: translations["static.item.unknown"],
      }
    ];
  }, [drugClasses, foodClasses, retailClasses, translations]);
  return cascadeOptions;
};
