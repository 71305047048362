import { TaxonomyConfiguration } from "types/appConfig";
import { ItemID } from "types/common";
import { FormTaxonomyData } from "types/formDataTypes";

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { duplicationItemID, getTaxonomyListPath, getTaxonomyReadPath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";

import { useCreateTaxonomyItem } from "./api/useCreateTaxonomyItem";
import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { CommonTaxonomyFormModule } from "modules/TaxonomyModule/CommonTaxonomyFormModule";

import { Preloader, PreloaderType } from "ui/Preloader";

import { CommonTaxonomyFormModuleProps } from "../CommonTaxonomyFormModule/CommonTaxonomyFormModule.types";
import { createTaxonomyItemFromFormData } from "../CommonTaxonomyFormModule/helpers/createTaxonomyItemFromFormData";

import { useTaxonomyChainCreationFilter } from "./hooks/useTaxonomyChainCreationFilter";

export const TaxonomyCreateForm: React.FC<{
  taxonomyClass: string;
  createItemCallback?: (itemID: ItemID) => void;
  ignoreDuplicationId?: boolean;
  additionalComponents: CommonTaxonomyFormModuleProps["additionalComponents"];
}> = ({ taxonomyClass, createItemCallback, ignoreDuplicationId = false, additionalComponents }) => {
  const navigate = useNavigate();

  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;

  const { hasChain, addCreatedItemToChain, getChainFilterString } = useTaxonomyChainCreationFilter();

  const [searchParams, setSearchParams] = useSearchParams();
  const itemID = searchParams.get(duplicationItemID);
  const { taxonomyItem } = useGetTaxonomyItemByClassAndId(ignoreDuplicationId ? "" : itemID || "");

  const { createTaxonomyItem, errorSchema } = useCreateTaxonomyItem(taxonomyClass);

  const handleSubmit = React.useCallback(
    async (data: FormTaxonomyData, isDuplicating?: boolean) => {
      const itemID = await createTaxonomyItem(data);

      if (createItemCallback) {
        createItemCallback(itemID);
        return;
      }

      if (isDuplicating) {
        addCreatedItemToChain(createTaxonomyItemFromFormData(data));
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      if (hasChain) {
        navigate(getTaxonomyListPath(taxonomyClass, getChainFilterString(createTaxonomyItemFromFormData(data))));
        return;
      }

      if (itemID) navigate(getTaxonomyReadPath(itemID));
    },
    [
      createTaxonomyItem,
      createItemCallback,
      hasChain,
      navigate,
      addCreatedItemToChain,
      taxonomyClass,
      getChainFilterString,
    ]
  );

  // remove duplication item id from url
  React.useEffect(() => {
    if (itemID && taxonomyItem) {
      searchParams.delete(duplicationItemID);
      setSearchParams(searchParams, { replace: true });
    }
  }, [taxonomyItem, searchParams, setSearchParams, itemID]);

  if (!configurationServiceInstance.isConfigurationExist()) return <div>Configuration for form empty</div>;
  if (!taxonomyConfig) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;
  if (itemID && !taxonomyItem) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;

  return (
    <CommonTaxonomyFormModule
      showDuplication={!ignoreDuplicationId}
      additionalComponents={additionalComponents}
      isCreating={true}
      formData={taxonomyItem}
      taxonomyClass={taxonomyClass}
      onSubmitCallback={handleSubmit}
      errorSchema={errorSchema}
    />
  );
};
