import { SynonymType } from "types/entities/Synonym";

import React from "react";
import { Divider, Input, Typography } from "antd";
import { useSearch } from "hooks/useSearch";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { SynonymList } from "modules/SynonymModule/SynonymListModule/components/SynonymList";
import { useGetSynonymItems } from "modules/SynonymModule/SynonymModule/api/useGetSynonymItems";

import { SynonymListNewItem } from "./components/SynonymListNewItem/SynonymListNewItem";
import { SynonymListModuleProps } from "./SynonymListModule.types";

const searchPlaceholderKeys = {
  [SynonymType.EXACT]: "synonym.search.placeholder.exact",
  [SynonymType.WILDCARD]: "synonym.search.placeholder.wildcard",
  [SynonymType.EXCLUSION]: "synonym.search.placeholder.exclusion",
};

const newItemPlaceholderKeys = {
  [SynonymType.EXACT]: "synonym.search.add.exact",
  [SynonymType.WILDCARD]: "synonym.search.add.wildcard",
  [SynonymType.EXCLUSION]: "synonym.search.add.exclusion",
};

const registeredPlaceholderKeys = {
  [SynonymType.EXACT]: "synonym.search.registered.exact",
  [SynonymType.WILDCARD]: "synonym.search.registered.wildcard",
  [SynonymType.EXCLUSION]: "synonym.search.registered.exclusion",
};

export const SynonymListModule = React.memo<SynonymListModuleProps>((props) => {
  const { taxonomyItemId, synonymType } = props;
  const catalogSynonymCreateEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_SYNONYM_CREATE);
  const translations = useTranslations();

  const { queryValue, inputValue, handleChange } = useSearch("", 0);

  const { refetch } = useGetSynonymItems(taxonomyItemId, synonymType, queryValue);

  const handleSearch = React.useCallback(() => refetch(), [refetch]);

  return (
    <div>
      {synonymType !== SynonymType.EXACT && catalogSynonymCreateEnabled && (
        <>
          <Typography.Title level={5}>{translations[newItemPlaceholderKeys[synonymType]]}</Typography.Title>
          <SynonymListNewItem taxonomyItemId={taxonomyItemId} synonymType={synonymType} />
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        </>
      )}
      <div className="mb-4 flex flex-row items-baseline">
        <Typography.Title level={5} className="xl:w-1/3 w-2/4">
          {translations[registeredPlaceholderKeys[synonymType]]}
        </Typography.Title>
        <Input.Search
          className="xl:w-2/3 w-2/4"
          placeholder={translations[searchPlaceholderKeys[synonymType]]}
          onChange={handleChange}
          onSearch={handleSearch}
          value={inputValue}
          allowClear
        />
      </div>
      <SynonymList taxonomyItemId={taxonomyItemId} synonymType={synonymType} searchValue={queryValue} />
    </div>
  );
});
