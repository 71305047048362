import { useIsPath } from "router/hooks/useIsPath";
import { useUrlQueryService } from "services/UrlQueryService/UrlQueryService";

const TAXONOMY_URL_QUERY_SERVICE_KEY = "taxonomyQuery";
const TAXONOMY_FIC_URL_QUERY_SERVICE_KEY = "taxonomyFicQuery";

export const useTaxonomyUrlQueryService = () => {
  const { isInMatching } = useIsPath();
  return useUrlQueryService(isInMatching ? TAXONOMY_FIC_URL_QUERY_SERVICE_KEY : TAXONOMY_URL_QUERY_SERVICE_KEY);
}
