import { ItemID } from "types/common";

import React from "react";
import { Card, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetItemCodes } from "api/useGetItemCodes";

import { Preloader, PreloaderType } from "ui/Preloader";

import { TaxonomyItemCodesTable } from "./components/TaxonomyItemCodesTable";

export const TaxonomyItemCodesListModule = React.memo<{ itemID: ItemID }>(({ itemID }) => {
  const translations = useTranslations();

  const { itemCodes, isLoading } = useGetItemCodes(itemID);

  return (
    <Card
      title={<Typography.Title level={4} style={{ margin: 0 }}>{translations["item.codes.table.title"]}</Typography.Title>}
      headStyle={{ padding: "24px" }}
    >
      {isLoading ? (
        <Preloader type={PreloaderType.SPINNER_BLOCK} />
      ) : (
        <TaxonomyItemCodesTable itemID={itemID} codes={itemCodes || []} />
      )}
    </Card>
  );
});
