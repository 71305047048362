import React from "react";
import { Collapse, CollapseProps, theme } from "antd";

import {
  MatchingInformationSourceContextProvider
} from "modules/MatchingModule/MatchingInformationSourceModule/MatchingInformationSource.context";

import { MatchingInformationSourceContent } from "./components/MatchingInformationSourceContent";
import { MatchingInformationSourceLabel } from "./components/MatchingInformationSourceLabel";

const FIRST_SECTION_KEY = "1";

const items: CollapseProps["items"] = [{
  key: FIRST_SECTION_KEY,
  label: <MatchingInformationSourceLabel />,
  children: <MatchingInformationSourceContent />,
}];

export const MatchingInformationSourceModule = React.memo(() => {
  const { token } = theme.useToken();

  return (
    <MatchingInformationSourceContextProvider>
      <div className="[&>.ant-collapse_.ant-collapse-content>.ant-collapse-content-box]:p-0">
        <Collapse
          items={items}
          defaultActiveKey={[FIRST_SECTION_KEY]}
          className="overflow-hidden"
          style={{ backgroundColor: token.colorFillAlter }}
        />
      </div>
    </MatchingInformationSourceContextProvider>
  );
});
