import React from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";

export const MatchingTableRefreshButton = React.memo(() => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching([QueryKeys.GET_LIST_MATCH_GROUPS])

  const handleClick = React.useCallback(() => {
    queryClient.invalidateQueries([QueryKeys.GET_LIST_MATCH_GROUPS]);
  }, [queryClient]);

  return (
    <Button onClick={handleClick} icon={<ReloadOutlined />} size="small" loading={Boolean(isLoading)}>
      {translations["matching.table.button.refresh"]}
    </Button>
  );
});
