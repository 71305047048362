import { useTranslations } from "translations/useTranslations";

export const useDiscardText = () => {
  const translations = useTranslations();

  return {
    title: translations["jobs.form.modal.discard.title"],
    content: translations["jobs.form.modal.discard.content"],
    okText: translations["jobs.form.modal.discard.button.ok"],
  };
};
