import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React, { useCallback, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { AdditionalPartForCustomTaxonomyReferenceFieldType } from "../../CommonTaxonomyFormModule/CommonTaxonomyFormModule.types";
import { TaxonomyCreateForm } from "../TaxonomyCreateForm";

type CreateNewTaxonomyItemInDrawerButtonProps = {
  onClick: (itemID: ItemID) => void;
  taxonomyClass: string;
  setMenuIsOpen: (isOpen: boolean) => void;
};
const CreateNewTaxonomyItemInDrawerButton = React.memo<CreateNewTaxonomyItemInDrawerButtonProps>(
  ({ onClick, taxonomyClass, setMenuIsOpen }) => {
    const translations = useTranslations();

    const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const showDrawer = useCallback(() => {
      setMenuIsOpen(false);
      setDrawerIsOpen(true);
    }, [setMenuIsOpen]);
    const onClose = useCallback(() => {
      setDrawerIsOpen(false);
    }, []);

    const createItemCallback = useCallback(
      (itemID: ItemID) => {
        onClose();
        onClick(itemID);
      },
      [onClick, onClose]
    );

    const title = `${translations["item.create.title"]} ${(taxonomyConfig?.title || taxonomyClass).toLowerCase()}`;

    return (
      <>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <Button type="ghost" onClick={showDrawer} htmlType="submit" icon={<PlusOutlined />}>
          {title}
        </Button>
        <Drawer title={title} placement="right" onClose={onClose} open={drawerIsOpen}>
          {drawerIsOpen && (
            <TaxonomyCreateForm
              additionalComponents={{ AdditionalPartForCustomTaxonomyReferenceField }}
              createItemCallback={createItemCallback}
              taxonomyClass={taxonomyClass}
              ignoreDuplicationId
            />
          )}
        </Drawer>
      </>
    );
  }
);

export const AdditionalPartForCustomTaxonomyReferenceField: AdditionalPartForCustomTaxonomyReferenceFieldType = ({
  addNewSelectedValue,
  refetch,
  relationInfo,
  setMenuIsOpen,
}) => {
  const onClick = useCallback(
    (itemID: ItemID) => {
      refetch().then((response) => {
        const newCreatedTaxonomyItem = (response?.data?.data || ([] as TaxonomyItem[])).find(
          (taxonomyItem) => taxonomyItem.id === itemID
        );
        if (newCreatedTaxonomyItem) {
          addNewSelectedValue(newCreatedTaxonomyItem.id);
        }
      });
    },
    [addNewSelectedValue, refetch]
  );

  return (
    <div className="flex flex-col">
      <CreateNewTaxonomyItemInDrawerButton
        onClick={onClick}
        taxonomyClass={relationInfo.to}
        setMenuIsOpen={setMenuIsOpen}
      />
    </div>
  );
};
