import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { useEndpointByTaxonomyClass } from "api/useEndpoint";

import { Preloader, PreloaderType } from "ui/Preloader";

import { ItemReadRowValue } from "../ItemReadRowValue";
import { TaxonomyItemReadRow } from "../TaxonomyItemReadRow/TaxonomyItemReadRow";

const createChain = (listOfItems: TaxonomyItem[], itemFromList?: TaxonomyItem): TaxonomyItem[] => {
  if (!itemFromList) return [];

  const nextItem = Object.values(itemFromList.relations || {})?.[0]?.[0];
  if (!nextItem) return [itemFromList];

  const nextItemFromList = listOfItems.find((taxonomyItem) => taxonomyItem.id === nextItem.id);
  return [itemFromList, ...createChain(listOfItems, nextItemFromList)].reverse();
};

export const TaxonomyItemWithRelationToSelf = React.memo<{
  title: string;
  taxonomyClass: string;
  relationItems?: TaxonomyItem[];
}>(({ title, taxonomyClass, relationItems }) => {
  const { data = [], isLoading } = useEndpointByTaxonomyClass<TaxonomyItem>(taxonomyClass, true);

  if (!relationItems || relationItems.length === 0) return <>—</>;

  const el = relationItems[0];
  const firstItem = (data || []).find((taxonomyItem) => taxonomyItem.id === el.id);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={false} />

  return (
    <TaxonomyItemReadRow
      title={title}
      value={<ItemReadRowValue taxonomyItem={createChain(data, firstItem)} separator=" / " />}
    />
  );
});
