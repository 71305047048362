import { Job, JobStatus } from "types/entities/Jobs";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import { getJobListUrl } from "api/rootUrl";

const getJobsList = (statuses?: JobStatus[]) => () =>
  axios
    .get<Job[]>(getJobListUrl(statuses))
    .then((data) => data.data);

export const useGetJobsList = (statuses?: JobStatus[]) => {
  const { data = [], isLoading, isError } = useQuery({
    queryKey: [QueryKeys.GET_ACTIVE_JOB_LIST, statuses],
    queryFn: getJobsList(statuses),
  });

  return { jobList: data, isLoading: isLoading, isError };
}
