import { Job, JobStatus } from "types/entities/Jobs";

import React from "react";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  InboxOutlined,
  SyncOutlined
} from "@ant-design/icons";
import { Tag } from "antd";
import { useTranslations } from "translations/useTranslations";

const tagPropsPerStatus: Partial<Record<JobStatus, { titleKey: string, icon: React.ReactNode, color: string }>> = {
  [JobStatus.CREATED]: {
    titleKey: "jobs.card.tag.created",
    icon: <SyncOutlined />,
    color: "processing",
  },
  [JobStatus.COMPLETED]: {
    titleKey: "jobs.card.tag.completed",
    icon: <CheckCircleOutlined />,
    color: "success",
  },
  [JobStatus.PENDING_COMPLETION]: {
    titleKey: "jobs.card.tag.pending_completion",
    icon: <ClockCircleOutlined />,
    color: "processing",
  },
  [JobStatus.DELETED]: {
    titleKey: "jobs.card.tag.deleted",
    icon: <CloseCircleOutlined />,
    color: "error",
  },
  [JobStatus.ARCHIVED]: {
    titleKey: "jobs.card.tag.archived",
    icon: <InboxOutlined />,
    color: "default",
  },
}

export const JobCardStatus = React.memo<{ job: Job }>(({ job }) => {
  const { status } = job;
  const translations = useTranslations();

  const tagProps = tagPropsPerStatus[status];
  if (!tagProps) return null;

  const { titleKey, color, icon } = tagProps;
  const title = translations[titleKey];

  return (
    <Tag color={color} icon={icon} style={{ margin: 0 }} className="max-w-full overflow-hidden overflow-ellipsis">
      {title}
    </Tag>
  );
});
