import { AppConfig, SynonymScopeSet, TaxonomyClasses, TaxonomyConfiguration } from "types/appConfig";

function normalizeConfiguration(config: AppConfig): AppConfig {
  return {
    ...config,
    taxonomyClasses: Object.keys(config.taxonomyClasses).reduce<TaxonomyClasses>((acc, taxonomyClass) => {
      acc[taxonomyClass.toLowerCase()] = config.taxonomyClasses[taxonomyClass];
      return acc;
    }, {}),
  };
}

class ConfigurationService {
  configuration: AppConfig | null = null;
  listOfRelationsKeys: string[] = [];
  listOfTaxonomyClasses: string[] = [];

  isConfigurationExist = () => Boolean(this.configuration);

  setConfiguration = (newConfiguration: AppConfig) => {
    if (!newConfiguration) return;
    this.configuration = normalizeConfiguration(newConfiguration);
    this.listOfTaxonomyClasses = Object.keys(newConfiguration.taxonomyClasses);
    this.listOfRelationsKeys = Object.values(this.configuration.taxonomyClasses)
      .map((taxonomyItemConf) => Object.keys(taxonomyItemConf.meta.relations || {}))
      .flat(1);
  };

  getTaxonomyItems = () => {
    if (!this.configuration?.taxonomyClasses) return;
    return this.configuration.taxonomyClasses;
  };

  getTaxonomyItemRelationName = (taxonomyClass: string, relationKey: string): string | undefined => {
    if (!this.configuration?.taxonomyClasses) return;
    const taxonomyItem = this.configuration.taxonomyClasses[taxonomyClass.toLowerCase()] as TaxonomyConfiguration;

    return taxonomyItem?.meta?.relations?.[relationKey]?.to.toLowerCase();
  };

  getTaxonomyItemRelation = (taxonomyClass: string, relationKey: string): TaxonomyConfiguration | undefined => {
    const relationName = this.getTaxonomyItemRelationName(taxonomyClass.toLowerCase(), relationKey) || "";
    return this.getTaxonomyItemByName(relationName);
  };

  getTaxonomyItemByName = (name: string): TaxonomyConfiguration | undefined => {
    if (!this.configuration?.taxonomyClasses) return;
    return this.configuration.taxonomyClasses[name.toLowerCase()];
  };

  isTaxonomyItemByNameExist = (name: string): boolean => {
    return Boolean(this.getTaxonomyItemByName(name));
  };

  getCodesConfig = () => this.configuration?.codes || {};

  getSynonymLanguages = () => this.configuration?.synonymLanguages || [];

  getSynonymScopes = () => this.configuration?.synonymScopeSets || [];

  getSynonymScopeByTaxonomyClass = (taxonomyClass: string): SynonymScopeSet | undefined => {
    const synonymScopeSetName = this.getTaxonomyItemByName(taxonomyClass)?.meta?.synonymScopeSet;
    return this.configuration?.synonymScopeSets?.find((scope) => scope.set_name === synonymScopeSetName);
  };
}

export const configurationServiceInstance = new ConfigurationService();
