import { JobStatus } from "types/entities/Jobs";

import React from "react";
import { Empty, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetJobsList } from "modules/JobsModule/api/useGetJobsList";
import { JobCard } from "modules/JobsModule/components/JobCard";
import { getSortedJobs } from "modules/JobsModule/helpers/getSortedJobs";

import { Preloader, PreloaderType } from "ui/Preloader";

export const ActiveJobsModule = React.memo<{ isJobsManager?: boolean }>(({ isJobsManager }) => {
  const translations = useTranslations();

  const { jobList, isLoading } = useGetJobsList([JobStatus.ACTIVE, ...(isJobsManager ? [JobStatus.CREATED] : [])]);
  const sortedJobList = React.useMemo(() => getSortedJobs(jobList), [jobList]);

  return (
    <div>
      <Typography.Title level={2}>{translations["jobs.active.title"]}</Typography.Title>
      <Preloader type={PreloaderType.SPINNER} spinning={isLoading} size="large">
        {sortedJobList?.length
          ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5 gap-4 items-start">
              {sortedJobList.map((job) => <JobCard key={job.id} job={job} isJobsManager={isJobsManager}/>)}
            </div>
          )
          : <Empty description={translations["jobs.active.empty.title"]} className="flex-grow" />
        }
      </Preloader>
    </div>
  );
});
