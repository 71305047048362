import { Modal } from "antd";

export const showPromiseConfirm = (
  title: string,
  content: string,
  okText: string,
  onOk: () => void,
  onCancel: () => void,
) => {
  Modal.confirm({
    title,
    content,
    okText,
    onOk,
    onCancel,
  });
};
