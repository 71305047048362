import React from "react";
import { EMPTY } from "consts/strings";
import { filterCodesByMarket } from "helpers/filterCodesByMarket";

import { useGetItemCodesWithCache } from "api/useGetItemCodes";

import { Preloader, PreloaderType } from "ui/Preloader";

import { CodeBoxesList } from "components/codeComponents/CodeBoxesList";

import { TaxonomyItemCodesProps } from "./TaxonomyItemCodes.types";

export const TaxonomyItemCodes = React.memo<TaxonomyItemCodesProps>(({ itemID, market, className, showEmpty }) => {
  const { itemCodes = [], isLoading } = useGetItemCodesWithCache(itemID);

  const filteredItemCodes = filterCodesByMarket(itemCodes, market);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} title={false} />;

  if (filteredItemCodes.length === 0) return showEmpty ? <div className={className}>{EMPTY}</div> : null;

  return (
    <div className={className}>
      <CodeBoxesList codes={filteredItemCodes}/>
    </div>
  );
});
