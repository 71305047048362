import React from "react";
import { Link } from "react-router-dom";
import { ApiOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { matchingPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { Breadcrumb } from "ui/Breadcrumb";

import { MatchingBreadcrumbsProps } from "./MatchingBreadcrumbs.types";

export const MatchingBreadcrumbs = React.memo<MatchingBreadcrumbsProps>(({ breadcrumbItems = [] }) => {
  const translations = useTranslations();

  const matchingTitle = React.useMemo(() => <Space><ApiOutlined />{translations["menu.matching"]}</Space>, [translations]);

  const items = React.useMemo(() => [
    { title: breadcrumbItems.length ? <Link to={matchingPath}>{matchingTitle}</Link> : matchingTitle },
    ...breadcrumbItems
  ], [breadcrumbItems, matchingTitle]);

  return (
    <Breadcrumb items={items} />
  );
});
