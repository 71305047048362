import React from "react";
import { useParams } from "react-router-dom";
import { MatchingParamsType } from "router/navigationTypes";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { useGetJob } from "modules/JobsModule/api/useGetJob";
import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

type MatchingInformationSourceContextType = {
  providers: string[];
  isLoading?: boolean;
  isError?: boolean;
  selectedProviders: string[];
  switchProvider: (provider: string) => void;
}

const MatchingInformationSourceContext = React.createContext({
  providers: [],
  selectedProviders: [],
  switchProvider: () => {},
} as MatchingInformationSourceContextType);

export const MatchingInformationSourceContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  const { jobId = "" } = useParams<MatchingParamsType>();

  const { job, isLoading: isJobLoading } = useGetJob(jobId);
  const {
    data: providers = [],
    isLoading: isProvidersLoading,
    isError
  } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.PROVIDER.toLowerCase());
  const { providersValue, setProvidersToSearchParams } = useInformationSourceInSearchParams();

  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(providersValue.split(",").filter(Boolean));

  const providersNames = React.useMemo<string[]>(() => {
    if (!job) return [];
    return providers
      .filter((provider) => provider.name && (!job.providers?.length || job.providers.includes(provider.name)))
      .map((provider) => provider.name as string);
  }, [job, providers]);

  const switchProvider = React.useCallback((newSelectedProvider: string) => {
    const newSelectedProviders = providersNames.filter((provider) => {
      if (!provider) return false;
      if (provider !== newSelectedProvider && !selectedProviders.includes(provider)) return false;
      if (provider === newSelectedProvider && selectedProviders.includes(provider)) return false;
      return true;
    });

    const newSearchParams = newSelectedProviders.length === providersNames.length ? "" : newSelectedProviders.join(",");

    setSelectedProviders(newSelectedProviders);
    setProvidersToSearchParams(newSearchParams);
  }, [providersNames, selectedProviders, setProvidersToSearchParams]);

  /* Preset all providers as selected */
  React.useEffect(() => {
    if (!providersNames.length) return;
    setSelectedProviders(prevState => prevState.length ? prevState : providersNames);
  }, [providersNames]);

  const value = {
    providers: providersNames,
    isLoading: isJobLoading || isProvidersLoading,
    isError,
    selectedProviders,
    switchProvider,
  };

  return (
    <MatchingInformationSourceContext.Provider value={value}>
      {children}
    </MatchingInformationSourceContext.Provider>
  );
}

export const useMatchingInformationSourceContext = () => React.useContext(MatchingInformationSourceContext);
