import React from "react";
import { Link } from "react-router-dom";
import { useAccount } from "@azure/msal-react";
import { Avatar, theme } from "antd";
import { rootPath } from "router/path";

import styles from "ui/Layout/Layout.module.scss";
import { Logo } from "ui/Logo";
import { MainMenu } from "ui/MainMenu";

const { useToken } = theme;

const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();
};

export const Header = React.memo(() => {
  const account = useAccount();

  const { token } = useToken();

  return (
    <div className="w-full flex items-center mx-auto">
      <div className="flex w-full justify-between items-center">
        <div className="flex items-center gap-8 flex-grow">
          <div className="text-base">
            <Link to={rootPath} className={styles.logoLink}><Logo /></Link>
          </div>
          <div className="flex-grow">
            <MainMenu />
          </div>
        </div>
        <div>
          <Avatar style={{ backgroundColor: token.colorBorderBg, color: token.colorTextBase }} size={40}>
            {getInitials(account?.name || "") || "?"}
          </Avatar>
        </div>
      </div>
    </div>
  );
});
