import { MatchStatus } from "types/entities/Match";

import React from "react";
import { Divider, theme } from "antd";
import clsx from "clsx";
import { useTranslations } from "translations/useTranslations";

import { ignoredStatuses } from "modules/MatchingModule/consts/statusLists";
import { getStatusTitleMap } from "modules/MatchingModule/helpers/statusMaps";

import { MatchingProgressTooltipContentProps } from "./MatchingProgress.types";

const sections = [
  {
    titleKey: "matching.progress.tooltip.total",
  },
  {
    titleKey: "matching.progress.tooltip.matched",
    statuses: [
      MatchStatus.PERFECT,
      MatchStatus.MANUAL,
      MatchStatus.GOOD,
      MatchStatus.APPROVED,
    ],
  },
  {
    titleKey: "matching.progress.tooltip.todo",
    statuses: [
      MatchStatus.NOMATCH,
      MatchStatus.CONFLICT,
      MatchStatus.REMATCH,
      MatchStatus.ITEM_REQUEST,
      MatchStatus.MOVED,
    ],
  },
  {
    titleKey: "matching.progress.tooltip.misc",
    statuses: [
      MatchStatus.IGNORED,
    ],
  },
];



export const MatchingProgressTooltipContent = React.memo<MatchingProgressTooltipContentProps>(({ values = [] }) => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  const renderedSections = React.useMemo(() => {
    const statusTitleMap = getStatusTitleMap(translations);

    return sections.map(section => {
      const count = values.reduce((acc, { id, count = 0 }) => {
        if (!section.statuses && !ignoredStatuses.includes(id as MatchStatus)) return acc + count;
        if (section.statuses?.includes(id as MatchStatus)) return acc + count;
        return acc;
      }, 0);

      return {
        title: translations[section.titleKey],
        count,
        statuses: section.statuses?.map(status => ({
          title: statusTitleMap[status],
          count: values.find(item => item.id === status)?.count || 0,
        }))
      };
    });
  }, [values, translations]);

  return (
    <div className="min-w-[200px]">
      {renderedSections.map((section, index) => (
        <>
          <div key={index}>
            <div style={{ fontSize: token.fontSizeLG }} className="flex justify-between items-center gap-4 mb-2">
              <span className={clsx("font-semibold", index === 0 && "uppercase")}>{section.title}</span>
              <span>{section.count}</span>
            </div>
            {section.statuses?.map(status => (
              <div key={status.title} className="flex justify-between items-center gap-2">
                <span className="uppercase text-xs">{status.title}</span>
                <span>{status.count}</span>
              </div>
            ))}
          </div>
          {index !== sections.length - 1 && (
            <Divider style={{ marginTop: token.marginXS, marginBottom: token.marginXS, borderColor: token.colorWhite }} />
          )}
        </>
      ))}
    </div>
  );
});
