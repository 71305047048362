import { Code, TrimmedCode } from "types/entities/Code";

import React from "react";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useTranslations } from "translations/useTranslations";

import { checkCodeHasChanges } from "./helpers/checkCodeHasChanges";

import {
  getConflictingCode
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/helpers/getConflictingCode";
import {
  useShowCodeOverwriteConfirm
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/hooks/useShowCodeOverwriteConfirm";
import {
  useDeleteItemCode
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesListModule/api/useDeleteItemCode";
import {
  useUpdateItemCode
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesListModule/api/useUpdateItemCode";

import { TaxonomyItemCodesTableControlsProps } from "./TaxonomyItemCodesTableControls.types";

export const TaxonomyItemCodesTableControls = React.memo<TaxonomyItemCodesTableControlsProps>(({
  itemID,
  code,
  itemCodes,
  form,
  setEditingId,
  isEditing,
}) => {
  const translations = useTranslations();

  const { updateCode, isLoading: isUpdating } = useUpdateItemCode(itemID, code.id);
  const { deleteCode, isLoading: isDeleting } = useDeleteItemCode(itemID, code.id);

  const handleUpdate = React.useCallback(async (newCode: TrimmedCode) => {
    await updateCode(newCode);
    setEditingId("");
  }, [setEditingId, updateCode]);

  const showCodeOverwriteConfirm = useShowCodeOverwriteConfirm();

  const handleEdit = React.useCallback((code: Partial<Code> & { id: string }) => {
    form.setFieldsValue(code);
    setEditingId(code.id);
  }, [form, setEditingId]);

  const handleCancel = React.useCallback(() => setEditingId(""), [setEditingId]);

  const handleSave = async () => {
    const newCode = await form.validateFields();

    if (!checkCodeHasChanges(code, newCode)) {
      setEditingId("");
      return;
    }

    const conflictingCode = getConflictingCode(newCode, itemCodes);
    if (conflictingCode) {
      showCodeOverwriteConfirm(conflictingCode, newCode, () => handleUpdate(newCode));
      return;
    }

    handleUpdate(newCode);
  }

  const handleDelete = React.useCallback(() => deleteCode(), [deleteCode]);

  const isLoading = isUpdating || isDeleting;

  return (
    <div className="flex items-center gap-2">
      {isEditing(code) ? (
        <>
          <Button type="primary" onClick={handleSave} icon={<CheckOutlined />} loading={isLoading} />
          <Button type="text" onClick={handleCancel} icon={<CloseOutlined />} disabled={isLoading} />
        </>
      ) : (
        <>
          <Button type="link" onClick={() => handleEdit(code)} icon={<EditOutlined />} />
          <Popconfirm
            title={translations["item.codes.delete.prompt.text"]}
            onConfirm={handleDelete}
            okText={translations["item.codes.delete.prompt.ok"]}
          >
            <Button type="link" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      )}
    </div>
  )
});
