import React from "react";
import { Link } from "react-router-dom";

import { CatalogContextType } from "modules/CatalogModule/CatalogModule.types";
import { CreateTaxonomyItemButton } from "modules/TaxonomyModule/components/CreateTaxonomyItemButton";
import { TaxonomyItemReadControls } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadControls";

const initialState: CatalogContextType = {
  getFacetsKey: (taxonomyClass) => "taxonomyFacets" + taxonomyClass,
  getFacetsPos: () => ({ height: "100vh", top: 0 }),
  components: {
    CatalogLink: React.memo(({ children, ...props }) => <Link {...props}>{children}</Link>),
    ListHeaderControls: CreateTaxonomyItemButton,
    DetailHeaderControls: TaxonomyItemReadControls,
  },
  callbacks: {},
  mandatoryAdditionalFilter: [],
};

export const CatalogContext = React.createContext<CatalogContextType>(initialState);

export const useCatalogContext = (): CatalogContextType => {
  return React.useContext(CatalogContext);
};
