import { AppConfig } from "types/appConfig";

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { configurationServiceInstance } from "services/ConfigurationService";

import { configurationUrl } from "api/rootUrl";

const key = "configuration";
const getConfiguration = () => {
  return axios.get<AppConfig>(configurationUrl).then((data) => data.data);
};

export const useGetConfiguration = () => {
  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: getConfiguration,
  });

  const [isSetUpingConfiguration, setIsSetUpConfiguration] = useState(true);
  useEffect(() => {
    if (data) {
      configurationServiceInstance.setConfiguration(data);
      setIsSetUpConfiguration(false);
    }
  }, [isLoading, data]);

  return { isLoading: isLoading && isSetUpingConfiguration };
};
