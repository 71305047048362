import { MatchGroupPrematch } from "types/entities/Match";
import { UNKNOWN_PREMATCH_CLASS } from "types/entities/Prematch";

import { getFacetKeyByTaxonomyClass } from "helpers/facetHelpers/getFacetKeyByTaxonomyClass";

import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

type PrematchRealTaxonomyClass =
  | TaxonomyClass.MEDICAL_PRODUCT
  | TaxonomyClass.FOOD_PRODUCT
  | TaxonomyClass.RETAIL_PRODUCT
  | TaxonomyClass.DIAGNOSTIC_TEST;

export type GetCatalogPresetFromPrematch = (prematch?: MatchGroupPrematch) => {
  initialTaxonomyClass?: PrematchRealTaxonomyClass;
  initialTaxonomyFacet?: { [facetKey: string]: string[] };
}

export const getCatalogPresetFromPrematch: GetCatalogPresetFromPrematch = (prematch?: MatchGroupPrematch) => {
  if (!prematch || prematch[GenericTaxonomyClass][0] === UNKNOWN_PREMATCH_CLASS) return {};

  const initialTaxonomyClass = prematch[GenericTaxonomyClass][0] as PrematchRealTaxonomyClass | undefined;
  const initialTaxonomyFacet: { [facetKey: string]: string[] } = {};

  Object.entries(prematch).forEach(([key, value]) => {
    if (key === GenericTaxonomyClass || key === TaxonomyClass.MARKET) return;
    if (!initialTaxonomyClass) return;
    if (value.length === 0) return;

    const facetKey = getFacetKeyByTaxonomyClass(initialTaxonomyClass, key as TaxonomyClass);

    if (!facetKey) return;

    initialTaxonomyFacet[facetKey] = value;
  });

  return { initialTaxonomyClass, initialTaxonomyFacet };
}
