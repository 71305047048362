import React from "react";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { MatchingModule } from "modules/MatchingModule";

import { AccessDenied } from "ui/AccessDenied";

export const MatchingPage = React.memo(() => {
  const matchingViewMainEnabled = useFeatureFlag(FeatureFlagNames.MATCHING_VIEW_MAIN);

  if (!matchingViewMainEnabled) return <AccessDenied />;

  return (
    <div className="py-10">
      <MatchingModule />
    </div>
  );
});
