import { ItemID } from "types/common";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { getItemCodeUrl } from "api/rootUrl";

const deleteItemCode = (itemID: ItemID, codeID: string) => () =>
  axios.delete(getItemCodeUrl(itemID, codeID)).then((res) => res.data);

export const useDeleteItemCode = (itemID: ItemID, codeID: string) => {
  const { showSuccess } = useMessage();
  const queryClient = useQueryClient();
  const translations = useTranslations();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: deleteItemCode(itemID, codeID),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.GET_ITEM_CODES, itemID]);
      showSuccess(translations["item.codes.delete.modal.success"]);
    }
  });

  return { deleteCode: mutateAsync, isLoading: isLoading || !!queryClient.isFetching([QueryKeys.GET_ITEM_CODES]), error };
}
