import React from "react";
import { debounce } from "helpers/debounce";

const TOTAL_DRAWER_HEIGHT = 450;
const HEAD_DRAWER_HEIGHT = 65;
const MAIN_DRAWER_HEIGHT = TOTAL_DRAWER_HEIGHT - HEAD_DRAWER_HEIGHT;
const MIN_VIABLE_TOP_MARGIN = 100;

const calcHeight = (): number => {
  if (window.innerHeight < MAIN_DRAWER_HEIGHT) return window.innerHeight - HEAD_DRAWER_HEIGHT;
  if ((window.innerHeight / 2) > MAIN_DRAWER_HEIGHT) return window.innerHeight / 2;
  if (window.innerHeight - TOTAL_DRAWER_HEIGHT < MIN_VIABLE_TOP_MARGIN) return window.innerHeight - HEAD_DRAWER_HEIGHT;
  return MAIN_DRAWER_HEIGHT;
}

export const useMatchingTargetsDrawerHeights = () => {
  const [height, setHeight] = React.useState<number>(calcHeight());

  /** Handle height on resize */
  React.useEffect(() => {
    const handleResize = debounce(() => setHeight(calcHeight()));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { mainDrawerHeight: height, headDrawerHeight: HEAD_DRAWER_HEIGHT };
}
