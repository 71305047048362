import { useLocation } from "react-router-dom";
import { isCatalogPath, isMatchingPath } from "router/helpers/isPath";

export const useIsPath = () => {
  const location = useLocation();

  return {
    isInMatching: isMatchingPath(location.pathname),
    isInCatalog: isCatalogPath(location.pathname),
  };
}
