import { Result } from "antd";
import { useItemMetaFromParams } from "hooks/useItemMetaFromParams";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { TaxonomyEditModule } from "modules/TaxonomyModule/TaxonomyEditModule";

import { AccessDenied } from "ui/AccessDenied";
import { BackHomeButton } from "ui/BackHomeButton";

export const TaxonomyEditPage = () => {
  const translations = useTranslations();
  const { taxonomyClass = "" } = useItemMetaFromParams();
  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  const catalogItemEditEnabledForRole = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_EDIT);
  if (!catalogItemEditEnabledForRole) return <AccessDenied />;

  const taxonomyName = taxonomyConfig?.title || taxonomyClass;
  if (!taxonomyConfig?.meta.editable)
    return (
      <Result
        status="warning"
        title={translations["item.create.prohibited"].replace("{{taxonomyClass}}", taxonomyName)}
        extra={<BackHomeButton />}
      />
    );

  return (
    <div className="pt-4 pb-6">
      <TaxonomyEditModule />
    </div>
  );
};
