import React from "react";
import { Select } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";

import {
  TaxonomyItemCodeType
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/components/TaxonomyItemCodeType";

export const SelectCodeType = React.memo<{ value?: string, onChange?: () => void }>(({
  value,
  onChange,
}) => {
  const codes = configurationServiceInstance.getCodesConfig();

  return (
    <Select value={value} onChange={onChange}>
      {Object.keys(codes).map((key) => (
        <Select.Option key={key} value={key}>
          <TaxonomyItemCodeType type={key} />
        </Select.Option>
      ))}
    </Select>
  );
});
