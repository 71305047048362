import { TaxonomyItem } from "types/entities/Taxonomy";

import { getEmptyValue } from "../generateByUIConfiguration.consts";
import {
  ContentForm,
  ContentType,
  InstructionResultForm,
  InstructionResultPartialType,
} from "../generateByUIConfiguration.types";

import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export const getTemplateValue = (key: string, item: Partial<TaxonomyItem>): ContentType => {
  if (item[key] !== undefined) {
    const value = item[key];
    if (value === null) return getEmptyValue();

    return { value: String(value), form: ContentForm.TEXT };
  }

  const relationValue = item.relations?.[key] || item.relations?.[capitalizeFirstLetter(key)];

  if (relationValue && relationValue.length) {
    return {
      value: relationValue[0].name || relationValue[0].id,
      form: ContentForm.LINK,
      to: relationValue[0].id,
    };
  }

  return getEmptyValue();
};

export const getInstructionsWithValue = (
  instructions: RegExpMatchArray[],
  item: Partial<TaxonomyItem>
): InstructionResultPartialType[] => {
  return instructions.map(([withBrackets, insideBrackets]) => {
    const content = getTemplateValue(insideBrackets, item);

    return {
      template: { withBrackets, insideBrackets },
      form: InstructionResultForm.VALUE,
      content,
    };
  });
};
