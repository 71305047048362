import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import {
  checkRequirementsConflict
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/helpers/checkRequirementsConflict";
import {
  getEnabledClassesAndItemsForDiagnosticMatching
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/helpers/getEnabledClassesAndItemsForDiagnosticMatching";
import {
  EnabledClassesAndItemsForDiagnosticsMatching
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/MatchingTargetsDiagnosticsModule.types";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { PROVIDER_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

export const useProviderRequirements = (): {
  providerRequirementsId: string;
  providerRequirements: EnabledClassesAndItemsForDiagnosticsMatching;
  hasConflict: boolean;
  isLoading: boolean;
} => {
  const { selectedGroupIds, matchGroupsList } = useMatchingContext();
  const selectedGroups = getSelectedGroups(matchGroupsList, selectedGroupIds);

  const { data: providers, isLoading: isProvidersLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(
    TaxonomyClass.PROVIDER,
    { expand: true }
  );

  const { data: requirements, isLoading: isRequirementsLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(
    TaxonomyClass.DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS,
    { expand: true }
  );

  const selectedGroupsProviders = React.useMemo<TaxonomyItem[]>(() => {
    if (!providers || !selectedGroups) return [];

    return providers.filter((provider) =>
      selectedGroups.some((group) => group.metadata_?.metadata?.provider_id === provider.name)
    );
  }, [providers, selectedGroups]);

  const selectedGroupsProvidersRequirements = React.useMemo<EnabledClassesAndItemsForDiagnosticsMatching[]>(
    () => {
      if (!selectedGroupsProviders) return [];

      return selectedGroupsProviders.map((provider) => {
        const providerRequirements = requirements?.find(
          (requirement) =>
            requirement.id === provider.relations?.[PROVIDER_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS]?.[0]?.id
        );

        return getEnabledClassesAndItemsForDiagnosticMatching(providerRequirements);
      });
    },
    [requirements, selectedGroupsProviders]);

  const hasConflict = React.useMemo(
    () => checkRequirementsConflict(selectedGroupsProvidersRequirements),
    [selectedGroupsProvidersRequirements]
  );

  const isLoading = isProvidersLoading || isRequirementsLoading;

  return {
    providerRequirementsId: selectedGroupsProviders?.[0]?.relations?.[PROVIDER_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS]?.[0]?.id || "",
    providerRequirements: selectedGroupsProvidersRequirements[0],
    hasConflict,
    isLoading,
  };
}
