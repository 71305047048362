import { FacetSectionConfigurationItem } from "modules/FacetsModule/FacetsModule.types";
import {
  diagnosticTestFacetSectionsConfiguration,
  foodProductFacetSectionsConfiguration,
  medicalProductFacetSectionsConfiguration, retailProductFacetSectionsConfiguration
} from "modules/TaxonomyModule/consts/facetConfigurations";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";

export const facetConfigurationMap: { [taxonomyClass: string]: FacetSectionConfigurationItem[] } = {
  [TaxonomyClass.DIAGNOSTIC_TEST]: diagnosticTestFacetSectionsConfiguration,
  [TaxonomyClass.FOOD_PRODUCT]: foodProductFacetSectionsConfiguration,
  [TaxonomyClass.MEDICAL_PRODUCT]: medicalProductFacetSectionsConfiguration,
  [TaxonomyClass.RETAIL_PRODUCT]: retailProductFacetSectionsConfiguration,
};
