import { TaxonomyItem } from "types/entities/Taxonomy";

import { getEmptyValue, valueBetweenBracesRegExp } from "../generateByUIConfiguration.consts";
import {
  ContentForm,
  ContentType,
  InstructionResultForm,
  InstructionResultPartialType,
} from "../generateByUIConfiguration.types";

import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { findAndRemoveRegExp } from "./findAndRemoveRegExp";

const getValues = (key: string, item: Partial<TaxonomyItem>): ContentType[] => {
  if (item[key]) {
    const value = item[key];
    if (value === null) return [getEmptyValue()];
    if (typeof value === "string" || typeof value === "number")
      return [{ value: String(value), form: ContentForm.TEXT }];
  }

  if (item.relations?.[key] || item.relations?.[capitalizeFirstLetter(key)]) {
    const values = item.relations?.[key] || item.relations?.[capitalizeFirstLetter(key)];
    if (values.length) {
      return values.map((v) => ({
        value: v.name || v.id,
        form: ContentForm.LINK,
        to: v.id,
      }));
    }
  }

  return [getEmptyValue()];
};

const findAllValuesForOneArrayInstruction = (
  [arrayWithBrackets, arrayInsideBrackets]: RegExpMatchArray,
  item: Partial<TaxonomyItem>
): InstructionResultPartialType => {
  const [, instructionsForReplacement] = findAndRemoveRegExp(arrayInsideBrackets, valueBetweenBracesRegExp);

  const allValuesInArray = instructionsForReplacement.map((ins) => getValues(ins[1], item));
  const biggestArray = allValuesInArray.reduce((acc, el) => (acc.length > el.length ? acc : el), []);

  const values = biggestArray.reduce<ContentType[][]>(
    (acc, _, i) => [
      ...acc,
      allValuesInArray.map(
        (valuesArray) => valuesArray[i] || (valuesArray.length === 1 ? valuesArray[0] : getEmptyValue())
      ),
    ],
    []
  );

  return {
    template: { withBrackets: arrayWithBrackets, insideBrackets: arrayInsideBrackets },
    form: InstructionResultForm.ARRAY,
    content: values,
  };
};

export const getInstructionsWithArrayValues = (
  arrayInstructions: RegExpMatchArray[],
  item: Partial<TaxonomyItem>
): InstructionResultPartialType[] => {
  return arrayInstructions.map((arrayInstruction) => findAllValuesForOneArrayInstruction(arrayInstruction, item));
};
