import { MatchStatus } from "types/entities/Match";

import React from "react";
import { ApiOutlined } from "@ant-design/icons";
import { Alert, Button, Form } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { mapValuesFEtoBE } from "./helpers/mapValuesFEtoBE";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { LocationSelector } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/components/LocationSelector";
import { ManufacturerSelector } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/components/ManufacturerSelector";
import { MatchingTargetsDiagnosticsConflict } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/components/MatchingTargetsDiagnosticsConflict";
import { PanelTypeSelector } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/components/PanelTypeSelector";
import { useProviderRequirements } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/hooks/useProviderRequirements";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { PROVIDER_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { Preloader, PreloaderType } from "ui/Preloader";

import { useInitialFormValues } from "./hooks/useInitialFormValues";
import { MatchingTargetsDiagnosticsFormValues } from "./MatchingTargetsDiagnosticsModule.types";

export const MatchingTargetsDiagnosticsModule = React.memo(() => {
  const [form] = Form.useForm<MatchingTargetsDiagnosticsFormValues>();
  const translations = useTranslations();

  const { data: locationItems = [], isLoading: isLocationItemsLoading } =
    useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.DIAGNOSTIC_TEST_LOCATION);

  const { patchSelectedGroups, isMatchGroupsUpdating, isMatchGroupsLoading } = useMatchingContext();

  const { providerRequirementsId, providerRequirements, hasConflict, isLoading } = useProviderRequirements();

  const handleSubmit = React.useCallback(
    async (values: MatchingTargetsDiagnosticsFormValues) => {
      if (hasConflict) return;

      const final = mapValuesFEtoBE(values, {
        [PROVIDER_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS]: providerRequirementsId ? [providerRequirementsId] : [],
      });

      await patchSelectedGroups({
        status: MatchStatus.MANUAL,
        final: [final],
      });
    },
    [hasConflict, patchSelectedGroups, providerRequirementsId]
  );

  const hasRequirements = Boolean(providerRequirements && Object.keys(providerRequirements).length > 0);
  const hasPanelType = providerRequirements?.[TaxonomyClass.PANEL_TYPE] || !hasRequirements;
  const hasManufacturer = providerRequirements?.[TaxonomyClass.MANUFACTURER] || !hasRequirements;
  const hasLocation = providerRequirements?.[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION] || !hasRequirements;

  const initialValues = useInitialFormValues();
  React.useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  // remove non-existent location from values
  React.useEffect(() => {
    const initialLocationsValue = initialValues[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION];

    if (!initialLocationsValue || !locationItems) return;
    if (!locationItems.find((item) => item.id === initialLocationsValue)) {
      form.setFieldValue(TaxonomyClass.DIAGNOSTIC_TEST_LOCATION, "");
    }
  }, [form, initialValues, locationItems]);

  if (isLoading) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;
  if (!providerRequirementsId) {
    return <Alert type="warning" message={translations["matching.diagnostics.form.no_requirements"]} showIcon />;
  }
  if (hasConflict) {
    return (
      <div className="py-4">
        <MatchingTargetsDiagnosticsConflict />
      </div>
    );
  }

  return (
    <Form form={form} layout="vertical" requiredMark="optional" className="py-4" onFinish={handleSubmit}>
      {hasPanelType && <PanelTypeSelector required={!hasManufacturer && !hasLocation} />}
      {hasManufacturer && <ManufacturerSelector />}
      {hasLocation && <LocationSelector items={locationItems} isLoading={isLocationItemsLoading} />}
      <div className="flex justify-end">
        <Button
          icon={<ApiOutlined />}
          type="primary"
          htmlType="submit"
          loading={isMatchGroupsLoading || isMatchGroupsUpdating}
        >
          {translations["matching.diagnostics.form.submit"]}
        </Button>
      </div>
    </Form>
  );
});
