import { ItemID } from "types/common";

import React from "react";
import { Progress } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { MatchingMatchButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingMatchButton";

import { Preloader, PreloaderType } from "ui/Preloader";

const SCORE_SIZE = 15;

export const MatchingTargetControls = React.memo<{ itemID: ItemID, score: number }>(({ itemID, score }) => {
  const translations = useTranslations();
  const percent = Math.round((score || 0) * 100);

  const { taxonomyItem, isLoading } = useGetTaxonomyItemByClassAndId(itemID);

  return (
    <div className="flex flex-col gap-2 items-center shrink-0 lg:flex-row lg:justify-between xxl:flex-col xxl:justify-start">
      <div className="flex items-center gap-2">
        <Progress type="circle" size={SCORE_SIZE} percent={percent} showInfo={false} />
        <span>{percent}% {translations["matching.target.score.text"]}</span>
      </div>

      {isLoading
        ? <Preloader type={PreloaderType.SKELETON_BUTTON} className="w-full lg:w-auto xxl:w-full" />
        : <MatchingMatchButton itemID={itemID} taxonomyItem={taxonomyItem} className="w-full lg:w-auto xxl:w-full" />
      }
    </div>
  );
});
