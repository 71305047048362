import { RelationElementConfiguration, RelationElementConfigurationTypeEnum } from "types/appConfig";
import { ItemID } from "types/common";

import { useCallback } from "react";
import { FieldProps } from "@rjsf/utils";

export const useSelectModeByRelationType = (
  relationInfo: RelationElementConfiguration,
  onChange: FieldProps["onChange"],
  selectedValue: null | string[]
): {
  mode: "multiple" | "tags" | undefined;
  onSelectChange: (v: string | string[]) => void;
  addNewSelectedValue: (itemID: ItemID) => void;
} => {
  const isMultiple = relationInfo.type === RelationElementConfigurationTypeEnum.MULTIPLE;

  return {
    mode: isMultiple ? "multiple" : undefined,
    onSelectChange: useCallback(
      (value) => {
        if (value) onChange(isMultiple ? value : [value]);
        else onChange(isMultiple ? null : []);
      },
      [onChange, isMultiple]
    ),
    addNewSelectedValue: (value) => {
      onChange([...(isMultiple ? selectedValue || [] : []), value]);
    },
  };
};
