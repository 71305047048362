import { MatchGroup } from "types/entities/Match";

import React from "react";
import { Typography } from "antd";
import { EMPTY } from "consts/strings";
import { getCountryConfig } from "helpers/countryHelpers";
import { formatDate } from "helpers/formatDate";
import { useTranslations } from "translations/useTranslations";

import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";

import { SecondaryLabelledList } from "components/SecondaryLabelledList";

export const MatchingTableAdditionalDetails = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const { group_id, created_dts, modified_dts, modified_by, metadata_: { metadata } } = matchGroup;
  const translations = useTranslations();
  const { isTransactions } = useCheckDataset();

  const sections = React.useMemo(() => {
    const visibleSections = [];

    if (isTransactions) {
      const { country = "", provider_id, source_clinic_id } = metadata || {};
      visibleSections.push({
        title: translations["matching.table.details.title.source"],
        items: [
          {
            title: translations["matching.table.details.labels.market"],
            value: getCountryConfig(country)?.countryName || EMPTY,
          },
          {
            title: translations["matching.table.details.labels.provider"],
            value: provider_id?.toUpperCase() || EMPTY,
          },
          {
            title: translations["matching.table.details.labels.clinic"],
            value: source_clinic_id || EMPTY,
          }
        ]
      });
    }

    visibleSections.push({
      title: translations["matching.table.details.title.logs"],
      items: [
        {
          title: translations["matching.table.details.labels.created"],
          value: formatDate(created_dts, { hasTime: true, hasSeconds: true }),
        },
        {
          title: translations["matching.table.details.labels.updated"],
          value: formatDate(modified_dts, { hasTime: true, hasSeconds: true }),
        },
        {
          title: translations["matching.table.details.labels.user"],
          value: modified_by,
        },
        {
          title: translations["matching.table.details.labels.group_id"],
          value: group_id,
        }
      ]
    });

    return visibleSections;
  }, [created_dts, isTransactions, group_id, metadata, modified_by, modified_dts, translations]);

  return (
    <div className="flex gap-4 flex-wrap">
      {sections.map(({ title, items }, i) => (
        <div key={i} className="grow min-w-[160px]">
          <Typography.Text strong className="block mb-2">{title}</Typography.Text>
          <SecondaryLabelledList items={items} />
        </div>
      ))}
    </div>
  );
});
