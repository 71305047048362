import React from "react";
import { ApiOutlined } from "@ant-design/icons";
import { theme, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { JobsLists } from "modules/JobsModule/JobsLists";

export const MatchingModule = React.memo(() => {
  const translations = useTranslations();

  const { token } = theme.useToken();

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <Typography.Title className="flex gap-3 items-center">
          <ApiOutlined width={40} height={40} style={{ color: token.colorPrimary }} />
          {translations["matching.title"]}
        </Typography.Title>
      </div>
      <JobsLists />
    </div>
  );
});
