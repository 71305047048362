import { TaxonomyItem } from "types/entities/Taxonomy";

import { MapKeysToComponents } from "modules/TaxonomyModule/TaxonomyTableModule/helpers/useMapKeysToComponents";

import { CustomDefaultColumn } from "../components/CustomDefaultColumn";

export type RenderFunction = (value: unknown, taxonomyItem: TaxonomyItem) => JSX.Element | string;

export const getRenderFunction = (keyName: string, mapKeysToComponents: MapKeysToComponents): RenderFunction => {
  if (mapKeysToComponents[keyName]) return mapKeysToComponents[keyName];

  return (value, taxonomyItem): JSX.Element | string => {
    const v = value || taxonomyItem?.relations?.[keyName];
    return <CustomDefaultColumn value={v} />;
  };
};
