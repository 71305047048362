import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";

import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

import { Preloader, PreloaderType } from "ui/Preloader";

import { TaxonomyItemCodes } from "components/codeComponents/TaxonomyItemCodes";
import { SecondaryLabelledList } from "components/SecondaryLabelledList";

import { useMatchingResultListItems } from "./hooks/useMatchingResultListItems";
import { MatchingItemDetailsListProps } from "./MatchingItemDetailsList.types";

export const MatchingItemDetailsList = React.memo<MatchingItemDetailsListProps>(({
  matchTarget,
  prematchInformationKinds,
  prematch,
  horizontal,
  className,
}) => {
  const taxonomyItemId = matchTarget?.taxonomy_item_id || "";
  const taxonomyItemClass = getItemClassFromItemId(taxonomyItemId)?.[0] || "";
  const hasCodes = configurationServiceInstance.getTaxonomyItemByName(taxonomyItemClass)?.meta?.codes;
  const { countryValue } = useInformationSourceInSearchParams();

  const { items, isLoading } = useMatchingResultListItems({ matchTarget, prematchInformationKinds, prematch });

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} />

  return (
    <div>
      <SecondaryLabelledList items={items} horizontal={horizontal} className={className} />
      {taxonomyItemId && hasCodes && (
        <TaxonomyItemCodes itemID={taxonomyItemId} market={countryValue} className="mt-2" />
      )}
    </div>
  );
});
