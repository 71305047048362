import React from "react";
import { useNavigate } from "react-router-dom";
import { getTaxonomyListPath } from "router/path";

import { useApplyPrematchAsTaxonomyFacets } from "modules/MatchingModule/hooks/useApplyPrematchAsTaxonomyFacets";
import { MATCHING_TARGETS_TABS } from "modules/MatchingModule/MatchingListModule/consts/matchingTargetsTabs";
import { useModifiedMatchingPaths } from "modules/MatchingModule/MatchingListModule/hooks/useModifiedMatchingPaths";
import {
  useMatchingTargetsClassTabsContext
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";

export const useHandleMatchingTargetsTabChange = () => {
  const navigate = useNavigate();
  const { getMatchingPathWithSearch, getMatchingCatalogPathWithMatchingSearch } = useModifiedMatchingPaths();

  const { activeTaxonomyClass } = useMatchingTargetsClassTabsContext();
  const applyPrematchAsTaxonomyFacet = useApplyPrematchAsTaxonomyFacets();

  return React.useCallback((key: string) => {
    const pathMap: Record<string, string> = {
      [MATCHING_TARGETS_TABS.SUGGESTIONS]: getMatchingPathWithSearch(),
      [MATCHING_TARGETS_TABS.CATALOG]: getMatchingCatalogPathWithMatchingSearch(getTaxonomyListPath(activeTaxonomyClass.toLowerCase()))
    }

    navigate(pathMap[key]);

    if (key === MATCHING_TARGETS_TABS.CATALOG) applyPrematchAsTaxonomyFacet();
  }, [activeTaxonomyClass, applyPrematchAsTaxonomyFacet, getMatchingCatalogPathWithMatchingSearch, getMatchingPathWithSearch, navigate]);
}
