import { Job, JobStatus } from "types/entities/Jobs";

import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useUpdateJobStatus } from "modules/JobsModule/api/useUpdateJobStatus";
import { JobDeleteButton } from "modules/JobsModule/components/JobDeleteButton";
import { useShowCompleteConfirm } from "modules/JobsModule/hooks/useShowCompleteConfirm";
import { useShowDeleteConfirm } from "modules/JobsModule/hooks/useShowDeleteConfirm";

export const JobActiveControls = React.memo<{ job: Job }>(({ job }) => {
  const translation = useTranslations();
  const jobsCompleteEnabled = useFeatureFlag(FeatureFlagNames.JOBS_COMPLETE);
  const jobsDeleteEnabled = useFeatureFlag(FeatureFlagNames.JOBS_DELETE);

  const { updateJobStatus } = useUpdateJobStatus(job.id);

  const showCompleteConfirm = useShowCompleteConfirm(() => updateJobStatus(JobStatus.PENDING_COMPLETION));
  const showDeleteConfirm = useShowDeleteConfirm(() => updateJobStatus(JobStatus.DELETED));

  const handleButtonClick = React.useCallback(() => {
    showCompleteConfirm();
  }, [showCompleteConfirm]);

  const menuProps = React.useMemo<{ items: MenuProps["items"] }>(() => {
    const items = [];

    if (jobsDeleteEnabled) {
      items.push({
        key: "Delete",
        label: translation["jobs.card.button.delete"],
        icon: <DeleteOutlined />,
        disabled: !jobsDeleteEnabled,
        danger: true,
        onClick: showDeleteConfirm,
      });
    }

    return { items };
  }, [jobsDeleteEnabled, showDeleteConfirm, translation]);

  if (!jobsCompleteEnabled && !jobsDeleteEnabled) return null;
  if (!jobsCompleteEnabled) return <JobDeleteButton job={job} />;
  if (!menuProps.items?.length) {
    return <Button onClick={handleButtonClick}>{translation["jobs.card.button.complete"]}</Button>;
  }

  return (
    <Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
      {translation["jobs.card.button.complete"]}
    </Dropdown.Button>
  );
});
