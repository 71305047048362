import { MatchStatus } from "types/entities/Match";

import { AlertProps, TagProps } from "antd";

export const specialStatuses = [
  MatchStatus.APPROVED,
  MatchStatus.CONFLICT,
  MatchStatus.GOOD,
  MatchStatus.IGNORED,
  MatchStatus.ITEM_REQUEST,
  MatchStatus.MANUAL,
  MatchStatus.MOVED,
  MatchStatus.PERFECT,
  MatchStatus.REMATCH,
];

export const getStatusTitleMap = (translations: Record<string, string>): Record<MatchStatus, string> => ({
  [MatchStatus.APPROVED]: translations["static.facets.status.approved"],
  [MatchStatus.CONFLICT]: translations["static.facets.status.automatch-conflict"],
  [MatchStatus.GOOD]: translations["static.facets.status.automatch-good"],
  [MatchStatus.IGNORED]: translations["static.facets.status.ignored"],
  [MatchStatus.ITEM_REQUEST]: translations["static.facets.status.item-requested"],
  [MatchStatus.MANUAL]: translations["static.facets.status.manual"],
  [MatchStatus.MOVED]: translations["static.facets.status.moved"],
  [MatchStatus.NOMATCH]: translations["static.facets.status.pending"],
  [MatchStatus.PERFECT]: translations["static.facets.status.automatch-perfect"],
  [MatchStatus.REMATCH]: translations["static.facets.status.needs-rematch"],
});

export const getStatusAlertTypeMap = (): Record<MatchStatus, AlertProps["type"]> => ({
  [MatchStatus.APPROVED]: "success",
  [MatchStatus.CONFLICT]: "warning",
  [MatchStatus.GOOD]: "info",
  [MatchStatus.IGNORED]: "error",
  [MatchStatus.ITEM_REQUEST]: "warning",
  [MatchStatus.MANUAL]: "info",
  [MatchStatus.MOVED]: "warning",
  [MatchStatus.NOMATCH]: undefined,
  [MatchStatus.PERFECT]: "success",
  [MatchStatus.REMATCH]: "warning",
});

export const getStatusTagTypeMap = (): Record<MatchStatus, TagProps["color"]> => ({
  [MatchStatus.APPROVED]: "success",
  [MatchStatus.CONFLICT]: "warning",
  [MatchStatus.GOOD]: "processing",
  [MatchStatus.IGNORED]: "error",
  [MatchStatus.ITEM_REQUEST]: "warning",
  [MatchStatus.MANUAL]: "processing",
  [MatchStatus.MOVED]: "warning",
  [MatchStatus.NOMATCH]: "default",
  [MatchStatus.PERFECT]: "success",
  [MatchStatus.REMATCH]: "warning",
});
