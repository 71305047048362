import React from "react";
import { Tag } from "antd";

import { facetSetMap } from "modules/FacetsModule/components/facetSetMap/facetSetMap";
import { useFacetTitle } from "modules/FacetsModule/hooks/useFacetTitle";
import { useGetStaticFacetItemTitle } from "modules/FacetsModule/hooks/useGetStaticFacetItemTitle";

import { FacetSetItemProps } from "./FacetSetItem.types";

export const FacetSetItem = React.memo<FacetSetItemProps>((props) => {
  const { configuration, onClose, values = [] } = props;

  const handleClose = React.useCallback(() => {
    onClose(configuration.key);
  }, [configuration.key, onClose]);

  const getStaticItemTitle = useGetStaticFacetItemTitle(configuration.key);

  const valuesToShow = React.useMemo(() => {
    return "isStatic" in configuration && configuration.isStatic
      ? values.map((item) => getStaticItemTitle(item))
      : values;
  }, [configuration, values, getStaticItemTitle]);

  const title = useFacetTitle(configuration);

  if (!configuration?.key || !valuesToShow?.length || configuration.meta?.isHidden) return null;

  const ContentComponent = facetSetMap[configuration.type];

  return (
    <Tag closable={!configuration.meta?.isImmutable} onClose={handleClose} className="whitespace-normal" style={{ margin: 0 }}>
      <ContentComponent {...configuration} facetKey={configuration.key} values={valuesToShow} title={title} />
    </Tag>
  );
});
