import { useEffect, useState } from "react";
import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

import { FacetSectionConfiguration } from "../FacetsModule.types";
import { FacetsManagerService } from "../FacetsService";

export const useInitialFacetsServiceLoading = (
  facetsKey: string,
  urlQueryService: UrlQueryService,
  facetSectionsConfiguration: FacetSectionConfiguration
) => {
  const [isFacetsServiceLoading, setIsFacetsServiceLoading] = useState(true);
  const facetsService = FacetsManagerService.getServiceByFacetsKey(facetsKey);
  facetsService.setConfiguration(facetSectionsConfiguration);
  const searchParams = urlQueryService.getOnlyThisSearchParams();

  const parsedSearchParams = facetsService.getArrayFromSearchParams(searchParams);
  facetsService.writeFromArrayToFacetMap(parsedSearchParams);

  useEffect(() => {
    setIsFacetsServiceLoading(false);
  }, [searchParams, facetsService, urlQueryService]);
  return { isFacetsServiceLoading, facetsService };
};
