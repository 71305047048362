import { useMemo } from "react";

export const useFormattedOptions = (
  // TODO: find a correct replacement for unstableArray
  data: any[] = []
) => {
  return useMemo(() => {
    const useFormattedOptions = data
      .map<{ value: string; label: string }>((object) => ({
        value: object.id,
        label: (object.name || object.value || "—").trim(),
      }))
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

    return useFormattedOptions;
  }, [data]);
};
