import { MatchGroup } from "types/entities/Match";

import React from "react";

export const useSelectGroups = (matchGroupsList: MatchGroup[]) => {
  const matchGroupsIdsList = React.useMemo(() => matchGroupsList.map(({ group_id }) => group_id), [matchGroupsList]);
  const selectionLastIdxRef = React.useRef<number>(0);
  const needScrollToSelected = React.useRef<boolean>(false);

  const [selectedGroupIds, setSelectedGroupIds] = React.useState<number[]>([]);

  const handleSelectGroup = React.useCallback((groupIds: number[]) => {
    setSelectedGroupIds(groupIds);

    selectionLastIdxRef.current = 0;

    if (!groupIds.length) return;

    for (const groupId of groupIds) {
      const index = matchGroupsIdsList.indexOf(groupId);
      if (index > selectionLastIdxRef.current) selectionLastIdxRef.current = index;
    }
  }, [matchGroupsIdsList]);

  React.useEffect(() => {
    setSelectedGroupIds(prevState => prevState.filter(selectedGroupId => matchGroupsIdsList.includes(selectedGroupId)));
  }, [matchGroupsIdsList]);

  const clearSelection = React.useCallback(() => setSelectedGroupIds([]), []);

  const pickNextGroup = React.useCallback((oldMatchGroupIdsList: number[], newMatchGroupsIdsList: number[]) => {
    let firstPrevSelectedGroupIdxInOldList = oldMatchGroupIdsList.length - 1;
    let lastPrevSelectedGroupIdxInNewList = -1;
    let nextIdx = -1;

    selectedGroupIds.forEach(selectedGroupId => {
      const idxInOldList = oldMatchGroupIdsList.indexOf(selectedGroupId);
      const idxInNewList = newMatchGroupsIdsList.indexOf(selectedGroupId);

      if (idxInOldList < firstPrevSelectedGroupIdxInOldList) firstPrevSelectedGroupIdxInOldList = idxInOldList;
      if (idxInNewList > lastPrevSelectedGroupIdxInNewList) lastPrevSelectedGroupIdxInNewList = idxInNewList;
    });

    const newSelectedGroupsIds: number[] = [];

    if (lastPrevSelectedGroupIdxInNewList > -1) {
      nextIdx = lastPrevSelectedGroupIdxInNewList + 1;
    } else {
      nextIdx = firstPrevSelectedGroupIdxInOldList;
    }

    if (nextIdx >= 0 && nextIdx < newMatchGroupsIdsList.length) newSelectedGroupsIds.push(newMatchGroupsIdsList[nextIdx]);

    needScrollToSelected.current = true;
    setSelectedGroupIds(newSelectedGroupsIds);
  }, [selectedGroupIds]);

  const clearSelectedGroupsAndPickNext = React.useCallback((newMatchGroupsList: MatchGroup[]) => {
    const newMatchGroupsIdsList = newMatchGroupsList.map(({ group_id }) => group_id);

    if (!newMatchGroupsIdsList.length) {
      clearSelection();
    } else {
      pickNextGroup(matchGroupsIdsList, newMatchGroupsIdsList);
    }
  }, [clearSelection, matchGroupsIdsList, pickNextGroup]);

  return {
    selectedGroupIds,
    setSelectedGroupIds: handleSelectGroup,
    clearSelectedGroupsAndPickNext,
    needScrollToSelected,
  }
}
