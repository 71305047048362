import React from "react";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { FacetSetItemUI } from "modules/FacetsModule/components/FacetSetItemUI";

import { Preloader, PreloaderType } from "ui/Preloader";

import { FacetSetMapItemProps } from "./facetSetMap";

const FacetsSetTaxonomyItemFlatTree = React.memo<{ title: string; values: string[]; taxonomyClass: string; }>(({
  title,
  values,
  taxonomyClass,
}) => {
  const { data: items = [], isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(taxonomyClass);

  const valuesToShow = React.useMemo(() => items
    .filter(item => values.includes(item.id))
    .map(item => item.name || item.id),
  [items, values]);
  if (isLoading) return <Preloader type={PreloaderType.SKELETON_BUTTON} size="small" style={{ height: "20px" }} />;

  return <FacetSetItemUI title={title} items={valuesToShow} />;
});

export const FacetsSetItemFlatTree = React.memo<FacetSetMapItemProps>(({
  title,
  values,
  taxonomyClass,
}) => taxonomyClass
  ? <FacetsSetTaxonomyItemFlatTree title={title} values={values} taxonomyClass={taxonomyClass} />
  : <FacetSetItemUI title={title} items={values} />
);
