import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { TaxonomyItemReadModule } from "modules/TaxonomyModule";

import { AccessDenied } from "ui/AccessDenied";

export const TaxonomyReadPage = () => {
  const catalogViewDetailEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_VIEW_DETAIL);

  if (!catalogViewDetailEnabled) return <AccessDenied />;

  return (
    <div className="pt-4 pb-6">
      <TaxonomyItemReadModule />
    </div>
  );
};
