import React from "react";
import { useIsFetching } from "@tanstack/react-query";
import { Result } from "antd";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";

import { BackHomeButton } from "ui/BackHomeButton";
import { Preloader, PreloaderType } from "ui/Preloader";

export const AccessDenied = React.memo(() => {
  const translations = useTranslations();
  const isFetching = useIsFetching({ queryKey: [QueryKeys.GET_FEATURE_FLAGS_CONFIG_BY_ROLE] });

  if (isFetching) return <Preloader className="my-4" type={PreloaderType.SPINNER_BLOCK} />;

  return (
    <Result status="403" title="403" subTitle={translations["role.page.not_enabled"]} extra={<BackHomeButton />} />
  );
});
