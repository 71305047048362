import React from "react";

import {
  diagnosticFacetKeysConfig,
  facetSectionsConfigurationSpecies,
  facetSectionsConfigurationTransactions,
  foodProductFacetKeysConfig,
  initialFacetKeysConfig,
  matchingFacetsKeys,
  medicalProductFacetKeysConfig,
  retailProductFacetKeysConfig,
  unknownFacetKeysConfig,
} from "modules/MatchingModule/consts/matchingFacets";
import { checkInformationKind } from "modules/MatchingModule/helpers/checkInformationKind";

import { useCheckDataset } from "./useCheckDataset";
import { useMatchingUrlQueryService } from "./useMatchingUrlQueryService";

export const useMatchingFacetConfigurationByDataset = () => {
  const { isTransactions } = useCheckDataset();

  const urlQueryService = useMatchingUrlQueryService();
  const selectedClass = urlQueryService.getValueByKey(matchingFacetsKeys.CLASS);

  const {
    isMedicalProduct: isMedicalProductSelected,
    isFoodProduct: isFoodProductSelected,
    isRetailProduct: isRetailProductSelected,
    isDiagnosticTest: isDiagnosticTestSelected,
    isUnknown: isUnknownProductSelected,
  } = checkInformationKind(selectedClass);

  const actualConfig = React.useMemo(() => {
    const config = [
      isMedicalProductSelected ? medicalProductFacetKeysConfig : [],
      isFoodProductSelected ? foodProductFacetKeysConfig : [],
      isRetailProductSelected ? retailProductFacetKeysConfig : [],
      isDiagnosticTestSelected ? diagnosticFacetKeysConfig : [],
      isUnknownProductSelected ? unknownFacetKeysConfig : [],
      initialFacetKeysConfig,
    ].flat(1);

    return isTransactions
      ? facetSectionsConfigurationTransactions.filter((el) => config.includes(el.key))
      : facetSectionsConfigurationSpecies;
  }, [
    isMedicalProductSelected,
    isFoodProductSelected,
    isRetailProductSelected,
    isDiagnosticTestSelected,
    isUnknownProductSelected,
    isTransactions,
  ]);

  return {
    facetSectionsConfiguration: actualConfig,
    matchingFiltersKeys: React.useMemo(() => actualConfig.map((el) => el.key), [actualConfig]),
  };
};
