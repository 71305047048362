import React from "react";
import { Alert, Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { MATCHING_TARGETS_TABS } from "modules/MatchingModule/MatchingListModule/consts/matchingTargetsTabs";
import {
  useHandleMatchingTargetsTabChange
} from "modules/MatchingModule/MatchingListModule/hooks/useHandleMatchingTargetsTabChange";

export const MatchingTargetListTooltip = React.memo(() => {
  const translations = useTranslations();

  const handleMatchingTargetsTabChange = useHandleMatchingTargetsTabChange();

  const handleClick = React.useCallback(() => {
    handleMatchingTargetsTabChange(MATCHING_TARGETS_TABS.CATALOG);
  }, [handleMatchingTargetsTabChange]);

  return (
    <Alert
      type="info"
      banner
      message={(
        <div>
          {translations["matching.target.suggestions.cant_find.tooltip"].split("{{button}}")[0]}
          <Button type="link" style={{ padding: 0 }} onClick={handleClick}>{translations["matching.target.suggestions.cant_find.button"]}</Button>
          {translations["matching.target.suggestions.cant_find.tooltip"].split("{{button}}")[1]}
        </div>
      )}
    />
  );
})
