import { TaxonomyItem } from "types/entities/Taxonomy";

import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";

import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

export const getDiagnosticTestItemName = (taxonomyItem: TaxonomyItem) => {
  const panelTypeRelations = taxonomyItem.relations?.[DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE];

  if (!panelTypeRelations?.length) return getTaxonomyItemName(taxonomyItem);

  return panelTypeRelations
    .sort((a, b) => a.name && b.name
      ? a.name.localeCompare(b.name)
      : a.id.localeCompare(b.id))
    .reduce<string>((acc, panelTypeRel, idx) => {
      return `${acc}${panelTypeRel.name || panelTypeRel.id}${idx === panelTypeRelations.length - 1 ? "" : ", "}`;
    }, "");
}
