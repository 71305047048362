import React from "react";
import { Tag, Tooltip } from "antd";

export const TaxonomyItemCodeBox = React.memo<{ type: string; code: string }>(({ type, code }) => {
  if (!code || !type) return null;

  return (
    <Tooltip title={type}>
      <Tag style={{ margin: 0 }} className="max-w-full whitespace-normal cursor-help">{code}</Tag>
    </Tooltip>
  );
});
