import React from "react";
import { useParams } from "react-router-dom";
import { getTaxonomyClassChain } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyClassChain";
import { MatchingParamsType } from "router/navigationTypes";

import { useGetJob } from "modules/JobsModule/api/useGetJob";
import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

const defaultTabsTaxonomyClasses = [
  TaxonomyClass.MEDICAL_PRODUCT,
  TaxonomyClass.FOOD_PRODUCT,
  TaxonomyClass.RETAIL_PRODUCT,
  TaxonomyClass.DIAGNOSTIC_TEST,
];

export const useMatchingTargetsClassTabs = (): TaxonomyClass[] => {
  const { jobId = "" } = useParams<MatchingParamsType>();
  const { job } = useGetJob(jobId);

  const rootClassesToMatch: Set<string> = React.useMemo(() => {
    const rootClassesSet = new Set<string>();
    const filterByPrematch = job?.filter_by_prematch || [];

    filterByPrematch.forEach(
      (item) => {
        const parents = getTaxonomyClassChain(item.type === GenericTaxonomyClass ? item.value : item.type);
        parents.forEach((parent) => rootClassesSet.add(parent.toLowerCase()));
      }
    );

    return rootClassesSet;
  }, [job?.filter_by_prematch]);

  return React.useMemo(() => {
    if (!rootClassesToMatch.size) return defaultTabsTaxonomyClasses;

    return defaultTabsTaxonomyClasses
      .filter(item => rootClassesToMatch.has(item.toLowerCase()))
      .sort((a, b) => {
        const indexOfA = Array.from(rootClassesToMatch).indexOf(a.toLowerCase());
        const indexOfB = Array.from(rootClassesToMatch).indexOf(b.toLowerCase());
        return indexOfA - indexOfB;
      });
  }, [rootClassesToMatch]);
}
