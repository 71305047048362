import React from "react";
import { useFilterContext } from "context/FilterContext/Filter.context";

import { initialFacets, matchingFacetsKeys } from "modules/MatchingModule/consts/matchingFacets";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";

export const useInitialFacets = () => {
  const [isInitialFacetReady, setIsInitialFacetReady] = React.useState(false);

  const urlQueryService = useMatchingUrlQueryService();
  const { allowedFilterParams } = useFilterContext();

  React.useEffect(() => {
    if (isInitialFacetReady) return;

    setIsInitialFacetReady(true);
    if (allowedFilterParams.includes(matchingFacetsKeys.STATUS)) return;

    urlQueryService.setSearchParam(matchingFacetsKeys.STATUS, initialFacets[matchingFacetsKeys.STATUS]);
  }, [allowedFilterParams, isInitialFacetReady, urlQueryService]);

  return isInitialFacetReady;
};
