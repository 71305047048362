import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import {
  DIAGNOSTIC_TEST_RELATION,
  FOOD_BRAND_RELATION,
  FOOD_PRODUCT_LINE_RELATION,
  FOOD_PRODUCT_RELATION,
  MEDICAL_BRAND_RELATION,
  MEDICAL_PRODUCT_RELATION,
  RETAIL_BRAND_RELATION,
  RETAIL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { getRelationsKeysFromConfig } from "./getRelationsKeysFromConfig";

// TODO: separate service/helper for chained relations (e.g. MedicalProduct -> DrugClass) (used here and in facets)
const relationsByTaxonomyClass: Record<string, { [key: string]: string }> = {
  [TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()]: {
    [TaxonomyClass.SPECIES]: MEDICAL_PRODUCT_RELATION.TO_SPECIES,
    [TaxonomyClass.DRUG_CLASS]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_DRUG_CLASS}`,
  },
  [TaxonomyClass.FOOD_PRODUCT.toLowerCase()]: {
    [TaxonomyClass.SPECIES]: FOOD_PRODUCT_RELATION.TO_SPECIES,
    [TaxonomyClass.FOOD_CLASS]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}/${FOOD_BRAND_RELATION.TO_FOOD_CLASS}`,
  },
  [TaxonomyClass.RETAIL_PRODUCT.toLowerCase()]: {
    [TaxonomyClass.SPECIES]: RETAIL_PRODUCT_RELATION.TO_SPECIES,
    [TaxonomyClass.RETAIL_CLASS]: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_RETAIL_CLASS}`,
  },
  [TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase()]: {
    [TaxonomyClass.PANEL_TYPE]: DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE,
    [TaxonomyClass.MANUFACTURER]: DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER,
    [TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]: DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION,
  },
};

export const getRelationsMapForMatching = (taxonomyClass: string): { [key: string]: string } => {
  if (!relationsByTaxonomyClass[taxonomyClass.toLowerCase()]) return {};

  return relationsByTaxonomyClass[taxonomyClass.toLowerCase()];
};

export const getRelationsKeysForMatching = (taxonomyClass: string): string[] => {
  const relationsKeysForTaxonomyItemName = getRelationsKeysFromConfig(taxonomyClass);
  return [Object.values(getRelationsMapForMatching(taxonomyClass)), relationsKeysForTaxonomyItemName].flat(1);
};
