import { MatchGroup } from "types/entities/Match";

import React from "react";

import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import {
  MatchingTableAdditionalDetails
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableAdditionalDetails";
import {
  MatchingTableRowExtensionTransactions
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableRowExtension/MatchingTableRowExtensionTransactions";

export const MatchingTableRowExtension = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const { isTransactions } = useCheckDataset();

  return (
    <div>
      {isTransactions
        ? <MatchingTableRowExtensionTransactions matchGroup={matchGroup} />
        : <MatchingTableAdditionalDetails matchGroup={matchGroup}/>
      }
    </div>
  );
});
