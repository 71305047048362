import { Job } from "types/entities/Jobs";

import { getProvidersAndClinicsFromClinicsIds } from "modules/JobsModule/components/JobCard/helpers/getProvidersAndClinicsFromClinicsIds";

export const getProvidersAndClinicsFromJob = (job: Job): { providers: string[], clinics: string[] } => {
  const { providers: parsedProviders, clinics } = getProvidersAndClinicsFromClinicsIds(job.clinics || []);

  const providers = parsedProviders?.length ? parsedProviders : job.providers || [];

  return { providers, clinics };
}
