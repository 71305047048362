import React from "react";
import { Typography } from "antd";
import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";
import { useIsPath } from "router/hooks/useIsPath";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { TaxonomyBreadcrumbs } from "modules/TaxonomyModule/components/TaxonomyBreadcrumbs";
import { TaxonomyItemAbstractTag } from "modules/TaxonomyModule/components/TaxonomyItemAbstractTag";
import { TaxonomyItemStatusTag } from "modules/TaxonomyModule/components/TaxonomyItemStatusTag";

import { Preloader, PreloaderType } from "ui/Preloader";

import { NoNameError } from "components/NoNameError";

import { TaxonomyItemReadHeaderProps } from "./TaxonomyItemReadHeader.types";

export const TaxonomyItemReadHeader = React.memo<TaxonomyItemReadHeaderProps>(({ taxonomyItem, isLoading }) => {
  const { components: { DetailHeaderControls } } = useCatalogContext();
  const { isInCatalog } = useIsPath();

  const itemName = React.useMemo(
    () => getTaxonomyItemName(taxonomyItem) || <NoNameError itemID={taxonomyItem?.id} />,
    [taxonomyItem]
  );

  const breadcrumbItems = React.useMemo(
    () => [{ title: <Typography.Text>{itemName}</Typography.Text> }],
    [itemName]
  );

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={{ rows: 1 }} />

  return (
    <div>
      <TaxonomyBreadcrumbs breadcrumbItems={breadcrumbItems} />
      <div className="flex flex-col gap-4 mb-6 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
          <Typography.Title level={isInCatalog ? 1 : 3} style={{ margin: 0 }}>{itemName}</Typography.Title>
          <div>
            <TaxonomyItemStatusTag status={taxonomyItem?.status} />
            <TaxonomyItemAbstractTag isAbstract={taxonomyItem?.is_abstract} />
          </div>
        </div>
        {DetailHeaderControls && <DetailHeaderControls taxonomyItem={taxonomyItem} />}
      </div>
    </div>
  );
});
