export type ValidationErrors = Array<{
  loc: Array<string | number>;
  msg: string;
  type: string;
}>;

export type ResponseError = {
  detail: string | ValidationErrors;
};

export type PaginatedResponse<T> = {
  data: T[];
  nextPage: number | null;
};

export enum ResponseStatus {
  SUCCESS = 200,
  BAD_REQUEST = 400,
}
