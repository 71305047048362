import React from "react";
import { Empty, Input, Space, Tree, TreeProps } from "antd";
import clsx from "clsx";
import { buildTree } from "helpers/treeHelpers/buildTree";
import { useTranslations } from "translations/useTranslations";

import { prepareCountAndTitle } from "./helpers/prepareCountAndTitle";

import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import {
  formatterFacetsValuesFromBEToFEFormat
} from "modules/FacetsModule/helpers/formatterFacetsValuesFromBEToFEFormat";

import { Preloader, PreloaderType } from "ui/Preloader";

import { useSearch } from "./hooks/useSearch";
import { TreeCheckboxFacetProps } from "./TreeCheckboxFacet.types";

import styles from "./TreeCheckboxFacet.module.scss";

const concatUniqueElements = (array1: string[], array2: string[]) => Array.from(new Set([...array1, ...array2]));

const MAX_HEIGHT = 328;
const INPUT_HEIGHT = 32;
const GAP_SIZE = 8;
const SEARCH_HEIGHT = INPUT_HEIGHT + GAP_SIZE;

export const TreeCheckboxFacet: React.FC<TreeCheckboxFacetProps> = ({
  isFlatTree,
  treeData: initialTreeData = [] as FacetsValueFlatTreeType["value"],
  facetSectionChange,
  checkedKeys,
  isLoading
}) => {
  const translations = useTranslations();
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);
  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  React.useEffect(() => {
    setExpandedKeys(checkedKeys);
    setAutoExpandParent(true);
    // Dependency is empty to make it run once without more hustle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = React.useCallback(
    (newCheckedKeysValues: string[]) => {
      facetSectionChange(newCheckedKeysValues);
      if (checkedKeys.length < newCheckedKeysValues.length) {
        setExpandedKeys(concatUniqueElements(newCheckedKeysValues, expandedKeys as string[]));
        setAutoExpandParent(true);
      }
    },
    [checkedKeys.length, expandedKeys, facetSectionChange]
  );

  const { onSearchChange, searchedTreeData, searchedFlatData } = useSearch(
    initialTreeData,
    setExpandedKeys,
    setAutoExpandParent
  );

  const memoTreeData = React.useMemo(() => {
    const treeDataForFormatter = (isFlatTree ? searchedFlatData : searchedTreeData) || [];
    const nodeTrees = formatterFacetsValuesFromBEToFEFormat(treeDataForFormatter) || [];
    const treeFormat = Object.values(buildTree(nodeTrees).tree);
    treeFormat.map((el) => prepareCountAndTitle(el));

    return treeFormat;
  }, [isFlatTree, searchedFlatData, searchedTreeData]);

  if (!initialTreeData?.length) {
    return (
      <div style={{ height: MAX_HEIGHT }} className="flex items-center justify-center">
        {isLoading
          ? <Preloader type={PreloaderType.SPINNER_BLOCK} />
          : <Empty description={translations["item.table.facets.empty"]} />
        }
      </div>
    )
  }

  return (
    <Space direction="vertical" className="w-full">
      {!!initialTreeData.length && (
        <div style={{ height: INPUT_HEIGHT }}>
          <Input.Search placeholder="input search text" onChange={onSearchChange}/>
        </div>
      )}
      {memoTreeData.length === 0 || !memoTreeData ? (
        <div className="flex items-center justify-center">
          <Empty />
        </div>
      ) : (
        <div className="overflow-y-auto overflow-x-hidden" style={{ maxHeight: MAX_HEIGHT - SEARCH_HEIGHT }}>
          <Tree
            className={clsx(styles.common, isFlatTree && styles.flatTree)}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            multiple
            checkable
            onCheck={onChange as TreeProps["onCheck"]}
            checkedKeys={checkedKeys}
            selectable={false}
            treeData={memoTreeData}
            blockNode
          />
        </div>
      )}
    </Space>
  );
};
