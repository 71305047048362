import { Job, JobStatus } from "types/entities/Jobs";

import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useUpdateJobStatus } from "modules/JobsModule/api/useUpdateJobStatus";
import { useShowDeleteConfirm } from "modules/JobsModule/hooks/useShowDeleteConfirm";

export const JobDeleteButton = React.memo<{ job: Job }>(({ job }) => {
  const translation = useTranslations();
  const jobsDeleteEnabled = useFeatureFlag(FeatureFlagNames.JOBS_DELETE);

  const { updateJobStatus } = useUpdateJobStatus(job.id);
  const showDeleteConfirm = useShowDeleteConfirm(() => updateJobStatus(JobStatus.DELETED));

  if (!jobsDeleteEnabled) return null;

  return (
    <Button type="text" danger icon={<DeleteOutlined />} onClick={showDeleteConfirm}>
      {translation["jobs.card.button.delete"]}
    </Button>
  );
});
