import React from "react";
import { useParams } from "react-router-dom";
import { Input } from "antd";
import { useSearch } from "hooks/useSearch";
import { ListOfTaxonomyParamsType } from "router/navigationTypes";

import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";

import { useTaxonomySearchPlaceholder } from "./hooks/useTaxonomySearchPlaceholder";

export const SEARCH_KEY = "search";

export const TaxonomySearch = React.memo(() => {
  const { taxonomyClass = "" } = useParams<ListOfTaxonomyParamsType>();

  const urlQueryService = useTaxonomyUrlQueryService();
  const { inputValue, queryValue, handleChange } = useSearch(urlQueryService.getValueByKey(SEARCH_KEY) as string || "")

  React.useEffect(() => {
    urlQueryService.setSearchParam(SEARCH_KEY, queryValue);
  }, [urlQueryService, queryValue]);

  const placeholder = useTaxonomySearchPlaceholder(taxonomyClass);

  return (
    <Input.Search
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
    />
  );
});
