import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { MatchingTargetsDiagnosticsFormValues } from "../MatchingTargetsDiagnosticsModule.types";

import { getCleanListValues } from "./getCleanListValues";

export const mapValuesFEtoBE = (
  values: MatchingTargetsDiagnosticsFormValues,
  additionalRelations?: Record<string, string[]>
) => ({
  taxonomy_item_body: {
    class: TaxonomyClass.DIAGNOSTIC_TEST,
    relations: {
      [DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE]: getCleanListValues(values[TaxonomyClass.PANEL_TYPE]),
      [DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER]: getCleanListValues(values[TaxonomyClass.MANUFACTURER]),
      [DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION]: getCleanListValues(
        values[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]
      ),
      ...additionalRelations,
    },
  },
});
