export enum QueryKeys {
  GET_LIST_TAXONOMY_ITEMS = "getListOfTaxonomyItems",
  GET_TAXONOMY_ITEM = "getTaxonomyItem",
  GET_BY_ENDPOINT = "getByEndpoint",
  GET_LIST_SYNONYM_ITEMS = "getSynonymItemsList",
  GET_LIST_MATCH_GROUPS = "getMatchingList",
  GET_LIST_MATCH_ITEMS = "getMatchingItems",
  GET_LIST_FACETS_VALUES = "getListFacetsValues",
  GET_ITEM_PRICE = "getItemPrice",
  GET_ITEM_CODES = "getItemCodes",
  PATCH_MATCH_GROUP = "patchMatchGroup",
  PATCH_UPDATE_PRE_MATCH = "patchUpdatePreMatch",
  GET_FEATURE_FLAGS_CONFIG_BY_ROLE = "GetFeatureFlagsConfigByRoles",
  GET_TAXONOMY_FEATURE_FLAGS_CONFIG_BY_ROLE = "GetTaxonomyFeatureFlagsConfigByRoles",
  GET_MATCHING_FEATURE_FLAGS_CONFIG_BY_ROLE = "GetMatchingFeatureFlagsConfigByRoles",
  GET_JOBS_FEATURE_FLAGS_CONFIG_BY_ROLE = "GetJobsFeatureFlagsConfigByRoles",
  GET_ACTIVE_JOB_LIST = "getActiveJobList",
  GET_COMPLETED_JOB_LIST = "getCompletedJobList",
  GET_JOB_ITEM = "getJobItem",
}
