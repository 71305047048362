import { MatchGroup } from "types/entities/Match";

import React from "react";

import {
  useListenForTextSelection
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTable/hooks/useListenForTextSelection";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const useClickOnRow = () => {
  const { isSelectingText } = useListenForTextSelection();
  const { selectedGroupIds, matchGroupsList } = useMatchingContext();
  const lastSelectedIdRef = React.useRef<number | null>(null);

  return React.useCallback((e: React.MouseEvent, matchGroup: MatchGroup, selectMatchGroups: (ids: number[]) => void) => {
    if (isSelectingText) return;

    let newSelectedGroupIds: number[] = [];
    let affectedGroups: MatchGroup[] = [];

    if (e.shiftKey) {
      const lastSelectedGroupIndex = matchGroupsList.findIndex((group) => group.group_id === (lastSelectedIdRef.current));
      const currentSelectedGroupIndex = matchGroupsList.findIndex((group) => group.group_id === selectedGroupIds[0]);

      let startIndex = 0;

      if (lastSelectedGroupIndex > 0) {
        startIndex = lastSelectedGroupIndex;
      } else if (currentSelectedGroupIndex > 0) {
        startIndex = currentSelectedGroupIndex;
      }

      const newSelectedIndex = matchGroupsList.findIndex((group) => group.group_id === matchGroup.group_id);

      affectedGroups = matchGroupsList.slice(Math.min(startIndex, newSelectedIndex), Math.max(startIndex, newSelectedIndex) + 1);
    } else {
      affectedGroups = [matchGroup];
    }

    if (e.ctrlKey && selectedGroupIds.includes(matchGroup.group_id)) {
      newSelectedGroupIds = selectedGroupIds.filter(
        (id) => affectedGroups.findIndex((group) => group.group_id === id) === -1
      );
    } else if (e.ctrlKey || e.shiftKey || e.metaKey) {
      newSelectedGroupIds = Array.from(
        new Set([...selectedGroupIds, ...affectedGroups.map((group) => group.group_id)])
      );
    } else if (selectedGroupIds.includes(matchGroup.group_id) && selectedGroupIds.length === 1) {
      newSelectedGroupIds = [];
    } else {
      newSelectedGroupIds = affectedGroups.map((group) => group.group_id);
    }

    lastSelectedIdRef.current = matchGroup.group_id;
    selectMatchGroups(newSelectedGroupIds);
  }, [isSelectingText, matchGroupsList, selectedGroupIds]);
}
