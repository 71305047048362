import React from "react";
import { Collapse } from "antd";
import { useTranslations } from "translations/useTranslations";

import { ActiveJobsModule } from "modules/JobsModule/ActiveJobsModule";
import { ArchivedJobsModule } from "modules/JobsModule/ArchivedJobsModule";
import { CompletedJobsModule } from "modules/JobsModule/CompletedJobsModule";
import { DeletedJobsModule } from "modules/JobsModule/DeletedJobsModule";

export const JobsLists = React.memo<{ isJobManager?: boolean }>(({ isJobManager }) => {
  const translations = useTranslations();

  return (
    <div>
      <div className="mb-6">
        <ActiveJobsModule isJobsManager={isJobManager} />
      </div>
      <div>
        <Collapse className="bg-white">
          <Collapse.Panel header={translations["jobs.completed.title"]} key="completed">
            <CompletedJobsModule isJobsManager={isJobManager} />
          </Collapse.Panel>
          {isJobManager && (
            <>
              <Collapse.Panel header={translations["jobs.archived.title"]} key="archived">
                <ArchivedJobsModule isJobsManager={isJobManager} />
              </Collapse.Panel>
              <Collapse.Panel header={translations["jobs.deleted.title"]} key="deleted">
                <DeletedJobsModule isJobsManager={isJobManager} />
              </Collapse.Panel>
            </>
          )}
        </Collapse>
      </div>
    </div>
  );
});
