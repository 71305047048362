import { TaxonomyItem } from "types/entities/Taxonomy";

import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";

import { findAndRemoveRegExp } from "./helpers/findAndRemoveRegExp";
import { getInstructionsWithArrayValues } from "./helpers/getInstructionsWithArrayValues";
import { getInstructionsWithPairedValue } from "./helpers/getInstructionsWithPairedValue";
import { getInstructionsWithSeparators } from "./helpers/getInstructionsWithSeparators";
import { getInstructionsWithValue } from "./helpers/getInstructionsWithValue";
import { setupOrder } from "./helpers/setupOrder";

import { buildStringByTemplateWithInstructions } from "./buildStringByTemplate";
import {
  valueBetweenBracesRegExp,
  valueBetweenPairsRegExp,
  valueBetweenSquareBracketsRegExp,
  valueSeparatorRegExp,
} from "./generateByUIConfiguration.consts";
import { InstructionResultPartialType } from "./generateByUIConfiguration.types";
import { UIConfigurationTemplates } from "./UIConfiguration";

const buildInstructionsByTemplate = (template: string, item: Partial<TaxonomyItem>): InstructionResultPartialType[] => {
  const [newStr0, instructionsForPairedValues] = findAndRemoveRegExp(template, valueBetweenPairsRegExp);
  const [newStr1, instructionsForArrayReplacement] = findAndRemoveRegExp(newStr0, valueBetweenSquareBracketsRegExp);
  const [newStr2, instructionsForReplacement] = findAndRemoveRegExp(newStr1, valueBetweenBracesRegExp);
  const [, instructionsForSeparatorReplacement] = findAndRemoveRegExp(newStr2, valueSeparatorRegExp);

  const instructionsWithPairedValue = getInstructionsWithPairedValue(instructionsForPairedValues, item);
  const instructionsWithValue = getInstructionsWithValue(instructionsForReplacement, item);
  const instructionsWithArrayValue = getInstructionsWithArrayValues(instructionsForArrayReplacement, item);
  const instructionsWithSeparators = getInstructionsWithSeparators(instructionsForSeparatorReplacement);

  return [...instructionsWithValue, ...instructionsWithArrayValue, ...instructionsWithPairedValue, ...instructionsWithSeparators];
};

export const generateInstructionsByUIConfiguration = (template: string, item: Partial<TaxonomyItem>) => {
  const instructionResult = buildInstructionsByTemplate(template, item);

  return setupOrder(template, instructionResult);
};

export const generateStringByTemplate = (template: string, item: Partial<TaxonomyItem>) => {
  const instructions = generateInstructionsByUIConfiguration(template, item);
  return buildStringByTemplateWithInstructions(template, instructions);
};

export const generateNameByConfiguration = (taxonomyClass: string, item: TaxonomyItem) => {
  if (taxonomyClass in UIConfigurationTemplates.read) {
    const template = UIConfigurationTemplates.read[taxonomyClass].name;
    return generateStringByTemplate(template, item);
  }
  return getTaxonomyItemName(item);
};
