import { TaxonomyItem } from "types/entities/Taxonomy";

import { getRelationOnSelf } from "helpers/expandHelpers/getRelationWithExpandOnSelf";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { FacetsValueFlatTreeType, NodeTree } from "modules/FacetsModule/FacetsModule.types";

const extractParentFromRelations = (sectionValue: FacetsValueFlatTreeType["value"][0] | TaxonomyItem) => {
  if ("relations" in sectionValue && sectionValue.relations) {
    // example of data: { relations: { hasParent: [{ name: 'name', id: 'id' }] } }
    // we need this part { name: 'name', id: 'id' }

    const [taxonomyClass] = getItemClassFromItemId(sectionValue.id);
    // in example above: relationNameToSelf = hasParent
    const relationNameToSelf = getRelationOnSelf(taxonomyClass);

    const relation = sectionValue.relations[relationNameToSelf];
    if (!relation || !relation.length) return null;
    return relation.map((el) => ({ title: el.name || el.id, key: el.id }))[0];
  }
  return null;
};

export const formatterFacetsValuesFromBEToFEFormat = (
  facetSectionValues: FacetsValueFlatTreeType["value"] | TaxonomyItem[]
): NodeTree[] | undefined => {
  if (!facetSectionValues) return undefined;

  return facetSectionValues
    ?.sort(
      (a, b) => a.name && b.name
        ? ((a.name || "").toLowerCase() > (b.name || "").toLowerCase() ? 1 : -1)
        : a.id.localeCompare(b.id)
    )
    ?.map((sectionValue) => {
      return {
        // TODO: check types for ((sectionValue as TaxonomyItem).title as string)
        title: ((sectionValue as TaxonomyItem).title as string) || (sectionValue.name || "").trim(),
        key: sectionValue.id,
        parent: extractParentFromRelations(sectionValue),
        count: (sectionValue.count as number) || 0,
      };
    });
};
