import { MatchStatus } from "types/entities/Match";

import React from "react";
import { Progress, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { formatNumberWithK } from "helpers/formatNumberWithK";
import { useTranslations } from "translations/useTranslations";

import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import { useGetFacetsValuesWithoutContext } from "modules/FacetsModule/hooks/useGetFacetsValues";
import { MatchingProgressProps } from "modules/MatchingModule/components/MatchingProgress/MatchingProgress.types";
import { matchingFacetsKeys } from "modules/MatchingModule/consts/matchingFacets";
import { ignoredStatuses, matchedStatuses } from "modules/MatchingModule/consts/statusLists";
import { useGenerateMatchingFacetsApiUrl } from "modules/MatchingModule/hooks/useGenerateMatchingFacetsApiUrl";

import { Preloader, PreloaderType } from "ui/Preloader";

import { MatchingProgressTooltipContent } from "./MatchingProgressTooltipContent";

export const MatchingProgress = React.memo((props: MatchingProgressProps) => {
  const { jobId, datasetName, className, showText = false } = props;
  const translations = useTranslations();

  const generateApiUrl = useGenerateMatchingFacetsApiUrl(jobId, datasetName);

  const { getAllowedFilterParamsWithExcludedKeys } = useFilterContext();
  const filterUrl = generateApiUrl(
    matchingFacetsKeys.STATUS,
    getAllowedFilterParamsWithExcludedKeys([matchingFacetsKeys.STATUS])
  );
  const { data, isFacetsValuesLoading: isLoading } = useGetFacetsValuesWithoutContext(
    matchingFacetsKeys.STATUS,
    filterUrl
  );

  const countAll = React.useMemo(
    () =>
      (data as FacetsValueFlatTreeType)?.value?.reduce((acc, { count, id = 0 }) => {
        if (ignoredStatuses.includes(id as MatchStatus)) return acc;
        return acc + count;
      }, 0),
    [data]
  );

  const countMatched = React.useMemo(
    () =>
      (data as FacetsValueFlatTreeType)?.value?.reduce((acc, { id, count = 0 }) => {
        if (matchedStatuses.includes(id as MatchStatus)) return acc + count;
        return acc;
      }, 0),
    [data]
  );

  const percent = React.useMemo(
    () => (countAll ? Math.round((countMatched / countAll) * 100) : 0),
    [countAll, countMatched]
  );

  return (
    <div className={clsx("flex gap-4 items-center", className)}>
      {isLoading && <Preloader type={PreloaderType.SPINNER} className="shrink-0" size="small" />}
      {showText && !isLoading && (
        <Typography.Text className="whitespace-nowrap font-bold">
          {translations["matching.progress.title"]}: {formatNumberWithK(countMatched)}/{formatNumberWithK(countAll)}
        </Typography.Text>
      )}
      <Tooltip
        title={<MatchingProgressTooltipContent values={(data as FacetsValueFlatTreeType).value} />}
        placement="bottom"
      >
        <Progress percent={percent} showInfo={!showText || percent === 100} className="m-0" />
      </Tooltip>
    </div>
  );
});
