import React from "react";
import { Typography } from "antd";
import clsx from "clsx";

import { BreadcrumbProps } from "ui/Breadcrumb/Breadcrumb.types";

export const Breadcrumb = React.memo<BreadcrumbProps>(({ items, className }) => {
  if (!items?.length) return null;

  return (
    <nav aria-label="breadcrumb" className={clsx("mb-4", className)}>
      <ol className="flex items-baseline list-none m-0 p-0">
        {items.map((item, index) => (
          <React.Fragment key={`item.title-${index}`}>
            <li>{item.title}</li>
            {index !== items.length - 1 && (
              <li aria-hidden="true">
                <Typography.Text className="mx-2">/</Typography.Text>
              </li>
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  )
})
