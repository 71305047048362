import { EMPTY } from "consts/strings";

const dateOnlyRegex = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");

export const formatDate = (stringDate: string | undefined | null, options?: { hasTime?: boolean; hasSeconds?: boolean }): string => {
  if (!stringDate) return EMPTY;

  const date = new Date(stringDate.match(dateOnlyRegex) ? `${stringDate}T00:00:00` : stringDate);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString(
    [],
    { hour: "2-digit", minute: "2-digit", second: options?.hasSeconds ? "2-digit" : undefined }
  );

  return `${formattedDate}${options?.hasTime ? ` ${formattedTime}` : ""}`;
}
