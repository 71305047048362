import React from "react";
import { Modal } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

export const useShowDeleteItemPromiseConfirm = (itemID: string, deleteCb?: () => Promise<{}>) => {
  const translations = useTranslations();

  const [taxonomyClass = ""] = getItemClassFromItemId(itemID);
  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  const title = translations["item.popup.delete.title"].replace("{{taxonomyClass}}", taxonomyItemConfig?.title || taxonomyClass);
  const content = translations["item.popup.delete.content"];
  const okText = translations["item.popup.delete.button.ok"];

  return React.useCallback(() => {
    Modal.confirm({
      title,
      content,
      okText,
      onOk: deleteCb,
      onCancel() {},
    });
  }, [title, content, okText, deleteCb]);
}
