import React from "react";
import { Templates } from "@rjsf/antd";
import { WidgetProps } from "@rjsf/utils";
import { useTranslations } from "translations/useTranslations";

export const BaseInputTemplate = React.memo<WidgetProps>((props) => {
  const translations = useTranslations();

  const onChange = React.useCallback((value: any) => {
    props.onChange(value ?? undefined);
  }, [props]);

  if (!Templates.BaseInputTemplate) return null;

  const placeholder = `${translations["item.form.input.placeholder"]} ${props.schema.title?.toLowerCase()}`
  return <Templates.BaseInputTemplate {...props} onChange={onChange} placeholder={placeholder} />;
});
