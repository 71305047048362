import { TaxonomyItem } from "types/entities/Taxonomy";

import { valueBetweenBracesRegExp } from "../generateByUIConfiguration.consts";
import { InstructionResultForm, InstructionResultPartialType } from "../generateByUIConfiguration.types";

import { findAndRemoveRegExp } from "./findAndRemoveRegExp";
import { getTemplateValue } from "./getInstructionsWithValue";

export const getInstructionsWithPairedValue = (
  instructions: RegExpMatchArray[],
  item: Partial<TaxonomyItem>
): InstructionResultPartialType[] => {
  return instructions.map(([withBrackets, insideBrackets]) => {
    const [, instructionsForReplacement] = findAndRemoveRegExp(insideBrackets, valueBetweenBracesRegExp);
    const values = instructionsForReplacement.map(([, insideBracket]) => getTemplateValue(insideBracket, item));

    return {
      template: { withBrackets, insideBrackets },
      form: InstructionResultForm.PAIR,
      content: values,
    };
  });
};
