import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { CatalogLinkWithPrevent } from "components/CatalogLinkWithPrevent";
import { RelationsInlineList } from "components/RelationsInlineList";

export const ItemReadRowValue = React.memo<{
  taxonomyItem: TaxonomyItem | TaxonomyItem[] | undefined,
  separator?: string,
}>(
  ({ taxonomyItem, separator }) => (
    <RelationsInlineList taxonomyItem={taxonomyItem} separator={separator} LinkComponent={CatalogLinkWithPrevent} />
  )
);
