import { TaxonomyItem } from "types/entities/Taxonomy";

import { useMemo } from "react";
import { getRelationWithExpandOnSelf } from "helpers/expandHelpers/getRelationWithExpandOnSelf";
import { buildTree } from "helpers/treeHelpers/buildTree";

import { getUnlimitedPaginationParams, taxonomyPartOfUrl } from "api/rootUrl";
import { useEndpoint } from "api/useEndpoint";

import { formatterFacetsValuesFromBEToFEFormat } from "../helpers/formatterFacetsValuesFromBEToFEFormat";

const EMPTY_VALUE: TaxonomyItem[] = [];
export const useInitialTreeWithEndpointData = (taxonomyClass: string = "") => {
  const { data: { data } = { data: EMPTY_VALUE }, isLoading } = useEndpoint<{ data: TaxonomyItem[] }>(
    taxonomyPartOfUrl +
      taxonomyClass.toLowerCase() +
      "/?" +
      getUnlimitedPaginationParams() +
      "&" +
      getRelationWithExpandOnSelf(taxonomyClass.toLowerCase())
  );

  const { treeMap: initialTreeMap } = useMemo(
    () => buildTree(formatterFacetsValuesFromBEToFEFormat(data) || []),
    [data]
  );

  return { endpointData: data, initialTreeMap, isLoading };
};
