import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { rootPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

export const BackHomeButton = React.memo(() => {
  const translations = useTranslations();
  return (
    <Link to={rootPath}>
      <Button type="primary">{translations["back.home"]}</Button>
    </Link>
  );
});
