import React from "react";
import { theme } from "antd";
import { formatNumberWithK } from "helpers/formatNumberWithK";

import { NodeTree } from "modules/FacetsModule/FacetsModule.types";

export const FacetOption = React.memo(({ title, count }: { title: NodeTree["title"]; count: number }) => {
  const { token } = theme.useToken();

  return (
    <div className="flex flex-row justify-between">
      <div className="overflow-hidden text-ellipsis">{title}</div>
      <div style={{ color: token.colorTextDescription }}>{formatNumberWithK(count)}</div>
    </div>
  );
});
