import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Form } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { SelectTaxonomyItem } from "components/SelectTaxonomyItem";

export const ManufacturerSelector = React.memo(() => {
  const translations = useTranslations();
  const { getTaxonomyItemByName } = configurationServiceInstance;

  const filterManufacturers = React.useCallback(
    (items: TaxonomyItem[]) => items.filter(({ isLaboratory }) => isLaboratory),
    []
  );

  return (
    <Form.Item
      name={TaxonomyClass.MANUFACTURER}
      label={getTaxonomyItemByName(TaxonomyClass.MANUFACTURER)?.title || TaxonomyClass.MANUFACTURER}
    >
      <SelectTaxonomyItem
        taxonomyClass={TaxonomyClass.MANUFACTURER}
        filterOptions={filterManufacturers}
        placeholder={translations["matching.diagnostics.form.manufacturer.placeholder"]}
        allowClear
      />
    </Form.Item>
  );
});
