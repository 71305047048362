import React from "react";
import { Tabs, TabsProps } from "antd";

import {
  MatchingTargetsClassTabItem
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabItem";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { useMatchingTargetsClassTabsContext } from "./MatchingTargetsClassTabs.context";

export const MatchingTargetsClassTabs = React.memo(() => {
  const { activeTaxonomyClass, availableTaxonomyClasses, changeActiveTaxonomyClass } = useMatchingTargetsClassTabsContext();

  const items: TabsProps["items"] = React.useMemo(() => availableTaxonomyClasses.map((taxonomyClass) => ({
    key: taxonomyClass.toLowerCase(),
    label: (
      <MatchingTargetsClassTabItem
        taxonomyClass={taxonomyClass}
        showMatchToClass={taxonomyClass === TaxonomyClass.DIAGNOSTIC_TEST}
      />
    ),
  })), [availableTaxonomyClasses]);

  const handleChange = React.useCallback((key: string) => {
    changeActiveTaxonomyClass(key);
  }, [changeActiveTaxonomyClass]);

  return (
    <Tabs items={items} type="card" activeKey={activeTaxonomyClass} onChange={handleChange} />
  );
});
