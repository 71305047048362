import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { QueryKeys } from "api/queryKeys";
import { taxonomyUrl } from "api/rootUrl";

import { generateNameByConfiguration } from "modules/GenerateByUIConfigurationModule";

const getItemsByIds = (itemIds: ItemID[]) => () =>
  axios.get<TaxonomyItem[]>(taxonomyUrl, { params: { ids: itemIds.join(",") } }).then((data) => {
    return data.data.map((item) => {
      const [itemClass] = getItemClassFromItemId(item.id);
      return { ...item, generatedName: generateNameByConfiguration(itemClass, item) };
    });
  });

const EMPTY: TaxonomyItem[] = [];
export const useGetItemsByIds = (itemIds: ItemID[]) => {
  const enabled = !!itemIds?.length;

  const {
    data = EMPTY,
    isLoading,
    isError,
  } = useQuery<TaxonomyItem[]>({
    queryKey: [QueryKeys.GET_LIST_MATCH_ITEMS, itemIds],
    queryFn: getItemsByIds(itemIds),
    enabled,
  });

  return { items: data, isLoading: isLoading && enabled, isError };
};
