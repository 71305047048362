import React from "react";
import { ErrorTransformer } from "@rjsf/utils";
import { useTranslations } from "translations/useTranslations";

export const useTransformErrors = (): ErrorTransformer => {
  const translations = useTranslations();

  const errorsMap = React.useMemo(() => ({
    required: translations["item.form.error.required"],
  }), [translations]);

  return (errors) => errors.map(error => {
    if (error.name === "required") return { ...error, message: errorsMap[error.name].replace("{{field}}", error.property || "—") };

    return error;
  });
}
