import { Job } from "types/entities/Jobs";

import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Radio, RadioChangeEvent } from "antd";
import { useTranslations } from "translations/useTranslations";

import { mapInfoToMatchFromFEtoBE } from "./helpers/mapInfoToMatchFromFEtoBE";

import { useCreateJob } from "modules/JobsModule/JobsCreateFormModule/api/useCreateJob";
import { ClinicFormSection } from "modules/JobsModule/JobsCreateFormModule/components/ClinicFormSection";
import { DiscardButton } from "modules/JobsModule/JobsCreateFormModule/components/DiscardButton";
import { MarketFormSection } from "modules/JobsModule/JobsCreateFormModule/components/MarketFormSection";
import { SelectInfoToMatch } from "modules/JobsModule/JobsCreateFormModule/components/SelectInfoToMatch";

import { useDiscardPromptOnLeave } from "./hooks/useDiscardPromptOnLeave";
import { JobsCreateFormValues, JobsFormValueKeys, Source } from "./JobsCreateFormModule.types";

const initialFormValues: Partial<JobsCreateFormValues> = {
  [JobsFormValueKeys._SOURCE]: Source.MARKET,
}

export const JobsCreateFormModule = React.memo(() => {
  const translations = useTranslations();
  const { setShouldPrevent } = useDiscardPromptOnLeave();

  const [form] = Form.useForm();

  const [source, setSource] = React.useState(Source.MARKET);
  const onSourceChange = React.useCallback((e: RadioChangeEvent) => {
    setSource(e.target.value);
  }, []);

  const { createJob, isLoading } = useCreateJob();

  const handleChange = React.useCallback(() => setShouldPrevent(true), [setShouldPrevent])

  const handleSubmit = React.useCallback((values: JobsCreateFormValues) => {
    const data: Partial<Job> = {
      name: values[JobsFormValueKeys.NAME],
      date_from: values[JobsFormValueKeys.PERIOD][0].format("YYYY-MM-DD") || "",
      date_to: values[JobsFormValueKeys.PERIOD][1].format("YYYY-MM-DD") || "",
      filter_by_prematch: mapInfoToMatchFromFEtoBE(values[JobsFormValueKeys.MATCH]),
    }

    if (source === Source.MARKET) {
      data.market = values[JobsFormValueKeys.MARKET];
      data.providers = values[JobsFormValueKeys.PROVIDERS]?.map(provider => provider.name || provider.id) || [];
    }

    if (source === Source.CLINIC) {
      data.clinics = values[JobsFormValueKeys.CLINICS] || [];
    }

    setShouldPrevent(false);
    createJob(data);
  }, [createJob, setShouldPrevent, source]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: 340 }}
        requiredMark="optional"
        initialValues={initialFormValues}
        onValuesChange={handleChange}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={JobsFormValueKeys.NAME}
          label={translations["jobs.form.name.label"]}
          rules={[{ required: true }]}
        >
          <Input placeholder={translations["jobs.form.name.placeholder"]} />
        </Form.Item>
        <Form.Item
          name={JobsFormValueKeys.PERIOD}
          label={translations["jobs.form.period.label"]}
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item
          name={JobsFormValueKeys.MATCH}
          label={translations["jobs.form.info_to_match.label"]}
        >
          <SelectInfoToMatch />
        </Form.Item>
        <Form.Item
          name={JobsFormValueKeys._SOURCE}
          label={translations["jobs.form.source.label"]}
          initialValue={source}
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={onSourceChange}>
            <Radio.Button value={Source.MARKET}>{translations["jobs.form.source.item.market"]}</Radio.Button>
            <Radio.Button value={Source.CLINIC}>{translations["jobs.form.source.item.clinic"]}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {source === Source.MARKET && <MarketFormSection />}
        {source === Source.CLINIC && <ClinicFormSection form={form} />}
        <div className="flex gap-4">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
            icon={<PlusCircleOutlined />}
          >{translations["jobs.form.button.submit"]}</Button>
          <DiscardButton setShouldPrevent={setShouldPrevent} />
        </div>
      </Form>
    </div>
  );
});
