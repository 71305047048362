import { ItemID } from "types/common";
import { Synonym, SynonymType } from "types/entities/Synonym";

import React, { useMemo } from "react";
import { Table } from "antd";
import { prepareClinicCodeForDisplay } from "helpers/entityHelpers/codeHelpers/prepareClinicCodeForDisplay";
import { sortAndPrepareForDisplay } from "helpers/entityHelpers/codeHelpers/sortAndPrepareForDisplay";
import { useTranslations } from "translations/useTranslations";

import { SynonymListItemProps } from "modules/SynonymModule/SynonymListModule/SynonymListModule.types";
import { useDeleteSynonym } from "modules/SynonymModule/SynonymUpdateModule/api/useDeleteSynonym";

import { SecondaryLabelledList } from "components/SecondaryLabelledList";

import { DeleteSynonymButton } from "../DeleteSynonymButton";

const SynonymListExactItem = React.memo<SynonymListItemProps>(({ synonymType, taxonomyItemId, synonym }) => {
  const { deleteSynonym } = useDeleteSynonym({ taxonomyItemId, synonymItemId: synonym.id });
  const translations = useTranslations();

  const items = useMemo(() => {
    const standardCodes = sortAndPrepareForDisplay(synonym.standard_codes);
    const clinicCode = prepareClinicCodeForDisplay(synonym.clinic_code, translations);
    return [...standardCodes, clinicCode[0]].filter(Boolean);
  }, [synonym.clinic_code, synonym.standard_codes, translations]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-1 gap-2 items-center">
        <span className="w-1/2">{synonym.value}</span>
        <div className="flex flex-col w-1/2">
          <SecondaryLabelledList items={items} />
        </div>
      </div>
      <div>
        <DeleteSynonymButton handleDelete={deleteSynonym} />
      </div>
    </div>
  );
});

export const SynonymExactTable = React.memo<{ synonymType:SynonymType, items: Synonym[]; taxonomyItemId: ItemID }>(({ synonymType, items, taxonomyItemId }) => {
  const translations = useTranslations();
  const columns = [
    {
      key: "exactSynonymRow",
      render: (_: any, synonym: Synonym) => {
        return <SynonymListExactItem synonymType={synonymType} taxonomyItemId={taxonomyItemId} synonym={synonym} />;
      },
    },
  ];

  const expandableProps = useMemo(() => {
    const isExpandableNeed = items.some((synonym) => Boolean(synonym.clinic_code?.code_classification));

    if (!isExpandableNeed) return {};

    return {
      expandable: {
        expandedRowRender: (synonym: Synonym) => (
          <div className="w-full">
            <SecondaryLabelledList
              className="w-full"
              items={[prepareClinicCodeForDisplay(synonym.clinic_code, translations)[1]]}
            />
          </div>
        ),
        rowExpandable: (synonym: Synonym) => Boolean(synonym.clinic_code?.code_classification),
      },
    };
  }, [items, translations]);

  return <Table columns={columns} dataSource={items} pagination={false} rowKey="id" size="small" {...expandableProps} />;
});
