import { TaxonomyConfiguration } from "types/appConfig";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";

export const getTaxonomyItemName = (taxonomyItem: TaxonomyItem): string => {
  if (!taxonomyItem) return "";
  const [taxonomyClass] = getItemClassFromItemId(taxonomyItem.id)
  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;
  const propertyInsteadOfName = taxonomyConfig?.meta?.propertyInsteadOfName;

  if (Object.hasOwn(taxonomyItem, "generatedName") || Object.hasOwn(taxonomyItem, "name")) {
    return taxonomyItem?.generatedName || taxonomyItem?.name || taxonomyItem.id;
  }

  if (propertyInsteadOfName) {
    const propInsteadOfNameValue = taxonomyItem[propertyInsteadOfName];
    return Array.isArray(propInsteadOfNameValue) ? propInsteadOfNameValue.join(", ") : `${propInsteadOfNameValue}`;
  }

  return taxonomyItem?.id || "";
}
