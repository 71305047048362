import { configurationServiceInstance } from "services/ConfigurationService";

export const getTaxonomyClassChain = (taxonomyClass: string): string[] => {
  const parents: string[] = [taxonomyClass];
  const taxonomyItems = configurationServiceInstance.getTaxonomyItems();

  if (!taxonomyItems) return parents;

  for (const [parentClass, parentClassConfig] of Object.entries(taxonomyItems)) {
    if (parentClass.toLowerCase() === taxonomyClass.toLowerCase()) continue;

    const relations = parentClassConfig.meta.relations || {};
    const hasRelationToChild = Object.values(relations).some((relation) => {
      return relation.to.toLowerCase() === taxonomyClass.toLowerCase();
    });

    if (hasRelationToChild) {
      parents.push(parentClass);
      const parentParents = getTaxonomyClassChain(parentClass);
      parents.push(...parentParents);
    }
  }

  return parents;
};
