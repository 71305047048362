import { ClinicCode } from "types/entities/Code";

import { EMPTY } from "consts/strings";

const MAX_CLINIC_CODE_CLASSIFICATION_LENGTH = 15;

export const prepareClinicCodeForDisplay = (
  clinicCode: undefined | ClinicCode,
  translations: Record<string, string>
) => {
  const code = clinicCode?.code || "";
  const code_classification = clinicCode?.code_classification || "";

  const codeClassificationValueShort = code_classification.length > MAX_CLINIC_CODE_CLASSIFICATION_LENGTH
    ? `${code_classification.slice(0, MAX_CLINIC_CODE_CLASSIFICATION_LENGTH)}...`
    : code_classification;

  return [
    {
      title: translations["matching.table.clinic_code.label"].toUpperCase(),
      value: code || EMPTY,
    },
    {
      title: translations["matching.table.clinic_classification.label"].toUpperCase(),
      value: codeClassificationValueShort || EMPTY,
      valueTitle: code_classification.length > MAX_CLINIC_CODE_CLASSIFICATION_LENGTH
        ? code_classification
        : undefined,
    },
  ];
};
