import React from "react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { getAuthRequest } from "services/AuthService";

import { Preloader, PreloaderType } from "ui/Preloader";

import { useFeatureFlagsContext } from "./featureFlags/FeatureFlag.context";
import { RolesNames } from "./featureFlags/roles.types";

export const AuthModule = React.memo<{ children: JSX.Element }>(({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const [accessToken, setAccessToken] = React.useState<string>("");
  const [isAxiosReady, setIsAxiosReady] = React.useState<boolean>(false);
  const account = accounts[0];

  const accessTokenRequest = React.useMemo(() => ({ ...getAuthRequest(), account }), [account]);

  const { setRole } = useFeatureFlagsContext();

  React.useEffect(() => {
    if (accessTokenRequest && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          setAccessToken(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [instance, accounts, inProgress, accessTokenRequest]);

  React.useEffect(() => {
    if (accessToken && account) {
      axios.interceptors.request.use(async (config) => {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        config.headers.Authorization = `Bearer ${response.accessToken}`;

        const roleName = account.idTokenClaims?.roles?.[0];
        if (roleName) setRole(roleName as RolesNames);
        return config;
      });

      setIsAxiosReady(true);
    }
  }, [accessToken, accessTokenRequest, account, instance, setRole]);

  if (!accessToken || !account || !isAxiosReady) return <Preloader type={PreloaderType.SPINNER} />;

  return children;
});
