export enum FeatureFlagNames {
  CATALOG_VIEW_MAIN = "navigateToCatalogue",
  CATALOG_VIEW_LIST = "listItemsInCatalogue",
  CATALOG_VIEW_DETAIL = "displayItem",
  CATALOG_VIEW_CHANGELOG = "seeItemChangelog",
  CATALOG_ITEM_CREATE = "createNewItem",
  CATALOG_ITEM_EDIT = "editItem",
  CATALOG_ITEM_DELETE = "deleteItem",
  CATALOG_ITEM_DEACTIVATE = "deactivateItem",
  CATALOG_SYNONYM_CREATE = "addSynonym",
  CATALOG_SYNONYM_EDIT = "editSynonym",
  CATALOG_SYNONYM_DELETE = "deleteSynonym",
  MATCHING_VIEW_MAIN = "navigateToMatching",
  MATCHING_VIEW_GROUPS = "displayTransactionGroups",
  MATCHING_VIEW_SUGGESTIONS = "seeSuggestions",
  MATCHING_VIEW_MATCHES = "seeMatches",
  MATCHING_VIEW_CREATION_REQUESTS = "viewItemCreationRequests",
  MATCHING_VIEW_REVIEW_REQUESTS = "viewRequestsForReview",
  MATCHING_ACTION_MATCH = "matchTransaction",
  MATCHING_ACTION_CORRECT_MATCH = "correctAutomaticMatches",
  MATCHING_ACTION_CLASSIFY = "changeTransactionClassification",
  MATCHING_ACTION_REQUEST_CREATE = "createRequestItemCreation",
  MATCHING_ACTION_REQUEST_REVIEW = "createRequestItemReview",
  MATCHING_ACTION_APPROVE = "approveMatch",
  MATCHING_ACTION_REJECT = "rejectMatch",
  JOBS_VIEW_MAIN = "navigateToJobs",
  JOBS_CREATE = "createJob",
  JOBS_COMPLETE = "completeJob",
  JOBS_DELETE = "deleteJob",
  JOBS_SELECT_AREAS = "selectJobAreas",
}

export type FeatureFlagsConfigType = Record<FeatureFlagNames, boolean>;
