import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Space } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { getTaxonomyReadPath } from "router/path";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { generateNameByConfiguration } from "modules/GenerateByUIConfigurationModule";
import { TaxonomyItemAbstractTag } from "modules/TaxonomyModule/components/TaxonomyItemAbstractTag";
import { TaxonomyItemStatusTag } from "modules/TaxonomyModule/components/TaxonomyItemStatusTag";

import { NoNameError } from "components/NoNameError";

export const CustomNameColumn = React.memo<{
  taxonomyItem: TaxonomyItem;
  value: string;
}>(({ taxonomyItem }) => {
  const {
    components: { CatalogLink },
  } = useCatalogContext();
  const [taxonomyClass] = getItemClassFromItemId(taxonomyItem.id);
  return (
    <Space direction="vertical" className="max-w-full">
      <CatalogLink to={getTaxonomyReadPath(taxonomyItem.id).toLowerCase()} className="break-words">
        {generateNameByConfiguration(taxonomyClass, taxonomyItem) || <NoNameError itemID={taxonomyItem.id} />}
      </CatalogLink>
      <TaxonomyItemStatusTag status={taxonomyItem.status} />
      <TaxonomyItemAbstractTag isAbstract={taxonomyItem.is_abstract} />
    </Space>
  );
});
