import React from "react";
import { Switch, theme, Typography } from "antd";
import clsx from "clsx";

import {
  useMatchingInformationSourceContext
} from "modules/MatchingModule/MatchingInformationSourceModule/MatchingInformationSource.context";

import { Preloader, PreloaderType } from "ui/Preloader";

export const SelectProviders = React.memo<{ containerClass: string }>(({ containerClass }) => {
  const { providers, isLoading, isError, selectedProviders, switchProvider } = useMatchingInformationSourceContext();

  const { token } = theme.useToken();

  if (isLoading) return <Preloader type={PreloaderType.SPINNER_BLOCK} className="flex items-center" />;
  if (isError) return null;

  return (
    <>
      {providers.map((provider) => {
        const isSelected = selectedProviders.includes(provider);
        return (
          <div
            key={provider}
            className={clsx(containerClass)}
            style={{
              borderColor: token.colorBorder,
              ...(isSelected ? { backgroundColor: token.colorBgContainer } : {}),
            }}
          >
            <Typography.Text strong>{provider}</Typography.Text>
            <Switch checked={isSelected} onChange={() => switchProvider(provider)} />
          </div>
        );
      })}
    </>
  );
});
