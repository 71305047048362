import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const useTaxonomySearchPlaceholder = (taxonomyClass: string) => {
  const translations = useTranslations();

  if (taxonomyClass.toLowerCase() === TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()) {
    return translations["item.search.placeholder.by"]
      .replace("{{taxonomyClass}}", getTaxonomyClassTitle(TaxonomyClass.MEDICAL_BRAND));
  }

  if (taxonomyClass.toLowerCase() === TaxonomyClass.FOOD_PRODUCT.toLowerCase()) {
    return translations["item.search.placeholder.by"]
      .replace("{{taxonomyClass}}", getTaxonomyClassTitle(TaxonomyClass.FOOD_PRODUCT_LINE));
  }

  return translations["item.search.placeholder.for"]
    .replace("{{taxonomyClass}}", getTaxonomyClassTitle(taxonomyClass));
}
