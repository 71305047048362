import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import diagnosticsImage from "assets/images/catalog-diagnostics.svg";
import foodImage from "assets/images/catalog-food.svg";
import medicationsImage from "assets/images/catalog-medications.svg";
import retailImage from "assets/images/catalog-retail.svg";

export const mainItemsCoverMap = new Map([
  [TaxonomyClass.MEDICAL_PRODUCT.toLowerCase(), medicationsImage],
  [TaxonomyClass.FOOD_PRODUCT.toLowerCase(), foodImage],
  [TaxonomyClass.RETAIL_PRODUCT.toLowerCase(), retailImage],
  [TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase(), diagnosticsImage],
]);
