import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useMatchingCreateRequestButton } from "./useMatchingCreateRequestButton";

export const MatchingCreateRequestButton = React.memo(() => {
  const translations = useTranslations();
  const { createRequest, showCreateRequestButton } = useMatchingCreateRequestButton();

  if (!showCreateRequestButton) return null;

  return (
    <Button icon={<PlusCircleOutlined />} size="small" onClick={createRequest}>
      {translations["matching.buttons.request_item"]}
    </Button>
  );
});
