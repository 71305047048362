import React from "react";

import { DetailHeaderControlsProps } from "modules/CatalogModule/CatalogModule.types";
import { MatchingDuplicateButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingDuplicateButton";
import { MatchingMatchButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingMatchButton";

import { Preloader, PreloaderType } from "ui/Preloader";

export const MatchingCatalogDetailHeaderControls = React.memo<DetailHeaderControlsProps>((props) => {
  const { taxonomyItem } = props;

  if (!taxonomyItem) return <Preloader type={PreloaderType.SKELETON_BUTTON} />;

  return (
    <div className="flex gap-2 w-full">
      <MatchingDuplicateButton itemID={taxonomyItem?.id} className="w-1/2" />
      <MatchingMatchButton itemID={taxonomyItem?.id} taxonomyItem={taxonomyItem} className="w-1/2" />
    </div>
  );
});
