import { FacetSectionConfiguration } from "modules/FacetsModule/FacetsModule.types";
import { FacetsServiceType } from "modules/FacetsModule/FacetsService";

export const getDefaultActiveKey = (
  facetSectionsConfiguration: FacetSectionConfiguration,
  facetsService: FacetsServiceType
): string[] => {
  return facetSectionsConfiguration
    .filter(
      (facetSectionElement) =>
        (facetsService.getValuesByKey(facetSectionElement.key) || [])
          .length !== 0
    )
    .map((facetSectionElement) => facetSectionElement.key);
};
