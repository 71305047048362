import React from "react";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

export const useGetStaticFacetItemTitle = (facetKey: string) => {
  const translations = useTranslations();

  return React.useCallback((itemID: string) => {
    return configurationServiceInstance.getTaxonomyItemByName(itemID)?.title
      || translations[`static.facets.${facetKey.toLowerCase()}.${itemID.toLowerCase()}`]
      || itemID;
  }, [facetKey, translations]);
}
