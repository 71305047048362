// https://create-react-app.dev/docs/adding-custom-environment-variables
// There is also a built-in environment variable called NODE_ENV.
// You can read it from process.env.NODE_ENV. When you run npm start, it is always equal to 'development',
// when you run npm test it is always equal to 'test', and when you run npm run build to make a production bundle,
// it is always equal to 'production'. You cannot override NODE_ENV manually.
// This prevents developers from accidentally deploying a slow development build to production.

import { ItemID } from "types/common";
import { JobStatus } from "types/entities/Jobs";
import { SynonymType } from "types/entities/Synonym";

import { API_GW_URL } from "env";

export const rootTaxonomyUrl = process.env.NODE_ENV === "production" ? `${API_GW_URL}/taxonomy` : "/taxonomy";

export const rootMatchingUrl = process.env.NODE_ENV === "production" ? `${API_GW_URL}/matches` : "/matches";

export const rootJobsUrl = process.env.NODE_ENV === "production" ? `${API_GW_URL}/jobs` : "/jobs";

export const taxonomyApiUrl = `${rootTaxonomyUrl}/api`;
export const matchingApiUrl = `${rootMatchingUrl}/api`;
export const jobsApiUrl = `${rootJobsUrl}/api`;

export const configurationUrl = `${taxonomyApiUrl}/configuration/`;
export const taxonomyFeatureFlagsConfigByRolesUrl = `${taxonomyApiUrl}/acl/`;
export const matchingFeatureFlagsConfigByRolesUrl = `${matchingApiUrl}/acl/`;
export const jobsFeatureFlagsConfigByRolesUrl = `${jobsApiUrl}/acl/`;

export const taxonomyPartOfUrl = "/item/";

export const taxonomyUrl = `${taxonomyApiUrl}${taxonomyPartOfUrl}`;
export const getTaxonomyUrlForItem = (taxonomyClass: string) => `${taxonomyUrl}${taxonomyClass}`;

export const getSynonymUrl = (taxonomyItemId: ItemID) => `${taxonomyApiUrl}/${taxonomyItemId}/synonym`;
export const getSynonymUrlWithParams = (
  taxonomyItemId: ItemID,
  synonymType: SynonymType,
  paginationParams: string,
  searchValue?: string
) =>
  `${getSynonymUrl(taxonomyItemId)}?type=${synonymType}&${paginationParams}${
    searchValue ? `&search=${searchValue}` : ""
  }`;

export const getUrlToItemByItemID = (itemID: ItemID) => `${taxonomyApiUrl}/${itemID}/`;

export const getItemPriceUrl = (itemID: ItemID) => `${getUrlToItemByItemID(itemID)}price`;
export const getItemRelationsUrl = (itemID: ItemID) => `${getUrlToItemByItemID(itemID)}relations`;
export const getItemCodesListUrl = (itemID: ItemID) => `${getUrlToItemByItemID(itemID)}code`;
export const getItemCodeUrl = (itemID: ItemID, codeID: string) => `${getItemCodesListUrl(itemID)}/${codeID}/`;

export const getFacetsUrl = (facetApiUrl: string, fieldsKeys: string) => `${facetApiUrl}/facets?fields=${fieldsKeys}`;
export const matchingUrl = `${matchingApiUrl}/matches/`;

export const getUrlToMatchingList = (jobId: string, datasetName: string) => `${matchingUrl}${jobId}/${datasetName}`;
export const getUrlToUpdatePremMatch = (jobId: string, datasetName: string) =>
  `${getUrlToMatchingList(jobId, datasetName)}/move`;

export const getUnlimitedPaginationParams = () => "limit=-1";

export const expandWithKeys = (keys: string | string[]) => {
  if (keys.length === 0) return "";
  return Array.isArray(keys) ? `expand=${keys.join(",")}` : `expand=${keys}`;
};

export const PAGINATION_LIMIT = 10;
export const MATCHING_PAGINATION_LIMIT = 50;
export const getPaginationParams = (pageParam: number = 1, limit: number = PAGINATION_LIMIT) => {
  return `page=${pageParam}&limit=${limit}`;
};

export const getMatchingFilterPart = (filterParams?: string, pageParam?: number, limit = MATCHING_PAGINATION_LIMIT) =>
  `/filter?${getPaginationParams(pageParam, limit)}${filterParams ? `&${filterParams}` : ""}`;

export const getFilterPart = (filterParams: string, pageParam: number, expandWithKeys: string) => {
  const compositeFilter = [filterParams, getPaginationParams(pageParam), expandWithKeys]
    .filter((el) => Boolean(el))
    .join("&");
  return `${filterParams ? "filter" : ""}?${compositeFilter}`;
};

export const getJobListUrl = (statuses?: JobStatus[]) => `${jobsApiUrl}/job/${statuses?.length ? `?${statuses.map((status) => `status=${status}`).join("&")}` : ""}`;
export const getJobUrl = (jobId: string) => `${getJobListUrl()}${jobId}`;
