import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { configurationServiceInstance } from "services/ConfigurationService";

export const getTaxonomyRelationTitleFromChain = (taxonomyClass: string, relationChain: string): string | null => {
  const relationChainArr = relationChain.split("/").filter(Boolean);
  const currentRelationKey = relationChainArr.shift();

  if (!currentRelationKey) return null;

  if (relationChainArr.length > 0) {
    const nextTaxonomyClass = configurationServiceInstance.getTaxonomyItemRelationName(taxonomyClass, currentRelationKey)
    if (!nextTaxonomyClass) return null;
    return getTaxonomyRelationTitleFromChain(nextTaxonomyClass, relationChainArr.join("/"));
  }

  return getTaxonomyRelationTitle(taxonomyClass, currentRelationKey);
}
