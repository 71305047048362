import { RelationsForm } from "types/entities/Relations";
import { TaxonomyItem } from "types/entities/Taxonomy";
import { FormTaxonomyData } from "types/formDataTypes";

import React, { useCallback, useMemo } from "react";
import JsonForm from "@rjsf/antd";
import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { FormContext } from "antd/es/form/context";
import { JSONSchema7 } from "json-schema";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { changeFromBEToFEFormat } from "./helpers/changeFromBEToFEFormat";
import { createFormDataFromSchemaWithRelations } from "./helpers/createFormDataFromSchemaWithRelations";
import { createSchemaWithRelationsProperties } from "./helpers/createSchemaWithRelationsProperties";
import { generateUiSchema } from "./helpers/generateUiSchema";
import { useTransformErrors } from "./helpers/useTransformErrors";

import { BaseInputTemplate } from "modules/TaxonomyModule/CommonTaxonomyFormModule/components/BaseInputTemplate";
import { createTaxonomyItemFromFormData } from "modules/TaxonomyModule/CommonTaxonomyFormModule/helpers/createTaxonomyItemFromFormData";
import { useErrorSchemaWithTranslations } from "modules/TaxonomyModule/hooks/useErrorSchemaWithTranslations";

import { FormControls } from "./components/FormControls";
import { useDiscardPromptOnLeave } from "./hooks/useDiscardPromptOnLeave";
import { useShowTaxonomyError } from "./hooks/useShowTaxonomyError";
import { CommonTaxonomyFormModuleProps } from "./CommonTaxonomyFormModule.types";

const AntdFormContext = React.memo<{ children: JSX.Element }>(({ children }) => {
  const itemRefPlug = () => () => {};
  return (
    // Example of using https://github.com/ant-design/ant-design/blob/master/components/form/Form.tsx
    // requiredMark - using inside
    // https://github.com/ant-design/ant-design/blob/master/components/form/FormItem/ItemHolder.tsx
    // FormItem - you can see inside
    // @rjsf/antd https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/antd/src/templates/FieldTemplate/index.tsx
    <FormContext.Provider value={{ requiredMark: "optional", vertical: true, itemRef: itemRefPlug }}>
      {children}
    </FormContext.Provider>
  );
});

const templates = { BaseInputTemplate };

const DEFAULT_ADDITIONAL_COMPONENTS = { AdditionalPartForCustomTaxonomyReferenceField: () => null };

export const CommonTaxonomyFormModule: React.FC<CommonTaxonomyFormModuleProps> = React.memo(
  ({
    isCreating,
    formData,
    onSubmitCallback,
    errorSchema,
    taxonomyClass,
    additionalComponents = DEFAULT_ADDITIONAL_COMPONENTS,
    showDuplication = true,
  }) => {
    const translations = useTranslations();
    const showError = useShowTaxonomyError();
    const transformErrors = useTransformErrors();

    const { setShouldPrevent } = useDiscardPromptOnLeave(isCreating, taxonomyClass);

    // This form does not save its state if the parent is rerendered.
    // For this reason we keep actual form state inside localFormData.
    //https://github.com/rjsf-team/react-jsonschema-form/issues/1631
    // https://github.com/rjsf-team/react-jsonschema-form/issues/2252
    // Parent re-rendering occurs when the "cancel" button is pressed inside useDiscardPromptOnLeave,
    // when we create chain, and with error validation.
    const localFormData = React.useRef<TaxonomyItem | undefined>(formData);

    const isDuplicatingRef = React.useRef<boolean>(false);

    const schema = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
    const formattedFormData = useMemo(
      () => changeFromBEToFEFormat({ initialFormData: localFormData.current }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [localFormData.current]
    );
    const formContext = useMemo(
      () => ({ taxonomyItemConfiguration: schema, additionalComponents }),
      [additionalComponents, schema]
    );

    const errorSchemaWithTranslations = useErrorSchemaWithTranslations(errorSchema, schema?.title || taxonomyClass);

    const onSubmit = useCallback(
      async (data: IChangeEvent) => {
        const relationKeys: string[] = schema?.meta?.relations ? Object.keys(schema.meta.relations) : [];
        const formData = createFormDataFromSchemaWithRelations(
          data.formData as Omit<TaxonomyItem, "relations"> & RelationsForm,
          relationKeys
        ) as FormTaxonomyData;

        setShouldPrevent(false);
        onSubmitCallback(formData, isDuplicatingRef.current);
        isDuplicatingRef.current = false;
      },
      [onSubmitCallback, schema, setShouldPrevent]
    );

    const onError = useCallback(() => {
      setShouldPrevent(true);
      showError();
      isDuplicatingRef.current = false;
    }, [setShouldPrevent, showError]);

    if (!schema) return <div>We can't find schema for {taxonomyClass}</div>;

    const schemaWithRelationsProperties = createSchemaWithRelationsProperties(taxonomyClass, schema, translations);
    const uiSchema = generateUiSchema(schema, taxonomyClass);

    return (
      <AntdFormContext>
        <JsonForm
          formContext={formContext}
          uiSchema={uiSchema}
          formData={formattedFormData}
          onSubmit={onSubmit}
          onError={onError}
          onChange={(data, formFieldId) => {
            localFormData.current = createTaxonomyItemFromFormData(data.formData);
            if (formFieldId) setShouldPrevent(true);
          }}
          className="w-full max-w-[340px]"
          schema={schemaWithRelationsProperties as JSONSchema7}
          showErrorList={false}
          transformErrors={transformErrors}
          validator={validator}
          templates={templates}
          extraErrors={errorSchemaWithTranslations}
        >
          <FormControls
            showDuplication={showDuplication}
            isCreating={isCreating}
            title={schema.title?.toLowerCase() || taxonomyClass}
            onDuplicate={() => (isDuplicatingRef.current = true)}
            setShouldPrevent={setShouldPrevent}
          />
        </JsonForm>
      </AntdFormContext>
    );
  }
);

CommonTaxonomyFormModule.displayName = "CommonTaxonomyFormModule";
