import { useCallback } from "react";
import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

import { FacetsServiceType } from "modules/FacetsModule/FacetsService";

import { FacetSectionChangeType } from "../FacetsModule.types";

export const useFacetsSearchParams = (facetsService: FacetsServiceType, urlQueryService: UrlQueryService) => {
  const facetSectionChange: FacetSectionChangeType = useCallback(
    (facetKey, facetValues) => {
      facetsService.setByKey(facetKey, facetValues);
      urlQueryService.setMultipleSearchParams(facetsService.getFacetMap());
    },
    [facetsService, urlQueryService]
  );

  const resetFacetsSearchParams = useCallback(() => {
    facetsService.reset();
    urlQueryService.setMultipleSearchParams(facetsService.getFacetMap());
  }, [facetsService, urlQueryService]);

  return {
    facetsService,
    facetSectionChange,
    resetFacetsSearchParams,
  };
};
