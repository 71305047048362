import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Form, FormInstance } from "antd";
import { useTranslations } from "translations/useTranslations";

import { SelectClinics } from "modules/JobsModule/JobsCreateFormModule/components/SelectClinics";
import { SelectProviders } from "modules/JobsModule/JobsCreateFormModule/components/SelectProviders";
import { JobsFormValueKeys } from "modules/JobsModule/JobsCreateFormModule/JobsCreateFormModule.types";

const MAX_CLINICS = 10;

export const ClinicFormSection = React.memo<{ form: FormInstance }>(({ form }) => {
  const translations = useTranslations();

  const [providerId, setProviderId] = React.useState(form.getFieldValue(JobsFormValueKeys._CLINICS_PROVIDERS)?.[0]?.id || "");
  const onProviderChange = React.useCallback((value: TaxonomyItem[]) => {
    form.resetFields([JobsFormValueKeys.CLINICS]);
    setProviderId(value[0]?.id);
  }, [form]);

  return (
    <>
      <Form.Item
        name={JobsFormValueKeys._CLINICS_PROVIDERS}
        label={translations["jobs.form.providers.label"]}
        rules={[{ required: true }]}
      >
        <SelectProviders type="radio" onChange={onProviderChange} />
      </Form.Item>
      <Form.Item
        name={JobsFormValueKeys.CLINICS}
        label={translations["jobs.form.clinics.label"]}
        rules={[{ required: true, type: "array", max: MAX_CLINICS }]}
      >
        <SelectClinics provider={providerId} />
      </Form.Item>
    </>
  );
});
