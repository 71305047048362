import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getTaxonomyClassPropTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import { getTaxonomyRelationTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { useTranslations } from "translations/useTranslations";

import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { valueAndUnitPairs } from "modules/GenerateByUIConfigurationModule/UIConfiguration/UIConfigurationPairs";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { RetailProductProperties } from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";
import {
  RETAIL_BRAND_RELATION,
  RETAIL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";
import { SubTitle } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/SubTitle";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";
import {
  OtherTaxonomyItemReadSection
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/OtherTaxonomyItemReadSection";

import { ItemReadRowByTemplate } from "../../components/ItemReadRowByTemplate";
import { ItemReadRowValue } from "../../components/ItemReadRowValue";
import { TaxonomyItemWithRelationToSelf } from "../../components/TaxonomyItemWithRelationToSelf";
import { TaxonomyItemReadSectionProps } from "../TaxonomyItemReadSections.types";

import { retailProductPropsKeys, retailProductRelationsKeys } from "./consts";

export const RetailProductTaxonomyItemReadSection = React.memo<TaxonomyItemReadSectionProps>(
  ({ taxonomyItem: retailProduct = {}, fieldsToHide }) => {
    const translations = useTranslations();

    const retailBrandTitle = getTaxonomyRelationTitle(
      TaxonomyClass.RETAIL_PRODUCT,
      RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND
    );

    const { taxonomyItem: retailBrand } = useGetTaxonomyItemByClassAndId(
      (retailProduct?.relations?.[RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND] as TaxonomyItem[])?.[0]?.id || ""
    );

    const retailClass = retailBrand?.relations?.[RETAIL_BRAND_RELATION.TO_RETAIL_CLASS];
    const region = retailBrand?.relations?.[RETAIL_BRAND_RELATION.TO_MARKET];
    const manufacturer = retailBrand?.relations?.[RETAIL_BRAND_RELATION.TO_MANUFACTURER];
    const primaryActiveIngredient =
      retailBrand?.relations?.[RETAIL_BRAND_RELATION.TO_PRIMARY_OTC_ACTIVE_INGREDIENT];
    const secondaryActiveIngredient =
      retailBrand?.relations?.[RETAIL_BRAND_RELATION.TO_SECONDARY_OTC_ACTIVE_INGREDIENT];

    const physicalForm = retailProduct?.relations?.[RETAIL_PRODUCT_RELATION.TO_PHYSICAL_FORM];
    const routeOfAdministration = retailProduct?.relations?.[RETAIL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION];
    const targetSpecies = retailProduct?.relations?.[RETAIL_PRODUCT_RELATION.TO_SPECIES];
    const breadSize = retailProduct?.relations?.[RETAIL_PRODUCT_RELATION.TO_BREED_SIZE];

    return (
      <div>
        <SubTitle text={retailBrandTitle} />
        <TaxonomyItemReadRow title={retailBrandTitle} value={<ItemReadRowValue taxonomyItem={retailBrand} />} />
        <TaxonomyItemWithRelationToSelf
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_BRAND, RETAIL_BRAND_RELATION.TO_RETAIL_CLASS)}
          taxonomyClass={TaxonomyClass.RETAIL_CLASS}
          relationItems={retailClass}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_BRAND, RETAIL_BRAND_RELATION.TO_MARKET)}
          value={<ItemReadRowValue taxonomyItem={region} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_BRAND, RETAIL_BRAND_RELATION.TO_MANUFACTURER)}
          value={<ItemReadRowValue taxonomyItem={manufacturer} />}
        />
        <SubTitle text={translations["item.details.subtitle.package"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_PRODUCT, RETAIL_PRODUCT_RELATION.TO_PHYSICAL_FORM)}
          value={<ItemReadRowValue taxonomyItem={physicalForm} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.PACK_SIZE)}
          value={
            <ItemReadRowByTemplate template={valueAndUnitPairs.retailProductPackSize.template} taxonomyItem={retailProduct} />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.PACK_UNIT_VOLUME)}
          value={
            <ItemReadRowByTemplate
              template={valueAndUnitPairs.retailProductPackUnitVolume.template}
              taxonomyItem={retailProduct}
            />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.DURATION_OF_EFFECT)}
          value={retailProduct?.[RetailProductProperties.DURATION_OF_EFFECT]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(
            TaxonomyClass.RETAIL_PRODUCT,
            RETAIL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION
          )}
          value={<ItemReadRowValue taxonomyItem={routeOfAdministration} />}
        />
        <SubTitle text={translations["item.details.subtitle.utilization"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(
            TaxonomyClass.RETAIL_BRAND,
            RETAIL_BRAND_RELATION.TO_PRIMARY_OTC_ACTIVE_INGREDIENT
          )}
          value={<ItemReadRowValue taxonomyItem={primaryActiveIngredient} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.STRENGTH)}
          value={
            <ItemReadRowByTemplate template={valueAndUnitPairs.retailProductStrength.template} taxonomyItem={retailProduct} />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_BRAND, RETAIL_BRAND_RELATION.TO_SECONDARY_OTC_ACTIVE_INGREDIENT)}
          value={<ItemReadRowValue taxonomyItem={secondaryActiveIngredient} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_PRODUCT, RETAIL_PRODUCT_RELATION.TO_SPECIES)}
          value={<ItemReadRowValue taxonomyItem={targetSpecies} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.RETAIL_PRODUCT, RETAIL_PRODUCT_RELATION.TO_BREED_SIZE)}
          value={<ItemReadRowValue taxonomyItem={breadSize} />}
        />
        <SubTitle text={translations["item.details.subtitle.rep"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REPCATEGORY)}
          value={retailProduct?.[RetailProductProperties.REPCATEGORY]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REP_MANUFACTURER)}
          value={retailProduct?.[RetailProductProperties.REP_MANUFACTURER]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REP_BRAND)}
          value={retailProduct?.[RetailProductProperties.REP_BRAND]}
        />

        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REP_ROA)}
          value={retailProduct?.[RetailProductProperties.REP_ROA]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REP_INCLUDE)}
          value={retailProduct?.[RetailProductProperties.REP_INCLUDE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.RETAIL_PRODUCT, RetailProductProperties.REP_CLASS)}
          value={retailProduct?.[RetailProductProperties.REP_CLASS]}
        />
        <OtherTaxonomyItemReadSection
          taxonomyClass={TaxonomyClass.RETAIL_PRODUCT}
          taxonomyItem={retailProduct}
          requiredPropsKeys={retailProductPropsKeys}
          requiredRelationsKeys={retailProductRelationsKeys}
          fieldsToHide={fieldsToHide}
        />
      </div>
    );
  }
);
