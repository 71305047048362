import React from "react";
import { Outlet } from "react-router-dom";
import { Layout as LayoutAntd } from "antd";

import { Footer } from "ui/Footer";
import { Header } from "ui/Header";

import { ScrollRestoration } from "components/ScrollRestoration";

import styles from "./Layout.module.scss";

const { Header: HeaderAntd, Content, Footer: FooterAntd } = LayoutAntd;

export const Layout = React.memo(() => {
  return (
    <LayoutAntd className={styles.root}>
      <HeaderAntd className="px-6 xl:px-12">
        <Header />
      </HeaderAntd>
      <Content className="grid px-6 xl:px-12">
        <Outlet />
      </Content>
      <FooterAntd>
        <Footer />
      </FooterAntd>
      <ScrollRestoration />
    </LayoutAntd>
  );
});
