import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import { taxonomyApiUrl } from "api/rootUrl";

const getQueryByEndpoint =
  (endpoint: string = "") =>
    () =>
      axios.get(`${taxonomyApiUrl}${endpoint}`).then((result) => result.data);

export const useEndpoint = <T,>(
  endpoint = ""
): { data: T | undefined; isLoading: boolean; isError: boolean; refetch: () => Promise<QueryObserverResult<T>> } => {
  const { data, isLoading, isError, refetch } = useQuery<T>({
    queryKey: [QueryKeys.GET_BY_ENDPOINT, endpoint],
    queryFn: getQueryByEndpoint(endpoint),
    enabled: !!endpoint,
  });

  return { data, isLoading, isError, refetch };
};
