import React from "react";
import { ReadOutlined } from "@ant-design/icons";
import { theme, Typography } from "antd";
import { useIsPath } from "router/hooks/useIsPath";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { mainItemsCoverMap } from "modules/CatalogModule/consts/mainItems";
import { useMainAndRestCatalogItems } from "modules/CatalogModule/helpers/useMainAndRestCatalogItems";

import { Preloader, PreloaderType } from "ui/Preloader";

import { CatalogItem } from "./components/CatalogItem";

const GridWrapper = React.memo<{ children: JSX.Element[] }>(({ children }) => {
  const { isInMatching } = useIsPath();

  const className = isInMatching
    ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4"
    : "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4";

  return (
    <div className={className}>{children}</div>
  );
});

export const CatalogModule = React.memo<{ filterKeys?: string[] }>(() => {
  const itemsList = configurationServiceInstance.getTaxonomyItems();
  const translations = useTranslations();

  const { mainItems, restItems } = useMainAndRestCatalogItems(mainItemsCoverMap, itemsList);

  const { token } = theme.useToken();

  return (
    <div>
      <div className="mb-6">
        <Typography.Title className="flex gap-3 items-center">
          <ReadOutlined width={40} height={40} style={{ color: token.colorPrimary }} />
          {translations["catalog.title"]}
        </Typography.Title>
      </div>
      {!itemsList && <Preloader type={PreloaderType.SPINNER_BLOCK} />}
      {!!mainItems.length && (
        <div className="mb-10">
          <GridWrapper>
            {mainItems.map(({label, href, cover}) => (
              <CatalogItem key={href} label={label} href={href} cover={cover}/>
            ))}
          </GridWrapper>
        </div>
      )}
      {!!restItems.length && (
        <div>
          <Typography.Title level={3} className="mb-4">{translations["catalog.subtitle"]}</Typography.Title>
          <GridWrapper>
            {restItems.map(({label, href, cover}) => (
              <CatalogItem key={href} label={label} href={href} cover={cover}/>
            ))}
          </GridWrapper>
        </div>
      )}
    </div>
  );
});
