import { MatchGroupPrematch, MatchTarget, MatchTargetFinal } from "types/entities/Match";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { useTranslations } from "translations/useTranslations";

import { useMatchedTaxonomyItem } from "modules/MatchingModule/api/useMatchedTaxonomyItem";
import {
  getCommonProductItems
} from "modules/MatchingModule/components/MatchingItemDetailsList/helpers/getCommonProductItems";
import {
  getDiagnosticTestItems
} from "modules/MatchingModule/components/MatchingItemDetailsList/helpers/getDiagnosticTestItems";
import {
  MatchingItemDetailsListItem
} from "modules/MatchingModule/components/MatchingItemDetailsList/MatchingItemDetailsList.types";
import { checkInformationKind } from "modules/MatchingModule/helpers/checkInformationKind";
import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

type MatchingResultListItemsProps = {
  matchTarget?: MatchTarget | MatchTargetFinal;
  prematchInformationKinds?: string[];
  prematch?: Partial<MatchGroupPrematch>;
};

const getTaxonomyItemClass = ({
  taxonomyItem,
  matchTarget,
  prematchInformationKinds,
}: MatchingResultListItemsProps & { taxonomyItem?: TaxonomyItem }) => {
  if (taxonomyItem) return getItemClassFromItemId(taxonomyItem.id)[0];
  if (matchTarget && "taxonomy_item_body" in matchTarget) return matchTarget.taxonomy_item_body?.class;
  return prematchInformationKinds;
};

export const useMatchingResultListItems = ({
  matchTarget,
  prematchInformationKinds,
  prematch = {},
}: MatchingResultListItemsProps) => {
  const translations = useTranslations();
  const { countryValue } = useInformationSourceInSearchParams();

  const { taxonomyItem, isLoading } = useMatchedTaxonomyItem(matchTarget?.taxonomy_item_id);

  const items = React.useMemo<MatchingItemDetailsListItem[]>(() => {
    const items: MatchingItemDetailsListItem[] = [];

    const taxonomyItemClass = getTaxonomyItemClass({
      taxonomyItem,
      matchTarget,
      prematchInformationKinds
    });
    const { isMedicalProduct, isFoodProduct, isRetailProduct, isDiagnosticTest } = checkInformationKind(taxonomyItemClass);
    const isProduct = isMedicalProduct || isFoodProduct || isRetailProduct;

    if (isProduct) {
      const commonProductItems = getCommonProductItems({
        taxonomyItem,
        prematch,
        translations,
        countryValue,
      });
      items.push(...commonProductItems);
    }

    if (isDiagnosticTest) {
      const diagnosticTestItems = getDiagnosticTestItems({
        taxonomyItem,
        prematch,
        matchTarget
      });
      items.push(...diagnosticTestItems);
    }

    return items.filter(({ value }) => value);
  }, [taxonomyItem, prematchInformationKinds, prematch, translations, countryValue, matchTarget]);

  return { items, isLoading };
}
