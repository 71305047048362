import React from "react";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { CatalogModule } from "modules/CatalogModule";

import { AccessDenied } from "ui/AccessDenied";

export const CatalogPage = React.memo(() => {
  const catalogViewMainEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_VIEW_MAIN);

  if (!catalogViewMainEnabled) return <AccessDenied />;

  return (
    <div className="py-10">
      <CatalogModule />
    </div>
  );
});
