import { Dataset } from "types/entities/Jobs";

import React from "react";
import { useParams } from "react-router-dom";
import { MatchingParamsType } from "router/navigationTypes";
import { useTranslations } from "translations/useTranslations";

import { useGetJob } from "modules/JobsModule/api/useGetJob";

import { Preloader, PreloaderType } from "ui/Preloader";

export const useMatchingListModuleTitle = () => {
  const translations = useTranslations();
  const { jobId = "", datasetName = "" } = useParams<MatchingParamsType>();
  const { job, isLoading } = useGetJob(jobId);

  return React.useMemo(() => {
    if (isLoading || !datasetName) return <Preloader type={PreloaderType.SKELETON_BUTTON} />;

    const titlesMap = {
      [Dataset.TRANSACTIONS]: translations["matching.dataset.transactions.title"],
      [Dataset.SPECIES]: translations["matching.dataset.species.title"],
    };
    return `${job?.name} — ${titlesMap[datasetName]}`;
  }, [datasetName, isLoading, job?.name, translations]);
}
