import React from "react";
import { SPACE } from "consts/strings";

export const Separator = React.memo<{ separator?: string }>(({ separator }) => {
  const needBreak = separator?.includes("\n");
  return separator ? (
    <>
      <div className="flex flex-row">{separator?.replace(new RegExp(" ", "g"), SPACE)}</div>
      {needBreak && <div className="flex basis-full" />}
    </>
  ) : (
    <div className="w-1" />
  );
});
