import { JobStatus } from "types/entities/Jobs";

import React from "react";
import { Empty } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetJobsList } from "modules/JobsModule/api/useGetJobsList";
import { JobCard } from "modules/JobsModule/components/JobCard";
import { getSortedJobs } from "modules/JobsModule/helpers/getSortedJobs";

import { Preloader, PreloaderType } from "ui/Preloader";

export const CompletedJobsModule = React.memo<{ isJobsManager?: boolean }>(({ isJobsManager }) => {
  const translations = useTranslations();

  const { jobList, isLoading } = useGetJobsList([JobStatus.PENDING_COMPLETION, JobStatus.COMPLETED]);
  const sortedJobList = React.useMemo(() => getSortedJobs(jobList), [jobList]);

  return (
    <Preloader type={PreloaderType.SPINNER} spinning={isLoading} size="large">
      {sortedJobList?.length
        ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5 gap-4">
            {sortedJobList.map((job) => <JobCard key={job.id} job={job} isJobsManager={isJobsManager}/>)}
          </div>
        )
        : <Empty description={translations["jobs.completed.empty.title"]} className="flex-grow" />
      }
    </Preloader>
  );
});
