import { configurationServiceInstance } from "services/ConfigurationService";

import { expandWithKeys } from "api/rootUrl";

export const getRelationOnSelf = (taxonomyClass: string) => {
  const configuration = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  const [relationNameToSelf] = Object.entries(configuration?.meta.relations || {}).find(
    ([_, relationConfig]) => relationConfig.to.toLowerCase() === taxonomyClass.toLowerCase()
  ) || [""];

  return relationNameToSelf;
};

export const getRelationWithExpandOnSelf = (taxonomyClass: string) => {
  return expandWithKeys(getRelationOnSelf(taxonomyClass));
};
