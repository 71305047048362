import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { SubmitButtonProps } from "./SubmitButton.types";

export const SubmitButton = (props: SubmitButtonProps) => {
  const { isCreating, taxonomyName } = props;
  const translations = useTranslations();

  const text = isCreating
    ? translations["item.form.button.submit.create"].replace("{{taxonomyName}}", taxonomyName)
    : translations["item.form.button.submit.update"].replace("{{taxonomyName}}", taxonomyName);

  return (
    <Button type="primary" htmlType="submit" icon={isCreating && <PlusOutlined />}>{text}</Button>
  )
}
