import { MatchStatus } from "types/entities/Match";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Alert, Space } from "antd";
import clsx from "clsx";
import { queries } from "consts";

import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useAlertsPropsMap } from "modules/MatchingModule/hooks/useAlertsPropsMap";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

import { DateAndUser } from "./components/DateAndUser";

export const MatchingTargetSpecialModule = React.memo(() => {
  const minLG = useMediaQuery({ query: queries.lg });
  const { commonStatus, matchGroupsList, selectedGroupIds } = useMatchingContext();
  const selectedGroups = React.useMemo(
    () => getSelectedGroups(matchGroupsList, selectedGroupIds),
    [selectedGroupIds, matchGroupsList]
  );

  const alertsPropsMap = useAlertsPropsMap();

  const { dates, users } = React.useMemo(() => selectedGroups.reduce<{ dates: string[], users: string[] }>(
    (acc, group) => {
      if (group.modified_by && !acc.users.includes(group.modified_by)) acc.users.push(group.modified_by);
      if (group.modified_dts) acc.dates.push(group.modified_dts);
      return acc;
    }, { dates: [], users: [] }), [selectedGroups]);

  if (!commonStatus || !(commonStatus in alertsPropsMap)) return null;

  const { content, controls, message, ...rest } = alertsPropsMap[commonStatus as keyof typeof alertsPropsMap];
  const showDateAndUser = commonStatus !== MatchStatus.CONFLICT;
  const showContent = !!content || (minLG && controls);

  return (
    <div className="-mx-6 -mt-[1px] lg:m-0">
      <Alert
        {...rest}
        message={minLG ? message : null}
        description={(
          <div>
            {showDateAndUser && (
              <div className={clsx(showContent && "mb-4")}>
                <DateAndUser dates={dates} users={users}/>
              </div>
            )}
            {showContent && (
              <Space direction="vertical" size="middle" className="w-full">
                {content}
                {minLG && controls}
              </Space>
            )}
          </div>
        )}
        style={{
          ...(!minLG && { borderTopLeftRadius: 0, borderTopRightRadius: 0 }),
        }}
      />
    </div>
  );
});
