import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import {
  getTaxonomyClassPropTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { useTranslations } from "translations/useTranslations";

import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { valueAndUnitPairs } from "modules/GenerateByUIConfigurationModule/UIConfiguration/UIConfigurationPairs";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { FoodProductProperties } from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";
import {
  FOOD_BRAND_RELATION,
  FOOD_PRODUCT_LINE_RELATION,
  FOOD_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";
import { SubTitle } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/SubTitle";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";
import {
  TaxonomyItemWithRelationToSelf
} from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemWithRelationToSelf";
import {
  OtherTaxonomyItemReadSection
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/OtherTaxonomyItemReadSection";

import { Preloader, PreloaderType } from "ui/Preloader";

import { ItemReadRowByTemplate } from "../../components/ItemReadRowByTemplate";
import { ItemReadRowValue } from "../../components/ItemReadRowValue";
import { TaxonomyItemReadSectionProps } from "../TaxonomyItemReadSections.types";

import { foodProductPropsKeys, foodProductRelationsKeys } from "./consts";

export const FoodProductTaxonomyItemReadSection = React.memo<TaxonomyItemReadSectionProps>(
  ({ taxonomyItem: foodProduct = {}, fieldsToHide }) => {
    const translations = useTranslations();

    const { taxonomyItem: foodProductLine, isLoading: isProductLineLoading } = useGetTaxonomyItemByClassAndId(
      (foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE] as TaxonomyItem[])?.[0]?.id || ""
    );

    const { taxonomyItem: foodBrand, isLoading: isFoodBrandLoading } = useGetTaxonomyItemByClassAndId(
      (foodProductLine?.relations?.[FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND] as TaxonomyItem[])?.[0]?.id || ""
    );

    const foodProductLineRel = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE];
    const prescription = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_PRESCRIPTION_OTC];
    const targetSpecies = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_SPECIES];
    const treatmentIndication = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_TREATMENT_INDICATION];
    const flavour = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_FLAVOR];
    const form = foodProduct?.relations?.[FOOD_PRODUCT_RELATION.TO_FOOD_FORM];

    const foodClass = foodBrand?.relations?.[FOOD_BRAND_RELATION.TO_FOOD_CLASS];
    const markets = foodBrand?.relations?.[FOOD_BRAND_RELATION.TO_MARKET];
    const manufacturer = foodBrand?.relations?.[FOOD_BRAND_RELATION.TO_MANUFACTURER];

    return (
      <div>
        <SubTitle text={translations["item.details.subtitle.food_line"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE)}
          value={<ItemReadRowValue taxonomyItem={foodProductLineRel} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_PRESCRIPTION_OTC)}
          value={<ItemReadRowValue taxonomyItem={prescription} />}
        />
        <SubTitle text={translations["item.details.subtitle.food_brand"]} />
        {isProductLineLoading || isFoodBrandLoading ? (
          <Preloader type={PreloaderType.SKELETON} />
        ) : (
          <>
            <TaxonomyItemReadRow
              title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT_LINE, FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND)}
              value={<ItemReadRowValue taxonomyItem={foodBrand} />}
            />
            <TaxonomyItemWithRelationToSelf
              title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_BRAND, FOOD_BRAND_RELATION.TO_FOOD_CLASS)}
              taxonomyClass={TaxonomyClass.FOOD_CLASS}
              relationItems={foodClass}
            />
            <TaxonomyItemReadRow
              title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_BRAND, FOOD_BRAND_RELATION.TO_MARKET)}
              value={<ItemReadRowValue taxonomyItem={markets} />}
            />
            <TaxonomyItemReadRow
              title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_BRAND, FOOD_BRAND_RELATION.TO_MANUFACTURER)}
              value={<ItemReadRowValue taxonomyItem={manufacturer} />}
            />
          </>
        )}
        <SubTitle text={translations["item.details.subtitle.utilization"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_SPECIES)}
          value={<ItemReadRowValue taxonomyItem={targetSpecies} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_TREATMENT_INDICATION)}
          value={<ItemReadRowValue taxonomyItem={treatmentIndication} />}
        />
        <SubTitle text="Package" />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_FLAVOR)}
          value={<ItemReadRowValue taxonomyItem={flavour} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.PACK_QUANTITY)}
          value={
            <ItemReadRowByTemplate
              template={valueAndUnitPairs.foodProductPackQuantity.template}
              taxonomyItem={foodProduct}
            />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.PACK_UNIT_VOLUME)}
          value={
            <ItemReadRowByTemplate
              template={valueAndUnitPairs.foodProductPackUnitVolume.template}
              taxonomyItem={foodProduct}
            />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.FOOD_PRODUCT, FOOD_PRODUCT_RELATION.TO_FOOD_FORM)}
          value={<ItemReadRowValue taxonomyItem={form} />}
        />
        <SubTitle text={translations["item.details.subtitle.rep"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REPCATEGORY)}
          value={foodProduct?.[FoodProductProperties.REPCATEGORY]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REP_MANUFACTURER)}
          value={foodProduct?.[FoodProductProperties.REP_MANUFACTURER]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REP_BRAND)}
          value={foodProduct?.[FoodProductProperties.REP_BRAND]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REP_PRODLINE)}
          value={foodProduct?.[FoodProductProperties.REP_PRODLINE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.GROUP)}
          value={foodProduct?.[FoodProductProperties.GROUP]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.RANGE)}
          value={foodProduct?.[FoodProductProperties.RANGE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.SUB_RANGE)}
          value={foodProduct?.[FoodProductProperties.SUB_RANGE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.LIFESTAGE)}
          value={foodProduct?.[FoodProductProperties.LIFESTAGE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.FOOD_BREED_SIZE)}
          value={foodProduct?.[FoodProductProperties.FOOD_BREED_SIZE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REPORT_INCLUDE)}
          value={foodProduct?.[FoodProductProperties.REPORT_INCLUDE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.MARKETING_PRODUCT_LINE)}
          value={foodProduct?.[FoodProductProperties.MARKETING_PRODUCT_LINE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.FOOD_PRODUCT, FoodProductProperties.REP_CLASS)}
          value={foodProduct?.[FoodProductProperties.REP_CLASS]}
        />
        <OtherTaxonomyItemReadSection
          taxonomyClass={TaxonomyClass.FOOD_PRODUCT}
          taxonomyItem={foodProduct}
          requiredPropsKeys={foodProductPropsKeys}
          requiredRelationsKeys={foodProductRelationsKeys}
          fieldsToHide={fieldsToHide}
        />
      </div>
    );
  }
);
