import { ItemID } from "types/common";

export enum ContentForm {
  TEXT = "text",
  LINK = "link",
}

export type ContentType = { value: string; form: ContentForm; to?: ItemID };

export enum InstructionResultForm {
  ARRAY = "array",
  VALUE = "value",
  PAIR = "pair",
  SEPARATOR = "separator",
}

type CommonPart = { template: { withBrackets: string; insideBrackets: string } };

export type InstructionResultPartialValueType = {
  form: InstructionResultForm.VALUE;
  content: ContentType;
} & CommonPart;

export type InstructionResultPartialArrayType = {
  form: InstructionResultForm.ARRAY;
  content: ContentType[][];
} & CommonPart;

export type InstructionResultPartialPairType = {
  form: InstructionResultForm.PAIR;
  content: ContentType[];
} & CommonPart;

export type InstructionResultPartialSeparatorType = {
  form: InstructionResultForm.SEPARATOR;
  content: ContentType;
} & CommonPart;

export type InstructionResultPartialType =
  | InstructionResultPartialValueType
  | InstructionResultPartialArrayType
  | InstructionResultPartialPairType
  | InstructionResultPartialSeparatorType;

export type InstructionResultType = InstructionResultPartialType & { order: number };

export type ComponentsConfigurationType = {
  getJsxByType: (value: ContentType) => JSX.Element;
  Separator: React.FC<{ separator?: string }>;
};
