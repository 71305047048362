import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { useCreateSynonym } from "./api/useCreateSynonym";

import { SynonymFormModule } from "modules/SynonymModule/SynonymFormModule/SynonymFormModule";

import { SynonymCreateModuleProps } from "./SynonymCreateModule.types";

export const SynonymCreateModule = React.memo<SynonymCreateModuleProps>(({ taxonomyItemId, synonymType }) => {
  const [value, setValue] = React.useState("");
  const [taxonomyClass] = getItemClassFromItemId(taxonomyItemId);
  const { createSynonym, isLoading, errorMessage } = useCreateSynonym({ taxonomyItemId, synonymType }, setValue);

  const handleSave = React.useCallback(
    async (value: string, language: string, scope?: string) => {
      const cleanValue = value.trim();

      if (cleanValue === "") return;

      await createSynonym({ value: cleanValue, language, scope });
    },
    [createSynonym]
  );

  return (
    <SynonymFormModule
      taxonomyClass={taxonomyClass}
      onSubmit={handleSave}
      isBusy={isLoading}
      errorMessage={errorMessage}
      value={value}
      setValue={setValue}
    />
  );
});
