import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { QueryKeys } from "api/queryKeys";
import { expandWithKeys, getUrlToItemByItemID } from "api/rootUrl";

import { generateNameByConfiguration } from "modules/GenerateByUIConfigurationModule";

const getTaxonomyItemByClassAndId = (itemID: ItemID, relationsKeysToExpand?: string[]) => ():Promise<TaxonomyItem> => {
  const filter = relationsKeysToExpand?.length ? expandWithKeys(relationsKeysToExpand) : undefined;
  return axios.get<TaxonomyItem>(`${getUrlToItemByItemID(itemID)}${filter ? `?${filter}` : ""}`)
    .then((data) => {
      const [itemClass] = getItemClassFromItemId(itemID);
      return { ...data.data, generatedName: generateNameByConfiguration(itemClass, data.data) };
    });
};

export const useGetTaxonomyItemByClassAndId = (
  itemID: ItemID,
  relationsKeysToExpand?: string[],
  opts?: Pick<UseQueryOptions, "cacheTime" | "staleTime">
) => {
  const enabled = !!itemID;

  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKeys.GET_TAXONOMY_ITEM, itemID, relationsKeysToExpand?.join(",")],
    queryFn: getTaxonomyItemByClassAndId(itemID, relationsKeysToExpand),
    enabled,
    ...opts,
  });

  return { taxonomyItem: data, isLoading: isLoading && enabled, isError };
};
