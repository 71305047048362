import { TreeNode } from "modules/JobsModule/JobsCreateFormModule/components/SelectInfoToMatch/SelectInfoToMatch.types";

export const getChildrenByPath = (tree: TreeNode[], path: (string | number)[]): string[] => {
  const startNode = tree.find((node) => node.value === path[0]);

  if (!startNode) return [];

  const parentNode = path.reduce<TreeNode>((node, pathItem) => {
    return node.children?.find((child) => child.value === pathItem) || node;
  }, startNode);

  return parentNode?.children?.map((child) => child.value) || [];
}
