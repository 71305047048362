import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { buildJsxComponentsByTemplate } from "modules/GenerateByUIConfigurationModule/buildJsxComponentsByTemplate";

export const CustomWithTemplate = React.memo<{ templates: string[]; taxonomyItem: TaxonomyItem }>(
  ({ templates, taxonomyItem }) => {
    return (
      <>
        {templates.map((template) => (
          <div key={template} className="flex flex-row flex-wrap">
            {buildJsxComponentsByTemplate(template, taxonomyItem)}
          </div>
        ))}
      </>
    );
  }
);
