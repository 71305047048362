import { CodeType } from "types/entities/Code";
import { MatchGroup } from "types/entities/Match";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Typography } from "antd";
import { queries } from "consts";
import { EMPTY } from "consts/strings";
import { getCountryConfig } from "helpers/countryHelpers";
import { prepareClinicCodeForDisplay } from "helpers/entityHelpers/codeHelpers/prepareClinicCodeForDisplay";
import { pluralize } from "helpers/pluralize";
import { getFormattedPrice } from "helpers/priceHelpers";
import { useTranslations } from "translations/useTranslations";

import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { MatchingTableDescriptionCounterTag } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableDescriptionCounterTag";
import { useInformationSourceInSearchParams } from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";
import { useMatchingTargetsClassTabsContext } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";

import { CodeBoxesList } from "components/codeComponents/CodeBoxesList";
import { SecondaryLabelledList } from "components/SecondaryLabelledList";

export const MatchingTableDescriptionCell = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const { match_text } = matchGroup;
  const translations = useTranslations();
  const { countryValue } = useInformationSourceInSearchParams();
  const { isDiagnosticTabSelected } = useMatchingTargetsClassTabsContext();
  const { currencyName } = getCountryConfig(countryValue);
  const { isTransactions } = useCheckDataset();

  const minXXL = useMediaQuery({ query: queries.xxl });

  const { price_avg, quantity_avg, rows_count, codes } = matchGroup.metadata_.metadata || {};

  const filteredStandardCodes = React.useMemo(() => {
    if (!codes?.standard?.length) return [];
    return codes.standard.filter((code) => !isDiagnosticTabSelected || code.type !== CodeType.CLINIC);
  }, [codes?.standard, isDiagnosticTabSelected]);

  const items = React.useMemo(
    () => [
      {
        title: translations["matching.table.avg_price.label"],
        titleAttr: translations["matching.table.avg_price.label.full"],
        value: getFormattedPrice(currencyName, price_avg || 0),
      },
      {
        title: translations["matching.table.avg_sold.label"],
        titleAttr: translations["matching.table.avg_sold.label.full"],
        value: quantity_avg
          ? `${quantity_avg} ${pluralize(translations["matching.table.avg_sold.unit"], quantity_avg)}`
          : EMPTY,
      },
      ...(isDiagnosticTabSelected ? prepareClinicCodeForDisplay(codes?.clinic, translations) : []),
    ],
    [codes?.clinic, currencyName, isDiagnosticTabSelected, price_avg, quantity_avg, translations]
  );

  return (
    <div>
      <div className="flex justify-between gap-2">
        <Typography.Text strong>{match_text || EMPTY}</Typography.Text>
        {rows_count && <MatchingTableDescriptionCounterTag count={rows_count} />}
      </div>
      {!!items.length && isTransactions && (
        <SecondaryLabelledList items={items} horizontal={minXXL} className="pt-2 xxl:flex xxl:gap-2" />
      )}
      {filteredStandardCodes?.length ? <div className="mt-2"><CodeBoxesList codes={filteredStandardCodes} /></div> : null}
    </div>
  );
});
