import { ItemID } from "types/common";
import { PrematchTaxonomyClasses } from "types/entities/Prematch";

import React from "react";
import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";

import { useGetItemsByIds } from "api/useGetItemsByIds";

export const useItemsToMatch = (items: (PrematchTaxonomyClasses | ItemID)[]): { itemsToMatch: string[], isLoading: boolean } => {
  const { classItems, itemIds } = items.reduce<{ classItems: string[], itemIds: string[] }>((acc, item) => {
    if (getItemClassFromItemId(item)[0]) {
      acc.itemIds.push(item);
    } else {
      acc.classItems.push(item);
    }

    return acc;
  }, { classItems: [], itemIds: [] });

  const { items: taxonomyItems, isLoading } = useGetItemsByIds(itemIds);

  const itemsToMatch = React.useMemo(() => [
    ...classItems.map((classItem) => configurationServiceInstance.getTaxonomyItemByName(classItem)?.title || classItem),
    ...taxonomyItems.map((taxonomyItem) => getTaxonomyItemName(taxonomyItem)),
  ], [classItems, taxonomyItems]);

  return { itemsToMatch, isLoading };
};
