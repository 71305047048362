export const formatNumberWithK = (num: number): string => {
  const power = Math.floor(String(num).split("").length / 4);

  if (!power) return String(num);

  let resultNumber = String(Math.round(num / Math.pow(1000, power)));

  for (let index = 0; index < power; index++) {
    resultNumber += "k";
  }

  return resultNumber;
}