import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { TaxonomyItemStatusTag } from "modules/TaxonomyModule/components/TaxonomyItemStatusTag";

import { MatchingMoveHighlighter } from "./MatchingMoveHighlighter";

export const LabelWithIgnore = React.memo<{ taxonomyItem: TaxonomyItem, searchValue?: string }>(({ taxonomyItem, searchValue = "" }) => {
  return (
    <div className="flex justify-between gap-2">
      <div>
        <MatchingMoveHighlighter searchValue={searchValue} text={taxonomyItem.name || ""} />
      </div>
      <TaxonomyItemStatusTag status={taxonomyItem.status} showTooltip={false} />
    </div>
  );
});
