import { RelationsForm } from "types/entities/Relations";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { relationFieldName } from "modules/TaxonomyModule/CommonTaxonomyFormModule/const";

export const createFormDataFromSchemaWithRelations = (
  data: Omit<TaxonomyItem, "relations"> & RelationsForm,
  relationKeys: string[]
) =>
  Object.entries(data).reduce(
    (acc, [key, value]) => {
      if (value === undefined || value === null) return acc;

      if (relationKeys.includes(key)) {
        return {
          ...acc,
          [relationFieldName]: {
            ...acc[relationFieldName],
            [key]: value as string[],
          },
        };
      }

      return { ...acc, [key]: value };
    },
    { [relationFieldName]: {} }
  );
