import { DeepPartial } from "types/common";
import { MatchGroup, MatchGroupPrematch, MatchStatus, MatchTargetFinal } from "types/entities/Match";

import { GenericTaxonomyClass } from "modules/TaxonomyModule/consts";

export const getPatchGroupData = (
  groupId: number,
  { status, final, prematch }: { status?: MatchStatus, final?: MatchTargetFinal[], prematch?: Partial<MatchGroupPrematch> },
  currentProps?: MatchGroup,
): DeepPartial<MatchGroup> => {
  const isChangingGenericTaxonomyClass = prematch?.[GenericTaxonomyClass]?.some(
    (taxonomyClass) => !currentProps?.metadata_?.prematch?.[GenericTaxonomyClass]?.includes(taxonomyClass)
  );
  const shouldRemoveFinal = !final && isChangingGenericTaxonomyClass;

  return {
    group_id: groupId,
    metadata_: {
      match: {
        ...(status && { status }),
        ...(shouldRemoveFinal ? { final: [] } : final && { final }),
      },
      ...(prematch && { prematch }),
    }
  };
}
