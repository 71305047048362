import React from "react";
import { Empty } from "antd";

import { LabelledRow } from "ui/LabelledRow";

import { LabelledListProps } from "./LabelledList.types";

export const LabelledList = React.memo<LabelledListProps>((props) => {
  const { items = [], className, alignValue, emptyMessage } = props;

  return (
    <div className={className}>
      {items.length
        ? items.map(({ label, value, ...rest }, i) => (
          <LabelledRow
            key={`${label}-${value}`}
            className={i < items.length - 1 ? "border-solid border-0 border-b border-b-black/5" : ""}
            label={label}
            value={value}
            alignValue={alignValue}
            {...rest}
          />
        ))
        : <Empty description={emptyMessage} />
      }
    </div>
  );
});
