import React from "react";

import { getCatalogPresetFromPrematch } from "modules/MatchingModule/helpers/getCatalogPresetFromPrematch";
import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import {
  useTaxonomyUrlQueryServiceInMatchingContext
} from "modules/MatchingModule/TaxonomyUrlQueryServiceInMatching.context";

export const useApplyPrematchAsTaxonomyFacets = () => {
  const { selectedGroupIds, matchGroupsList } = useMatchingContext();

  const { taxonomyUrlQueryService } = useTaxonomyUrlQueryServiceInMatchingContext();

  const currentPreset = React.useRef<{[p: string]: string[]} | undefined>();

  React.useEffect(() => {
    const selectedGroups = getSelectedGroups(matchGroupsList, selectedGroupIds);
    const { initialTaxonomyFacet } = getCatalogPresetFromPrematch(selectedGroups[0]?.metadata_?.prematch);
    currentPreset.current = initialTaxonomyFacet;
  }, [matchGroupsList, selectedGroupIds]);

  return React.useCallback(() => {
    if (!taxonomyUrlQueryService) return;

    taxonomyUrlQueryService.clearSearchParams();

    if (!currentPreset.current || Object.keys(currentPreset.current).length === 0) return;

    Object.entries(currentPreset.current).forEach(([key, values]) => {
      taxonomyUrlQueryService.setMultipleSearchParams({ [key]: values });
    });
  } ,[taxonomyUrlQueryService])
}
