import { EMPTY } from "consts/strings";

export const getFormattedPrice = (currencyName: string, price: number) => {
  if (!currencyName) return String(Number(price.toFixed(2)));

  return Number(price.toFixed(2)).toLocaleString(window.navigator.language, {
    style: "currency",
    currency: currencyName || undefined,
  });
};

export const getFormattedPriceRange = (currencyName: string, priceMin?: number | null, priceMax?: number | null) => {
  if (priceMin == null || priceMax == null) return EMPTY;

  return `${getFormattedPrice(currencyName, priceMin)} - ${getFormattedPrice(currencyName, priceMax)}`;
}
