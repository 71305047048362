import { Job } from "types/entities/Jobs";

import React from "react";

import { JobCardStatus } from "modules/JobsModule/components/JobCardStatus";

export const JobCardTitle = React.memo<{ job: Job, isJobManager?: boolean }>(({ job, isJobManager }) => {
  return (
    <div className="flex flex-col items-start gap-3 pr-2 overflow-hidden">
      <div className="whitespace-normal">{job.name}</div>
      {isJobManager && <JobCardStatus job={job} />}
    </div>
  );
});
