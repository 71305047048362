import { ItemID } from "types/common";
import { Code } from "types/entities/Code";

import React from "react";
import { Form, Table } from "antd";

import {
  TaxonomyItemCodesTableEditableCell
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesListModule/components/TaxonomyItemCodesTableEditableCell";

import { useColumns } from "./hooks/useColumns";

export const TaxonomyItemCodesTable = React.memo<{ itemID: ItemID, codes: Code[] }>(({ itemID, codes }) => {
  const [form] = Form.useForm();

  const columns = useColumns(itemID, form, codes);

  return (
    <Form
      form={form}
      component={false}
      onValuesChange={() => { form.validateFields(); }}
    >
      <Table
        columns={columns}
        dataSource={codes}
        pagination={false}
        components={{
          body: {
            cell: TaxonomyItemCodesTableEditableCell,
          },
        }}
        size="middle"
      />
    </Form>
  );
});
