import React from "react";
import { ScrollRestoration as ReactScrollRestoration } from "react-router-dom";
import { matchingPath } from "router/path";

import { CATALOG_PATH_SEPARATOR } from "modules/MatchingModule/consts/paths";

export const ScrollRestoration = React.memo(() => {
  return (
    <ReactScrollRestoration
      getKey={(location) => {
        if (location.pathname.includes(matchingPath)) {
          return location.pathname.split(CATALOG_PATH_SEPARATOR)[0];
        }
        return location.pathname;
      }}
    />
  );
})
