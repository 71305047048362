import React from "react";
import { Space } from "antd";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { DiscardButton } from "../DiscardButton";
import { SubmitAndDuplicateButton } from "../SubmitAndDuplicateButton";
import { SubmitButton } from "../SubmitButton";

type FormControlsProps = {
  isCreating?: boolean;
  title: string;
  onDuplicate: () => void;
  showDuplication: boolean;
  setShouldPrevent: React.Dispatch<React.SetStateAction<boolean>>;
};
export const FormControls = React.memo<FormControlsProps>(
  ({ isCreating, title, onDuplicate, showDuplication, setShouldPrevent }) => {
    const {
      components: { CreateFormControls },
    } = useCatalogContext();

    if (isCreating && CreateFormControls) return <CreateFormControls />;

    return (
      <Space>
        <SubmitButton isCreating={isCreating} taxonomyName={title} />
        {showDuplication && <SubmitAndDuplicateButton isCreating={isCreating} onMouseDown={onDuplicate} />}
        <DiscardButton setShouldPrevent={setShouldPrevent} isCreating={isCreating} taxonomyName={title} />
      </Space>
    );
  }
);
