import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Select, SelectProps } from "antd";

import { useEndpointByTaxonomyClass } from "api/useEndpoint";

import { Preloader, PreloaderType } from "ui/Preloader";

import { SelectTaxonomyItemProps } from "./SelectTaxonomyItem.types";

export const SelectTaxonomyItem = React.memo<SelectTaxonomyItemProps>(({ taxonomyClass, filterOptions, ...rest }) => {
  const { data = [], isLoading } = useEndpointByTaxonomyClass<TaxonomyItem>(taxonomyClass);

  const options = React.useMemo(() => {
    const rawItems = filterOptions ? filterOptions(data) : data;
    const items: SelectProps["options"] = rawItems.map(({ id, name, generatedName }) => ({
      value: id,
      label: generatedName || name || id,
    }));

    if (rest.options) rest.options.forEach(option => items.push(option));

    return items;
  }, [data, filterOptions, rest.options]);

  const dropdownRender = React.useCallback((menu: React.ReactElement) => {
    if (isLoading) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;
    return menu;
  }, [isLoading]);

  return (
    <Select
      dropdownRender={dropdownRender}
      {...rest}
      options={options}
    />
  );
});
