import { TaxonomyConfiguration } from "types/appConfig";

import React from "react";
import { Card, Result, Typography } from "antd";
import { useItemMetaFromParams } from "hooks/useItemMetaFromParams";
import { useIsPath } from "router/hooks/useIsPath";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { TaxonomyBreadcrumbs } from "modules/TaxonomyModule/components/TaxonomyBreadcrumbs";
import { TaxonomyCreateForm } from "modules/TaxonomyModule/TaxonomyCreateForm";
import { AdditionalPartForCustomTaxonomyReferenceField } from "modules/TaxonomyModule/TaxonomyCreateForm/AdditionalPartForCustomTaxonomyReferenceField";

import { AccessDenied } from "ui/AccessDenied";
import { BackHomeButton } from "ui/BackHomeButton";

const additionalComponents = { AdditionalPartForCustomTaxonomyReferenceField };
const emptyAdditionalComponents = {};

export const TaxonomyCreatePage = () => {
  const translations = useTranslations();
  const { callbacks: { createItemCallback } } = useCatalogContext();
  const { isInMatching } = useIsPath();

  const { taxonomyClass = "" } = useItemMetaFromParams();

  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;
  const taxonomyName = taxonomyConfig.title || taxonomyClass;

  const breadcrumbItems = React.useMemo(
    () => [
      {
        title: (
          <Typography.Text>
            {translations["breadcrumb.create"]} {taxonomyName}
          </Typography.Text>
        ),
      },
    ],
    [taxonomyName, translations]
  );

  const catalogItemCreateEnabledForRole = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_CREATE);
  if (!catalogItemCreateEnabledForRole) return <AccessDenied />;
  if (!taxonomyConfig.meta.creatable)
    return (
      <Result
        status="warning"
        title={translations["item.create.prohibited"].replace("{{taxonomyClass}}", taxonomyName)}
        extra={<BackHomeButton />}
      />
    );

  const title = `${translations["item.create.title"]} ${taxonomyName}`;

  return (
    <div className="pt-4 pb-6">
      <div>
        <TaxonomyBreadcrumbs breadcrumbItems={breadcrumbItems} />
        <Typography.Title>{title}</Typography.Title>
        <Card>
          <TaxonomyCreateForm
            taxonomyClass={taxonomyClass}
            createItemCallback={createItemCallback}
            additionalComponents={isInMatching ? emptyAdditionalComponents : additionalComponents}
          />
        </Card>
      </div>
    </div>
  );
};
