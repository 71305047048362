import React from "react";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { MatchingListModule } from "modules/MatchingModule/MatchingListModule";

import { AccessDenied } from "ui/AccessDenied";

export const MatchingListPage = React.memo(() => {
  const matchingViewMainEnabled = useFeatureFlag(FeatureFlagNames.MATCHING_VIEW_MAIN);

  if (!matchingViewMainEnabled) return <AccessDenied />;

  return (
    <div className="pt-4 pb-6 min-w-0">
      <MatchingListModule />
    </div>
  );
});
