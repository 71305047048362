import { Dataset } from "types/entities/Jobs";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { Card, Space, Typography } from "antd";
import { queries } from "consts";
import { MatchingParamsType } from "router/navigationTypes";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { FacetSet } from "modules/FacetsModule/components/FacetSet";
import { MatchingProgress } from "modules/MatchingModule/components/MatchingProgress";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";
import { MatchingTable } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTable";
import { MatchingTableControls } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableControls";
import { MatchingTableRefreshButton } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableRefreshButton";
import {
  MatchingTableUndoButton
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableUndoButton";
import { columnHeight, minColumnHeight } from "modules/MatchingModule/MatchingListModule/consts/matchingColumnSizes";

export const MatchingGroupsModule = React.memo(() => {
  const { jobId = "", datasetName = Dataset.TRANSACTIONS } = useParams<MatchingParamsType>();
  const translations = useTranslations();

  const minLG = useMediaQuery({ query: queries.lg });
  const minMD = useMediaQuery({ query: queries.md });

  const enabled = useFeatureFlag(FeatureFlagNames.MATCHING_VIEW_GROUPS);

  const urlQueryService = useMatchingUrlQueryService();
  const handleClearFacet = React.useCallback(
    (facetKey: string) => {
      urlQueryService.setSearchParam(facetKey, []);
    },
    [urlQueryService]
  );

  if (!enabled) return null;

  return (
    <Card style={!minMD ? { borderRadius: 0 } : undefined} bordered={false}>
      <div
        className="flex flex-col"
        style={{
          ...(minLG && {
            height: columnHeight,
            minHeight: minColumnHeight,
          })
        }}
      >
        <div className="flex justify-between items-center flex-wrap mb-4">
          <Typography.Title level={5} style={{ margin: 0 }}>
            {translations["matching.table.title"]}
          </Typography.Title>
          <Space>
            <MatchingTableRefreshButton />
            <MatchingTableUndoButton />
          </Space>
        </div>
        <MatchingTableControls className="mb-4" />
        <FacetSet urlQueryService={urlQueryService} onClearFacet={handleClearFacet} className="mb-4"/>
        <MatchingProgress jobId={jobId} datasetName={datasetName} showText={true} className="mb-4" />
        <MatchingTable />
      </div>
    </Card>
  );
});
