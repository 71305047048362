import { TaxonomyConfiguration } from "types/appConfig";

import { UIConfigurationTemplates } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { CustomTaxonomyReferenceField } from "modules/TaxonomyModule/CommonTaxonomyFormModule/components/CustomTaxonomyReferenceField";
import { facetConfigurationMap } from "modules/TaxonomyModule/consts/facetConfigurationMap";

import { customCompletenessUiField } from "../components/CustomCompletenessSwitch";
import { customStatusUiField } from "../components/CustomStatusSwitch";

const generateUISchemaForRelations = (config: TaxonomyConfiguration) => {
  const relationNames = Object.keys(config.meta.relations || {});

  return relationNames?.reduce(
    (acc, name) => ({
      ...acc,
      [name]: {
        "ui:widget": CustomTaxonomyReferenceField,
      },
    }),
    {}
  );
};

const getPairedPropsAndRelationsOrder = (config: TaxonomyConfiguration): string[] => {
  if (!config.meta.relations || !config.properties) return [];

  const pairs: string[] = [];

  Object.entries(config.meta.relations).forEach(([key]) => {
    const fieldTwin = Object.keys(config.properties!).find((field) => key.toLowerCase().includes(field.toLowerCase()));
    if (fieldTwin) pairs.push(fieldTwin, key);
  });

  return pairs;
};

export const generateUiSchema = (taxonomyConfiguration: TaxonomyConfiguration, taxonomyClass: string) => {
  const UISchemaForRelations = generateUISchemaForRelations(taxonomyConfiguration);
  const fieldPairs = getPairedPropsAndRelationsOrder(taxonomyConfiguration);

  const firstFields = [
    ...(Array.isArray(UIConfigurationTemplates.form[taxonomyClass]?.firstFields)
      ? (UIConfigurationTemplates.form[taxonomyClass]?.firstFields as string[])
      : []),
  ];

  const lastFields = [
    ...(Array.isArray(UIConfigurationTemplates.form.common.lastFields)
      ? (UIConfigurationTemplates.form.common.lastFields as string[])
      : []),
  ];

  const notOrderedKeys = [
    Object.keys(taxonomyConfiguration.meta.relations || {}),
    Object.keys(taxonomyConfiguration.properties || {}),
  ]
    .flat(1)
    .filter((key) => ![firstFields, fieldPairs, lastFields].flat(1).includes(key));

  const orderedKeysFromFacetsConfiguration = (facetConfigurationMap[taxonomyClass] || [])
    .map((conf) => conf.key)
    .filter((key) => notOrderedKeys.includes(key));

  return {
    ...UISchemaForRelations,
    ...customStatusUiField,
    ...customCompletenessUiField,
    "ui:order": [...firstFields, ...fieldPairs, ...orderedKeysFromFacetsConfiguration, "*", ...lastFields],
  };
};
