import React, { useEffect, useMemo, useState } from "react";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { useGetFeatureFlagsConfigByRoles } from "../api/useGetFeatureFlagsConfigByRoles";

import { FeatureFlagsConfigType } from "./featureFlags.types";
import { RolesNames } from "./roles.types";

type FeatureFlagContextType = {
  featureFlagsConfig: FeatureFlagsConfigType;
  setRole: (role: RolesNames) => void;
};
const initialState: FeatureFlagContextType = {
  featureFlagsConfig: {} as FeatureFlagsConfigType,
  setRole: () => {},
};
const FeatureFlagContext = React.createContext(initialState);

export const FeatureFlagProvider = React.memo<{ children: JSX.Element }>(({ children }) => {
  const { showError } = useMessage();
  const translations = useTranslations();

  const [role, setRole] = useState<RolesNames>();
  const [featureFlagsConfig, setFeatureFlagsConfig] = React.useState<FeatureFlagsConfigType>(
    {} as FeatureFlagsConfigType
  );

  const featureFlagsConfigByRoles = useGetFeatureFlagsConfigByRoles(role);
  useEffect(() => {
    if (!featureFlagsConfigByRoles || !role) return;

    const newFeatureFlagsConfig = featureFlagsConfigByRoles[role];
    if (!newFeatureFlagsConfig) {
      showError(translations["role.not_exist"]);
      return;
    }

    setFeatureFlagsConfig(newFeatureFlagsConfig);
  }, [featureFlagsConfigByRoles, role, showError, translations]);

  const value = useMemo(() => ({ featureFlagsConfig, setRole }), [featureFlagsConfig]);
  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
});

export const useFeatureFlagsContext = (): FeatureFlagContextType => React.useContext(FeatureFlagContext);
