import React from "react";
import { useMediaQuery } from "react-responsive";
import { Space } from "antd";
import { queries } from "consts";

import {
  MatchingCancelCreationRequestButton
} from "modules/MatchingModule/MatchingTargetSpecialModule/components/MatchingCancelCreationRequestButton";
import {
  MatchingCreateItemButton
} from "modules/MatchingModule/MatchingTargetSpecialModule/components/MatchingCreateItemButton";

export const MatchingTargetItemRequestControls = React.memo(() => {
  const minLG = useMediaQuery({ query: queries.lg });

  return (
    <Space size="small" direction={minLG ? "vertical" : "horizontal"} className="w-full">
      <MatchingCreateItemButton />
      <MatchingCancelCreationRequestButton />
    </Space>
  );
});
