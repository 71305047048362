import { TaxonomyItem, TaxonomyStatus } from "types/entities/Taxonomy";

const relationFormatter = (relations: TaxonomyItem["relations"] = {}) => {
  const relationsExist = Object.values(relations || {}).length !== 0;
  return {
    relationsExist,
    formattedRelations: Object.entries(relations || {}).reduce(
      (acc, [key, values]) => {
        return { ...acc, [key]: values.map((v) => v.id) };
      },
      {}
    ),
  };
};

const removeEmptyFields = (
  dataFromBE: Omit<TaxonomyItem, "relations"> | {}
) => {
  return Object.fromEntries(
    Object.entries(dataFromBE).filter(
      ([_, value]) => value !== null && value !== undefined
    )
  );
};

export const changeFromBEToFEFormat = ({
  initialFormData,
}: {
  initialFormData?: TaxonomyItem;
}) => {
  const { relations, ...fields } = initialFormData || {};

  const cleanFields = removeEmptyFields(fields);

  const { relationsExist, formattedRelations } = relationFormatter(relations);

  return {
    ...cleanFields,
    ...(relationsExist ? formattedRelations : {}),
    status: initialFormData?.status || TaxonomyStatus.active
  };
};
