import { ItemID } from "types/common";

import React from "react";
import { Cascader } from "antd";
import { useTranslations } from "translations/useTranslations";

import { getChildrenByPath } from "./helpers/getChildrenByPath";

import { Preloader, PreloaderType } from "ui/Preloader";

import { useCascaderOptions } from "./hooks/useCascaderOptions";
import { SelectInfoToMatchProps } from "./SelectInfoToMatch.types";

export const SelectInfoToMatch = React.memo<SelectInfoToMatchProps>(({ value, onChange }) => {
  const translations = useTranslations();

  const { options, isLoading } = useCascaderOptions();

  const DropdownRender = React.useCallback((menus: React.ReactNode) => isLoading
    ? <div className="w-[250px] p-3"><Preloader type={PreloaderType.SKELETON} title={false} paragraph={{ rows: 3 }} /></div>
    : <div>{menus}</div>,
  [isLoading]
  );

  const handleChange = React.useCallback((value: (string | number)[][]) => {
    const fullValue: (string | number)[][] = [];

    value.forEach(valueItem => {
      if (valueItem.at(-1)) {
        fullValue.push(valueItem);
      } else {
        const children = getChildrenByPath(options, valueItem);
        fullValue.push(...children.map(child => [...valueItem, child]));
      }
    });

    onChange?.(fullValue as ItemID[][]);
  }, [onChange, options]);

  return (
    <Cascader
      multiple
      showSearch
      value={value}
      options={options}
      dropdownRender={DropdownRender}
      placeholder={translations["jobs.form.info_to_match.placeholder"]}
      loading={isLoading}
      onChange={handleChange}
    />
  );
});
