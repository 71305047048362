import { ItemID } from "types/common";
import { RelationsForm } from "types/entities/Relations";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { useCallback } from "react";

import { useCreateRelationForTaxonomyItem } from "modules/TaxonomyModule/api/useCreateRelationForTaxonomyItem";
import { useDeleteRelationForTaxonomyItem } from "modules/TaxonomyModule/api/useDeleteRelationForTaxonomyItem";
import { compareRelationsForCreationAndDeletion } from "modules/TaxonomyModule/TaxonomyEditModule/helpers/compareRelationsForCreationAndDeletion";

export const useUpdateRelationsForItem = () => {
  const createRelationForTaxonomyItem = useCreateRelationForTaxonomyItem();
  const deleteRelationForTaxonomyItem = useDeleteRelationForTaxonomyItem();

  return useCallback(
    (taxonomyItem: TaxonomyItem, relationsFormData: RelationsForm, itemID: ItemID) => {
      const relationsListForCreationAndDeletion = compareRelationsForCreationAndDeletion(
        taxonomyItem,
        relationsFormData
      );

      if (relationsListForCreationAndDeletion?.listOfCreated.length) {
        createRelationForTaxonomyItem(itemID, relationsListForCreationAndDeletion.listOfCreated);
      }

      if (relationsListForCreationAndDeletion?.listOfDeleted.length) {
        deleteRelationForTaxonomyItem(itemID, relationsListForCreationAndDeletion.listOfDeleted);
      }
    },
    [deleteRelationForTaxonomyItem, createRelationForTaxonomyItem]
  );
};
