import React from "react";
import { DefaultOptionType } from "antd/es/cascader";

import { LabelWithIgnore } from "../LabelWithIgnore";
import { MatchingMoveHighlighter } from "../MatchingMoveHighlighter";

export const cascaderSearchRender = (searchValue: string, path: DefaultOptionType[]) => {
  return (
    <div className="flex gap-1">
      {path.map((option, index) => {
        const isLast = index === path.length - 1;
        return (
          <>
            {typeof option.label === "string"
              ? <MatchingMoveHighlighter searchValue={searchValue} text={option.label} />
              : <LabelWithIgnore taxonomyItem={option.taxonomyItem} searchValue={searchValue} />}
            {!isLast && " / "}
          </>
        );
      })}
    </div>
  )
}
