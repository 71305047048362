import React from "react";
import { theme } from "antd";
import clsx from "clsx";

import {
  MatchingTargetsDrawerTitle
} from "modules/MatchingModule/MatchingListModule/components/MatchingTargetsDrawerTitle";
import {
  useMatchingTargetsDrawerHeights
} from "modules/MatchingModule/MatchingListModule/hooks/useMatchingTargetsDrawerHeights";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { MatchingTargetsModule } from "modules/MatchingModule/MatchingTargetsModule";

import { useMatchingTargetsDrawerContext } from "./MatchingTargetsDrawer.context";

export const MatchingTargetsDrawer = React.memo(() => {
  const { token } = theme.useToken();
  const { mainDrawerHeight, headDrawerHeight } = useMatchingTargetsDrawerHeights();

  const { selectedGroupIds } = useMatchingContext();
  const isTopDrawerOpen = !!selectedGroupIds.length;
  const { isDrawerOpen: isMainDrawerOpen, setIsDrawerOpen } = useMatchingTargetsDrawerContext();

  const toggleDrawer = React.useCallback(() => {
    setIsDrawerOpen(prevState => !prevState);
  }, [setIsDrawerOpen]);

  const height = React.useMemo(() => {
    if (isTopDrawerOpen && isMainDrawerOpen) return headDrawerHeight + mainDrawerHeight;
    if (isTopDrawerOpen) return headDrawerHeight;
    return 0;
  }, [isTopDrawerOpen, isMainDrawerOpen, headDrawerHeight, mainDrawerHeight]);

  return (
    <div
      style={{
        height,
        borderTopLeftRadius: token.borderRadiusLG,
        borderTopRightRadius: token.borderRadiusLG,
        backgroundColor: token.colorBgContainer,
      }}
      className={clsx(
        "z-10 fixed left-0 bottom-0 w-full shadow-[0_-10px_10px_-5px_rgba(0,0,0,0.3)] transition-all duration-300 overscroll-contain",
        isMainDrawerOpen ? "overflow-y-auto" : "overflow-hidden"
      )}
    >
      <div
        className="z-[10000] sticky top-0"
        style={{ backgroundColor: token.colorBgContainer  }}
      >
        <MatchingTargetsDrawerTitle isOpen={isMainDrawerOpen} onToggle={toggleDrawer} height={headDrawerHeight - 1} />
      </div>
      <div className="px-6">
        <MatchingTargetsModule/>
      </div>
    </div>
  );
});
