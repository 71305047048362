import { ItemID } from "types/common";
import { PrematchTaxonomyClasses, UNKNOWN_PREMATCH_CLASS } from "types/entities/Prematch";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

const preMatchClassToTaxonomyClass = {
  [TaxonomyClass.MEDICAL_PRODUCT]: (itemId?: ItemID) => ({ [TaxonomyClass.DRUG_CLASS]: itemId ? [itemId] : [] }),
  [TaxonomyClass.FOOD_PRODUCT]: (itemId?: ItemID) => ({ [TaxonomyClass.FOOD_CLASS]: itemId ? [itemId] : [] }),
  [TaxonomyClass.RETAIL_PRODUCT]: (itemId?: ItemID) => ({ [TaxonomyClass.RETAIL_CLASS]: itemId ? [itemId] : [] }),
  [TaxonomyClass.DIAGNOSTIC_TEST]: () => ({}),
  [UNKNOWN_PREMATCH_CLASS]: () => ({}),
};

export const getFilledPrematch = (preMatchClass: PrematchTaxonomyClasses, itemId?: ItemID) => {
  return preMatchClassToTaxonomyClass[preMatchClass](itemId);
}
