import { RelationElementConfiguration } from "types/appConfig";
import { ExtendedJSONSchemaDefinitionForTaxonomy } from "types/extendSchema";

import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { configurationServiceInstance } from "services/ConfigurationService";

import { UIConfigurationTemplates } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { JSONSchemaForRelationsType } from "modules/TaxonomyModule/CommonTaxonomyFormModule/CommonTaxonomyFormModule.types";

const generateRelationsObject = (
  taxonomyClass: string,
  relations: [string, RelationElementConfiguration][],
  translations: Record<string, string>
) => {
  const createJSONSchemaForRelations = (
    acc: JSONSchemaForRelationsType,
    [key, relation]: [string, RelationElementConfiguration]
  ): JSONSchemaForRelationsType => {
    const definition = configurationServiceInstance.getTaxonomyItemByName(relation.to);
    if (!definition) return acc;

    const description = translations[UIConfigurationTemplates.form[key]?.description as string];
    const title = getTaxonomyRelationTitle(taxonomyClass, key) || definition.title || relation.title || relation.to;

    return {
      ...acc,
      [key]: {
        title,
        relationInfo: relation,
        endpoint: definition.meta.endpoint,
        type: "array",
        items: { type: "string" },
        ...(description && { description }),
      },
    };
  };
  return relations.reduce(createJSONSchemaForRelations, {});
};

export const createSchemaWithRelationsProperties = (
  taxonomyClass: string,
  schema: ExtendedJSONSchemaDefinitionForTaxonomy,
  translations: Record<string, string>
) => {
  const newSchema = { ...schema, title: "" };
  if (!schema.meta.relations) return newSchema;

  const relationsProperties = generateRelationsObject(taxonomyClass, Object.entries(schema.meta.relations), translations);

  return {
    ...newSchema,
    properties: {
      ...schema.properties,
      ...relationsProperties,
    },
  };
};
