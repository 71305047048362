import { ItemID } from "types/common";

import { ClinicCode, TrimmedCode } from "./Code";

export enum SynonymType {
  EXACT = "exact",
  WILDCARD = "wildcard",
  EXCLUSION = "exclusion",
}

export type Synonym = {
  id: ItemID;
  type: SynonymType;
  value: string;
  language: string;
  scope?: string;
  standard_codes: TrimmedCode[];
  clinic_code?: ClinicCode;
};
