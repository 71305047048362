import React from "react";
import { Space, theme, Typography } from "antd";

import { Preloader } from "../Preloader";
import { PreloaderType } from "../Preloader.types";

export const PaginationPreloader = React.memo<{ text: string }>(({ text }) => {
  const { token } = theme.useToken();

  return (
    <Space className="flex py-4" direction="vertical" align="center">
      <Preloader type={PreloaderType.SPINNER} />
      <Typography.Text style={{ color: token.colorPrimary }}>{text}</Typography.Text>
    </Space>
  );
});
