export const getFirstVisibleEl = (scrollContainer: HTMLElement, elementsList: HTMLElement[], offsetTop = 0): HTMLElement | null => {
  const scrollContainerRect = scrollContainer.getBoundingClientRect();

  for (let i = 0; i < elementsList.length; i++) {
    const groupEl = elementsList[i] as HTMLElement;
    const groupRect = groupEl.getBoundingClientRect();

    const isVisible =
      groupRect.top >= scrollContainerRect.top + offsetTop
      && groupRect.top + groupRect.height / 2 <= scrollContainerRect.bottom;

    if (isVisible) return groupEl;
  }

  return null;
}
