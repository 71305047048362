import React from "react";
import { Modal } from "antd";
import { ModalFuncProps } from "antd/es/modal/interface";
import { useTranslations } from "translations/useTranslations";

export const useInteractiveMessage = () => {
  const translations = useTranslations();

  const showInteractiveSuccess = React.useCallback((props: ModalFuncProps) => {
    Modal.success(props);
  }, []);

  const showInteractiveError = React.useCallback((props: ModalFuncProps) => {
    Modal.error({
      okType: "default",
      okText: translations["item.error.modal.cancel"],
      ...props,
    });
  }, [translations]);

  return { showInteractiveSuccess, showInteractiveError }
}
