import React from "react";
import { useParams } from "react-router-dom";
import { MarketCode } from "consts/markets";
import { MatchingParamsType } from "router/navigationTypes";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { useGetJob } from "modules/JobsModule/api/useGetJob";
import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const COUNTRY_KEY_FOR_SEARCH_PARAMS = "country";
export const PROVIDERS_KEY_FOR_SEARCH_PARAMS = "providers";

export const useInformationSourceInSearchParams = () => {
  const urlQueryService = useMatchingUrlQueryService();
  const { jobId = "" } = useParams<MatchingParamsType>();
  const { isTransactions } = useCheckDataset();

  const { job, isLoading: isJobLoading } = useGetJob(jobId);
  const {
    data: allCountries,
    isLoading: isCountriesLoading
  } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.MARKET);
  const countries = React.useMemo(
    () => {
      if (!job) return null;
      return allCountries?.filter((country) => country.name && (!job?.market?.length || job.market.includes(country.name)));
    },
    [allCountries, job]
  );

  const { getCountryFromSearchParams, getProvidersFromSearchProviders, setProvidersToSearchParams } = React.useMemo(
    () => ({
      getCountryFromSearchParams: () =>
        (urlQueryService.getValueByKey(COUNTRY_KEY_FOR_SEARCH_PARAMS) as MarketCode) || "",
      getProvidersFromSearchProviders: () =>
        (urlQueryService.getValueByKey(PROVIDERS_KEY_FOR_SEARCH_PARAMS) as string) || "",
      setProvidersToSearchParams: (value: string) =>
        urlQueryService.setSearchParam(PROVIDERS_KEY_FOR_SEARCH_PARAMS, value),
    }),
    [urlQueryService]
  );

  const setCountryToSearchParams = React.useCallback(
    (value: string) => {
      const currentCountry = getCountryFromSearchParams();
      if (currentCountry === value) return;
      urlQueryService.setSearchParam(COUNTRY_KEY_FOR_SEARCH_PARAMS, value);
    },
    [getCountryFromSearchParams, urlQueryService]
  );

  const countryValue = getCountryFromSearchParams();
  const providersValue = getProvidersFromSearchProviders();

  const informationSourceFilterString =
    [
      countryValue ? `${COUNTRY_KEY_FOR_SEARCH_PARAMS}=${countryValue}` : "",
      providersValue ? `${PROVIDERS_KEY_FOR_SEARCH_PARAMS}=${providersValue}` : "",
    ]
      .filter((el) => Boolean(el))
      .join("&") || "";

  React.useEffect(() => {
    const firstCountryWithName = countries?.find(({ name }) => !!name);
    if (isTransactions && !countryValue && firstCountryWithName) setCountryToSearchParams(firstCountryWithName.name!);
  }, [countryValue, isTransactions, countries, setCountryToSearchParams]);

  return {
    informationSourceFilterString,

    getProvidersFromSearchProviders,
    setProvidersToSearchParams,
    providersValue,

    countries,
    getCountryFromSearchParams,
    setCountryToSearchParams,
    countryValue,

    isLoading: isJobLoading || isCountriesLoading,
  };
};
