import React from "react";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { jobsCreatePath } from "router/path";
import { useTranslations } from "translations/useTranslations";

export const JobCreateButton = React.memo(() => {
  const translations = useTranslations();

  return (
    <Link to={jobsCreatePath}>
      <Button size="large" type="primary" icon={<PlusCircleOutlined />}>
        {translations["jobs.create.button"]}
      </Button>
    </Link>
  );
});
