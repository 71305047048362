import { TaxonomyConfiguration } from "types/appConfig";

import React from "react";
import { useParams } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ListOfTaxonomyParamsType } from "router/navigationTypes";
import { getTaxonomyCreatePath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

export const CreateTaxonomyItemButton = React.memo(() => {
  const { taxonomyClass = "" } = useParams<ListOfTaxonomyParamsType>();
  const translations = useTranslations();
  const {
    components: { CatalogLink },
  } = useCatalogContext();
  const catalogItemCreateEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_CREATE);

  if (!catalogItemCreateEnabled) return null;

  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;

  if (!taxonomyConfig.meta.creatable) return null;

  const title = translations["item.table.button.create"]
    .replace("{{taxonomyClass}}", taxonomyConfig.title || taxonomyClass);

  return (
    <CatalogLink to={getTaxonomyCreatePath(taxonomyClass)}>
      <Button size="large" type="primary" icon={<PlusCircleOutlined />}>
        {title}
      </Button>
    </CatalogLink>
  );
});
