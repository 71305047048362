import { Job } from "types/entities/Jobs";

import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useMessage } from "hooks/useMessage";
import { jobsListPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { getJobListUrl } from "api/rootUrl";

const postCreateJob = (job: Partial<Job>) => axios.post(getJobListUrl(), job).then((res) => res.data);

export const useCreateJob = () => {
  const { showSuccess } = useMessage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const translations = useTranslations();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: postCreateJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.GET_ACTIVE_JOB_LIST]);
      navigate(jobsListPath);
      showSuccess(translations["jobs.form.modal.success"]);
    }
  });

  return { createJob: mutate, isLoading, error };
}
