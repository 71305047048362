import { Modal } from "antd";
import { useTranslations } from "translations/useTranslations";

export const useShowReactivateConfirm = (
  reactivateJob: () => Promise<{}>
) => {
  const translations = useTranslations();

  return () => {
    Modal.confirm({
      title: translations["jobs.reactivate.popup.title"],
      content: translations["jobs.reactivate.popup.content"],
      okText: translations["jobs.reactivate.popup.button.ok"],
      onOk: reactivateJob,
      onCancel() {},
    });
  }
};
