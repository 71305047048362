import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { ListOfTaxonomyModule } from "modules/TaxonomyModule/ListOfTaxonomyModule";

import { AccessDenied } from "ui/AccessDenied";

export const TaxonomyListPage = () => {
  const catalogViewListEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_VIEW_LIST);

  if (!catalogViewListEnabled) return <AccessDenied />;

  return (
    <div className="pt-4 pb-6 min-w-0">
      <ListOfTaxonomyModule />
    </div>
  );
};
