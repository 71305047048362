import React from "react";
import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";

import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";
import { ItemReadRowValue } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/ItemReadRowValue";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";
import {
  OtherTaxonomyItemReadSection
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/OtherTaxonomyItemReadSection";

import { TaxonomyItemReadSectionProps } from "../TaxonomyItemReadSections.types";

import { diagnosticTestPropsKeys, diagnosticTestRelationsKeys } from "./consts";

export const DiagnosticTestTaxonomyItemReadSection = React.memo<TaxonomyItemReadSectionProps>(
  ({ taxonomyItem: diagnosticTest = {}, fieldsToHide }) => {

    const panelType = diagnosticTest?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE];
    const manufacturer = diagnosticTest?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER];
    const location = diagnosticTest?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION];
    const requirements = diagnosticTest?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS];

    return (
      <div>
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.DIAGNOSTIC_TEST, DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE)}
          value={<ItemReadRowValue taxonomyItem={panelType} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.DIAGNOSTIC_TEST, DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER)}
          value={<ItemReadRowValue taxonomyItem={manufacturer} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.DIAGNOSTIC_TEST, DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION)}
          value={<ItemReadRowValue taxonomyItem={location} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.DIAGNOSTIC_TEST, DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS)}
          value={<ItemReadRowValue taxonomyItem={requirements} />}
        />
        <OtherTaxonomyItemReadSection
          taxonomyClass={TaxonomyClass.DIAGNOSTIC_TEST}
          taxonomyItem={diagnosticTest}
          requiredPropsKeys={diagnosticTestPropsKeys}
          requiredRelationsKeys={diagnosticTestRelationsKeys}
          fieldsToHide={fieldsToHide}
        />
      </div>
    );
  });
