import { MatchTarget, MatchTargetFinal } from "types/entities/Match";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";

import { getRelationKeysMapByTaxonomyClass } from "modules/MatchingModule/components/MatchingItemDetailsList/helpers/getRelationKeysMapByTaxonomyClass";
import {
  MatchingItemDetailsListProps
} from "modules/MatchingModule/components/MatchingItemDetailsList/MatchingItemDetailsList.types";
import { TaxonomyItemsNamesViewer } from "modules/MatchingModule/components/TaxonomyItemsViewer";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

export const getDiagnosticTestItems = ({
  taxonomyItem,
  matchTarget,
  prematch = {},
}: {
  taxonomyItem?: TaxonomyItem;
  matchTarget?: MatchTarget | MatchTargetFinal;
  prematch: Partial<MatchingItemDetailsListProps["prematch"]>;
}) => {
  const items = [];

  const relationKeysMapByTaxonomyClass = getRelationKeysMapByTaxonomyClass(taxonomyItem);

  const manufacturerIDs = (() => {
    if (taxonomyItem)
      return taxonomyItem.relations?.[relationKeysMapByTaxonomyClass[TaxonomyClass.MANUFACTURER]]?.map(({ id }) => id);

    if (matchTarget && "taxonomy_item_body" in matchTarget)
      return matchTarget.taxonomy_item_body?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER];

    return prematch[TaxonomyClass.MANUFACTURER];
  })();

  const locationsIDs = (() => {
    if (taxonomyItem)
      return taxonomyItem.relations?.[relationKeysMapByTaxonomyClass[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]]?.map(({ id }) => id);

    if (matchTarget && "taxonomy_item_body" in matchTarget)
      return matchTarget.taxonomy_item_body?.relations?.[DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION];

    return prematch[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION];
  })();

  items.push({
    title: getTaxonomyClassTitle(TaxonomyClass.MANUFACTURER),
    value: <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.MANUFACTURER} itemsIDs={manufacturerIDs || []}/>,
  });

  items.push({
    title: getTaxonomyClassTitle(TaxonomyClass.DIAGNOSTIC_TEST_LOCATION),
    value: <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.DIAGNOSTIC_TEST_LOCATION} itemsIDs={locationsIDs || []}/>,
  });

  return items;
}
