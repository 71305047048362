import { MatchGroup, MatchGroupPrematch } from "types/entities/Match";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Alert, Tag, Typography } from "antd";
import { queries } from "consts";
import { useTranslations } from "translations/useTranslations";

import { MatchingItemDetailsList } from "modules/MatchingModule/components/MatchingItemDetailsList";
import { MatchingTargetItemTitle } from "modules/MatchingModule/components/MatchingTargetItemTitle";
import { checkMatchStatus } from "modules/MatchingModule/helpers/checkMatchStatus";
import { getStatusTagTypeMap, getStatusTitleMap } from "modules/MatchingModule/helpers/statusMaps";
import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import {
  MatchingTablePrematchResultTitle
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTablePrematchResultTitle";
import { GenericTaxonomyClass } from "modules/TaxonomyModule/consts";

export const MatchingTableResultCell = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const { match: { status, final }, prematch = {} as Partial<MatchGroupPrematch> } = matchGroup.metadata_;
  const translations = useTranslations();
  const statusTitleMap = getStatusTitleMap(translations);
  const statusTypeMap = getStatusTagTypeMap();

  const minXXL = useMediaQuery({ query: queries.xxl });

  const { isTransactions } = useCheckDataset();
  const { isMatched } = checkMatchStatus(status);

  const prematchInformationKinds = prematch[GenericTaxonomyClass] || [];

  if (isMatched && !final?.[0])
    return <Alert type="error" message={translations["matching.error.no_final_item"]} showIcon />;

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="flex flex-wrap gap-2 items-start w-full">
          <Typography.Text strong className="grow">
            {final?.[0] ? (
              <MatchingTargetItemTitle matchTarget={final[0]} />
            ) : (
              <MatchingTablePrematchResultTitle informationKinds={prematchInformationKinds} prematch={prematch} />
            )}
          </Typography.Text>
          <Tag color={statusTypeMap[status]} className="uppercase ml-auto" style={{ marginRight: 0 }}>
            {statusTitleMap[status] || status}
          </Tag>
        </div>
      </div>
      {isTransactions && (
        <MatchingItemDetailsList
          prematchInformationKinds={prematchInformationKinds}
          matchTarget={final?.[0]}
          prematch={prematch}
          horizontal={minXXL}
          className="pt-2 xxl:flex xxl:gap-2"
        />
      )}
    </div>
  );
});
