import React from "react";
import { DefaultOptionType } from "antd/es/cascader";

export const cascaderSearchFilter = (inputValue: string, path: DefaultOptionType[]) => {
  return path.some((item) =>
    typeof item.label === "string"
      ? item.label.toLowerCase().includes(inputValue.toLowerCase())
      : (item.label as React.ReactElement)?.props?.taxonomyItem?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );
};
