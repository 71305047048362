import { MatchTarget, MatchTargetFinal } from "types/entities/Match";

import React from "react";
import { EMPTY } from "consts/strings";
import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { useMatchedTaxonomyItem } from "modules/MatchingModule/api/useMatchedTaxonomyItem";
import {
  getDiagnosticTestItemName
} from "modules/MatchingModule/components/MatchingTargetItemTitle/helpers/getDiagnosticTestItemName";
import { TaxonomyItemsNamesViewer } from "modules/MatchingModule/components/TaxonomyItemsViewer";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { Preloader, PreloaderType } from "ui/Preloader";

import { NoNameError } from "components/NoNameError";

export const MatchingTargetItemTitle = React.memo<{ matchTarget: MatchTarget | MatchTargetFinal }>(
  ({ matchTarget }) => {
    const { taxonomyItem, isLoading } = useMatchedTaxonomyItem(matchTarget.taxonomy_item_id);

    const title = React.useMemo(() => {
      if (taxonomyItem) {
        const [taxonomyClass] = getItemClassFromItemId(taxonomyItem.id);

        if (taxonomyClass.toLowerCase() === TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase()) {
          return getDiagnosticTestItemName(taxonomyItem);
        }

        return getTaxonomyItemName(taxonomyItem) || <NoNameError itemID={taxonomyItem.id} />;
      }

      if ("taxonomy_item_body" in matchTarget && matchTarget.taxonomy_item_body) {
        return (
          <TaxonomyItemsNamesViewer
            taxonomyClass={TaxonomyClass.PANEL_TYPE}
            itemsIDs={matchTarget.taxonomy_item_body.relations?.[DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE] || []}
          />
        );
      }

      return EMPTY;
    }, [taxonomyItem, matchTarget]);

    if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={false} />;

    return <span>{title}</span>;
  });
