import { UIConfigurationColumnsMapType } from "types/UIConfigurationColumnsTypes";

import { UIConfigurationTemplatesRead } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { foodProductFacetsKeys, medicalProductFacetsKeys, retailProductFacetsKeys } from "modules/TaxonomyModule/consts/facetsKeys";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { MEDICAL_BRAND_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { NAME_COLUMN_KEY } from "./components/CustomNameColumn/consts";
import { SPECIES_COLUMN_KEY } from "./components/CustomSpeciesColumn/consts";

export const DEFAULT_LIST_OF_COLUMNS = [{ key: NAME_COLUMN_KEY }];
export const UIConfigurationColumns: UIConfigurationColumnsMapType = {
  [TaxonomyClass.FOOD_PRODUCT.toLowerCase()]: [
    { key: NAME_COLUMN_KEY },
    { key: foodProductFacetsKeys[TaxonomyClass.SPECIES], customColumnKey: SPECIES_COLUMN_KEY },
    { key: foodProductFacetsKeys[TaxonomyClass.FOOD_FORM] },
  ],
  [TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()]: [
    { key: NAME_COLUMN_KEY },
    { key: medicalProductFacetsKeys[TaxonomyClass.SPECIES], customColumnKey: SPECIES_COLUMN_KEY },
    {
      key: medicalProductFacetsKeys[TaxonomyClass.ACTIVE_INGREDIENT],
      templates: [
        UIConfigurationTemplatesRead[TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()].activeIngredient,
        UIConfigurationTemplatesRead[TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()].secondaryActiveIngredient,
      ],
    },
  ],
  [TaxonomyClass.MEDICAL_BRAND.toLowerCase()]: [
    { key: NAME_COLUMN_KEY },
    { key: MEDICAL_BRAND_RELATION.TO_DRUG_CLASS },
    { key: MEDICAL_BRAND_RELATION.TO_MARKET },
    { key: MEDICAL_BRAND_RELATION.TO_PRIMARY_ACTIVE_INGREDIENT },
    { key: MEDICAL_BRAND_RELATION.TO_SECONDARY_ACTIVE_INGREDIENT },
    { key: MEDICAL_BRAND_RELATION.TO_MANUFACTURER },
    { key: MEDICAL_BRAND_RELATION.TO_PRINCIPAL_GENERIC },
  ],
  [TaxonomyClass.RETAIL_PRODUCT.toLowerCase()]: [
    { key: NAME_COLUMN_KEY },
    { key: retailProductFacetsKeys[TaxonomyClass.SPECIES], customColumnKey: SPECIES_COLUMN_KEY },
  ],
};
