import React from "react";
import { Card, Typography } from "antd";
import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";
import { useItemMetaFromParams } from "hooks/useItemMetaFromParams";
import { getTaxonomyReadPath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { useUpdateTaxonomyItemWithRelations } from "./api/useUpdateTaxonomyItemWithRelations";
import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { CommonTaxonomyFormModule } from "modules/TaxonomyModule/CommonTaxonomyFormModule";
import { TaxonomyBreadcrumbs } from "modules/TaxonomyModule/components/TaxonomyBreadcrumbs";

import { Preloader, PreloaderType } from "ui/Preloader";

export const TaxonomyEditModule = React.memo(() => {
  const { taxonomyClass = "", itemID = "" } = useItemMetaFromParams();
  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  const translation = useTranslations();
  const {
    components: { CatalogLink },
  } = useCatalogContext();

  const { taxonomyItem, isLoading, isError } = useGetTaxonomyItemByClassAndId(itemID);
  const { updateTaxonomyItem, errorSchema } = useUpdateTaxonomyItemWithRelations(itemID, taxonomyItem);

  const taxonomyName = taxonomyConfig?.title || taxonomyClass;
  const itemName = taxonomyItem && getTaxonomyItemName(taxonomyItem);

  const breadcrumbItems = React.useMemo(
    () => [
      { title: <CatalogLink to={getTaxonomyReadPath(itemID)}>{itemName}</CatalogLink> },
      {
        title: (
          <Typography.Text>
            {translation["breadcrumb.edit"]} {taxonomyName.toLowerCase()}
          </Typography.Text>
        ),
      },
    ],
    [CatalogLink, taxonomyName, itemID, itemName, translation]
  );

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} />;
  if (isError) return <div>Something went wrong.</div>;

  const title = `${translation["item.edit.title"]} ${taxonomyName}`;

  return (
    <div>
      <TaxonomyBreadcrumbs breadcrumbItems={breadcrumbItems} />
      <Typography.Title>{title}</Typography.Title>
      <Card>
        <CommonTaxonomyFormModule
          formData={taxonomyItem}
          onSubmitCallback={updateTaxonomyItem}
          taxonomyClass={taxonomyClass}
          errorSchema={errorSchema}
        />
      </Card>
    </div>
  );
});

TaxonomyEditModule.displayName = "TaxonomyEditModule";
