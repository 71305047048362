import { ResponseError } from "types/apiTypes";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { formatBEErrorToString } from "helpers/formatErrorBEToFE";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { taxonomyApiUrl } from "api/rootUrl";

import { UseUpdateSynonymProps } from "modules/SynonymModule/SynonymUpdateModule/api/useUpdateSynonym.types";

const putUpdateSynonym =
  ({ synonym: { id, type } }: UseUpdateSynonymProps) =>
    ({ value, language, scope }: { value: string; language: string, scope?: string }) =>
      axios.put(`${taxonomyApiUrl}/${id}`, { type, value, language, ...(scope && { scope }) });

export const useUpdateSynonym = (props: UseUpdateSynonymProps) => {
  const { taxonomyItemId } = props;
  const queryClient = useQueryClient();

  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: putUpdateSynonym(props),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_LIST_SYNONYM_ITEMS, taxonomyItemId],
      });
      showSuccess(translations["synonym.messages.update.success"]);
    },
  });

  const errorMsgOrKey = error ? formatBEErrorToString(error as AxiosError<ResponseError>) : "";
  const errorMessage = translations[errorMsgOrKey] || errorMsgOrKey;

  return { updateSynonym: mutateAsync, isLoading, isError, errorMessage };
};
