import { ItemID } from "types/common";

export enum JobStatus {
  CREATED = "created",
  ACTIVE = "active",
  COMPLETED = "completed",
  PENDING_COMPLETION = "pending_completion",
  DELETED = "deleted",
  ARCHIVED = "archived",
}

export type FilterByPrematchItem = { type: string; value: ItemID };

export type Job = {
  id: string;
  name: string;
  created_dts: string;
  modified_dts: string;
  completed_dts: string;
  // yyyy-mm-dd
  date_from: string;
  // yyyy-mm-dd
  date_to: string;
  status: JobStatus;
  market: string;
  providers?: string[];
  // item/clinic/gl:1000
  clinics?: string[];
  filter_by_prematch: FilterByPrematchItem[];
}

export enum Dataset {
  TRANSACTIONS = "transactions",
  SPECIES = "patients"
}
