import { NodeTree } from "modules/FacetsModule/FacetsModule.types";

const addChildren = (el: NodeTree, treeMap: Record<string, NodeTree[]>) => {
  const parentId = el.key;
  const children = treeMap[parentId];
  el.children = children;
  if ((el.children || []).length) el.children?.forEach((c) => addChildren(c, treeMap));
};

const createTreeMapFromTree = (treeElements: NodeTree[] | undefined): Record<string, NodeTree> => {
  if (!treeElements) return {};
  return {
    ...treeElements.map((el) => ({ [el.key]: el })).reduce((acc, el) => ({ ...acc, ...el }), {}),
    ...treeElements.map((el) => createTreeMapFromTree(el.children)).reduce((acc, el) => ({ ...acc, ...el }), {}),
  };
};

export const buildTree = (dataExample: NodeTree[]) => {
  const tree = {} as Record<string, NodeTree>;

  const copyData: NodeTree[] = dataExample.map((el) => ({ ...el }));

  const parents = copyData.filter((el) => !el.parent);

  const treeMap = copyData.reduce((acc, el) => {
    if (!el.parent?.key) return acc;
    const prev = acc[el.parent?.key] || [];
    return {
      ...acc,
      [el.parent?.key]: [...prev, el],
    };
  }, {} as Record<string, NodeTree[]>);

  parents.forEach((p) => {
    tree[p.key] = p;
  });
  parents.forEach((el) => addChildren(el, treeMap));

  return { tree, treeMap: createTreeMapFromTree(Object.values(tree)) };
};
