import React from "react";
import { useNavigate } from "react-router-dom";
import { getTaxonomyListPath } from "router/path";

import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { useIsFindInCatalogOpened } from "modules/MatchingModule/hooks/useIsFindInCatalogOpened";
import { useModifiedMatchingPaths } from "modules/MatchingModule/MatchingListModule/hooks/useModifiedMatchingPaths";
import { useMatchingTargetsClassTabs } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/hooks/useMatchingTargetsClassTabs";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

type MatchingTargetsClassTabsContextType = {
  activeTaxonomyClass: string;
  availableTaxonomyClasses: TaxonomyClass[];
  changeActiveTaxonomyClass: (taxonomyClass: string) => void;
  isDiagnosticTabSelected: boolean;
};

const DEFAULT_SPECIES_CLASS = TaxonomyClass.SPECIES.toLowerCase();

const initialState: MatchingTargetsClassTabsContextType = {
  activeTaxonomyClass: "",
  availableTaxonomyClasses: [],
  changeActiveTaxonomyClass: () => {},
  isDiagnosticTabSelected: false,
};

export const MatchingTargetsClassTabsContext = React.createContext<MatchingTargetsClassTabsContextType>(initialState);

export const MatchingTargetsClassTabsProvider = React.memo<{ children: React.ReactNode }>(({ children }) => {
  const navigate = useNavigate();
  const { isSpecies } = useCheckDataset();

  const availableTaxonomyClasses = useMatchingTargetsClassTabs();

  const [activeTaxonomyClass, setActiveTaxonomyClass] = React.useState<string>(
    isSpecies ? DEFAULT_SPECIES_CLASS : availableTaxonomyClasses[0].toLowerCase()
  );

  const { getMatchingCatalogPathWithAllSearch } = useModifiedMatchingPaths();

  const isCatalogOpened = useIsFindInCatalogOpened();

  const changeActiveTaxonomyClass = React.useCallback(
    (newActiveTaxonomyClass: string) => {
      setActiveTaxonomyClass(newActiveTaxonomyClass);
      if (isCatalogOpened) {
        const newPath = getMatchingCatalogPathWithAllSearch(getTaxonomyListPath(newActiveTaxonomyClass.toLowerCase()));
        navigate(newPath, { replace: true });
      }
    },
    [getMatchingCatalogPathWithAllSearch, isCatalogOpened, navigate]
  );

  React.useEffect(() => {
    if (!isSpecies) setActiveTaxonomyClass(availableTaxonomyClasses[0].toLowerCase());
  }, [availableTaxonomyClasses, isSpecies]);

  const value = {
    activeTaxonomyClass,
    availableTaxonomyClasses,
    changeActiveTaxonomyClass,
    isDiagnosticTabSelected: activeTaxonomyClass === TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase(),
  };

  return <MatchingTargetsClassTabsContext.Provider value={value}>{children}</MatchingTargetsClassTabsContext.Provider>;
});

export const useMatchingTargetsClassTabsContext = () => React.useContext(MatchingTargetsClassTabsContext);
