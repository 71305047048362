import { MedicalProductProperties,TaxonomyItemPatientWeightProperties } from "modules/TaxonomyModule/consts";
import { MEDICAL_PRODUCT_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

export const medicalProductPropsKeys: string[] = Object.values({
  ...MedicalProductProperties,
  ...TaxonomyItemPatientWeightProperties
});

export const medicalProductRelationsKeys: string[] = [
  MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND,
  MEDICAL_PRODUCT_RELATION.TO_PHYSICAL_FORM,
  MEDICAL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION,
  MEDICAL_PRODUCT_RELATION.TO_SPECIES,
  MEDICAL_PRODUCT_RELATION.TO_PACK_COLOR,
  MEDICAL_PRODUCT_RELATION.TO_TREATMENT_INDICATION,
];
