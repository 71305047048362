import { TaxonomyConfiguration } from "types/appConfig";

const staticProperties = ["status", "is_abstract", "name"];

export const getIsDeepRelation = (relationConfiguration?: TaxonomyConfiguration) => {
  if (!relationConfiguration) return false;

  return (
    [
      ...Object.keys(relationConfiguration?.properties || {}),
      ...Object.keys(relationConfiguration?.meta.relations || {}),
    ].length > staticProperties.length
  );
};
