import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { MarketCode } from "consts/markets";
import { EMPTY, SPACE } from "consts/strings";
import { getConvertedValuesString } from "helpers/getConvertedValuesString";
import { useTranslations } from "translations/useTranslations";

import { generateStringByTemplate } from "modules/GenerateByUIConfigurationModule";
import { buildJsxComponentsByTemplate } from "modules/GenerateByUIConfigurationModule/buildJsxComponentsByTemplate";
import { valueAndUnitPairs } from "modules/GenerateByUIConfigurationModule/UIConfiguration/UIConfigurationPairs";
import { MEDICAL_PRODUCT_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

const DOG_NAME = "Canine";
const CAT_NAME = "Feline";

const SpeciesListItem = React.memo<{
  taxonomyItem: TaxonomyItem;
  taxonomySpeciesItem: TaxonomyItem;
  template: string;
  RelationViewer?: React.FC<{ taxonomyItem: TaxonomyItem }>,
  convertToMarket?: MarketCode,
}>(({
  taxonomyItem,
  taxonomySpeciesItem,
  template,
  RelationViewer,
  convertToMarket,
}) => {
  const value = buildJsxComponentsByTemplate(template, taxonomyItem);
  const stringValue = generateStringByTemplate(template, taxonomyItem);
  const convertedValue = convertToMarket ? getConvertedValuesString(stringValue, convertToMarket) : "";

  return (
    <div className="flex flex-row flex-wrap">
      <div className="whitespace-nowrap">
        {RelationViewer ? <RelationViewer taxonomyItem={taxonomySpeciesItem} /> : taxonomySpeciesItem.name}
        {!!value?.length && `:${SPACE}`}
      </div>
      {convertToMarket ? stringValue : value}
      {!!convertedValue && `${SPACE}(${convertedValue})`}
    </div>
  );
});

export const SpeciesListWithWeight = React.memo<{
  taxonomyItem: TaxonomyItem,
  RelationViewer?: React.FC<{ taxonomyItem: TaxonomyItem }>,
  convertToMarket?: MarketCode,
}>(({
  taxonomyItem,
  RelationViewer,
  convertToMarket
}) => {
  const translations = useTranslations();
  const species = taxonomyItem?.relations?.[MEDICAL_PRODUCT_RELATION.TO_SPECIES] || [];

  const dogSpecies = species.find((el) => el.name?.toLowerCase() === DOG_NAME.toLowerCase());
  const catSpecies = species.find((el) => el.name?.toLowerCase() === CAT_NAME.toLowerCase());

  const otherSpeciesCount = species.filter(
    (el) => ![DOG_NAME.toLowerCase(), CAT_NAME.toLowerCase()].includes(el.name?.toLowerCase() || "")
  ).length;

  if (!dogSpecies && !catSpecies && !otherSpeciesCount) return <div>{EMPTY}</div>;

  return (
    <div className="flex flex-col">
      {dogSpecies && (
        <SpeciesListItem
          taxonomyItem={taxonomyItem}
          taxonomySpeciesItem={dogSpecies}
          template={valueAndUnitPairs.dog.template}
          RelationViewer={RelationViewer}
          convertToMarket={convertToMarket}
        />
      )}
      {catSpecies && (
        <SpeciesListItem
          taxonomyItem={taxonomyItem}
          taxonomySpeciesItem={catSpecies}
          template={valueAndUnitPairs.cat.template}
          RelationViewer={RelationViewer}
          convertToMarket={convertToMarket}
        />
      )}
      {otherSpeciesCount > 0 && (
        <div>
          {(!!dogSpecies || !!catSpecies)
            ? `+ ${otherSpeciesCount} ${translations["item.table.species.others"]}`
            : species.map(({ name }) => name).sort().join(", ")
          }
        </div>
      )}
    </div>
  );
});
