import { FacetsSetItemFlatTree } from "modules/FacetsModule/components/facetSetMap/FacetSetItemFlatTree";
import { FacetSetItemRange } from "modules/FacetsModule/components/facetSetMap/FacetSetItemRange";
import { FacetsSetItemTree } from "modules/FacetsModule/components/facetSetMap/FacetSetItemTree";
import { FacetsComponentsEnum } from "modules/FacetsModule/FacetsModule.types";

export type FacetSetMapItemProps = {
  facetKey: string;
  title: string;
  values: string[];
  taxonomyClass?: string;
  isStatic?: boolean;
}

export const facetSetMap = {
  [FacetsComponentsEnum.RANGE]: FacetSetItemRange,
  [FacetsComponentsEnum.TREE]: FacetsSetItemTree,
  [FacetsComponentsEnum.FLAT_TREE]: FacetsSetItemFlatTree,
}
