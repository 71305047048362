import React from "react";
import { useItemMetaFromParams } from "hooks/useItemMetaFromParams";

import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { Preloader, PreloaderType } from "ui/Preloader";

import { TabsWrapper } from "components/TabsWrapper";

import { TaxonomyItemReadHeader } from "./components/TaxonomyItemReadHeader";
import { useTabItems } from "./hooks/useTabItems";

export const TaxonomyItemReadModule: React.FC = React.memo(() => {
  const { taxonomyClass = "", itemID = "" } = useItemMetaFromParams();
  const { taxonomyItem, isLoading } = useGetTaxonomyItemByClassAndId(itemID);

  const tabItems = useTabItems(taxonomyClass, taxonomyItem);

  if (!taxonomyItem && isLoading) return <Preloader type={PreloaderType.SKELETON} />;

  // TODO: replace with 404 page
  if (!taxonomyItem) return <div>{taxonomyClass} {itemID} not exist</div>;

  return (
    <div>
      <TaxonomyItemReadHeader taxonomyClass={taxonomyClass} taxonomyItem={taxonomyItem} isLoading={isLoading} />
      <TabsWrapper defaultActiveKey="1" items={tabItems} />
    </div>
  );
});
