import React from "react";
import { Badge, theme, Tooltip } from "antd";
import { useTranslations } from "translations/useTranslations";

const getCounterTooltipText = (count: number, translation: string) => {
  const text = translation.replace("{{count}}", `${count}`);
  const isTextPlural = text.at(text.length - 1) === "s";

  const pluralText = isTextPlural ? text : `${text}s`;
  const singularText = isTextPlural ? text.substring(0, text.length - 1) : text;

  return count === 1 ? singularText : pluralText;
}

export const MatchingTableDescriptionCounterTag = React.memo<{ count: number }>(({ count }) => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  return (
    <Tooltip title={getCounterTooltipText(count, translations["matching.table.counter.tooltip"])}>
      <Badge
        style={{color: token.colorPrimaryText, backgroundColor: token.colorPrimaryBg}}
        count={`x${count}`}
      />
    </Tooltip>
  );
});
