import React from "react";

import { stringifyValue } from "modules/TaxonomyModule/helpers/stringifyValue";

import { LabelledRow } from "ui/LabelledRow";

import { TaxonomyItemReadRowProps } from "./TaxonomyItemReadRow.types";

const getRenderedContentItem = (value: unknown) => {
  return React.isValidElement(value as JSX.Element) ? (value as JSX.Element) : stringifyValue(value);
};

const getRenderedContent = (value: unknown) => {
  return Array.isArray(value)
    ? value.map((item, i) => <div key={`${item.id || i}-render`}>{getRenderedContentItem(item)}</div>)
    : getRenderedContentItem(value);
};

export const TaxonomyItemReadRow: React.FC<TaxonomyItemReadRowProps> = ({ title, value }) => {
  const content = React.useMemo(() => getRenderedContent(value), [value]);

  return (
    <div className="border-solid border-0 border-b border-b-black/5">
      <LabelledRow label={title} value={content || "—"} />
    </div>
  );
};
