import React, { useMemo } from "react";
import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

import { FacetSectionChangeType, generateApiUrlType } from "./FacetsModule.types";
import { FacetsServiceType } from "./FacetsService";

type FacetsModuleContextType = {
  urlQueryService: UrlQueryService;
  facetsService: FacetsServiceType;
  facetSectionChange: FacetSectionChangeType;
  generateApiUrl: generateApiUrlType;
  allowedKeys: string[];
};

// TODO: think about other solution instead null as unknown as UrlQueryService, null as unknown as FacetsServiceType
const initialContextValue = {
  urlQueryService: null as unknown as UrlQueryService,
  facetsService: null as unknown as FacetsServiceType,
  facetSectionChange: () => {},
  generateApiUrl: () => "",
  allowedKeys: [],
};

const FacetsModuleContext = React.createContext<FacetsModuleContextType>(initialContextValue);

export const FacetsModuleContextProvider = React.memo<{
  children: JSX.Element;
  urlQueryService: UrlQueryService;
  facetsService: FacetsServiceType;
  facetSectionChange: FacetSectionChangeType;
  generateApiUrl: generateApiUrlType;
  allowedKeys: string[];
}>(({ children, urlQueryService, facetsService, facetSectionChange, generateApiUrl, allowedKeys }) => {
  const value = useMemo(
    () => ({
      urlQueryService,
      facetsService,
      facetSectionChange,
      generateApiUrl,
      allowedKeys,
    }),
    [facetSectionChange, facetsService, generateApiUrl, urlQueryService, allowedKeys]
  );
  return <FacetsModuleContext.Provider value={value}>{children}</FacetsModuleContext.Provider>;
});

export const useFacetsModuleContext = (): FacetsModuleContextType => React.useContext(FacetsModuleContext);
