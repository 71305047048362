import React from "react";
import { Space } from "antd";

import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import {
  MatchingCreateRequestButton
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCreateRequestButton";
import { MatchingIgnoreButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingIgnoreButton";
import { MatchingMoveButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingMoveButton";

export const MatchingTargetsActions = React.memo(() => {
  const { isTransactions } = useCheckDataset();
  return (
    <Space wrap={true}>
      <MatchingCreateRequestButton/>
      {isTransactions && <MatchingMoveButton/>}
      {isTransactions && <MatchingIgnoreButton/>}
    </Space>
  );
});
