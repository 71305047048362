import React from "react";

import { getFacetsUrl, getUrlToMatchingList } from "api/rootUrl";

export const useGenerateMatchingFacetsApiUrl = (jobId: string, datasetName: string) => {
  return React.useCallback((fieldsKeys: string, filterParams: string) => {
    const url = getUrlToMatchingList(jobId, datasetName);

    return getFacetsUrl(url, fieldsKeys) + (filterParams ? `&${filterParams}` : "");
  },
  [datasetName, jobId])
}
