import { Code, TrimmedCode } from "types/entities/Code";

import { MarketCode } from "consts/markets";
import { configurationServiceInstance } from "services/ConfigurationService";

export const filterCodesByMarket = (codes: (Code | TrimmedCode)[], market?: MarketCode) => {
  if (!market) return codes;

  const codesConfig = configurationServiceInstance.getCodesConfig();

  return codes.filter(({ type }) => {
    const codeConfig = codesConfig[type];
    return !codeConfig || codeConfig.countries.includes(market);
  });
};
