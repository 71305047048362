import React from "react";
import { Form, Radio, Space } from "antd";
import { getCountryConfig } from "helpers/countryHelpers";
import { useTranslations } from "translations/useTranslations";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { SelectProviders } from "modules/JobsModule/JobsCreateFormModule/components/SelectProviders";
import { JobsFormValueKeys } from "modules/JobsModule/JobsCreateFormModule/JobsCreateFormModule.types";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { Preloader, PreloaderType } from "ui/Preloader";

export const MarketFormSection = React.memo(() => {
  const translations = useTranslations();
  const { data, isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.MARKET);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} />;

  return (
    <>
      <Form.Item
        name={JobsFormValueKeys.MARKET}
        label={translations["jobs.form.market.label"]}
        initialValue={data?.[0].name}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Space direction="vertical">
            {data?.filter(({ name }) => !!name)?.map(({ name }) => {
              const countryConfig = getCountryConfig(name!);
              const icon = countryConfig.image;
              return (
                <Radio key={name} value={name}>
                  {icon && <img src={icon} alt="country-flag" className="mr-2" />}
                  {name}
                </Radio>
              )
            })}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={JobsFormValueKeys.PROVIDERS}
        label={translations["jobs.form.providers.label"]}
      >
        <SelectProviders type="checkbox" />
      </Form.Item>
    </>
  );
});
