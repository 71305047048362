import { MatchGroup, MatchTarget, MatchTargetFinal } from "types/entities/Match";

import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { extractMatchTargetsFromGroups } from "modules/MatchingModule/MatchingListModule/helpers/extractMatchTargetsFromGroups";

const CONFLICT_SCORE = 1;

export const useMatchingTargetItems = (matchGroups: MatchGroup[], selectedGroupIds: number[]): {
  suggestions: { [taxonomyClass: string]: MatchTarget[] };
  conflicts: MatchTarget[];
  final?: MatchTargetFinal;
} => {
  const { candidates, final } = React.useMemo(
    () => {
      const selectedGroups = matchGroups.filter((group) => selectedGroupIds.includes(group.group_id));
      const { candidates, final } = extractMatchTargetsFromGroups(selectedGroups);

      const itemsIds = candidates.map((candidate) => candidate.taxonomy_item_id);
      if (final?.[0]?.taxonomy_item_id) itemsIds.push(final[0].taxonomy_item_id);

      return { candidates, final, itemsIds };
    },
    [matchGroups, selectedGroupIds]
  );

  return React.useMemo(() => {
    const suggestions: { [taxonomyClass: string]: MatchTarget[] } = {};
    const conflicts: MatchTarget[] = [];

    for (const candidate of candidates) {
      if (candidate.taxonomy_item_id === final[0]?.taxonomy_item_id) continue;

      const [taxonomyClass] = getItemClassFromItemId(candidate.taxonomy_item_id);
      suggestions[taxonomyClass] = suggestions[taxonomyClass] ?? [];
      suggestions[taxonomyClass].push(candidate);

      if (candidate.score === CONFLICT_SCORE) conflicts.push(candidate);
    }

    return { suggestions, conflicts, final: final[0] };
  }, [candidates, final]);
}
