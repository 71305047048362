import { ItemID } from "types/common";
import { Code } from "types/entities/Code";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import { getItemCodesListUrl } from "api/rootUrl";

const getItemCodes = (itemID: ItemID) => () => {
  return axios
    .get<Code[]>(getItemCodesListUrl(itemID))
    .then((data) => data.data);
}

export const useGetItemCodes = (itemID: ItemID, opts?: Pick<UseQueryOptions, "cacheTime" | "staleTime">) => {
  const enabled = !!itemID;

  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKeys.GET_ITEM_CODES, itemID],
    queryFn: getItemCodes(itemID),
    enabled,
    ...opts,
  });

  return { itemCodes: data, isLoading: isLoading && enabled, isError };
}
