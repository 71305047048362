import { SynonymType } from "types/entities/Synonym";

import React, { useRef } from "react";
import { Alert, Empty } from "antd";
import { useActionOnAppearanceInViewport } from "hooks/useActionOnAppearanceInViewport";
import { useTranslations } from "translations/useTranslations";

import { SynonymExactTable } from "modules/SynonymModule/SynonymListModule/components/SynonymExactTable";
import { SynonymWildcardTable } from "modules/SynonymModule/SynonymListModule/components/SynonymWildcardTable";
import { useGetSynonymItems } from "modules/SynonymModule/SynonymModule/api/useGetSynonymItems";

import { Preloader, PreloaderType } from "ui/Preloader";
import { PaginationPreloader } from "ui/Preloader/PaginationPreloader";

import { SynonymListProps } from "./SynonymList.types";

const emptyDescriptionKeys = {
  [SynonymType.EXACT]: "synonym.table.empty.exact",
  [SynonymType.WILDCARD]: "synonym.table.empty.wildcard",
  [SynonymType.EXCLUSION]: "synonym.table.empty.exclusion",
};

export const SynonymList = React.memo<SynonymListProps>((props) => {
  const { taxonomyItemId, synonymType, searchValue } = props;

  const translations = useTranslations();

  const {
    synonymItems = [],
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetSynonymItems(taxonomyItemId, synonymType, searchValue);

  const actionOnAppearanceInViewport = React.useCallback(
    () => !isFetchingNextPage && hasNextPage && fetchNextPage(),
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  );
  const divRef = useRef(null);
  useActionOnAppearanceInViewport(divRef, actionOnAppearanceInViewport);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} />;
  if (isError) return <Alert type="error" message={translations["error.default"]} />;
  if (!synonymItems?.length) return <Empty description={translations[emptyDescriptionKeys[synonymType]]} />;

  return (
    <div className="max-h-[420px] overflow-y-auto">
      {synonymType === SynonymType.EXACT
        ? <SynonymExactTable items={synonymItems} synonymType={synonymType} taxonomyItemId={taxonomyItemId}/>
        : <SynonymWildcardTable items={synonymItems} synonymType={synonymType} taxonomyItemId={taxonomyItemId}/>
      }
      {isFetchingNextPage && <PaginationPreloader text={translations["synonym.table.load_more"]}/>}
      {/* className="mb-2" required because IntersectionObserver
          did not work on some computers when scrolling to the end. */}
      <div ref={divRef} className="h-2 mb-2" />
    </div>
  );
});
