import React from "react";
import { getTaxonomyReadPath } from "router/path";

import { stringifyValue } from "modules/TaxonomyModule/helpers/stringifyValue";

import { CatalogLinkWithPrevent } from "components/CatalogLinkWithPrevent";

const isTaxonomyItem = (value: unknown): value is { id: string; name: string } =>
  !!value && typeof value === "object" && "name" in value && "id" in value;

export const CustomDefaultColumn = React.memo<{ value: unknown }>(({ value }) => {
  if (Array.isArray(value)) {
    const sortedValue = value.sort(
      (a, b) => a.name && b.name ? a.name.localeCompare(b.name) : a.id.localeCompare(b.id)
    );
    return (
      <>
        {sortedValue.map((v, index) => (
          <React.Fragment key={v.id || index}>
            <CustomDefaultColumn value={v} />
            {value.length - 1 === index ? "" : ", "}
          </React.Fragment>
        ))}
      </>
    );
  }

  if (isTaxonomyItem(value)) {
    return <CatalogLinkWithPrevent href={getTaxonomyReadPath(value.id)} name={value.name} />;
  }
  return <>{stringifyValue(value)}</>;
});
