import { ItemID } from "types/common";
import { Price } from "types/entities/Price";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import { getItemPriceUrl } from "api/rootUrl";

const getItemPrices = (itemID: ItemID) => () => {
  return axios
    .get<Price[]>(getItemPriceUrl(itemID))
    .then((data) => data.data);
}

export const useGetItemPrices = (itemID: ItemID, opts?: Pick<UseQueryOptions, "cacheTime" | "staleTime">) => {
  const enabled = !!itemID;

  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKeys.GET_ITEM_PRICE, itemID],
    queryFn: getItemPrices(itemID),
    enabled,
    ...opts,
  });

  return { itemPrices: data, isLoading: isLoading && enabled, isError };
}
