import { UNKNOWN_PREMATCH_CLASS } from "types/entities/Prematch";

import React from "react";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { useGetInfoToMatchData } from "./useGetInfoToMatchData";
import { useTreeBuilders } from "./useTreeBuilders";

export const useCascaderOptions = () => {
  const translations = useTranslations();
  const {
    drugClasses,
    medicalBrands,
    foodClasses,
    foodBrands,
    foodProductLines,
    retailClasses,
    retailBrands,
    isLoading
  } = useGetInfoToMatchData();

  const { buildMedicalTree, buildFoodTree, buildRetailTree } = useTreeBuilders();

  const medicalTree = React.useMemo(
    () => isLoading ? [] : buildMedicalTree(drugClasses, medicalBrands),
    [isLoading, buildMedicalTree, drugClasses, medicalBrands]
  );
  const foodTree = React.useMemo(
    () => isLoading ? [] : buildFoodTree(foodClasses, foodBrands, foodProductLines),
    [isLoading, buildFoodTree, foodClasses, foodBrands, foodProductLines]
  );
  const retailTree = React.useMemo(
    () => isLoading ? [] : buildRetailTree(retailClasses, retailBrands),
    [buildRetailTree, isLoading, retailBrands, retailClasses]
  );

  const options = React.useMemo(() => [
    {
      label: getTaxonomyClassTitle(TaxonomyClass.MEDICAL_PRODUCT),
      value: TaxonomyClass.MEDICAL_PRODUCT,
      children: medicalTree
    },
    {
      label: getTaxonomyClassTitle(TaxonomyClass.FOOD_PRODUCT),
      value: TaxonomyClass.FOOD_PRODUCT,
      children: foodTree
    },
    {
      label: getTaxonomyClassTitle(TaxonomyClass.RETAIL_PRODUCT),
      value: TaxonomyClass.RETAIL_PRODUCT,
      children: retailTree
    },
    {
      label: getTaxonomyClassTitle(TaxonomyClass.DIAGNOSTIC_TEST),
      value: TaxonomyClass.DIAGNOSTIC_TEST
    },
    {
      label: translations["static.item.unknown"],
      value: UNKNOWN_PREMATCH_CLASS
    }
  ], [medicalTree, foodTree, retailTree, translations]);

  return { options, isLoading };
}
