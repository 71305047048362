import { ItemID } from "types/common";

import React from "react";
import { getCountryConfig } from "helpers/countryHelpers";
import { getFormattedPriceRange } from "helpers/priceHelpers";

import { useGetItemPricesWithCache } from "api/useGetItemPrices";

import { Preloader, PreloaderType } from "ui/Preloader";

export const TaxonomyItemPrice = React.memo<{ itemID: ItemID, country: string }>(({ itemID, country }) => {
  const { itemPrices, isLoading } = useGetItemPricesWithCache(itemID);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={false} />

  const { price_min, price_max } = itemPrices?.find((price) => price.country.toLowerCase() === country.toLowerCase()) || {};
  const { currencyName } = getCountryConfig(country);

  return <span>{getFormattedPriceRange(currencyName, price_min, price_max)}</span>;
});
