import {
  FacetsComponentsEnum,
  FacetSectionConfigurationItem,
  FacetSectionConfigurationStaticItem
} from "modules/FacetsModule/FacetsModule.types";
import {
  diagnosticTestFacetsKeys,
  foodProductFacetsKeys,
  medicalProductFacetsKeys,
  retailProductFacetsKeys
} from "modules/TaxonomyModule/consts/facetsKeys";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import {
  MedicalProductProperties,
  RetailProductProperties
} from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";

export const statusFacetSection: FacetSectionConfigurationStaticItem = {
  key: "status",
  titleTranslationKey: "item.table.facets.item.status.title",
  type: FacetsComponentsEnum.FLAT_TREE,
  isStatic: true,
};
export const completenessFacetSection: FacetSectionConfigurationStaticItem = {
  key: "is_abstract",
  titleTranslationKey: "item.table.facets.item.completeness.title",
  type: FacetsComponentsEnum.FLAT_TREE,
  isStatic: true,
};

export const defaultFacetSectionConfiguration: FacetSectionConfigurationItem[] = [
  statusFacetSection,
  completenessFacetSection,
];

export const diagnosticTestFacetSectionsConfiguration: FacetSectionConfigurationItem[] = [
  statusFacetSection,
  {
    key: diagnosticTestFacetsKeys[TaxonomyClass.PANEL_TYPE],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PANEL_TYPE,
  },
  {
    key: diagnosticTestFacetsKeys[TaxonomyClass.MANUFACTURER],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MANUFACTURER,
  },
  {
    key: diagnosticTestFacetsKeys[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_TEST_LOCATION,
  },
  {
    key: diagnosticTestFacetsKeys[TaxonomyClass.DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS,
  }
];

export const foodProductFacetSectionsConfiguration: FacetSectionConfigurationItem[] = [
  statusFacetSection,
  completenessFacetSection,
  {
    key: foodProductFacetsKeys[TaxonomyClass.FOOD_PRODUCT_LINE],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_PRODUCT_LINE,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.PRESCRIPTION_OTC],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PRESCRIPTION_OTC,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.FOOD_BRAND],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_BRAND,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.FOOD_CLASS],
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.FOOD_CLASS,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.MARKET],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MARKET,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.MANUFACTURER],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MANUFACTURER,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.CORPORATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.CORPORATION,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.SPECIES],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.SPECIES,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.TREATMENT_INDICATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.TREATMENT_INDICATION,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.FLAVOR],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FLAVOR,
  },
  {
    key: foodProductFacetsKeys[TaxonomyClass.FOOD_FORM],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_FORM,
  },
];

export const medicalProductFacetSectionsConfiguration: FacetSectionConfigurationItem[] = [
  statusFacetSection,
  completenessFacetSection,
  {
    key: medicalProductFacetsKeys[TaxonomyClass.MEDICAL_BRAND],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MEDICAL_BRAND,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.PRINCIPAL_GENERIC],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PRINCIPAL_GENERIC,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.DRUG_CLASS],
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.DRUG_CLASS,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.MARKET],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MARKET,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.PHYSICAL_FORM],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PHYSICAL_FORM,
  },
  {
    key: medicalProductFacetsKeys.DURATION_OF_EFFECT,
    taxonomyClassPropKey: MedicalProductProperties.DURATION_OF_EFFECT,
    type: FacetsComponentsEnum.RANGE,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.ROUTE_OF_ADMINISTRATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ROUTE_OF_ADMINISTRATION,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.ACTIVE_INGREDIENT],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ACTIVE_INGREDIENT,
  },
  {
    key: medicalProductFacetsKeys.SECONDARY_ACTIVE_INGREDIENT,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ACTIVE_INGREDIENT,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.SPECIES],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.SPECIES,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.PACK_COLOR],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PACK_COLOR,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.TREATMENT_INDICATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.TREATMENT_INDICATION,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.MANUFACTURER],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MANUFACTURER,
  },
  {
    key: medicalProductFacetsKeys[TaxonomyClass.CORPORATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.CORPORATION,
  },
];

export const retailProductFacetSectionsConfiguration: FacetSectionConfigurationItem[] = [
  statusFacetSection,
  completenessFacetSection,
  {
    key: retailProductFacetsKeys[TaxonomyClass.RETAIL_CLASS],
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.RETAIL_CLASS,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.RETAIL_BRAND],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.RETAIL_BRAND,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.MARKET],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MARKET,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.PHYSICAL_FORM],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PHYSICAL_FORM,
  },
  {
    key: retailProductFacetsKeys.DURATION_OF_EFFECT,
    taxonomyClassPropKey: RetailProductProperties.DURATION_OF_EFFECT,
    type: FacetsComponentsEnum.RANGE,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.ROUTE_OF_ADMINISTRATION],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ROUTE_OF_ADMINISTRATION,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.ACTIVE_INGREDIENT],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.OTC_ACTIVE_INGREDIENT,
  },
  {
    key: retailProductFacetsKeys.SECONDARY_OTC_ACTIVE_INGREDIENT,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.OTC_ACTIVE_INGREDIENT,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.SPECIES],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.SPECIES,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.BREED_SIZE],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.BREED_SIZE,
  },
  {
    key: retailProductFacetsKeys[TaxonomyClass.MANUFACTURER],
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MANUFACTURER,
  },
];
