import React from "react";
import Highlighter from "react-highlight-words";

export const MatchingMoveHighlighter = React.memo<{ searchValue: string; text: string }>(({
  searchValue,
  text,
}) => (
  <Highlighter
    searchWords={[searchValue]}
    textToHighlight={text}
    highlightClassName="ant-cascader-menu-item-keyword"
    highlightTag="span"
  />
));
