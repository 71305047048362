import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Checkbox, CheckboxProps, Radio, RadioChangeEvent, Result, Space } from "antd";

import { useEndpointByTaxonomyClass } from "api/useEndpoint";

import { JobsFormValueKeys } from "modules/JobsModule/JobsCreateFormModule/JobsCreateFormModule.types";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { Preloader, PreloaderType } from "ui/Preloader";

import { SelectProvidersProps } from "./SelectProviders.types";

export const SelectProviders = React.memo<SelectProvidersProps>(({ type, value, onChange }) => {
  const {
    data: providers,
    isError,
    isLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.PROVIDER.toLowerCase(), true);

  const handleCheckboxChange = React.useCallback((value: CheckboxProps["value"][]) => {
    onChange?.(value.map(id => providers.find(provider => provider.id === id) as TaxonomyItem))
  }, [onChange, providers]);

  const handleRadioChange = React.useCallback((e: RadioChangeEvent) => {
    onChange?.([providers.find(provider => provider.id === e.target.value) as TaxonomyItem])
  }, [onChange, providers]);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} />;
  if (isError) return <Result status="error" title="Error" />;

  if (type === "checkbox") {
    return (
      <Checkbox.Group
        name={JobsFormValueKeys.PROVIDERS}
        value={value?.map(provider => provider.id)}
        onChange={handleCheckboxChange}
      >
        <Space direction="vertical">
          {providers.map((provider) => (
            <Checkbox key={provider.id} value={provider.id}>{provider.name}</Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    )
  }

  if (type === "radio") {
    return (
      <Radio.Group
        name={JobsFormValueKeys._CLINICS_PROVIDERS}
        value={value?.[0]?.id}
        onChange={handleRadioChange}>
        <Space direction="vertical">
          {providers.map((provider) => (
            <Radio key={provider.id} value={provider.id}>{provider.name}</Radio>
          ))}
        </Space>
      </Radio.Group>
    );
  }

  return null;
});
