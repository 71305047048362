import React from "react";
import { Link } from "react-router-dom";

import { CatalogLinkProps } from "modules/CatalogModule/CatalogModule.types";
import { useModifiedMatchingPaths } from "modules/MatchingModule/MatchingListModule/hooks/useModifiedMatchingPaths";

export const MatchingCatalogLink = React.memo<CatalogLinkProps>((props) => {
  const { getMatchingCatalogPathWithMatchingSearch } = useModifiedMatchingPaths();
  const { children, to, ...rest } = props;
  const path = getMatchingCatalogPathWithMatchingSearch(to as string);

  return <Link to={path} {...rest}>{children}</Link>;
});
