import { TaxonomyItem } from "types/entities/Taxonomy";

import { generateStringByTemplate } from "../generateByUIConfiguration";

const checkRegExp = /[A-Za-z0-9?—]/;

export const getIsResultByTemplateExist = (template: string, taxonomyItem: Partial<TaxonomyItem>) => {
  const result = generateStringByTemplate(template, taxonomyItem).trim();
  return checkRegExp.test(result);
};
