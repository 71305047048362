import { TaxonomyItem } from "types/entities/Taxonomy";

import { useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { getRelationsKeysForTaxonomyTable } from "helpers/expandHelpers/getRelationsKeysForTaxonomyTable";
import { combinePages, getNextPageParam } from "helpers/paginationHelpers";

import { QueryKeys } from "api/queryKeys";
import { getFilterPart, taxonomyUrl } from "api/rootUrl";

import { UIConfigurationColumns } from "modules/TaxonomyModule/TaxonomyTableModule/UIConfigurationColumns";

import { PaginationResultType } from "./useGetListOfTaxonomyByTaxonomyClass.types";

const getListOfTaxonomyByName = (taxonomyClass = "", filterString: string) => {
  return ({ pageParam }: QueryFunctionContext) => {
    const expandWithKeys = getRelationsKeysForTaxonomyTable(taxonomyClass, UIConfigurationColumns);
    return axios
      .get<PaginationResultType>(
        `${taxonomyUrl}${taxonomyClass?.toLowerCase()}/${getFilterPart(filterString, pageParam, expandWithKeys)}`
      )
      .then((res) => res.data);
  };
};

export const useGetListOfTaxonomyByTaxonomyClass = (taxonomyClass: string, filterParams: string) => {
  const enabled = !!taxonomyClass;

  const { data, isLoading, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [QueryKeys.GET_LIST_TAXONOMY_ITEMS, taxonomyClass, filterParams],
    queryFn: getListOfTaxonomyByName(taxonomyClass, filterParams),
    enabled,
    getNextPageParam: getNextPageParam,
  });

  return {
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    data: useMemo(() => combinePages<TaxonomyItem>(data?.pages), [data]),
    isLoading: isLoading && enabled,
    isError,
  };
};
