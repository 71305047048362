import { UIError } from "types/UIError";

import React from "react";
import { useTranslations } from "translations/useTranslations";

export const useGetTranslatedUiError = (): (error: UIError) => UIError => {
  const translations = useTranslations();

  return React.useCallback((error: UIError) => {

    if (typeof error === "string") return translations[error] || error;

    return {
      title: translations[error.title] || error.title,
      content: translations[error.content] || error.content,
    }
  }, [translations]);
}
