import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { defaultTimeToLive } from "consts";

import { QueryKeys } from "api/queryKeys";

import { useFacetsModuleContext } from "../FacetsModule.context";
import { FacetsValuesCommonType } from "../FacetsModule.types";
import { FacetsServiceType } from "../FacetsService";

const getListFacetsValues = (facetApiUrl: string) => () =>
  axios.get<FacetsValuesCommonType>(facetApiUrl).then((result) => result.data);

const EMPTY = { value: [] };

export const useGetFacetsValuesWithoutContext = (facetKey: string, facetApiUrl: string, enabled?: boolean) => {
  const { isFetching, isError, refetch, data, isLoading } = useQuery({
    queryKey: [QueryKeys.GET_LIST_FACETS_VALUES, facetApiUrl],
    queryFn: getListFacetsValues(facetApiUrl),
    enabled: !!facetApiUrl && !!facetKey && enabled,
    retry: false,
    keepPreviousData: true,
    ...defaultTimeToLive,
  });

  return {
    isFacetsValuesLoading: isFetching,
    isError,
    refetch,
    data: data?.[facetKey] || EMPTY,
    isLoading,
  };
};

export const useGetFacetsValues = (facetKey: string, facetsService: FacetsServiceType, enabled?: boolean) => {
  const { generateApiUrl } = useFacetsModuleContext();

  const facetApiUrl = generateApiUrl(facetKey);

  return useGetFacetsValuesWithoutContext(facetKey, facetApiUrl, enabled);
};
