import { UIConfigurationColumnsType, UIConfigurationColumnType } from "types/UIConfigurationColumnsTypes";

import { JSONSchema7 } from "json-schema";
import { configurationServiceInstance } from "services/ConfigurationService";

import { propertiesForHiding } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { NAME_COLUMN_KEY } from "modules/TaxonomyModule/TaxonomyTableModule/components/CustomNameColumn/consts";

export const getColumnsByConfiguration = (
  taxonomyClass: string,
  leftFixedColumnName: string
): UIConfigurationColumnsType => {
  const taxonomyItemConfiguration = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  const propertiesKeys = Object.keys(taxonomyItemConfiguration?.properties || {});
  const relationsKeys = Object.keys(taxonomyItemConfiguration?.meta?.relations || {});

  const propertyInsteadOfName = taxonomyItemConfiguration?.meta?.propertyInsteadOfName;

  const columns = [...propertiesKeys, ...relationsKeys]
    .filter((key) => !propertiesForHiding.includes(key) && key !== propertyInsteadOfName)
    .sort((elA, elB) => {
      if (elA === leftFixedColumnName) return -1;
      if (elB === leftFixedColumnName) return 1;
      return 0;
    })
    .map((key) => ({ key }));

  if (propertyInsteadOfName && !Object.keys(taxonomyItemConfiguration?.properties || {}).includes(NAME_COLUMN_KEY))  {
    const nameColumn: UIConfigurationColumnType = { key: NAME_COLUMN_KEY };
    const propertyInsteadOfNameTitle = (taxonomyItemConfiguration?.properties?.[propertyInsteadOfName] as JSONSchema7)?.title;
    if (propertyInsteadOfNameTitle) nameColumn.title = propertyInsteadOfNameTitle;
    columns.unshift(nameColumn);
  }

  return columns;
};
