import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Form, Radio, Space } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { Preloader, PreloaderType } from "ui/Preloader";

export const LocationSelector = React.memo<{ items: TaxonomyItem[], isLoading: boolean }>(({
  items,
  isLoading,
}) => {
  const translations = useTranslations();
  const { getTaxonomyItemByName } = configurationServiceInstance;

  return (
    <Form.Item
      name={TaxonomyClass.DIAGNOSTIC_TEST_LOCATION}
      label={getTaxonomyItemByName(TaxonomyClass.DIAGNOSTIC_TEST_LOCATION)?.title || TaxonomyClass.DIAGNOSTIC_TEST_LOCATION}
      required
    >
      <Radio.Group>
        <Space direction="vertical">
          {isLoading && <Preloader type={PreloaderType.SKELETON} title={false} />}
          {items.map(({ id, name }) => (
            <Radio key={id} value={id}>{name}</Radio>
          ))}
          <Radio value={""}>{translations["matching.diagnostics.location.unknown.label"]}</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
});
