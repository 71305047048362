import { TaxonomyItem } from "types/entities/Taxonomy";

import React, { useMemo } from "react";
import { configurationServiceInstance } from "services/ConfigurationService";

import { getIsDeepRelation } from "modules/TaxonomyModule/TaxonomyItemReadModule/helpers/getIsDeepRelation";

import { TaxonomyItemReadRelation } from "../TaxonomyItemReadRelation/TaxonomyItemReadRelation";

export const RelationsList = React.memo<{
  relations: TaxonomyItem["relations"];
  nestingDepth: number;
  taxonomyClass: string;
}>(({ relations, taxonomyClass, nestingDepth }) => {
  const sortedRelations = useMemo(
    () =>
      Object.entries(relations || {}).sort(([relationNameA], [relationNameB]) => {
        const relationConfigurationA = configurationServiceInstance.getTaxonomyItemRelation(
          taxonomyClass,
          relationNameA
        );
        const isDeepRelationA = getIsDeepRelation(relationConfigurationA);

        const relationConfigurationB = configurationServiceInstance.getTaxonomyItemRelation(
          taxonomyClass,
          relationNameB
        );
        const isDeepRelationB = getIsDeepRelation(relationConfigurationB);

        return isDeepRelationA === isDeepRelationB ? 0 : isDeepRelationA > isDeepRelationB ? 1 : -1;
      }),
    [relations, taxonomyClass]
  );

  return (
    <>
      {!!relations &&
        sortedRelations.map(([key, relationsList]) => (
          <TaxonomyItemReadRelation
            key={key}
            relationName={key}
            relationItems={relationsList}
            parentTaxonomyClass={taxonomyClass}
            nestingDepth={nestingDepth + 1}
          />
        ))}
    </>
  );
});
