import { MatchStatus, MatchTargetFinal } from "types/entities/Match";

import React from "react";
import { CheckCircleFilled, CloseCircleFilled, WarningFilled } from "@ant-design/icons";
import { AlertProps, Result, Space, theme, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { getStatusAlertTypeMap, getStatusTitleMap } from "modules/MatchingModule/helpers/statusMaps";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { MatchingTargetFinalControls } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetFinalControls";
import { MatchingTargetItem } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetItem";
import { MatchingTargetItemRequestControls } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetItemRequestControls";
import { MatchingUnignoreButton } from "modules/MatchingModule/MatchingTargetSpecialModule/components/MatchingUnignoreButton";

const AlertTitle = React.memo(({ children }: { children: React.ReactNode }) => (
  <Typography.Title level={5} className="flex gap-2 items-center" style={{ margin: 0 }}>
    {children}
  </Typography.Title>
));

const ErrorMessage = () => {
  const translations = useTranslations();

  return <Result title={translations["matching.error.no_final_item"]} status="error" />;
};

const getContent = (finalItem?: MatchTargetFinal, shouldShowItem?: boolean) => {
  if (!shouldShowItem) return null;
  if (!finalItem) return <ErrorMessage />;
  return <MatchingTargetItem matchTarget={finalItem} isFinal={true} isLinkedToFindInCatalog={false} />;
};

export const useAlertsPropsMap = (): Record<
  Exclude<MatchStatus, MatchStatus.NOMATCH>,
  {
    message: React.ReactNode;
    type: AlertProps["type"];
    content?: React.ReactNode;
    controls?: React.ReactNode;
  }
> => {
  const translations = useTranslations();
  const { token } = theme.useToken();
  const { targetItems: { conflicts, final } } = useMatchingContext();
  const statusTypeMap = getStatusAlertTypeMap();
  const statusTitleMap = getStatusTitleMap(translations);

  const hasFinalItem = Boolean(final);

  return {
    [MatchStatus.APPROVED]: {
      message: (
        <AlertTitle>
          <CheckCircleFilled style={{ color: token.colorSuccess }} />
          {statusTitleMap[MatchStatus.APPROVED]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.APPROVED],
      content: getContent(final, hasFinalItem),
      controls: hasFinalItem && <MatchingTargetFinalControls />,
    },
    [MatchStatus.CONFLICT]: {
      message: (
        <AlertTitle>
          <WarningFilled style={{ color: token.colorWarning }} />
          {statusTitleMap[MatchStatus.CONFLICT]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.CONFLICT],
      content: (
        <>
          <Typography.Text>{translations["matching.special.description.conflict"]}</Typography.Text>
          <Space direction="vertical" size="middle" className="w-full">
            {conflicts.map((conflictedItem) => (
              <MatchingTargetItem
                key={conflictedItem.taxonomy_item_id}
                matchTarget={conflictedItem}
                isLinkedToFindInCatalog={false}
              />
            ))}
          </Space>
        </>
      ),
    },
    [MatchStatus.GOOD]: {
      message: <AlertTitle>{statusTitleMap[MatchStatus.GOOD]}</AlertTitle>,
      type: statusTypeMap[MatchStatus.GOOD],
      content: getContent(final, hasFinalItem),
      controls: hasFinalItem && <MatchingTargetFinalControls />,
    },
    [MatchStatus.IGNORED]: {
      message: (
        <AlertTitle>
          <CloseCircleFilled style={{ color: token.colorError }} />
          {statusTitleMap[MatchStatus.IGNORED]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.IGNORED],
      controls: <MatchingUnignoreButton />,
    },
    [MatchStatus.ITEM_REQUEST]: {
      message: (
        <AlertTitle>
          <WarningFilled style={{ color: token.colorWarning }} />
          {statusTitleMap[MatchStatus.ITEM_REQUEST]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.ITEM_REQUEST],
      controls: <MatchingTargetItemRequestControls />,
    },
    [MatchStatus.MANUAL]: {
      message: <AlertTitle>{statusTitleMap[MatchStatus.MANUAL]}</AlertTitle>,
      type: statusTypeMap[MatchStatus.MANUAL],
      content: getContent(final, hasFinalItem),
      controls: hasFinalItem && <MatchingTargetFinalControls />,
    },
    [MatchStatus.MOVED]: {
      message: (
        <AlertTitle>
          <WarningFilled style={{ color: token.colorWarning }} />
          {statusTitleMap[MatchStatus.MOVED]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.MOVED],
    },
    [MatchStatus.PERFECT]: {
      message: (
        <AlertTitle>
          <CheckCircleFilled style={{ color: token.colorSuccess }} />
          {statusTitleMap[MatchStatus.PERFECT]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.PERFECT],
      content: getContent(final, hasFinalItem),
      controls: hasFinalItem && <MatchingTargetFinalControls />,
    },
    [MatchStatus.REMATCH]: {
      message: (
        <AlertTitle>
          <WarningFilled style={{ color: token.colorWarning }} />
          {statusTitleMap[MatchStatus.REMATCH]}
        </AlertTitle>
      ),
      type: statusTypeMap[MatchStatus.REMATCH],
      content: (
        <>
          <Typography.Text strong>{translations["matching.special.label.rejected"]}</Typography.Text>
          {getContent(final, hasFinalItem)}
        </>
      ),
    },
  };
};
