import React, { ChangeEvent } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { InlineSelect } from "components/InlineSelect";

import { SynonymFormModuleProps } from "./SynonymFormModule.types";

export const SynonymFormModule = React.memo<SynonymFormModuleProps>((props) => {
  const { taxonomyClass, synonym, onSubmit, onClose, errorMessage, isBusy, okText, value, setValue } = props;
  const translations = useTranslations();

  const languages = configurationServiceInstance.getSynonymLanguages();
  const languagesOptions = languages.map((languageName) => ({ value: languageName, label: languageName }));

  const [language, setLanguage] = React.useState(synonym?.language || languages[0]);

  const scopeSet = configurationServiceInstance.getSynonymScopeByTaxonomyClass(taxonomyClass);
  const scopeSetOptions = React.useMemo(
    () => scopeSet?.values.map(({ value, title }) => ({ value, label: title })),
    [scopeSet?.values]
  );

  const [scope, setScope] = React.useState(
    synonym?.scope ||
    scopeSet?.values.find((scope) => scope.default)?.value ||
    scopeSet?.values[0]?.value
  );

  const handleChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value.trimStart()), [setValue]);
  const handleChangeScope = React.useCallback((newScope: string) => setScope(newScope), []);
  const handleCloseClick = React.useCallback(() => onClose?.(), [onClose]);

  const handleSubmit = React.useCallback(() => {
    const cleanValue = value.trim();
    onSubmit(cleanValue, language, scope);
  }, [value, onSubmit, language, scope]);

  return (
    <Form onFinish={handleSubmit}>
      <div className="flex gap-2 mb-2">
        <Input
          autoFocus={true}
          value={value}
          onChange={handleChange}
          placeholder={translations["synonym.form.input.placeholder"]}
          status={errorMessage && "error"}
        />
        <Select
          onChange={(newLang) => setLanguage(newLang)}
          value={language}
          style={{ width: 120 }}
          options={languagesOptions}
        />
        <Button type="primary" loading={isBusy} htmlType="submit">
          <PlusOutlined />
          {okText || translations["synonym.buttons.create"]}
        </Button>
        {onClose && (
          <Button onClick={handleCloseClick} type="text" disabled={isBusy} htmlType="button">
            {translations["synonym.buttons.cancel"]}
          </Button>
        )}
      </div>
      {!!scopeSetOptions?.length && (
        <div>
          <span>
            {translations["synonym.form.criteria.text"]}
            <InlineSelect
              items={scopeSetOptions}
              selectedItemId={scope}
              onSelect={handleChangeScope}
            />
          </span>
        </div>
      )}
      {!!errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </Form>
  );
});
