import { MatchGroup } from "types/entities/Match";

import { Table } from "antd";
import { ColumnType } from "antd/lib/table";

import {
  MatchingTableDescriptionCell
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableDescriptionCell";
import {
  MatchingTableResultCell
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableResultCell";

export const createColumns = (translations: Record<string, string>): ColumnType<MatchGroup>[] => {
  return [
    Table.EXPAND_COLUMN,
    Table.SELECTION_COLUMN,
    {
      key: "match_text",
      dataIndex: "match_text",
      title: translations["matching.table.column.description"],
      width: "49%",
      render: (_, matchGroup) => <MatchingTableDescriptionCell matchGroup={matchGroup} />
    },
    {
      key: "match_text",
      dataIndex: "match_text",
      title: translations["matching.table.column.result"],
      width: "49%",
      render: (_, matchGroup) => <MatchingTableResultCell matchGroup={matchGroup} />
    }
  ];
};
