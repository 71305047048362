import { SynonymType } from "types/entities/Synonym";

import { JSONSchema7 } from "json-schema";

// Relations
export enum RelationElementConfigurationTypeEnum {
  SINGLE = "single",
  MULTIPLE = "multiple",
}
export type RelationElementConfiguration = {
  type: RelationElementConfigurationTypeEnum;
  to: string;
  title?: string;
};
export type RelationsConfiguration = {
  [relationName: string]: RelationElementConfiguration;
};

// Taxonomy
export type TaxonomyConfiguration = JSONSchema7 & {
  meta: {
    propertyInsteadOfName?: string;
    endpoint: string;
    relations: RelationsConfiguration;
    synonyms: SynonymType[];
    synonymScopeSet?: string;
    prices?: boolean;
    codes?: boolean;
    creatable: boolean;
    editable: boolean;
  };
};

export type TaxonomyClasses = {
  [key: string]: TaxonomyConfiguration;
};

export type CodesConfig = {
  [code: string]: {
    countries: string[];
    title?: string;
    unique?: boolean;
  };
};

export type SynonymScopeSetItem = {
  value: string;
  title: string;
  default?: boolean;
}

export type SynonymScopeSet = {
  set_name: string;
  values: SynonymScopeSetItem[];
}

// AppConfig
export type AppConfig = {
  taxonomyClasses: TaxonomyClasses;
  codes: CodesConfig;
  synonymLanguages: string[];
  synonymScopeSets: SynonymScopeSet[];
};
