import { ItemID } from "types/common";
import { FilterByPrematchItem } from "types/entities/Jobs";

import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";

import { GenericTaxonomyClass } from "modules/TaxonomyModule/consts";

export const mapInfoToMatchFromFEtoBE = (values?: ItemID[][]): FilterByPrematchItem[] => {
  if (!values) return [];

  const data = [];

  const finalValues = values.map((value) => value.at(-1));

  const taxonomyClasses = configurationServiceInstance.listOfTaxonomyClasses;

  for (const value of finalValues) {
    if (!value) continue;
    const [itemTaxonomyClass] = getItemClassFromItemId(value);
    const taxonomyClass = taxonomyClasses.find((taxonomyClass) => itemTaxonomyClass === taxonomyClass.toLowerCase());
    data.push({ type: taxonomyClass || GenericTaxonomyClass, value })
  }

  return data;
}
