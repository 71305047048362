import { Route } from "react-router-dom";
import { TaxonomyCreatePage } from "pages/TaxonomyCreatePage";
import { TaxonomyEditPage } from "pages/TaxonomyEditPage";
import { TaxonomyListPage } from "pages/TaxonomyListPage";
import { TaxonomyReadPage } from "pages/TaxonomyReadPage";
import {
  taxonomyCreatePath,
  taxonomyListPath,
  taxonomyReadPath,
  taxonomyUpdatePath,
} from "router/path";

export const TaxonomyRoutes = [
  <Route key={taxonomyListPath} path={taxonomyListPath} element={<TaxonomyListPage />} />,
  <Route key={taxonomyCreatePath} path={taxonomyCreatePath} element={<TaxonomyCreatePage />} />,
  <Route key={taxonomyReadPath} path={taxonomyReadPath} element={<TaxonomyReadPage />} />,
  <Route key={taxonomyUpdatePath} path={taxonomyUpdatePath} element={<TaxonomyEditPage />} />
];
