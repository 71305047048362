import { ItemID } from "types/common";

import { JSONSchema7Type } from "json-schema";

export enum TaxonomyStatus {
  active = "active",
  deactivated = "deactivated",
  ignored = "ignored",
}

export type TaxonomyCompleteness = boolean;

type HistoryTaxonomyPart = {
  created_dts: string;
  created_by: string;
  modified_dts: string;
  modified_by: string;
};

export type TaxonomyItem = {
  id: ItemID;
  name?: string;
  generatedName?: string;
  relations?: {
    [relationName: string]: TaxonomyItem[];
  };
  status: TaxonomyStatus;
  is_abstract: boolean;
} & HistoryTaxonomyPart & {
    [prop: string]: JSONSchema7Type;
  };
