import { ItemID } from "types/common";

import { NodeTree } from "modules/FacetsModule/FacetsModule.types";

const getParent = (treeElement: NodeTree, initialTreeMap: Record<string, NodeTree>): NodeTree[] => {
  if (!treeElement.parent) return [];
  return [treeElement.parent, ...getParent(initialTreeMap[treeElement.parent.key], initialTreeMap)];
};

export const findAllValuesFromTree = (
  initialTreeMap: Record<string, NodeTree>,
  newTreeValues: { name?: string; id: ItemID }[]
): string[] => {
  const listOfSavedValues: string[] = [];

  newTreeValues.forEach((treeValue) => {
    const { id } = treeValue;
    const treeElementFromMap = initialTreeMap[id];

    if (!treeElementFromMap) return;

    getParent(treeElementFromMap, initialTreeMap).forEach(
      (ch) => listOfSavedValues.includes(ch.key) || listOfSavedValues.push(ch.key)
    );
    if (!listOfSavedValues.includes(id)) listOfSavedValues.push(id);
  });

  return listOfSavedValues;
};
