import React, { RefObject } from "react";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const useScrollToTop = (containerRef: RefObject<HTMLDivElement>) => {
  const { selectedGroupIds } = useMatchingContext();

  const prevSelectedGroupIds = React.useRef<number[]>([]);

  React.useEffect(() => {
    if (
      prevSelectedGroupIds.current.length !== selectedGroupIds.length ||
      !prevSelectedGroupIds.current.every((id) => selectedGroupIds.includes(id))
    ) {
      containerRef.current?.scrollTo(0, 0);
    }

    prevSelectedGroupIds.current = selectedGroupIds;
  }, [containerRef, selectedGroupIds]);
}
