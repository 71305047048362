import { MatchGroupPrematch } from "types/entities/Match";

import React from "react";
import { EMPTY } from "consts/strings";

import { TaxonomyItemsNamesViewer } from "modules/MatchingModule/components/TaxonomyItemsViewer";
import { checkInformationKind } from "modules/MatchingModule/helpers/checkInformationKind";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const MatchingTablePrematchResultTitle = React.memo<{ informationKinds: string[]; prematch: Partial<MatchGroupPrematch> }>(({
  informationKinds,
  prematch,
}) => {
  const { isMedicalProduct, isFoodProduct, isRetailProduct, isDiagnosticTest } = checkInformationKind(informationKinds)

  const nameItems: React.ReactNode[] = [];

  if (isMedicalProduct) {
    const medicalBrands = prematch[TaxonomyClass.MEDICAL_BRAND];
    if (medicalBrands?.length) {
      nameItems.push(
        <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.MEDICAL_BRAND} itemsIDs={medicalBrands} />
      );
    }
  }

  if (isFoodProduct) {
    const foodProductLines = prematch[TaxonomyClass.FOOD_PRODUCT_LINE];
    if (foodProductLines?.length) {
      nameItems.push(
        <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.FOOD_PRODUCT_LINE} itemsIDs={foodProductLines} />
      );
    }
  }

  if (isRetailProduct) {
    const retailBrands = prematch[TaxonomyClass.RETAIL_BRAND];
    if (retailBrands?.length) {
      nameItems.push(
        <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.RETAIL_BRAND} itemsIDs={retailBrands} />
      );
    }
  }

  if (isDiagnosticTest) {
    const panelTypes = prematch[TaxonomyClass.PANEL_TYPE];
    if (panelTypes?.length) {
      nameItems.push(
        <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.PANEL_TYPE} itemsIDs={panelTypes} />
      );
    }
  }

  return (
    <span className="flex flex-wrap items-center gap-2">
      {nameItems.length ? nameItems.map((item, i) => (
        <React.Fragment key={i}>
          <span key={i}>{item}</span>
          {i < nameItems.length - 1 && <span>|</span>}
        </React.Fragment>
      )) : EMPTY}
    </span>
  );
});
