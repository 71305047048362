import { MatchStatus } from "types/entities/Match";

export const matchedStatuses = [
  MatchStatus.PERFECT,
  MatchStatus.GOOD,
  MatchStatus.MANUAL,
  MatchStatus.APPROVED,
];

export const unmatchedStatuses = [
  MatchStatus.CONFLICT,
  MatchStatus.NOMATCH,
  MatchStatus.REMATCH,
  MatchStatus.ITEM_REQUEST,
  MatchStatus.MOVED,
]

export const ignoredStatuses = [
  MatchStatus.IGNORED,
]
