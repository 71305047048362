export const stringifyValue = (value: unknown): string => {
  if (Array.isArray(value)) value.join(", ");

  if (value === null || value === undefined) return "—";

  if (typeof value === "object") {
    if ("value" in value) return String(value.value);

    return JSON.stringify(value);
  }

  return String(value);
}
