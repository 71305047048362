import { ItemID } from "types/common";

import React from "react";

import { TaxonomyItemCodesCreateModule } from "./TaxonomyItemCodesCreateModule";
import { TaxonomyItemCodesListModule } from "./TaxonomyItemCodesListModule";

export const TaxonomyItemCodesModule = React.memo<{ itemID: ItemID }>(({ itemID }) => {
  return (
    <div>
      <div className="mb-6">
        <TaxonomyItemCodesCreateModule itemID={itemID} />
      </div>
      <div>
        <TaxonomyItemCodesListModule itemID={itemID} />
      </div>
    </div>
  );
});
