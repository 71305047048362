import React from "react";
import { WarningFilled } from "@ant-design/icons";
import { Card, theme, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

export const MatchingTargetsDiagnosticsConflict = React.memo(() => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  return (
    <Card
      style={{ backgroundColor: token.colorWarningBg, borderColor: token.colorWarningBorder }}
    >
      <div className="flex gap-2 items-start">
        <WarningFilled style={{ fontSize: "24px", color: token.colorWarning }} />
        <Typography.Title level={5} style={{ marginBottom: 16 }}>
          <span className="whitespace-normal">{translations["matching.diagnostics.conflict.title"]}</span>
        </Typography.Title>
      </div>
      <div>
        <Typography.Paragraph>
          {translations["matching.diagnostics.conflict.message.part1"]}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ margin: 0 }}>
          {translations["matching.diagnostics.conflict.message.part2"]}
        </Typography.Paragraph>
      </div>
    </Card>
  );
});
