import React from "react";

import { FacetSet, FacetSetProps } from "modules/FacetsModule/components/FacetSet";
import { getFacetConfiguration } from "modules/TaxonomyModule/helpers/getFacetConfiguration";
import { FacetContainer } from "modules/TaxonomyModule/ListOfTaxonomyModule/components/FacetContainer";

export const getFacetsByTaxonomyClass = (taxonomyClass: string) => {
  const facetConfiguration = getFacetConfiguration(taxonomyClass);
  if (facetConfiguration) {
    return {
      FacetContainer: () => <FacetContainer taxonomyClass={taxonomyClass} />,
      FacetSet: (props: Omit<FacetSetProps, "facetsSectionConfiguration">) => <FacetSet {...props} />,
      facetConfiguration,
      isFacetsExist: true,
    };
  }
  return { FacetContainer: () => null, FacetSet: () => null, isFacetsExist: false, facetConfiguration: [] };
};
