import { Modal } from "antd";
import { useTranslations } from "translations/useTranslations";

export const useShowDeleteConfirm = (
  deleteJob: () => Promise<{}>
) => {
  const translations = useTranslations();

  return () => {
    Modal.confirm({
      title: translations["jobs.delete.popup.title"],
      content: translations["jobs.delete.popup.content"],
      okText: translations["jobs.delete.popup.button.ok"],
      onOk: deleteJob,
      okType: "danger",
      onCancel() {},
    });
  }
};
