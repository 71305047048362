import { TaxonomyItem } from "types/entities/Taxonomy";

import { useEndpointByTaxonomyClass } from "api/useEndpoint";

import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import {
  FOOD_BRAND_RELATION,
  FOOD_PRODUCT_LINE_RELATION,
  MEDICAL_BRAND_RELATION,
  RETAIL_BRAND_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

export const useGetInfoToMatchData = () => {
  const {
    data: drugClasses = [],
    isLoading: isDrugClassesLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.DRUG_CLASS);

  const {
    data: medicalBrands = [],
    isLoading: isMedicalBrandsLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(
    TaxonomyClass.MEDICAL_BRAND,
    true,
    MEDICAL_BRAND_RELATION.TO_DRUG_CLASS
  );

  const {
    data: foodClasses = [],
    isLoading: isFoodClassesLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(
    TaxonomyClass.FOOD_CLASS
  );

  const {
    data: foodBrands = [],
    isLoading: isFoodBrandsLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(
    TaxonomyClass.FOOD_BRAND,
    true,
    FOOD_BRAND_RELATION.TO_FOOD_CLASS
  );

  const {
    data: foodProductLines = [],
    isLoading: isFoodProductLinesLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(
    TaxonomyClass.FOOD_PRODUCT_LINE,
    true,
    FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND
  );

  const {
    data: retailClasses = [],
    isLoading: isRetailClassesLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.RETAIL_CLASS);

  const {
    data: retailBrands = [],
    isLoading: isRetailBrandsLoading,
  } = useEndpointByTaxonomyClass<TaxonomyItem>(
    TaxonomyClass.RETAIL_BRAND,
    true,
    RETAIL_BRAND_RELATION.TO_RETAIL_CLASS
  );

  const isLoading =
    isDrugClassesLoading ||
    isMedicalBrandsLoading ||
    isFoodClassesLoading ||
    isFoodBrandsLoading ||
    isFoodProductLinesLoading ||
    isRetailBrandsLoading ||
    isRetailClassesLoading;

  return {
    drugClasses,
    medicalBrands,
    foodClasses,
    foodBrands,
    foodProductLines,
    retailClasses,
    retailBrands,
    isLoading,
  }
}
