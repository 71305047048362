import { ItemID } from "types/common";
import { Code } from "types/entities/Code";

import React from "react";
import { FormInstance } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EMPTY } from "consts/strings";
import { formatDate } from "helpers/formatDate";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyItemCodeType } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/components/TaxonomyItemCodeType";
import { TaxonomyItemCodesTableControls } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesListModule/components/TaxonomyItemCodesTableControls";

const defaultRender = (value: unknown) => value || EMPTY;

export const useColumns = (itemID: ItemID, form: FormInstance, codes: Code[]): ColumnsType<Code> => {
  const translations = useTranslations();

  const [editingId, setEditingId] = React.useState("");
  const isEditing = (code: Code) => code.id === editingId;

  return [
    {
      key: "code",
      dataIndex: "code",
      title: translations["item.codes.table.column.code"],
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: defaultRender,
      onCell: (code: Code) => ({
        codeId: code.id,
        dataIndex: "code",
        title: translations["item.codes.table.column.code"],
        editing: isEditing(code),
        codes,
      }),
    },
    {
      key: "type",
      dataIndex: "type",
      title: translations["item.codes.table.column.type"],
      width: "200px",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (_, code) => <TaxonomyItemCodeType type={code.type} />,
      onCell: (code: Code) => ({
        codeId: code.id,
        dataIndex: "type",
        title: translations["item.codes.table.column.type"],
        editing: isEditing(code),
        codes,
      }),
    },
    {
      key: "modified_by",
      title: translations["item.codes.table.column.modified_by"],
      width: "200px",
      sorter: (a, b) => (a.modified_by && b.modified_by ? a.modified_by.localeCompare(b.modified_by) : 0),
      render: (code: Code) => code.modified_by || code.created_by,
    },
    {
      key: "modified_dts",
      title: translations["item.codes.table.column.modified_dts"],
      width: "100px",
      sorter: (a, b) =>
        a.modified_dts && b.modified_dts
          ? new Date(a.modified_dts).getTime() > new Date(b.modified_dts).getTime() ? 1 : -1
          : 0,
      render: (code: Code) => formatDate(code.modified_dts || code.created_dts),
    },
    {
      title: "",
      dataIndex: "_controls",
      width: "100px",
      render: (_: any, code: Code) => (
        <TaxonomyItemCodesTableControls
          itemID={itemID}
          code={code}
          itemCodes={codes}
          form={form}
          setEditingId={setEditingId}
          isEditing={isEditing}
        />
      ),
    },
  ];
};
