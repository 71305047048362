import { MatchGroup } from "types/entities/Match";

import React from "react";
import {
  getTaxonomyClassTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";

import { TaxonomyItemsNamesViewer } from "modules/MatchingModule/components/TaxonomyItemsViewer";
import { GenericTaxonomyClass } from "modules/TaxonomyModule/consts";

import { SecondaryLabelledList, SecondaryLabelledListProps } from "components/SecondaryLabelledList";

export const MatchingTablePrematchDetails = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const items = React.useMemo<SecondaryLabelledListProps["items"]>(() => {
    return Object.entries(matchGroup.metadata_?.prematch || [])
      .filter(([_, values]) => values.length > 0)
      .map(([taxonomyClass, values]) => ({
        title: getTaxonomyClassTitle(taxonomyClass),
        value: taxonomyClass === GenericTaxonomyClass
          ? values.map(getTaxonomyClassTitle).join(", ")
          : <TaxonomyItemsNamesViewer taxonomyClass={taxonomyClass} itemsIDs={values} links />
      }));
  }, [matchGroup]);

  return (
    <SecondaryLabelledList items={items} horizontal className="flex flex-wrap gap-2" />
  );
});
