export const scrollIntoView = (
  parent?: HTMLElement | null,
  child?: HTMLElement,
  offset: number = 0
) => {
  if (!parent || !child) return;

  const diff = child.getBoundingClientRect().top - parent.getBoundingClientRect().top - offset;
  parent.scrollTo({ top: parent.scrollTop + diff, behavior: "smooth" });
};

export const scrollIntoViewIfNotVisible = (
  parent?: HTMLElement | null,
  child?: HTMLElement,
  offset: number = 0
) => {
  if (!parent || !child) return;

  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  if (parentRect.top < childRect.top && parentRect.bottom > childRect.bottom) return;
  scrollIntoView(parent, child, offset);
}
