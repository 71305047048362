import React from "react";

export const useListenForTextSelection = (): { isShift: boolean, isSelectingText: boolean } => {
  const [isShift, setIsShift] = React.useState(false);
  const [isSelectingText, setIsSelectingText] = React.useState(false);

  React.useEffect(() => {
    let startPos: { x: number; y: number } | null = null;

    const handleMouseDown = (e: MouseEvent) => {
      startPos = { x: e.x, y: e.y };
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (startPos && (Math.abs(e.y - startPos.y) > 10 || Math.abs(e.x - startPos.x) > 10)) {
        setIsSelectingText(true);
      }
    }

    const handleMouseUp = () => {
      startPos = null;
      setTimeout(() => { setIsSelectingText(false); }, 0);
    }

    const handleShiftDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") setIsShift(true);
    }

    const handleShiftUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") setIsShift(false);
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", handleShiftDown);
    document.addEventListener("keyup", handleShiftUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", handleShiftDown);
      document.removeEventListener("keyup", handleShiftUp);
    }
  }, []);

  return { isShift, isSelectingText };
}
