import { ItemID } from "types/common";

import React from "react";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { TaxonomyItemCodes } from "components/codeComponents/TaxonomyItemCodes";

export const CustomCodeColumn = React.memo<{ itemID: ItemID }>(({ itemID }) => {
  const { market } = useCatalogContext();

  return <TaxonomyItemCodes itemID={itemID} market={market} showEmpty />;
});
