import { ItemID } from "types/common";

import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

import { ClinicCode, TrimmedCode } from "./Code";

export enum MatchStatus {
  APPROVED = "approved",
  CONFLICT = "automatch-conflict",
  GOOD = "automatch-good",
  IGNORED = "ignored",
  ITEM_REQUEST = "item-requested",
  MANUAL = "manual",
  MOVED = "moved",
  NOMATCH = "pending",
  PERFECT = "automatch-perfect",
  REMATCH = "needs-rematch",
}

export type MatchTarget = {
  taxonomy_item_id: string;
  score: number;
};

export type MatchTargetFinal = Partial<Omit<MatchTarget, "score">> & {
  taxonomy_item_body?: {
    class: TaxonomyClass;
    relations: Partial<Record<string, ItemID[]>>;
  };
};

export type MatchGroupMatch = {
  status: MatchStatus;
  candidates: MatchTarget[];
  final: MatchTargetFinal[];
};

export type MatchGroupMetadataSecondLvl = {
  country: string;
  price_avg: number;
  price_max: number;
  price_min: number;
  rows_count: number;
  provider_id: string;
  quantity_avg: number;
  quantity_max: number;
  quantity_min: number;
  source_clinic_id: string;
  transaction_date_max: string;
  transaction_date_min: string;
  codes?: {
    standard: TrimmedCode[];
    clinic?: ClinicCode;
  };
};

export type MatchGroupPrematch = { [GenericTaxonomyClass]: string[] } & Partial<Record<TaxonomyClass, string[]>>;

export type MatchGroupMetadata = {
  match: MatchGroupMatch;
  metadata?: MatchGroupMetadataSecondLvl;
  prematch?: MatchGroupPrematch;
};

export type MatchGroup = {
  group_id: number;
  match_text: string;
  metadata_: MatchGroupMetadata;
  created_dts: string;
  created_by: string;
  modified_dts: string;
  modified_by: string;
};
