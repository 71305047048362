import React from "react";

import { DeleteTaxonomyItemButton } from "modules/TaxonomyModule/components/DeleteTaxonomyItemButton";
import { DuplicateTaxonomyItemButton } from "modules/TaxonomyModule/components/DuplicateTaxonomyItemButton";
import { EditTaxonomyItemButton } from "modules/TaxonomyModule/components/EditTaxonomyItemButton";

import { TaxonomyItemReadControlsProps } from "./TaxonomyItemReadControls.types";

export const TaxonomyItemReadControls = React.memo<TaxonomyItemReadControlsProps>((props) => {
  const { taxonomyItem } = props;

  return (
    <div className="flex gap-4 items-start">
      <EditTaxonomyItemButton itemID={taxonomyItem?.id} className="w-1/3 md:w-auto" />
      <DuplicateTaxonomyItemButton itemID={taxonomyItem?.id} className="w-1/3 md:w-auto" />
      <DeleteTaxonomyItemButton itemID={taxonomyItem?.id} className="w-1/3 md:w-auto" />
    </div>
  );
});
