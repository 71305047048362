import React from "react";
import { useMediaQuery } from "react-responsive";
import { Typography } from "antd";
import { queries } from "consts";

import { MatchingBreadcrumbs } from "modules/MatchingModule/components/MatchingBreadcrumbs";
import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { MatchingInformationSourceModule } from "modules/MatchingModule/MatchingInformationSourceModule";
import { MatchingListColumns } from "modules/MatchingModule/MatchingListModule/components/MatchingListColumns";
import { MatchingListHeader } from "modules/MatchingModule/MatchingListModule/components/MatchingListHeader";
import { MatchingTargetsDrawer } from "modules/MatchingModule/MatchingListModule/components/MatchingTargetsDrawer";
import { MatchingTargetsDrawerContextProvider } from "modules/MatchingModule/MatchingListModule/components/MatchingTargetsDrawer/MatchingTargetsDrawer.context";
import { MatchingModuleProvider } from "modules/MatchingModule/MatchingModule.context";
import {
  MatchingTargetsClassTabsProvider
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";
import { TaxonomyUrlQueryServiceInMatchingProvider } from "modules/MatchingModule/TaxonomyUrlQueryServiceInMatching.context";

import { useInformationSourceInSearchParams } from "../MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

import { MatchingGroupsFilterProvider } from "./components/MatchingGroupsFilterProvider";
import { useMatchingListModuleTitle } from "./hooks/useMatchingListModuleTitle";

export const MatchingListModule = React.memo(() => {
  const { isTransactions, isSpecies } = useCheckDataset();

  const minLG = useMediaQuery({ query: queries.lg });

  const { countryValue } = useInformationSourceInSearchParams();
  const showLists = isSpecies || Boolean(countryValue);

  const title = useMatchingListModuleTitle();
  const breadcrumbItems = React.useMemo(() => [{ title: <Typography.Text>{title}</Typography.Text> }], [title]);

  return (
    <TaxonomyUrlQueryServiceInMatchingProvider>
      <MatchingGroupsFilterProvider>
        <MatchingModuleProvider>
          <MatchingTargetsClassTabsProvider>
            <MatchingTargetsDrawerContextProvider>
              <div className="flex flex-col h-full">
                <MatchingBreadcrumbs breadcrumbItems={breadcrumbItems} />
                <MatchingListHeader title={title} />
                {isTransactions && (
                  <div className="mb-4">
                    <MatchingInformationSourceModule />
                  </div>
                )}
                {showLists && (
                  <>
                    <MatchingListColumns />
                    {!minLG && <MatchingTargetsDrawer />}
                  </>
                )}
              </div>
            </MatchingTargetsDrawerContextProvider>
          </MatchingTargetsClassTabsProvider>
        </MatchingModuleProvider>
      </MatchingGroupsFilterProvider>
    </TaxonomyUrlQueryServiceInMatchingProvider>
  );
});
