import { Job } from "types/entities/Jobs";

import { parseClinicId } from "modules/JobsModule/components/JobCard/helpers/parseClinicId";

export const getProvidersAndClinicsFromClinicsIds = (clinicsIds: Job["clinics"]): { providers: string[], clinics: string[] } => {
  const providers = new Set<string>();
  const clinics = new Set<string>();

  clinicsIds?.forEach((rawClinicId) => {
    const { providerId, clinicId } = parseClinicId(rawClinicId);

    if (providerId) providers.add(providerId);
    if (clinicId) clinics.add(clinicId);
  });

  return { providers: Array.from(providers), clinics: Array.from(clinics) };
}
