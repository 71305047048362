import { MatchStatus } from "types/entities/Match";

import React from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const MatchingUnignoreButton = React.memo(() => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();

  const { patchSelectedGroups } = useMatchingContext();

  const handleUnignore = React.useCallback(async () => {
    await patchSelectedGroups({ status: MatchStatus.NOMATCH });
    showSuccess(translations["matching.messages.unignore.success"]);
  }, [patchSelectedGroups, showSuccess, translations]);

  return (
    <Button type="text" icon={<UndoOutlined />} onClick={handleUnignore} className="w-full">{translations["matching.buttons.unignore"]}</Button>
  );
});
