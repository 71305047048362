import React from "react";

import { FacetSetItemUI } from "modules/FacetsModule/components/FacetSetItemUI";

import { FacetSetMapItemProps } from "./facetSetMap";

export const FacetSetItemRange = React.memo<FacetSetMapItemProps>((props) => {
  const { title, values } = props;
  return <FacetSetItemUI title={title} items={values} separator={" — "} />
});
