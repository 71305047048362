import { Code, TrimmedCode } from "types/entities/Code";

import { EMPTY } from "consts/strings";

export const sortAndPrepareForDisplay = (codes?: (TrimmedCode | Code)[]) => {
  if (!codes) return [];
  return codes
    .sort((a, b) => (a.type === b.type ? a.code.localeCompare(b.code) : a.type.localeCompare(b.type)))
    .map((codeInfo) => ({
      title: codeInfo.type,
      value: codeInfo.code || EMPTY,
    }));
};
