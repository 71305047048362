import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getTaxonomyClassPropTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import { getTaxonomyRelationTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { useTranslations } from "translations/useTranslations";

import { useGetTaxonomyItemByClassAndId } from "api/useGetTaxonomyItemByClassAndId";

import { valueAndUnitPairs } from "modules/GenerateByUIConfigurationModule/UIConfiguration/UIConfigurationPairs";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { MedicalProductProperties } from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";
import {
  MEDICAL_BRAND_RELATION,
  MEDICAL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";
import { SubTitle } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/SubTitle";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";
import {
  OtherTaxonomyItemReadSection
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/OtherTaxonomyItemReadSection";

import { ItemReadRowByTemplate } from "../../components/ItemReadRowByTemplate";
import { ItemReadRowValue } from "../../components/ItemReadRowValue";
import { TaxonomyItemWithRelationToSelf } from "../../components/TaxonomyItemWithRelationToSelf";
import { TaxonomyItemReadSectionProps } from "../TaxonomyItemReadSections.types";

import { medicalProductPropsKeys, medicalProductRelationsKeys } from "./consts";

export const MedicalProductTaxonomyItemReadSection = React.memo<TaxonomyItemReadSectionProps>(
  ({ taxonomyItem: medicalProduct = {}, fieldsToHide }) => {
    const translations = useTranslations();

    const { taxonomyItem: medicalBrand } = useGetTaxonomyItemByClassAndId(
      (medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND] as TaxonomyItem[])?.[0]?.id || ""
    );

    const principalGeneric = medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_PRINCIPAL_GENERIC];
    const drugClass = medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_DRUG_CLASS];
    const markets = medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_MARKET];
    const primaryActiveIngredient = medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_PRIMARY_ACTIVE_INGREDIENT];
    const secondaryActiveIngredient =
      medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_SECONDARY_ACTIVE_INGREDIENT];
    const manufacturer = medicalBrand?.relations?.[MEDICAL_BRAND_RELATION.TO_MANUFACTURER];

    const physicalForm = medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_PHYSICAL_FORM];
    const routeOfAdministration = medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION];
    const targetSpecies = medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_SPECIES];
    const packColor = medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_PACK_COLOR];
    const treatmentIndication = medicalProduct.relations?.[MEDICAL_PRODUCT_RELATION.TO_TREATMENT_INDICATION];

    return (
      <div>
        <SubTitle text={translations["item.details.subtitle.medical_brand"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND)}
          value={<ItemReadRowValue taxonomyItem={medicalBrand} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_PRINCIPAL_GENERIC)}
          value={<ItemReadRowValue taxonomyItem={principalGeneric} />}
        />
        <TaxonomyItemWithRelationToSelf
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_DRUG_CLASS)}
          taxonomyClass={TaxonomyClass.DRUG_CLASS}
          relationItems={drugClass}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_MARKET)}
          value={<ItemReadRowValue taxonomyItem={markets} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_MANUFACTURER)}
          value={<ItemReadRowValue taxonomyItem={manufacturer} />}
        />
        <SubTitle text={translations["item.details.subtitle.package"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_PHYSICAL_FORM)}
          value={<ItemReadRowValue taxonomyItem={physicalForm} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.PACK_SIZE)}
          value={
            <ItemReadRowByTemplate
              template={valueAndUnitPairs.medicalProductPackSize.template}
              taxonomyItem={medicalProduct}
            />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.DURATION_OF_EFFECT)}
          value={medicalProduct[MedicalProductProperties.DURATION_OF_EFFECT]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION)}
          value={<ItemReadRowValue taxonomyItem={routeOfAdministration} />}
        />
        <SubTitle text={translations["item.details.subtitle.utilization"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_PRIMARY_ACTIVE_INGREDIENT)}
          value={<ItemReadRowValue taxonomyItem={primaryActiveIngredient} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.STRENGTH)}
          value={
            <ItemReadRowByTemplate
              template={valueAndUnitPairs.medicalProductStrength.template}
              taxonomyItem={medicalProduct}
            />
          }
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_BRAND, MEDICAL_BRAND_RELATION.TO_SECONDARY_ACTIVE_INGREDIENT)}
          value={<ItemReadRowValue taxonomyItem={secondaryActiveIngredient} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_SPECIES)}
          value={<ItemReadRowValue taxonomyItem={targetSpecies} />}
        />
        <TaxonomyItemReadRow
          title={translations["item.details.label.weight.dog"]}
          value={<ItemReadRowByTemplate template={valueAndUnitPairs.dog.template} taxonomyItem={medicalProduct} />}
        />
        <TaxonomyItemReadRow
          title={translations["item.details.label.weight.cat"]}
          value={<ItemReadRowByTemplate template={valueAndUnitPairs.cat.template} taxonomyItem={medicalProduct} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_PACK_COLOR)}
          value={<ItemReadRowValue taxonomyItem={packColor} />}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyRelationTitle(TaxonomyClass.MEDICAL_PRODUCT, MEDICAL_PRODUCT_RELATION.TO_TREATMENT_INDICATION)}
          value={<ItemReadRowValue taxonomyItem={treatmentIndication} />}
        />
        <SubTitle text={translations["item.details.subtitle.codes"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REGISTRATION_NUMBER)}
          value={medicalProduct[MedicalProductProperties.REGISTRATION_NUMBER]}
        />
        <SubTitle text={translations["item.details.subtitle.rep"]} />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REP_BRAND)}
          value={medicalProduct[MedicalProductProperties.REP_BRAND]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REPCATEGORY)}
          value={medicalProduct[MedicalProductProperties.REPCATEGORY]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REPORT_INCLUDE)}
          value={medicalProduct[MedicalProductProperties.REPORT_INCLUDE]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REP_MANUFACTURER)}
          value={medicalProduct[MedicalProductProperties.REP_MANUFACTURER]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REP_ROA)}
          value={medicalProduct[MedicalProductProperties.REP_ROA]}
        />
        <TaxonomyItemReadRow
          title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, MedicalProductProperties.REP_CLASS)}
          value={medicalProduct[MedicalProductProperties.REP_CLASS]}
        />
        <OtherTaxonomyItemReadSection
          taxonomyClass={TaxonomyClass.MEDICAL_PRODUCT}
          taxonomyItem={medicalProduct}
          fieldsToHide={fieldsToHide}
          requiredPropsKeys={medicalProductPropsKeys}
          requiredRelationsKeys={medicalProductRelationsKeys}
        />
      </div>
    );
  }
);
