import { customCodeColumn } from "modules/TaxonomyModule/TaxonomyTableModule/components/CustomCodeColumn";
import { customNameColumn } from "modules/TaxonomyModule/TaxonomyTableModule/components/CustomNameColumn/consts";
import { customPriceColumn } from "modules/TaxonomyModule/TaxonomyTableModule/components/CustomPriceColumn/consts";
import { customSpeciesColumn } from "modules/TaxonomyModule/TaxonomyTableModule/components/CustomSpeciesColumn/consts";

import { RenderFunction } from "./getRenderFunction";

export type MapKeysToComponents = {
  [key: string]: RenderFunction;
};

export const useMapKeysToComponents: () => MapKeysToComponents = () => {
  return {
    ...customNameColumn,
    ...customPriceColumn,
    ...customSpeciesColumn,
    ...customCodeColumn,
  };
};
