import React from "react";

import { FacetSetItemUI } from "modules/FacetsModule/components/FacetSetItemUI";
import { useInitialTreeWithEndpointData } from "modules/FacetsModule/hooks/useInitialTreeWithEndpointData";

import { Preloader, PreloaderType } from "ui/Preloader";

import { FacetSetMapItemProps } from "./facetSetMap";

export const FacetsSetItemTree = React.memo<FacetSetMapItemProps>((props) => {
  const { title, values, taxonomyClass = "" } = props;

  const { initialTreeMap: treeMap, isLoading } = useInitialTreeWithEndpointData(taxonomyClass);

  const valuesToShow = React.useMemo(
    () =>
      values.reduce<string[]>((acc, value) => {
        const item = treeMap[value] || {};
        if (!item.parent || !values.includes(item.parent.key)) acc.push(item.title as string);
        return acc;
      }, []),
    [treeMap, values]
  );

  if (isLoading) return <Preloader type={PreloaderType.SKELETON_BUTTON} size="small" style={{ height: "20px" }} />;

  return <FacetSetItemUI title={title} items={valuesToShow} />;
});
