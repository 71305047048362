import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";
import {
  getFiltersFromTaxonomyItemList
} from "modules/TaxonomyModule/TaxonomyCreateForm/helpers/getFiltersFromTaxonomyItemList";

export const useTaxonomyChainCreationFilter = () => {
  const urlQueryService = useTaxonomyUrlQueryService();
  const [createdItemsList, setCreatedItemsList] = React.useState<TaxonomyItem[]>([]);

  const addCreatedItemToChain = React.useCallback((item: TaxonomyItem) => {
    setCreatedItemsList((prev) => [...prev, item]);
  }, []);

  const getChainFilterString = React.useCallback((taxonomyItem: TaxonomyItem) => {
    if (!createdItemsList.length) return "";

    return urlQueryService.generateUrlFilterStringFromFacets(
      getFiltersFromTaxonomyItemList([...createdItemsList, taxonomyItem])
    );
  }, [createdItemsList, urlQueryService]);

  React.useEffect(() => () => setCreatedItemsList([]), []);

  return { hasChain: !!createdItemsList.length, addCreatedItemToChain, getChainFilterString }
}
