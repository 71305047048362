import { ResponseError } from "types/apiTypes";
import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";
import { FormTaxonomyData } from "types/formDataTypes";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { formatBEErrorToErrorSchema } from "helpers/formatErrorBEToFE";
import { useMessage } from "hooks/useMessage";
import { getTaxonomyReadPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { useUpdateTaxonomyItemByClassAndId } from "modules/TaxonomyModule/api/useUpdateTaxonomyItemByClassAndId";
import { relationFieldName } from "modules/TaxonomyModule/CommonTaxonomyFormModule/const";

import { useUpdateRelationsForItem } from "./useUpdateRelationsForItem";

export const useUpdateTaxonomyItemWithRelations = (
  itemID: ItemID,
  taxonomyItem?: TaxonomyItem
) => {
  const navigate = useNavigate();
  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutate: updateTaxonomyItemByClassAndId, error } = useUpdateTaxonomyItemByClassAndId(
    itemID,
    () => {
      navigate(getTaxonomyReadPath(itemID));
      showSuccess(translations["item.messages.update.success"]);
    }
  );

  const updateRelationsForItem = useUpdateRelationsForItem();

  const updateTaxonomyItem = useCallback(
    async (formData: FormTaxonomyData) => {
      const { [relationFieldName]: relationsFormData, ...mainFormData } =
        formData;

      updateTaxonomyItemByClassAndId(mainFormData);

      if (!taxonomyItem) return;
      updateRelationsForItem(taxonomyItem, relationsFormData, itemID);
    },
    [
      updateTaxonomyItemByClassAndId,
      updateRelationsForItem,
      taxonomyItem,
      itemID,
    ]
  );

  const errorSchema = error ? formatBEErrorToErrorSchema(error as AxiosError<ResponseError>) : {};

  return { updateTaxonomyItem, errorSchema };
};
