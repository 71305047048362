import React, { useMemo } from "react";
import { Card, Typography } from "antd";
import { EMPTY } from "consts/strings";
import { formatDate } from "helpers/formatDate";
import { useTranslations } from "translations/useTranslations";

import { LabelledList } from "ui/LabelledList";

import { TaxonomyItemHistoryCardProps } from "./TaxonomyItemHistoryCard.types";

export const TaxonomyItemHistoryCard = React.memo<TaxonomyItemHistoryCardProps>(({ taxonomyItem }) => {
  const translations = useTranslations();
  const items = useMemo(() => {
    const { created_by, created_dts, modified_by, modified_dts } = taxonomyItem || {};
    return [
      {
        label: translations["item.history.created"],
        value: `${created_by || EMPTY} / ${formatDate(created_dts, { hasTime: true })}`,
      },
      {
        label: translations["item.history.modified"],
        value: `${modified_by || EMPTY} / ${formatDate(modified_dts, { hasTime: true })}`,
      },
    ];
  }, [taxonomyItem, translations]);

  return (
    <Card>
      <Typography.Title level={4}>{translations["item.history.title"]}</Typography.Title>
      <LabelledList items={items} />
    </Card>
  );
});
