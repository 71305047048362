import { getTaxonomyReadPath } from "router/path";

import { CatalogLinkWithPrevent } from "components/CatalogLinkWithPrevent";

import { Separator } from "./components/Separator";
import { ComponentsConfigurationType, ContentForm, ContentType } from "./generateByUIConfiguration.types";

export const VALUE_FOR_NULL = "";

export const getEmptyValue = () => ({
  value: VALUE_FOR_NULL,
  form: ContentForm.TEXT,
});
export const valueBetweenBracesRegExp = /\{([^{}]*[\w]+[^{}]*)\}/g;
export const valueBetweenSquareBracketsRegExp = /\[([^\]]+)\]/g;
export const valueBetweenPairsRegExp = /\[\[([^\]]+)\]\]/g;
export const valueSeparatorRegExp = /\{\{([^\w{}[\]]+)\}\}/g;

export const defaultComponentsConfiguration: ComponentsConfigurationType = {
  getJsxByType: (value: ContentType): JSX.Element => {
    if (value.form === ContentForm.LINK) {
      return (
        <CatalogLinkWithPrevent
          key={value.value}
          name={value.value}
          href={value.to ? getTaxonomyReadPath(value.to) : ""}
        />
      );
    }
    return <div key={`${value.value}-${Math.random()}`}>{value.value}</div>;
  },
  Separator: Separator,
};
