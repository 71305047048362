import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Typography } from "antd";

import { useGetItemsByIds } from "api/useGetItemsByIds";

import { propertiesForHiding } from "modules/GenerateByUIConfigurationModule/UIConfiguration";

import { ItemReadRowValue } from "../ItemReadRowValue";
import { TaxonomyItemReadRow } from "../TaxonomyItemReadRow/TaxonomyItemReadRow";
import { TaxonomyItemReadSection } from "../TaxonomyItemReadSection/TaxonomyItemReadSection";

export const DeepTaxonomyItemRelation = React.memo<{
  relationItems: TaxonomyItem[];
  title: string;
  nestingDepth: number;
  relationTaxonomyClass: string;
}>(({ relationItems, title, nestingDepth, relationTaxonomyClass }) => {
  const { items } = useGetItemsByIds(relationItems.map((el) => el.id));

  return (
    <>
      {relationItems.map((el, index) => (
        <React.Fragment key={el.id}>
          <Typography.Title level={5} className="mt-4">
            {title + (relationItems.length > 1 ? ` ${index + 1}` : "")}
          </Typography.Title>
          <TaxonomyItemReadRow title={title} value={<ItemReadRowValue taxonomyItem={el} />} />
          <TaxonomyItemReadSection
            taxonomyClass={relationTaxonomyClass}
            taxonomyItem={items.find((itemWithData) => itemWithData.id === el.id)}
            fieldsToHide={["name", ...propertiesForHiding]}
            nestingDepth={nestingDepth}
          />
        </React.Fragment>
      ))}
    </>
  );
});
