import { RenderFunction } from "modules/TaxonomyModule/TaxonomyTableModule/helpers/getRenderFunction";

import { CustomCodeColumn } from "./CustomCodeColumn";

export const CODE_TITLE_KEY = "item.table.column.codes";
export const CODE_COLUMN_KEY = "product_codes";
export const customCodeColumn: { [key: string]: RenderFunction } = {
  [CODE_COLUMN_KEY]: (_, taxonomyItem) => {
    return <CustomCodeColumn itemID={taxonomyItem.id} />
  },
};
