import React from "react";
import { Select } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useGetListOfTaxonomyByTaxonomyClass } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { CLINIC_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { Preloader, PreloaderType } from "ui/Preloader";

import { SelectClinicsProps } from "./SelectClinics.types";

export const SelectClinics = React.memo<SelectClinicsProps>(({ provider, value = [], onChange }) => {
  const translations = useTranslations();

  const [search, setSearch] = React.useState("");

  const filterParams = React.useMemo(() => {
    const params = [];

    if (search) params.push(`search=${search}`);
    if (provider) params.push(`${CLINIC_RELATION.TO_PROVIDER}=${provider}`);

    return params.join("&");
  }, [provider, search]);

  const { data: clinics, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetListOfTaxonomyByTaxonomyClass(TaxonomyClass.CLINIC, filterParams);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = React.useCallback((e) => {
    const target = e.target as HTMLDivElement;
    if (!isLoading && hasNextPage && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isLoading]);

  const dropdownRender = React.useCallback((menu: React.ReactElement) => {
    if (isLoading) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;

    return (
      <>
        {menu}
        {(isFetchingNextPage) && <Preloader type={PreloaderType.SPINNER_BLOCK} size="small" />}
      </>
    )
  }, [isFetchingNextPage, isLoading])

  const options = React.useMemo(() => clinics.map(clinic => ({ value: clinic.id, label: clinic.code })), [clinics]);

  return (
    <Select
      mode="multiple"
      value={value}
      options={options}
      onChange={onChange}
      placeholder={translations["jobs.form.clinics.placeholder"]}
      loading={isLoading || isFetchingNextPage}
      onPopupScroll={handleScroll}
      dropdownRender={dropdownRender}
      onSearch={setSearch}
      disabled={!provider}
    />
  );
});
