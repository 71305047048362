import React from "react";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { JobsModule } from "modules/JobsModule";

import { AccessDenied } from "ui/AccessDenied";

export const JobsPage = React.memo(() => {
  const jobsViewMainEnabled = useFeatureFlag(FeatureFlagNames.JOBS_VIEW_MAIN);

  if (!jobsViewMainEnabled) return <AccessDenied />;

  return (
    <div className="py-10">
      <JobsModule />
    </div>
  );
});
