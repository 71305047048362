import {
  ContentForm,
  InstructionResultForm,
  InstructionResultPartialType
} from "modules/GenerateByUIConfigurationModule/generateByUIConfiguration.types";

export const getInstructionsWithSeparators = (instructions: RegExpMatchArray[]): InstructionResultPartialType[] => {
  return instructions.map(([withBrackets, insideBrackets]) => {
    return {
      template: { withBrackets, insideBrackets },
      form: InstructionResultForm.SEPARATOR,
      content: {
        value: insideBrackets,
        form: ContentForm.TEXT,
      },
    };
  });
}
