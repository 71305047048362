import { UNKNOWN_PREMATCH_CLASS } from "types/entities/Prematch";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const checkInformationKind = (
  informationKind?: string | string[] | null
): {
  isMedicalProduct: boolean;
  isFoodProduct: boolean;
  isRetailProduct: boolean;
  isDiagnosticTest: boolean;
  isUnknown: boolean;
} => {
  const lowerCaseInformationKinds = Array.isArray(informationKind)
    ? informationKind.map((el) => el.toLowerCase())
    : informationKind?.toLowerCase().split(",") || [];

  return {
    isMedicalProduct: lowerCaseInformationKinds?.includes(TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()),
    isFoodProduct: lowerCaseInformationKinds?.includes(TaxonomyClass.FOOD_PRODUCT.toLowerCase()),
    isRetailProduct: lowerCaseInformationKinds?.includes(TaxonomyClass.RETAIL_PRODUCT.toLowerCase()),
    isDiagnosticTest: lowerCaseInformationKinds?.includes(TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase()),
    isUnknown: lowerCaseInformationKinds?.includes(UNKNOWN_PREMATCH_CLASS.toLowerCase()),
  };
};
