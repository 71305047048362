import React from "react";
import { useLocation } from "react-router-dom";
import { isCatalogPath } from "router/helpers/isPath";

export const useLastPath = () => {
  const location = useLocation();
  const [lastCatalogPath, setLastCatalogPath] = React.useState<string>("");

  React.useEffect(() => {
    if (isCatalogPath(location.pathname)) {
      setLastCatalogPath(`${location.pathname}${location.search}`);
    }
  }, [location]);

  return { lastCatalogPath };
}
