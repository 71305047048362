import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { getTaxonomyUpdatePath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { Preloader, PreloaderType } from "ui/Preloader";

import { EditTaxonomyItemButtonProps } from "./EditTaxonomyItemButton.types";

export const EditTaxonomyItemButton = React.memo<EditTaxonomyItemButtonProps>(
  ({ itemID, type = "default", className }) => {
    const translations = useTranslations();
    const {
      components: { CatalogLink },
    } = useCatalogContext();
    const catalogItemEditEnabledForRole = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_EDIT);

    const [taxonomyClass] = getItemClassFromItemId(itemID || "");
    const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

    if (!catalogItemEditEnabledForRole || !taxonomyItemConfig?.meta.editable) return null;
    if (!itemID) return <Preloader type={PreloaderType.SKELETON_BUTTON} />;

    return (
      <CatalogLink to={getTaxonomyUpdatePath(itemID)} className={className}>
        <Button type={type} icon={<EditOutlined />} className="w-full">
          {translations["item.button.edit"]}
        </Button>
      </CatalogLink>
    );
  }
);
