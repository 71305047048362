import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { ItemReadRowValue } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/ItemReadRowValue";

import { SpeciesListWithWeight } from "components/SpeciesListWithWeight";

export const CustomSpeciesColumn = React.memo<{ taxonomyItem: TaxonomyItem }>(({ taxonomyItem }) => {
  const { market } = useCatalogContext();

  return <SpeciesListWithWeight taxonomyItem={taxonomyItem} RelationViewer={ItemReadRowValue} convertToMarket={market} />;
});
