import { configurationServiceInstance } from "services/ConfigurationService";

export const getTaxonomyClassPropTitle = (taxonomyClass: string, propertyKey: string) => {
  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  if (!taxonomyItemConfig) return propertyKey;

  const propConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass)?.properties?.[propertyKey];

  if (typeof propConfig === "boolean") return propertyKey;
  return propConfig?.title || propertyKey;
}
