import { TaxonomyStatus } from "types/entities/Taxonomy";

import React from "react";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { CatalogContextType } from "modules/CatalogModule/CatalogModule.types";
import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";
import { statusFacetSection } from "modules/TaxonomyModule/consts/facetConfigurations";
import { foodProductFacetsKeys, medicalProductFacetsKeys, retailProductFacetsKeys } from "modules/TaxonomyModule/consts/facetsKeys";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";

export const useCatalogMandatoryFilters = () => {
  const { countryValue } = useInformationSourceInSearchParams();

  const { data, isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.MARKET);

  return React.useMemo(() => {
    const filters: CatalogContextType["mandatoryAdditionalFilter"] = [
      {
        facetSectionsKeys: [statusFacetSection.key],
        value: [TaxonomyStatus.active, TaxonomyStatus.ignored].join(","),
        isHidden: true,
      }
    ];

    if (!isLoading && data?.length) {
      const countryItemId = data.find((item) => item.name === countryValue)?.id;
      if (countryItemId) {
        filters.push({
          facetSectionsKeys: [
            medicalProductFacetsKeys[TaxonomyClass.MARKET],
            foodProductFacetsKeys[TaxonomyClass.MARKET],
            retailProductFacetsKeys[TaxonomyClass.MARKET]
          ],
          value: countryItemId
        })
      }
    }

    return filters;
  }, [countryValue, data, isLoading]);
}
