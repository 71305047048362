import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, theme } from "antd";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { CatalogItemProps } from "./CatalogItem.types";

export const CatalogItem = React.memo<CatalogItemProps>((props) => {
  const { label, href, cover } = props;
  const { components: { CatalogLink } } = useCatalogContext();

  const { token } = theme.useToken();

  return (
    <CatalogLink to={href}>
      <Card
        hoverable
        cover={cover && <div className="aspect-square p-6"><img alt={label} src={cover} className="w-full object-contain" /></div>}
        className="group text-base font-semibold"
      >
        <div className="flex justify-between items-center">
          {label}
          <div className="w-8 h-8 grid place-content-center transition-transform duration-300 group-hover:translate-x-2">
            <ArrowRightOutlined style={{ color: token.colorPrimary }} />
          </div>
        </div>
      </Card>
    </CatalogLink>
  )
});
