import React from "react";
import { FilterProviders } from "context/FilterContext/Filter.context";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { FacetSectionConfiguration, FacetSectionConfigurationItem } from "modules/FacetsModule/FacetsModule.types";
import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";

import { SEARCH_KEY } from "./components/TaxonomySearch";

export const TaxonomyListFilterProvider = React.memo<{ children: JSX.Element; facetSectionsConfiguration: FacetSectionConfiguration }>(
  ({ children, facetSectionsConfiguration }) => {
    const urlQueryService = useTaxonomyUrlQueryService();
    const { mandatoryAdditionalFilter } = useCatalogContext();

    const mandatorySections = React.useMemo(() => {
      return mandatoryAdditionalFilter.reduce<(FacetSectionConfigurationItem & { value: string })[]>(
        (acc, el) => {
          const facetSectionConfig = facetSectionsConfiguration.find(
            (section) => el.facetSectionsKeys.includes(section.key)
          );

          if (!facetSectionConfig) return acc;

          const newSection = {
            ...facetSectionConfig,
            value: el.value,
            meta: { isImmutable: true, isHidden: el.isHidden }
          };
          return [...acc, newSection];
        },
      []
      );
    }, [facetSectionsConfiguration, mandatoryAdditionalFilter]);

    const facetSectionsConfigurationWithMandatory = React.useMemo(() => {
      return facetSectionsConfiguration.map((sectionConfig) => {
        const mandatoryPart = mandatorySections.find(({ key }) => sectionConfig.key === key);
        return {...sectionConfig, ...mandatoryPart};
      });
    }, [facetSectionsConfiguration, mandatorySections]);

    const mandatoryFilterString = React.useMemo(
      () => mandatorySections.map(({ key, value }) => `${key}=${value}`).join("&"),
      [mandatorySections]
    );

    return (
      <FilterProviders
        allowedKeys={[...facetSectionsConfiguration.map((el) => el.key), SEARCH_KEY]}
        additionalKeys={[]}
        urlQueryService={urlQueryService}
        facetSectionsConfiguration={facetSectionsConfigurationWithMandatory}
        mandatoryFilterString={mandatoryFilterString}
      >
        {children}
      </FilterProviders>
    );
  }
);
