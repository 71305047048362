import React from "react";
import { Button } from "antd";

import { CommonDiscardButtonProps } from "./CommonDiscardButton.types";
import { showPromiseConfirm } from "./showPromiseConfirm";

export const CommonDiscardButton = React.memo<CommonDiscardButtonProps>(({
  button: { text, type = "text" },
  popup: { title, content, okText },
  setShouldPrevent,
}) => {
  const handleClick = React.useCallback(() => {
    let lastPreventValue = false;

    setShouldPrevent(prevValue => {
      lastPreventValue = prevValue;
      return false;
    });

    showPromiseConfirm(
      title,
      content,
      okText,
      () => window.history.back(),
      () => setShouldPrevent(lastPreventValue)
    );
  }, [content, okText, setShouldPrevent, title]);

  return (
    <Button type={type} onClick={handleClick}>{text}</Button>
  )
});
