import { Job, JobStatus } from "types/entities/Jobs";

import React from "react";
import { Button } from "antd";
import { SPACE } from "consts/strings";
import { useTranslations } from "translations/useTranslations";

import { useUpdateJobStatus } from "modules/JobsModule/api/useUpdateJobStatus";

import { useShowReactivateConfirm } from "./hooks/useShowReactivateConfirm";
import { useTimeTillCompleted } from "./hooks/useTimeTillCompleted";

export const JobReactivateButton = React.memo<{ job: Job }>(({ job}) => {
  const { id, modified_dts } = job;
  const translation = useTranslations();
  const timeTillCompleted = useTimeTillCompleted(modified_dts);

  const { updateJobStatus } = useUpdateJobStatus(id);

  const showReactivateConfirm = useShowReactivateConfirm(() => updateJobStatus(JobStatus.ACTIVE))

  if (timeTillCompleted <= 0) return null;

  return (
    <Button type="link" onClick={showReactivateConfirm} size="small">
      {translation["jobs.card.button.reactivate"]}
      {SPACE}
      ({timeTillCompleted} {translation["jobs.card.button.reactivate.min"]})
    </Button>
  );
});
