import { useMemo } from "react";
import { FilterProviders } from "context/FilterContext/Filter.context";

import { useMatchingFacetConfigurationByDataset } from "modules/MatchingModule/helpers/useMatchingFacetConfigurationByDataset";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";
import { SEARCH_KEY } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingSearch";
import { SORT_KEY } from "modules/MatchingModule/MatchingGroupsModule/consts";
import {
  COUNTRY_KEY_FOR_SEARCH_PARAMS,
  PROVIDERS_KEY_FOR_SEARCH_PARAMS,
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

const additionalKeys = [SEARCH_KEY, SORT_KEY];
const informationSourceKeys = [COUNTRY_KEY_FOR_SEARCH_PARAMS, PROVIDERS_KEY_FOR_SEARCH_PARAMS];

export const MatchingGroupsFilterProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const urlQueryService = useMatchingUrlQueryService();

  const { matchingFiltersKeys } = useMatchingFacetConfigurationByDataset();
  const allowedKeys = useMemo(() => [...matchingFiltersKeys, ...informationSourceKeys], [matchingFiltersKeys]);
  const { facetSectionsConfiguration } = useMatchingFacetConfigurationByDataset();

  return (
    <FilterProviders
      allowedKeys={allowedKeys}
      additionalKeys={additionalKeys}
      urlQueryService={urlQueryService}
      facetSectionsConfiguration={facetSectionsConfiguration}
    >
      {children}
    </FilterProviders>
  );
};
