import { MatchGroup, MatchTarget, MatchTargetFinal } from "types/entities/Match";

const allMatchGroupsHaveSameFinal = (matchGroups: MatchGroup[]) => {
  if (matchGroups.length === 0) return false;

  const firstFinal = matchGroups[0].metadata_.match.final[0];
  return matchGroups.every((group) => group.metadata_.match.final[0]?.taxonomy_item_id === firstFinal?.taxonomy_item_id);
}

export const extractMatchTargetsFromGroups = (matchGroups: MatchGroup[]) => {
  const result: { candidates: MatchTarget[], final: MatchTargetFinal[] } = { candidates: [], final: [] };

  matchGroups.forEach(matchGroup => {
    matchGroup.metadata_.match.candidates.forEach(candidate => {
      const existingCandidateIdx = result.candidates.findIndex(c => c.taxonomy_item_id === candidate.taxonomy_item_id);
      if (existingCandidateIdx !== -1) {
        const existingCandidate = result.candidates[existingCandidateIdx];
        if (existingCandidate.score < candidate.score) {
          result.candidates[existingCandidateIdx] = candidate;
        }
      } else {
        result.candidates.push(candidate);
      }
    });
  });

  result.candidates.sort((a, b) => b.score - a.score);

  if (allMatchGroupsHaveSameFinal(matchGroups)) result.final = matchGroups[0].metadata_.match.final;

  return result;
};
