import { TaxonomyCompleteness } from "types/entities/Taxonomy";

import React from "react";
import { FieldProps } from "@rjsf/utils";
import { Radio, RadioChangeEvent, Space, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

export const CustomCompletenessSwitch: React.FC<FieldProps<TaxonomyCompleteness | undefined>> = ({
  formData,
  onChange,
}) => {
  const translations = useTranslations();
  if (formData === undefined) {
    onChange(false);
  }

  const handleChange = React.useCallback(
    (e: RadioChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div>
      <Radio.Group value={formData} onChange={handleChange}>
        <Space direction="vertical">
          <Typography.Text strong>{translations["item.edit.completeness_title"]}</Typography.Text>
          <div>
            <Radio value={false}>
              <Typography.Text className="block">{translations["item.form.completeness.full.label"]}</Typography.Text>
            </Radio>
            <Typography.Text type="secondary" className="block ml-6">
              {translations["item.form.completeness.full.description"]}
            </Typography.Text>
          </div>
          <div>
            <Radio rootClassName="self-start" value={true}>
              <Typography.Text className="block">
                {translations["item.form.completeness.abstract.label"]}
              </Typography.Text>
            </Radio>
            <Typography.Text type="secondary" className="block ml-6">
              {translations["item.form.completeness.abstract.description"]}
            </Typography.Text>
          </div>
        </Space>
      </Radio.Group>
    </div>
  );
};
