import { JobStatus } from "types/entities/Jobs";

import React from "react";

import { JobActiveControls } from "modules/JobsModule/components/JobActiveControls";
import { JobDeleteButton } from "modules/JobsModule/components/JobDeleteButton";
import { JobReactivateButton } from "modules/JobsModule/components/JobReactivateButton";

import { JobCardControlProps } from "./JobCardControl.types";

export const JobCardControl = React.memo<JobCardControlProps>(({ job, isJobManager }) => {
  const { status } = job;

  if (!isJobManager) return null;

  if (status === JobStatus.ACTIVE) return <JobActiveControls job={job} />;
  if (status === JobStatus.PENDING_COMPLETION) return <JobReactivateButton job={job} />;
  if (status === JobStatus.CREATED) return <JobDeleteButton job={job} />

  return null;
});
