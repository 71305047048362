import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ApiOutlined, CheckCircleOutlined, ReadOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { catalogPath, jobsPath, matchingPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";

import { useLastPath } from "ui/MainMenu/hooks/useLastPath";

const MenuItem = React.memo(({ text, href }: { text: string; href: string }) => <Link to={href}>{text}</Link>);

export const MainMenu = React.memo(() => {
  const location = useLocation();
  const translations = useTranslations();

  const matchingViewMainEnabled = useFeatureFlag(FeatureFlagNames.MATCHING_VIEW_MAIN);
  const catalogViewMainEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_VIEW_MAIN);
  const jobsViewMainEnabled = useFeatureFlag(FeatureFlagNames.JOBS_VIEW_MAIN);

  const { lastCatalogPath } = useLastPath();

  const items: MenuProps["items"] = React.useMemo(
    () => {
      const enabledItems = [];

      if (matchingViewMainEnabled) {
        enabledItems.push({
          key: matchingPath,
          label: <MenuItem href={matchingPath} text={translations["menu.matching"]} />,
          icon: <ApiOutlined width={16} height={16} />,
        });
      }

      if (catalogViewMainEnabled) {
        enabledItems.push({
          key: catalogPath,
          label: <MenuItem href={lastCatalogPath || catalogPath} text={translations["menu.catalog"]} />,
          icon: <ReadOutlined width={16} height={16} />,
        });
      }

      if (jobsViewMainEnabled) {
        enabledItems.push({
          key: jobsPath,
          label: <MenuItem href={jobsPath} text={translations["menu.jobs"]} />,
          icon: <CheckCircleOutlined width={16} height={16} />,
        });
      }

      return enabledItems;
    },
    [
      catalogViewMainEnabled,
      jobsViewMainEnabled,
      lastCatalogPath,
      matchingViewMainEnabled,
      translations
    ]
  );

  const selectedKeys = React.useMemo(
    () => {
      const item = items.find((item) => {
        if (!item?.key) return false;
        const regex = new RegExp(`^${item.key}`);
        return regex.test(location.pathname);
      });
      return item ? [item.key as string] : [];
    },
    [items, location.pathname]
  );

  return <Menu items={items} mode="horizontal" selectedKeys={selectedKeys} theme={"dark"} />;
});
