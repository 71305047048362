import { ChangeEvent, useMemo, useState } from "react";
import { createSelectedTitle } from "helpers/createSelectedTitle";

import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";

// TODO: refactoring
export const useSearch = (
  initialTreeData: FacetsValueFlatTreeType["value"],
  setExpandedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>,
  setAutoExpandParent: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [searchValue, setSearchValue] = useState<string>("");
  return {
    onSearchChange: (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value),

    searchedFlatData: useMemo(() => {
      if (!searchValue) {
        return initialTreeData;
      }
      return initialTreeData.filter((treeElement) =>
        (treeElement.name as string).toLowerCase().includes(searchValue.toLowerCase())
      );
    }, [initialTreeData, searchValue]),

    searchedTreeData: useMemo(() => {
      if (!searchValue) {
        return initialTreeData;
      }
      const newExpandedKeys: React.Key[] = [];
      const searchedData = initialTreeData.map((treeElement) => {
        const isInclude = (treeElement.name as string).toLowerCase().includes(searchValue.toLowerCase());

        if (!isInclude) return treeElement;

        newExpandedKeys.push(treeElement.id);
        return {
          ...treeElement,
          title: createSelectedTitle(searchValue.toLowerCase(), treeElement.name as string),
        };
      });
      setExpandedKeys(newExpandedKeys);
      setAutoExpandParent(true);
      return searchedData;
    }, [initialTreeData, searchValue, setAutoExpandParent, setExpandedKeys]),
  };
};
