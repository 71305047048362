import { TaxonomyConfiguration } from "types/appConfig";

import React from "react";
import { useParams } from "react-router-dom";
import { ReadOutlined } from "@ant-design/icons";
import { BreadcrumbProps, Space, Typography } from "antd";
import { useIsPath } from "router/hooks/useIsPath";
import { catalogPath, getTaxonomyListPath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { useLastListSearch } from "modules/TaxonomyModule/ListOfTaxonomyModule/hooks/useLastListSearch";

import { Breadcrumb } from "ui/Breadcrumb";

import { TaxonomyBreadcrumbsProps } from "./TaxonomyBreadcrumbs.types";

export const TaxonomyBreadcrumbs = React.memo<TaxonomyBreadcrumbsProps>(
  ({ breadcrumbItems = [] }) => {
    const translations = useTranslations();
    const { taxonomyClass = "" } = useParams();
    const { isInCatalog } = useIsPath();
    const { components: { CatalogLink } } = useCatalogContext();

    const { getLastListSearch } = useLastListSearch();

    const items = React.useMemo<BreadcrumbProps["items"]>(() => {
      const catalogTitle = <Space><ReadOutlined />{translations["menu.catalog"]}</Space>;
      const _items: BreadcrumbProps["items"] = [
        {
          title: isInCatalog
            ? <CatalogLink to={catalogPath}>{catalogTitle}</CatalogLink>
            : <Typography.Text>{catalogTitle}</Typography.Text>
        }
      ];

      if (taxonomyClass) {
        const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;
        const taxonomyName = taxonomyConfig.title || taxonomyClass;
        _items.push({
          title: breadcrumbItems.length
            ? <CatalogLink to={getTaxonomyListPath(taxonomyClass, getLastListSearch())}>{taxonomyName}</CatalogLink>
            : <Typography.Text>{taxonomyName}</Typography.Text>
        });
      }

      if (breadcrumbItems) _items.push(...breadcrumbItems);

      return _items;
    }, [CatalogLink, breadcrumbItems, getLastListSearch, isInCatalog, taxonomyClass, translations]);

    return (
      <Breadcrumb items={items} />
    );
  }
);
