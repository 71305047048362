import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

export const SubmitAndDuplicateButton = React.memo<{ isCreating?: boolean, onMouseDown: () => void; }>(({ isCreating, onMouseDown }) => {
  const translations = useTranslations();

  if (!isCreating) return null;

  return (
    <Button type="dashed" onMouseDown={onMouseDown} htmlType="submit" icon={isCreating && <PlusOutlined />}>
      {translations["item.form.button.submit.clone"]}
    </Button>
  )
});
