import { TaxonomyStatus } from "types/entities/Taxonomy";

import React from "react";
import { FieldProps } from "@rjsf/utils";
import { Radio, RadioChangeEvent, Space, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

const RadioButton = React.memo<{ value: TaxonomyStatus; labelKey: string; descriptionKey: string }>(
  ({ value, labelKey, descriptionKey }) => {
    const translations = useTranslations();
    return (
      <div>
        <Radio value={value}>
          <Typography.Text className="block">{translations[labelKey]}</Typography.Text>
        </Radio>
        <Typography.Text type="secondary" className="block ml-6">
          {translations[descriptionKey]}
        </Typography.Text>
      </div>
    );
  }
);

export const CustomStatusSwitch: React.FC<FieldProps<TaxonomyStatus | undefined>> = ({
  formData,
  onChange,
  formContext,
}) => {
  const { canBeIgnored = false } = formContext.taxonomyItemConfiguration.meta;
  const translations = useTranslations();

  const handleChange = React.useCallback(
    (e: RadioChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div>
      <Radio.Group value={formData} onChange={handleChange}>
        <Space direction="vertical">
          <Typography.Text strong>{translations["item.edit.status_title"]}</Typography.Text>
          <RadioButton
            value={TaxonomyStatus.active}
            labelKey="item.form.status.active.label"
            descriptionKey="item.form.status.active.description"
          />
          {canBeIgnored && (
            <RadioButton
              value={TaxonomyStatus.ignored}
              labelKey="item.form.status.ignored.label"
              descriptionKey="item.form.status.ignored.description"
            />
          )}
          <RadioButton
            value={TaxonomyStatus.deactivated}
            labelKey="item.form.status.deactivated.label"
            descriptionKey="item.form.status.deactivated.description"
          />
        </Space>
      </Radio.Group>
    </div>
  );
};
