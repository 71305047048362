import { JobStatus } from "types/entities/Jobs";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { getJobUrl } from "api/rootUrl";

const updateJobStatus = (jobId: string) => (status: JobStatus) => axios.patch(getJobUrl(jobId), { status });

export const useUpdateJobStatus = (jobId: string) => {
  const queryClient = useQueryClient();
  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutateAsync } = useMutation({
    mutationFn: updateJobStatus(jobId),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GET_ACTIVE_JOB_LIST]);
      queryClient.invalidateQueries([QueryKeys.GET_COMPLETED_JOB_LIST]);
      showSuccess(translations["jobs.messages.complete.success"]);
    }
  });

  return { updateJobStatus: mutateAsync };
}
