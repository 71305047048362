import { ItemID } from "types/common";

export const catalogName = "item";
export const matchingName = "matches";
export const jobsName = "jobs";
export const rootPath = "/";
export const logoPagePath = "/logo-page";
export const catalogPath = `/${catalogName}`;
export const matchingPath = `/${matchingName}`;
export const jobsPath = `/${jobsName}`;

export const taxonomyListPath = `${catalogPath}/:taxonomyClass`;
export const taxonomyCreatePath = `${taxonomyListPath}/new`;
export const taxonomyReadPath = `${taxonomyListPath}/:id`;
export const taxonomyUpdatePath = `${taxonomyReadPath}/edit`

export const duplicationItemID = "duplicationItemID";
export const getTaxonomyCreatePath = (taxonomyClass: string, itemID?: string) => `${catalogPath}/${taxonomyClass}/new${itemID ? `?${duplicationItemID}=${itemID}` : ""}`;
export const getTaxonomyListPath = (taxonomyClass: string, filterString?: string) => `${catalogPath}/${taxonomyClass}${filterString ? `?${filterString}` : ""}`;
export const getTaxonomyReadPath = (itemID: ItemID) => `/${itemID}`;
export const getTaxonomyUpdatePath = (itemID: ItemID) => `/${itemID}/edit`;

export const matchingListPath = `${matchingPath}/:jobId?/:datasetName?/*`;
export const getMatchingListPath = (jobId: string, datasetName: string) => `${matchingPath}/${jobId}/${datasetName}`;

export const jobsListPath = jobsPath;
export const jobsCreatePath = `${jobsListPath}/new`;
