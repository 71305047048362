import { Code, TrimmedCode } from "types/entities/Code";

import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Modal, theme } from "antd";
import { useTranslations } from "translations/useTranslations";

const CodeItem = React.memo<{ code: Pick<Code, "code" | "type">, labelKey: string }>(({ code, labelKey }) => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  return (
    <div className="flex flex-col gap-2 basis-0 grow">
      <span className="uppercase" style={{ color: token.colorTextLabel }}>
        {translations[labelKey].replace("{{code}}", code.type)}
      </span>
      <span>
        {code.code}
      </span>
    </div>
  )
});

export const useShowCodeOverwriteConfirm = () => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  return (currCode: Code, newCode: TrimmedCode, onOk: () => Promise<void>) => {
    Modal.confirm({
      title: translations["item.codes.overwrite.modal.title"].replace("{{code}}", newCode.type),
      content: (
        <div className="mb-3">
          <div className="mb-2">{translations["item.codes.overwrite.modal.content"].replace("{{code}}", newCode.type)}</div>
          <div className="flex items-center justify-between gap-2">
            <CodeItem code={currCode} labelKey="item.codes.overwrite.modal.current_code" />
            <ArrowRightOutlined style={{ color: token.colorIcon }} />
            <CodeItem code={newCode} labelKey="item.codes.overwrite.modal.new_code" />
          </div>
        </div>
      ),
      okText: translations["item.codes.overwrite.modal.ok"],
      onOk,
      onCancel() {},
    });
  }
}
