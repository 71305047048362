import { FacetsComponentsEnum } from "modules/FacetsModule/FacetsModule.types";

import { FacetsSectionWrapperFlatTree } from "./FacetsSectionWrapperFlatTree";
import { FacetsSectionWrapperRange } from "./FacetsSectionWrapperRange";
import { FacetsSectionWrapperTree } from "./FacetsSectionWrapperTree";

export const componentsMap = {
  [FacetsComponentsEnum.FLAT_TREE]: FacetsSectionWrapperFlatTree,
  [FacetsComponentsEnum.RANGE]: FacetsSectionWrapperRange,
  [FacetsComponentsEnum.TREE]: FacetsSectionWrapperTree,
};
