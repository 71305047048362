import { MatchGroupPrematch } from "types/entities/Match";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { MarketCode } from "consts/markets";
import { getCountryConfig } from "helpers/countryHelpers";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";

import { TaxonomyItemsNamesViewer } from "modules/MatchingModule/components/TaxonomyItemsViewer";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { SpeciesListWithWeight } from "components/SpeciesListWithWeight";
import { TaxonomyItemPrice } from "components/TaxonomyItemPrice";

export const getCommonProductItems = ({
  taxonomyItem,
  prematch = {},
  translations,
  countryValue,
}: {
  taxonomyItem?: TaxonomyItem;
  prematch: Partial<MatchGroupPrematch>;
  translations: Record<string, string>;
  countryValue: MarketCode;
}) => {
  const items = [];
  const { countryNameISO } = getCountryConfig(countryValue);

  if (taxonomyItem) {
    items.push({
      title: `${translations["matching.table.price_band.label"]}, ${countryNameISO}`,
      value: <TaxonomyItemPrice itemID={taxonomyItem.id} country={countryValue} />
    });
  }

  items.push({
    title: getTaxonomyClassTitle(TaxonomyClass.SPECIES),
    value: taxonomyItem
      ? <SpeciesListWithWeight taxonomyItem={taxonomyItem} convertToMarket={countryValue} />
      : <TaxonomyItemsNamesViewer taxonomyClass={TaxonomyClass.SPECIES} itemsIDs={prematch[TaxonomyClass.SPECIES] || []} />,
  });

  return items;
}
