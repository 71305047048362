import React from "react";
import { theme, Tooltip } from "antd";
import clsx from "clsx";

import { LabelledRowProps } from "./LabelledRow.types";

export const LabelledRow = React.memo<LabelledRowProps>((props) => {
  const { label, value, alignValue = "left", nowrap, tooltip, className } = props;
  const { token } = theme.useToken();

  return (
    <div className={clsx("flex py-3 gap-2", className)}>
      <div className="w-1/2" style={{ color: token.colorTextLabel }}>{label}</div>
      <Tooltip title={tooltip} placement="bottom">
        <div className="w-1/2 overflow-hidden overflow-ellipsis" style={{ textAlign: alignValue, whiteSpace: nowrap ? "nowrap" : "normal" }}>
          {value}
        </div>
      </Tooltip>
    </div>
  );
});
