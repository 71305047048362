import { PrematchTaxonomyClasses } from "types/entities/Prematch";

import { facetConfigurationMap } from "modules/TaxonomyModule/consts/facetConfigurationMap";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";

export const getFacetKeyByTaxonomyClass = (
  mainTaxonomyClass: PrematchTaxonomyClasses,
  taxonomyClass: TaxonomyClass
) => {
  const facetConfig = facetConfigurationMap[mainTaxonomyClass];
  if (!facetConfig) return "";

  return facetConfig.find((section) => {
    if ("taxonomyClass" in section) return section.taxonomyClass?.toLowerCase() === taxonomyClass?.toLowerCase();
    return false;
  })?.key || "";
}
