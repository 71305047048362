import React, { RefObject } from "react";
import { debounce } from "helpers/debounce";

import { getFirstVisibleEl } from "../helpers/getFirstVisibleEl";
import { scrollIntoView as scrollIntoViewHelper } from "../helpers/scrollIntoView";

import {
  useMatchingTargetsClassTabsContext
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";

export const useKeepSelectedGroupVisible = (scrollContainerRef: RefObject<HTMLDivElement>, childClassName: string, topOffset?: number) => {
  const { isDiagnosticTabSelected } = useMatchingTargetsClassTabsContext();

  /* Track first visible group */
  const targetElRef = React.useRef<HTMLElement | null>(null);
  React.useEffect(() => {
    if (!scrollContainerRef.current) return;
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = debounce(() => {
      const groupsEls = scrollContainer.querySelectorAll(`.${childClassName}`);
      targetElRef.current = getFirstVisibleEl(scrollContainer, Array.from(groupsEls) as HTMLElement[], topOffset);
    }, 1500);

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, [scrollContainerRef, childClassName, topOffset]);

  const scrollIntoView = React.useCallback(() => {
    const containerEl = scrollContainerRef.current;
    const targetEl = targetElRef.current;

    if (!containerEl || !targetEl) return;

    setTimeout(() => {
      scrollIntoViewHelper(containerEl, targetEl, topOffset);
    }, 100);
  }, [scrollContainerRef, topOffset]);

  const resizeObserver = React.useMemo(() => {
    let prevWidth = 0;
    return new ResizeObserver(debounce((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === "number" && width !== prevWidth) {
          scrollIntoView();
          prevWidth = width;
        }
      }
    }));
  }, [scrollIntoView]);

  React.useEffect(() => {
    if (!scrollContainerRef.current) return;
    resizeObserver.observe(scrollContainerRef.current);
    return () => {
      resizeObserver.disconnect();
    }
  }, [resizeObserver, scrollContainerRef]);

  /* Recalculate scroll if diagnostic test is selected */
  React.useEffect(() => {
    scrollIntoView();
  }, [isDiagnosticTabSelected, scrollIntoView]);
}
