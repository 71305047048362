import { UIError } from "types/UIError";

export const errorMessageTranslationKeys: { [errorCode: string]: UIError } = {
  SAME_NAME_EXISTS: "item.form.error.duplicate_name",
  SAME_SYNONYM_EXISTS: "synonym.form.error.duplicate",
  USED_BY_OTHER_ITEMS: {
    title: "item.delete.error.has_relations.title",
    content: "item.delete.error.has_relations.content",
  }
}
