import React, { useRef } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";

type setSearchParamsRef = { current: (params: URLSearchParams, opts: NavigateOptions) => void };
export class UrlQueryService {
  serviceKey: string;
  private setSearchParamsRef: setSearchParamsRef;

  constructor(key: string, setSearchParamsObj: setSearchParamsRef) {
    this.serviceKey = key;
    this.setSearchParamsRef = setSearchParamsObj;
  }

  getAllSearchParams() {
    return UrlQueryService.getSearchParams();
  }

  getQueryStringWithoutOtherQueries() {
    const searchParams = UrlQueryService.getSearchParams();
    searchParams.forEach((value, key) => {
      if (key !== this.serviceKey) searchParams.delete(key);
    });
    return searchParams.toString();
  }

  getQueryStringWithoutThisQuery() {
    const searchParams = UrlQueryService.getSearchParams();
    searchParams.delete(this.serviceKey);
    return searchParams.toString();
  }

  getQueryForApi() {
    const searchParams = UrlQueryService.getSearchParams();
    return searchParams.get(this.serviceKey) || "";
  }

  getOnlyThisSearchParams() {
    const searchParams = UrlQueryService.getSearchParams();
    return new URLSearchParams(searchParams.get(this.serviceKey) || "");
  }

  getValueByKey(key: string): string | string[] | null {
    const params = this.getOnlyThisSearchParams();
    return params.get(key);
  }

  setSearchParam(key: string, value?: string | string[]) {
    const searchParams = UrlQueryService.getSearchParams();
    const thisSearchParams = this.getOnlyThisSearchParams();

    if (value && value.length) {
      thisSearchParams.set(key, Array.isArray(value) ? value.join(",") : value);
    } else {
      thisSearchParams.delete(key);
    }

    if (thisSearchParams.toString().length) {
      searchParams.set(this.serviceKey, thisSearchParams.toString());
    } else {
      searchParams.delete(this.serviceKey);
    }

    this.setSearchParamsRef.current(searchParams, { replace: true });
  }

  setMultipleSearchParams(params: Record<string, string | string[]>) {
    Object.entries(params).forEach(([key, value]) => this.setSearchParam(key, value));
  }

  generateUrlFilterStringFromFacets(facets: Record<string, string[]>) {
    const filterString = Object.entries(facets).reduce<string>((acc, [facetKey, facetValues]) => {
      const facetValuesString = facetValues.join(",");
      return `${acc}${acc ? "&" : ""}${facetKey}=${facetValuesString}`;
    }, "");
    const searchParams = UrlQueryService.getSearchParams();
    searchParams.set(this.serviceKey, filterString);
    return searchParams.toString();
  }

  clearSearchParams() {
    const searchParams = UrlQueryService.getSearchParams();
    searchParams.delete(this.serviceKey);
    this.setSearchParamsRef.current(searchParams, { replace: true });
  }

  static getSearchParams(): URLSearchParams {
    const query = window.location.search || "";
    return new URLSearchParams(query);
  }
}

export const useUrlQueryService = (serviceKey: string) => {
  const [, setSearchParams] = useSearchParams();

  const optimizationRef = useRef(() => {});
  optimizationRef.current = setSearchParams;

  return React.useMemo(() => new UrlQueryService(serviceKey, optimizationRef), [serviceKey]);
};
