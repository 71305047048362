import { PaginatedResponse } from "types/apiTypes";
import { DeepPartial } from "types/common";
import { MatchGroup } from "types/entities/Match";

import React from "react";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useFilterContext } from "context/FilterContext/Filter.context";

import { QueryKeys } from "api/queryKeys";
import { getUrlToMatchingList, MATCHING_PAGINATION_LIMIT } from "api/rootUrl";

import { getMatchingList } from "modules/MatchingModule/api/useGetMatchingList";
import { UseUpdateGroupMatchProps } from "modules/MatchingModule/api/useUpdateGroupMatch.types";

const patchMatchGroup =
  ({ jobId, datasetName }: UseUpdateGroupMatchProps) =>
    (data: DeepPartial<MatchGroup>) =>
      axios.patch(getUrlToMatchingList(jobId, datasetName), data);

export const useMatchMultipleGroups = ({ jobId, datasetName, cb }: UseUpdateGroupMatchProps) => {
  const queryClient = useQueryClient();
  const { allowedFilterParamsWithAdditionalKeys } = useFilterContext();

  const isLoading = queryClient.isMutating({ mutationKey: [QueryKeys.PATCH_MATCH_GROUP] }) > 0;
  const [isListUpdating, setIsListUpdating] = React.useState(false);

  const updateGroupsList = React.useCallback(async (matchGroupsListSize: number, needLoadNextPage?: boolean) => {
    setIsListUpdating(true);

    let limit = matchGroupsListSize || MATCHING_PAGINATION_LIMIT;
    if (matchGroupsListSize % MATCHING_PAGINATION_LIMIT > 0)
      limit += MATCHING_PAGINATION_LIMIT - (matchGroupsListSize % MATCHING_PAGINATION_LIMIT);
    if (needLoadNextPage) limit += MATCHING_PAGINATION_LIMIT;

    const { data: updatedMatchGroupsList, nextPage } = await getMatchingList({
      jobId,
      datasetName,
      filterParams: allowedFilterParamsWithAdditionalKeys,
    })({ pageParam: 1, limit });

    queryClient.setQueriesData({ queryKey: [QueryKeys.GET_LIST_MATCH_GROUPS] }, (oldData) => {
      const newData: InfiniteData<PaginatedResponse<MatchGroup>> = { pages: [], pageParams: [] };

      if (!updatedMatchGroupsList.length) {
        newData.pages.push({ data: [], nextPage: null });
        newData.pageParams.push(undefined);
        return newData;
      }

      for (let i = 0; i < updatedMatchGroupsList.length; i++) {
        if (newData.pages.length === 0 || i >= newData.pages.length * MATCHING_PAGINATION_LIMIT) {
          const currentPage = newData.pages.length + 1;
          newData.pages.push({ data: [], nextPage: nextPage ? currentPage + 1 : null });
          newData.pageParams.push(currentPage);
        }

        newData.pages[newData.pages.length - 1].data.push(updatedMatchGroupsList[i]);
      }

      return newData;
    });

    setIsListUpdating(false);

    if (cb) cb(updatedMatchGroupsList);

    await queryClient.invalidateQueries([QueryKeys.GET_LIST_FACETS_VALUES]);
  }, [allowedFilterParamsWithAdditionalKeys, cb, jobId, queryClient, datasetName]);

  const { mutateAsync } = useMutation({
    mutationKey: [QueryKeys.PATCH_MATCH_GROUP],
    mutationFn: patchMatchGroup({ jobId, datasetName }),
  });

  const updateMultipleGroups = React.useCallback(
    async (groups: DeepPartial<MatchGroup>[], matchGroupsListSize = 0, needLoadNextPage?: boolean) => {
      await Promise.all(groups.map((group) => mutateAsync(group)));
      return await updateGroupsList(matchGroupsListSize, needLoadNextPage);
    },
    [mutateAsync, updateGroupsList]
  );

  return { updateMultipleGroups, isLoading: isLoading || isListUpdating };
};
