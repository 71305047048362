import { MatchStatus } from "types/entities/Match";
import { PrematchTaxonomyClasses } from "types/entities/Prematch";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React, { useRef } from "react";
import { SwapRightOutlined } from "@ant-design/icons";
import { Button, Cascader, Divider, Input } from "antd";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { useEndpointByTaxonomyClass } from "api/useEndpoint";

import { cascaderSearchFilter } from "./helpers/cascaderSearchFilter";
import { cascaderSearchRender } from "./helpers/cascaderSearchRender";
import { getCurrentOptionTitleAndIgnored } from "./helpers/getCurrentOptionTitleAndIgnored";
import { getFilledPrematch } from "./helpers/getFilledPrematch";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

import { useCascadeOptions } from "./useCascadeOptions";

export const MatchingMoveButton = React.memo(() => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();

  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [selectedData, setSelectedData] = React.useState<(string | number)[]>([]);
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const { patchSelectedGroups, isMatchGroupsUpdating } = useMatchingContext();

  const { data: drugClasses = [] } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.DRUG_CLASS);
  const { data: foodClasses = [] } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.FOOD_CLASS);
  const { data: retailClasses = [] } = useEndpointByTaxonomyClass<TaxonomyItem>(TaxonomyClass.RETAIL_CLASS);

  const cascadeOptions = useCascadeOptions(drugClasses, foodClasses, retailClasses);

  const { title, isIgnored } = React.useMemo(
    () => getCurrentOptionTitleAndIgnored(selectedData, cascadeOptions, drugClasses, foodClasses),
    [cascadeOptions, drugClasses, foodClasses, selectedData]
  );

  const handleClose = React.useCallback(() => {
    if (open) {
      setOpen(false);
      setSearchValue("");
      setSelectedData([]);
    }
  }, [open]);

  const handleClick = React.useCallback(() => {
    if (!open) setOpen(true);
    else handleClose();
  }, [handleClose, open]);

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const handleMove = React.useCallback(
    async () => {
      const itemId = selectedData.length > 1 ? selectedData.at(-1) : undefined;

      await patchSelectedGroups({
        status: isIgnored ? MatchStatus.IGNORED : MatchStatus.MOVED,
        prematch: {
          [GenericTaxonomyClass]: [selectedData[0] as PrematchTaxonomyClasses],
          [TaxonomyClass.FOOD_BRAND]: [],
          [TaxonomyClass.FOOD_CLASS]: [],
          [TaxonomyClass.MEDICAL_BRAND]: [],
          [TaxonomyClass.DRUG_CLASS]: [],
          [TaxonomyClass.RETAIL_CLASS]: [],
          ...getFilledPrematch(selectedData[0] as PrematchTaxonomyClasses, itemId as string),
        }
      });
      handleClose();
      showSuccess(translations["matching.messages.move.success"]);
    },
    [selectedData, patchSelectedGroups, isIgnored, handleClose, showSuccess, translations]
  );

  const dropdownRenter = React.useCallback((menus: React.ReactNode) => {
    return (
      <div ref={dropdownRef}>
        <div className="p-2">
          <Input.Search
            placeholder={translations["matching.inputs.search.placeholder"]}
            value={searchValue}
            onChange={handleSearch}
          />
        </div>
        <Divider style={{ margin: 0 }} />
        {menus}
        <Divider style={{ margin: 0 }} />
        {title && (
          <div className="p-2">
            <Button onClick={handleMove} type="primary" className="w-full" loading={isMatchGroupsUpdating}>
              {translations["matching.buttons.move.to"]} {title} {isIgnored && `${translations["matching.buttons.move.ignore"]}`}
            </Button>
          </div>
        )}
      </div>
    )
  }, [translations, searchValue, handleSearch, title, handleMove, isMatchGroupsUpdating, isIgnored]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !buttonWrapperRef.current?.contains(event.target as Node) &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return function cleanup() {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClose]);

  return (
    <div ref={buttonWrapperRef}>
      <Cascader
        open={open}
        value={selectedData}
        dropdownRender={dropdownRenter}
        options={cascadeOptions}
        onChange={setSelectedData}
        showSearch={{ filter: cascaderSearchFilter, render: cascaderSearchRender }}
        searchValue={searchValue}
        changeOnSelect
      >
        <Button icon={<SwapRightOutlined />} size="small" onClick={handleClick}>
          {translations["matching.buttons.move"]}
        </Button>
      </Cascader>
    </div>
  );
});
