import { ResponseError } from "types/apiTypes";

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { useResponseErrorMessage } from "helpers/useResponseErrorMessage";
import { useMessage } from "hooks/useMessage";

const queryClient = (onError: (err: unknown) => void) => {
  return new QueryClient({
    defaultOptions: {
      queries: { onError, refetchOnWindowFocus: false },
      mutations: { onError },
    },
  });
};

export const ApiQueryClientProvider: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { showError } = useMessage();

  const getErrorMessage = useResponseErrorMessage();

  const handleQueryError = React.useCallback(
    (err: unknown) => {
      const errorMessage = getErrorMessage(err as AxiosError<ResponseError>);
      if (errorMessage) showError(errorMessage);
    },
    [getErrorMessage, showError]
  );

  return (
    <QueryClientProvider client={queryClient(handleQueryError)}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
