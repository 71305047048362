import { RelationsForm, RelationsToBE } from "types/entities/Relations";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { relationsToBEFormat } from "helpers/relationsToBEFormat";

const createRelationsListFromBEFormat = (relationsBE: TaxonomyItem["relations"]): RelationsToBE => {
  const relationsEntries = Object.entries(relationsBE || {});

  return relationsEntries.reduce((acc, [relationType, relationsValue]) => {
    const formattedArray = relationsValue.map((el) => ({
      type: relationType,
      to: el.id,
    }));
    return [...acc, ...formattedArray];
  }, [] as RelationsToBE);
};

const getListOfItemsForCreationAndDeletion = (
  oldRelations: RelationsToBE,
  newRelations: RelationsToBE
): {
  listOfCreated: RelationsToBE;
  listOfDeleted: RelationsToBE;
} => {
  const listOfCreated: RelationsToBE = [];
  let oldRelationsCopy = [...oldRelations];

  newRelations.forEach((newRelation) => {
    const existedRelation = oldRelationsCopy.find(
      (oldRelationEl) => newRelation.to === oldRelationEl.to && newRelation.type === oldRelationEl.type
    );
    if (existedRelation) {
      oldRelationsCopy = oldRelationsCopy.filter((el) => existedRelation !== el);
      return;
    }
    listOfCreated.push(newRelation);
  });

  return {
    listOfCreated,
    listOfDeleted: oldRelationsCopy,
  };
};

export const compareRelationsForCreationAndDeletion = (
  initialTaxonomyItem: TaxonomyItem | undefined,
  relationsForm: RelationsForm
) => {
  if (!initialTaxonomyItem) return;

  const oldRelations = createRelationsListFromBEFormat(initialTaxonomyItem.relations);

  const newRelationsList = relationsToBEFormat(relationsForm);

  return getListOfItemsForCreationAndDeletion(oldRelations, newRelationsList);
};
