import { TaxonomyItem } from "types/entities/Taxonomy";

import { JSONSchema7Type } from "json-schema";
import { configurationServiceInstance } from "services/ConfigurationService";

export const getRestPropsAndRelations = (
  taxonomyClass: string,
  taxonomyItem: Partial<TaxonomyItem>,
  requiredPropsKeys: string[],
  requiredRelationsKeys: string[]
): {
  restProps: Record<string, JSONSchema7Type | undefined>,
  restRelations: Record<string, TaxonomyItem[]>,
} => {
  const config = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  if (!config) return { restProps: {}, restRelations: {} };

  const restProps = Object.keys(config.properties || {})
    .filter((key) => !requiredPropsKeys.includes(key))
    .reduce<Record<string, JSONSchema7Type | undefined>>((acc, propKey) => {
      acc[propKey] = taxonomyItem[propKey];
      return acc;
    }, {});

  const restRelations = Object.keys(config.meta.relations)
    .filter((key) => !requiredRelationsKeys.includes(key))
    .reduce<Record<string, TaxonomyItem[]>>((acc, relationKey) => {
      acc[relationKey] = taxonomyItem.relations?.[relationKey] || [];
      return acc;
    }, {});

  return { restProps, restRelations };
}
