import { DataTypeForTable } from "types/common";

import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

type ListOfItemsProps = { columns: ColumnsType<Record<string, unknown>>; data: DataTypeForTable[] };
export const ListOfItems: React.FC<ListOfItemsProps> = ({ columns, data }) => {
  const tableRef = React.useRef<HTMLDivElement>(null);

  return (
    <Table
      ref={tableRef}
      columns={columns}
      dataSource={data}
      size="middle"
      sticky
      scroll={{ x: 300 }}
      pagination={false}
    />
  );
};
