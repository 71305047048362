import { Dataset } from "types/entities/Jobs";

import { useParams } from "react-router-dom";
import { MatchingParamsType } from "router/navigationTypes";

export const checkDataset = (datasetName?: string): { isTransactions: boolean; isSpecies: boolean } => {
  return {
    isTransactions: datasetName === Dataset.TRANSACTIONS,
    isSpecies: datasetName === Dataset.SPECIES,
  }
}

export const useCheckDataset = () => {
  const { datasetName } = useParams<MatchingParamsType>();
  return checkDataset(datasetName);
}
