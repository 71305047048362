import React from "react";
import { Radio, Space, theme, Typography } from "antd";
import { getCountryConfig } from "helpers/countryHelpers";
import { useTranslations } from "translations/useTranslations";

import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

import { Preloader, PreloaderType } from "ui/Preloader";

export const SelectCountry = React.memo<{ containerClass: string }>(
  ({ containerClass }) => {
    const translations = useTranslations();
    const { countries, countryValue, setCountryToSearchParams, isLoading } = useInformationSourceInSearchParams();

    const { token } = theme.useToken();

    return (
      <Space
        direction="vertical"
        size="middle"
        className={containerClass}
        style={{ borderColor: token.colorBorder, backgroundColor: token.colorBgContainer }}
      >
        <Typography.Text strong>{translations["matching.information_source.country"]}</Typography.Text>
        {isLoading ? (
          <Preloader type={PreloaderType.SKELETON} title={false} />
        ) : (
          <Radio.Group value={countryValue} onChange={(e) => setCountryToSearchParams(e.target.value)}>
            <Space direction="vertical">
              {countries?.filter(({ name }) => !!name)?.map(({ name }) => {
                const countryConfig = getCountryConfig(name!);
                const icon = countryConfig.image;
                return (
                  <Radio key={name} value={name}>
                    {icon && <img src={icon} alt="country-flag" className="mr-2" />}
                    {name}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        )}
      </Space>
    );
  }
);
