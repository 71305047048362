import React from "react";
import { Link } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { jobsListPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { Breadcrumb } from "ui/Breadcrumb";

import { JobsBreadcrumbsProps } from "./JobsBreadcrumbs.types";

export const JobsBreadcrumbs = React.memo<JobsBreadcrumbsProps>(({ breadcrumbItems = [] }) => {
  const translations = useTranslations();

  const jobsTitle = React.useMemo(() => <Space><CheckCircleOutlined />{translations["menu.jobs"]}</Space>, [translations]);

  const items = React.useMemo(() => [
    { title: breadcrumbItems.length ? <Link to={jobsListPath}>{jobsTitle}</Link> : jobsTitle },
    ...breadcrumbItems
  ], [breadcrumbItems, jobsTitle]);

  return (
    <Breadcrumb items={items} />
  );
});
