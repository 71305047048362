import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Link } from "react-router-dom";
import { EMPTY } from "consts/strings";
import { getTaxonomyItemName } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyItemName";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { Preloader, PreloaderType } from "ui/Preloader";

import { TaxonomyItemsNamesViewerProps } from "./TaxonomyItemsNamesViewer.types";

export const TaxonomyItemsNamesViewer = React.memo<TaxonomyItemsNamesViewerProps>(({
  taxonomyClass,
  itemsIDs,
  separator = ", ",
  className,
  links,
}) => {
  const { data = [], isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(taxonomyClass);

  const items: TaxonomyItem[] = React.useMemo(() => {
    return data
      .filter((item) => itemsIDs.includes(item.id))
      .sort((a,b) => a.name && b.name
        ? a.name.localeCompare(b.name)
        : a.id.localeCompare(b.id)
      );
  }, [data, itemsIDs]);

  if (isLoading && !items.length) {
    return <Preloader type={PreloaderType.SKELETON} title={{ width: 100, style: { marginBottom: 0 } }} paragraph={false} />;
  }

  return links ? (
    <span>
      {items.map(
        (item, i) => (
          <span key={item.id}>
            <Link to={`/${item.id}`} target="_blank">{getTaxonomyItemName(item)}</Link>
            {i < items.length - 1 ? ", " : ""}
          </span>
        )
      )}
    </span>
  ) : (
    <span className={className}>{items.length ? items.map(getTaxonomyItemName).join(separator) : EMPTY}</span>
  );
});
