import { MatchStatus } from "types/entities/Match";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Card, Empty, theme, Typography } from "antd";
import { queries } from "consts";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { specialStatuses } from "modules/MatchingModule/helpers/statusMaps";
import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { MatchingTargets } from "modules/MatchingModule/MatchingListModule/components/MatchingTargets";
import { columnHeight, minColumnHeight } from "modules/MatchingModule/MatchingListModule/consts/matchingColumnSizes";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { MatchingTargetsActions } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsActions";
import {
  MatchingTargetsClassTabs
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs";
import { MatchingTargetsTabs } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsTabs";
import { MatchingTargetSpecialModule } from "modules/MatchingModule/MatchingTargetSpecialModule";

import MatchingTargetsEmpty from "assets/images/MatchingTargetsEmpty.svg";

export const MatchingTargetsModule = React.memo(() => {
  const { selectedGroupIds, commonStatus } = useMatchingContext();
  const enabled = useFeatureFlag(FeatureFlagNames.MATCHING_VIEW_SUGGESTIONS);
  const minLG = useMediaQuery({ query: queries.lg });
  const { isTransactions } = useCheckDataset();

  const translations = useTranslations();

  const { token } = theme.useToken();

  const showTargetsHeader = minLG || (commonStatus && specialStatuses.includes(commonStatus))
  const showTargetsList = commonStatus !== MatchStatus.IGNORED;

  if (!enabled) return null;

  if (!selectedGroupIds.length) {
    return (
      <Empty
        image={MatchingTargetsEmpty}
        imageStyle={{ maxHeight: "320px", width: "100%", height: "auto", aspectRatio: "1", padding: "20px" }}
        description={(
          <>
            <Typography.Title level={4}>{translations["matching.target.list.empty.title"]}</Typography.Title>
            <Typography.Text style={{ color: token.colorTextLabel }}>{translations["matching.target.list.empty.subtitle"]}</Typography.Text>
          </>
        )}
      />
    );
  }

  return (
    <div className="flex flex-col gap-6 lg:gap-4">
      <MatchingTargetSpecialModule />
      {showTargetsList && (
        <Card
          bordered={false}
          style={{ ...(!minLG && { boxShadow: "none" })}}
          bodyStyle={{ ...(!minLG && { padding: 0 }) }}
        >
          <div
            className="flex flex-col"
            style={{
              ...(minLG && {
                height: columnHeight,
                minHeight: minColumnHeight,
              })
            }}
          >
            {showTargetsHeader && (
              <div className="flex justify-between flex-wrap gap-2 mb-4">
                <Typography.Title level={5} style={{marginBottom: 0}}>
                  {translations["matching.target.list.title"]}
                </Typography.Title>
                <MatchingTargetsActions />
              </div>
            )}
            {isTransactions && <MatchingTargetsClassTabs/>}
            <MatchingTargetsTabs />
            <MatchingTargets />
          </div>
        </Card>
      )}
    </div>
  );
});
