import React from "react";
import { Card, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { JobsBreadcrumbs } from "modules/JobsModule/components/JobsBreadcrumbs";
import { JobsCreateFormModule } from "modules/JobsModule/JobsCreateFormModule";

import { AccessDenied } from "ui/AccessDenied";

export const JobsCreatePage = () => {
  const translations = useTranslations();
  const jobsCreateEnabled = useFeatureFlag(FeatureFlagNames.JOBS_CREATE);

  const breadcrumbsItems = React.useMemo(() => [
    { title: <Typography.Text>{translations["jobs.create.title"]}</Typography.Text> }
  ], [translations]);

  if (!jobsCreateEnabled) return <AccessDenied />;

  return (
    <div className="pt-4 pb-6">
      <JobsBreadcrumbs breadcrumbItems={breadcrumbsItems} />
      <Typography.Title>{translations["jobs.create.title"]}</Typography.Title>
      <Card>
        <JobsCreateFormModule />
      </Card>
    </div>
  )
}
