import { ItemID } from "types/common";
import { TrimmedCode } from "types/entities/Code";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { getItemCodesListUrl } from "api/rootUrl";

const postCreateItemCode = (itemID: ItemID) => (data: TrimmedCode) =>
  axios.post(getItemCodesListUrl(itemID), data).then((res) => res.data);

export const useCreateItemCode = (itemID: ItemID) => {
  const { showSuccess } = useMessage();
  const queryClient = useQueryClient();
  const translations = useTranslations();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: postCreateItemCode(itemID),
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.GET_ITEM_CODES, itemID]);
      showSuccess(translations["item.codes.create.modal.success"]);
    }
  });

  return { createCode: mutateAsync, isLoading, error };
}
