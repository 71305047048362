import { PairNumberType, PairStringType } from "modules/FacetsModule/components/RangeFacet/RangeFacet.types";

export const getValidValue = ([min, max]: PairNumberType, value: string | number | null): number => {
  const numValue = value === null ? value : Number(value);
  if (!numValue && numValue !== 0) return min;
  if (numValue > max) return max;
  if (numValue < min) return min;
  return numValue;
}

export const getValidRange = ([min, max]: PairNumberType, facetValues: PairStringType | PairNumberType | []): PairNumberType => {
  if (!facetValues.length) return [min, max];

  const numericFacetValues = facetValues.map(Number);
  const [minValue = min, maxValue = max] = numericFacetValues;

  if (!minValue && !maxValue) return [min, max];
  if (minValue > maxValue) return [maxValue, minValue];

  return numericFacetValues as PairNumberType;
}
