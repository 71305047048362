import React from "react";

import { specialStatuses } from "modules/MatchingModule/helpers/statusMaps";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

import { MatchingTargetsDrawerCommonTitle } from "./MatchingTargetsDrawerCommonTitle";
import { MatchingTargetsDrawerSpecialTitle } from "./MatchingTargetsDrawerSpecialTitle";
import { MatchingTargetsDrawerTitleProps } from "./MatchingTargetsDrawerTitle.types";

export const MatchingTargetsDrawerTitle = React.memo<MatchingTargetsDrawerTitleProps>((props) => {
  const { isOpen, onToggle, height } = props;
  const { commonStatus } = useMatchingContext();

  if (commonStatus && specialStatuses.includes(commonStatus)) {
    return <MatchingTargetsDrawerSpecialTitle isOpen={isOpen} onToggle={onToggle} height={height} />
  } else {
    return <MatchingTargetsDrawerCommonTitle isOpen={isOpen} onToggle={onToggle} height={height} />;
  }
});
