import React from "react";
import { InputNumber, Slider } from "antd";
import { debounce } from "helpers/debounce";

import { getValidRange, getValidValue } from "./helpers/getValidValues";

import { PairNumberType, PairStringType, RangeFacetProps } from "./RangeFacet.types";

const getInitialRange = (
  [min, max]: PairNumberType,
  [facetValueMin, facetValueMax]: PairStringType | []
): PairNumberType => {
  return [facetValueMin ? Number(facetValueMin) : min, facetValueMax ? Number(facetValueMax) : max];
};

export const RangeFacet = React.memo<RangeFacetProps>(({ min, max, facetSectionChange, facetValues }) => {
  const [range, setRange] = React.useState<PairNumberType>(getInitialRange([min, max], facetValues));

  const updateFacet = React.useMemo(() => debounce((newRange: PairNumberType) => {
    const validRange = getValidRange([min, max], newRange);
    setRange(validRange);
    facetSectionChange(validRange);
  }, 1000), [facetSectionChange, max, min]);

  const updateRange = React.useCallback((newRange: PairNumberType) => {
    setRange(newRange);
    updateFacet(newRange);
  }, [updateFacet]);

  React.useEffect(() => {
    setRange(currentRange => getValidRange([min, max], currentRange));
  }, [max, min]);

  React.useEffect(() => {
    if (facetValues.length === 0) setRange([min, max]);
  }, [facetValues, max, min]);

  return (
    <>
      <Slider range min={min} max={max} onAfterChange={updateRange} onChange={setRange} value={range} />
      <div className="flex justify-between">
        <InputNumber
          min={min}
          max={max}
          value={range[0]}
          onChange={(value) => updateRange([getValidValue([min, max], value), range[1]] as PairNumberType)}
        />
        <InputNumber
          min={min}
          max={max}
          value={range[1]}
          onChange={(value) => updateRange([range[0], getValidValue([min, max], value)] as PairNumberType)}
        />
      </div>
    </>
  );
});
