import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { catalogPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

export const MatchingCreateItemButton = React.memo(() => {
  const translations = useTranslations();

  return (
    <Link to={catalogPath}>
      <Button type="primary" className="w-full">
        {translations["matching.buttons.create_item"]}
      </Button>
    </Link>
  );
});
