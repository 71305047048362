import { ResponseError } from "types/apiTypes";
import { UIError } from "types/UIError";

import { ErrorSchema } from "@rjsf/utils";
import { AxiosError } from "axios";
import { errorMessageTranslationKeys } from "consts/errorMessageTranslationKeys";

const extractErrorDetail = (error?: AxiosError<ResponseError> | null): ResponseError["detail"] | undefined => {
  if (!error?.response?.data?.detail) return;
  return error.response.data.detail;
}

export const formatBEErrorToErrorSchema = (error?: AxiosError<ResponseError> | null): ErrorSchema | undefined => {
  const detail = extractErrorDetail(error);
  if (!detail || typeof detail === "string") return;

  return detail.reduce((acc, { loc, msg }) => {
    const path = loc.join(".");

    const msgOrTranslationKey = typeof errorMessageTranslationKeys[msg] === "string"
      ? errorMessageTranslationKeys[msg] || msg
      : msg;

    return {
      ...acc,
      [path]: {
        __errors: [msgOrTranslationKey],
      },
    };
  }, {});
};

export const formatBEErrorToModals = (error?: AxiosError<ResponseError> | null): UIError[] => {
  const detail = extractErrorDetail(error);
  if (!detail) return [];

  if (typeof detail === "string") {
    return [errorMessageTranslationKeys[detail] || detail];
  }

  return detail.map(({ msg }) => errorMessageTranslationKeys[msg] || msg);
};

export const formatBEErrorToString = (error?: AxiosError<ResponseError> | null): string => {
  const detail = extractErrorDetail(error);
  if (!detail) return "";

  const msg = typeof detail === "string" ? detail : detail[0].msg;

  const msgOrTranslationKey = errorMessageTranslationKeys[msg] || msg;
  return typeof msgOrTranslationKey === "string"
    ? msgOrTranslationKey
    : msgOrTranslationKey.title || msgOrTranslationKey.content || msg;
}
