import { useMemo } from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { FacetSectionConfigurationItem, FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import { useGetStaticFacetItemTitle } from "modules/FacetsModule/hooks/useGetStaticFacetItemTitle";

export const useFillItemsWithCache = (
  values: FacetsValueFlatTreeType["value"],
  enabled: boolean,
  facetSectionsConfiguration: FacetSectionConfigurationItem
) => {
  const [taxonomyClass] = getItemClassFromItemId(values[0]?.id || "");
  const { data, isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(taxonomyClass, {
    enabled: enabled && !("isStatic" in facetSectionsConfiguration)
  });

  const getStaticItemTitle = useGetStaticFacetItemTitle(facetSectionsConfiguration.key);
  const filledValues = useMemo(() => {
    if ("isStatic" in facetSectionsConfiguration && facetSectionsConfiguration.isStatic) {
      return values.map((value) => ({
        ...value,
        name: getStaticItemTitle(value.id),
      }));
    }

    if (enabled && !data?.length) return [];

    if (enabled) {
      return values.map((value) => ({
        ...value,
        name: data?.find((el) => el.id === value.id)?.name || value.id,
      }));
    }

    return values;
  }, [data, enabled, facetSectionsConfiguration, getStaticItemTitle, values]);

  return { filledValues, isLoading };
};
