import React from "react";
import ReactDOM from "react-dom/client";
import { msalInstance } from "services/AuthService";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "antd/dist/reset.css";
import "index.css";
import "assets/styles/index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App pca={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
