import React from "react";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { SynonymCreateModule } from "modules/SynonymModule/SynonymCreateModule/SynonymCreateModule";

import { SynonymListNewItemProps } from "./SynonymListNewItem.types";

export const SynonymListNewItem = React.memo<SynonymListNewItemProps>((props) => {
  const catalogSynonymCreateEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_SYNONYM_CREATE);
  const { taxonomyItemId, synonymType } = props;

  if (!catalogSynonymCreateEnabled) return null;

  return <SynonymCreateModule taxonomyItemId={taxonomyItemId} synonymType={synonymType} />;
});
