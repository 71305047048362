import React from "react";
import { MessageContext } from "App";

export const useMessage = () => {
  const message = React.useContext(MessageContext);

  const showSuccess = React.useCallback((msg: string) => {
    if (!message) return;
    message.success(msg);
  }, [message]);

  const showError = React.useCallback((msg: string) => {
    if (!message) return;
    message.error(msg);
  }, [message]);

  return { showSuccess, showError };
}
