import { ItemID } from "types/common";

import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { getTaxonomyCreatePath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { Preloader, PreloaderType } from "ui/Preloader";

export const DuplicateTaxonomyItemButton = React.memo<{ itemID?: ItemID, className?: string }>(({ itemID, className }) => {
  const translations = useTranslations();
  const { components: { CatalogLink } } = useCatalogContext();


  const catalogItemCreateEnabledForRole = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_CREATE);

  if (!catalogItemCreateEnabledForRole) return null;
  if (!itemID) return <Preloader type={PreloaderType.SKELETON_BUTTON} />;

  const [taxonomyClass] = getItemClassFromItemId(itemID);
  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  if (!taxonomyItemConfig?.meta.creatable) return null;

  return (
    <CatalogLink to={getTaxonomyCreatePath(taxonomyClass, itemID)} className={className}>
      <Button icon={<CopyOutlined />} className="w-full">{translations["item.button.duplicate"]}</Button>
    </CatalogLink>
  );
});
