import { useMediaQuery } from "react-responsive";
import { queries } from "consts";

import {
  useMatchingTargetsDrawerHeights
} from "modules/MatchingModule/MatchingListModule/hooks/useMatchingTargetsDrawerHeights";

export const useMatchingFacetsHeight = (isDrawerOpen: boolean) => {
  const minLG = useMediaQuery({ query: queries.lg });
  const { headDrawerHeight, mainDrawerHeight } = useMatchingTargetsDrawerHeights();

  if (minLG) return "100%";

  const heightWithDrawerOpen = window.innerHeight - (headDrawerHeight + mainDrawerHeight);
  const heightWithDrawerClosed = window.innerHeight - headDrawerHeight;

  return isDrawerOpen ? heightWithDrawerOpen : heightWithDrawerClosed;
}
