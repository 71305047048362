import { SynonymType } from "types/entities/Synonym";

import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, theme } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { configurationServiceInstance } from "services/ConfigurationService";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { DeleteSynonymButton } from "modules/SynonymModule/SynonymListModule/components/DeleteSynonymButton";
import { SynonymListItemProps } from "modules/SynonymModule/SynonymListModule/SynonymListModule.types";
import { useDeleteSynonym } from "modules/SynonymModule/SynonymUpdateModule/api/useDeleteSynonym";
import { SynonymUpdateModule } from "modules/SynonymModule/SynonymUpdateModule/SynonymUpdateModule";

const { useToken } = theme;

export const SynonymListItem = React.memo<SynonymListItemProps>((props) => {
  const { taxonomyItemId, synonym, synonymType } = props;
  const { token } = useToken();
  const [taxonomyClass] = getItemClassFromItemId(taxonomyItemId);

  const catalogSynonymEditEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_SYNONYM_EDIT);
  const catalogSynonymDeleteEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_SYNONYM_DELETE);

  const synonymScopeSet = configurationServiceInstance.getSynonymScopeByTaxonomyClass(taxonomyClass);
  const synonymScope = synonymScopeSet?.values.find((scope) => scope.value === synonym.scope);

  const [isEdit, setIsEdit] = React.useState(false);

  const { deleteSynonym } = useDeleteSynonym({ taxonomyItemId, synonymItemId: synonym.id });

  const handleEdit = React.useCallback(() => setIsEdit(true), [setIsEdit]);
  const handleDelete = React.useCallback(() => deleteSynonym(), [deleteSynonym]);
  const handleClose = React.useCallback(() => setIsEdit(false), [setIsEdit]);

  const fontColorClass = synonymType === SynonymType.EXCLUSION ? "text-red-700" : "text-green-700";

  return isEdit ? (
    <SynonymUpdateModule taxonomyItemId={taxonomyItemId} synonym={synonym} onClose={handleClose} />
  ) : (
    <div className="flex justify-between items-center">
      <div className="flex flex-1 gap-2 items-center">
        <span className={`grow-[9] ${fontColorClass}`}>{synonym.value}</span> 
        <span className="grow-[3] text-right whitespace-nowrap">
          {synonymScope && !synonymScope.default ? `${synonymScope.title}, ` : ""}{synonym.language}
        </span>
      </div>
      <div>
        {catalogSynonymEditEnabled && (
          <Button type="text" onClick={handleEdit}>
            <EditOutlined width={16} height={16} style={{ color: token.colorPrimary }} />
          </Button>
        )}

        {catalogSynonymDeleteEnabled && <DeleteSynonymButton handleDelete={handleDelete} />}
      </div>
    </div>
  );
});
