import { ResponseError } from "types/apiTypes";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { formatBEErrorToString } from "helpers/formatErrorBEToFE";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { getSynonymUrl } from "api/rootUrl";

import { UseCreateSynonymProps } from "./useCreateSynonym.types";

const postCreateSynonym =
  ({ synonymType, taxonomyItemId }: UseCreateSynonymProps) =>
    ({ value, language, scope }: { value: string; language: string, scope?: string }) => {
      return axios
        .post<string>(getSynonymUrl(taxonomyItemId), { type: synonymType, value, language, ...(scope && { scope }) })
        .then(({ data }) => data);
    };

export const useCreateSynonym = (props: UseCreateSynonymProps, setValue: (newSynonymValue: string) => void) => {
  const { taxonomyItemId } = props;
  const queryClient = useQueryClient();

  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: postCreateSynonym(props),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_LIST_SYNONYM_ITEMS, taxonomyItemId],
      });
      showSuccess(translations["synonym.messages.create.success"]);
      setValue("");
    },
  });

  const errorMsgOrKey = error ? formatBEErrorToString(error as AxiosError<ResponseError>) : "";
  const errorMessage = translations[errorMsgOrKey] || errorMsgOrKey;

  return { createSynonym: mutateAsync, isLoading, isError, errorMessage };
};
