import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Typography } from "antd";
import { EMPTY } from "consts/strings";
import { getTaxonomyReadPath } from "router/path";

export const RelationsInlineList = React.memo<{
  taxonomyItem?: TaxonomyItem | TaxonomyItem[];
  LinkComponent?: React.FC<{ href: string; name: string }>;
  separator?: string
}>(
  ({
    taxonomyItem,
    LinkComponent,
    separator = ", "
  }) => {
    if (!taxonomyItem) return <>{EMPTY}</>;
    if (Array.isArray(taxonomyItem) && taxonomyItem.length === 0) return <>{EMPTY}</>;

    const relationItems = Array.isArray(taxonomyItem) ? taxonomyItem : [taxonomyItem];

    return (
      <>
        {relationItems
          .sort((a, b) => a.name && b.name
            ? a.name.localeCompare(b.name)
            : a.id.localeCompare(b.id)
          )
          .map(({ name, id }, index) => (
            <React.Fragment key={id}>
              <Typography.Text>{index === 0 ? "" : separator}</Typography.Text>
              {LinkComponent ? <LinkComponent href={getTaxonomyReadPath(id)} name={name || id} /> : name}
            </React.Fragment>
          ))}
      </>
    );
  }
);
