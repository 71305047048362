import React from "react";

import { ListRowControlsProps } from "modules/CatalogModule/CatalogModule.types";
import { MatchingMatchButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingMatchButton";

export const MatchingCatalogListRowControls = React.memo<ListRowControlsProps>((props) => {
  const { itemID, taxonomyItem } = props;

  return <MatchingMatchButton itemID={itemID} taxonomyItem={taxonomyItem} iconOnly={true} />;
});
