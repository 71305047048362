import { PaginatedResponse } from "types/apiTypes";
import { MatchGroup } from "types/entities/Match";

import React from "react";
import { useParams } from "react-router-dom";
import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { combinePages, getNextPageParam } from "helpers/paginationHelpers";
import { MatchingParamsType } from "router/navigationTypes";

import { QueryKeys } from "api/queryKeys";
import { getMatchingFilterPart, getUrlToMatchingList } from "api/rootUrl";

export const getMatchingList = ({ jobId, datasetName, filterParams}: {
  jobId?: string;
  datasetName?: string;
  filterParams?: string;
}) => {
  if (!jobId || !datasetName) return () => Promise.resolve({} as PaginatedResponse<MatchGroup>);

  return ({ pageParam, limit }: Pick<QueryFunctionContext, "pageParam"> & { limit?: number }) =>
    axios
      .get<PaginatedResponse<MatchGroup>>(
        `${getUrlToMatchingList(jobId, datasetName)}${getMatchingFilterPart(filterParams, pageParam, limit)}`
      )
      .then((response) => response.data);
};

export const useGetMatchingList = (filterParams?: string, isReady = true) => {
  const { jobId, datasetName } = useParams<MatchingParamsType>();

  const enabled = !!jobId && !!datasetName && isReady;

  const { data, isLoading, isFetching, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [QueryKeys.GET_LIST_MATCH_GROUPS, jobId, datasetName, filterParams],
    queryFn: getMatchingList({ jobId, datasetName, filterParams }),
    enabled,
    getNextPageParam: getNextPageParam,
  });

  const matchGroupsList: MatchGroup[] = React.useMemo(() => combinePages(data?.pages) || [], [data]);

  return {
    hasNextPage,
    matchGroupsList,
    isLoading: (isLoading || isFetching) && enabled,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  };
};
