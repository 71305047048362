import { MatchGroup } from "types/entities/Match";

import React from "react";
import { Collapse, Typography } from "antd";
import { EMPTY } from "consts/strings";
import { getCountryConfig } from "helpers/countryHelpers";
import { formatDate } from "helpers/formatDate";
import { getFormattedPrice } from "helpers/priceHelpers";
import { useTranslations } from "translations/useTranslations";

import {
  MatchingTableAdditionalDetails
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTableAdditionalDetails";
import {
  MatchingTablePrematchDetails
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTablePrematchDetails";
import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";

export const MatchingTableRowExtensionTransactions = React.memo<{ matchGroup: MatchGroup }>(({ matchGroup }) => {
  const translations = useTranslations();
  const { countryValue } = useInformationSourceInSearchParams();
  const { currencyName } = getCountryConfig(countryValue);

  const sections = React.useMemo(() => {
    const { price_min, price_avg, price_max, transaction_date_min, transaction_date_max } = matchGroup.metadata_.metadata || {};
    return [
      {
        title: translations["matching.table.price_trends.label"],
        value: (
          <div>
            <div>{translations["matching.table.price_trends.min.label"]}: {price_min ? getFormattedPrice(currencyName, price_min) : EMPTY}</div>
            <div>{translations["matching.table.price_trends.avg.label"]}: {price_avg ? getFormattedPrice(currencyName, price_avg) : EMPTY}</div>
            <div>{translations["matching.table.price_trends.max.label"]}: {price_max ? getFormattedPrice(currencyName, price_max) : EMPTY}</div>
          </div>
        ),
        width: 120
      },
      {
        title: translations["matching.table.transaction_dates.label"],
        value: `${formatDate(transaction_date_min)} - ${formatDate(transaction_date_max)}` || EMPTY,
        width: 120
      },
      {
        title: translations["matching.table.clinic_classification.label"],
        value: EMPTY,
        flexGrow: "1"
      }
    ]
  }, [currencyName, matchGroup.metadata_, translations]);

  return (
    <div>
      <div className="flex flex-wrap gap-2 px-2 py-1">
        {sections.map(({ title, value, width, flexGrow }, index) => (
          <div key={index} style={{ width, flexGrow }}>
            <Typography.Text type="secondary" title={title} className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
              <span className="uppercase">{title}</span>
            </Typography.Text>
            <div>{value}</div>
          </div>
        ))}
      </div>
      <Collapse bordered={false} style={{background: "transparent"}}>
        <Collapse.Panel key="1" header={translations["matching.table.prematch.label"]}>
          <MatchingTablePrematchDetails matchGroup={matchGroup} />
        </Collapse.Panel>
        <Collapse.Panel key="2" header={translations["matching.table.details.label"]}>
          <MatchingTableAdditionalDetails matchGroup={matchGroup}/>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
