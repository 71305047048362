import { Skeleton, Spin } from "antd";

import { PreloaderProps, PreloaderType } from "./Preloader.types";

export const Preloader: React.FC<PreloaderProps> = (props) => {
  const { type = PreloaderType.DEFAULT, ...rest } = props;

  switch (type) {
  case PreloaderType.SKELETON_BUTTON:
    return <Skeleton.Button active={true} {...rest} />;
  case PreloaderType.SKELETON:
    return <Skeleton active={true} {...rest} />;
  case PreloaderType.SPINNER_BLOCK:
    return (
      <div className="flex justify-center py-2"><Spin size="large" {...rest} /></div>
    );
  case PreloaderType.SPINNER:
    return <Spin {...rest} />;
  case PreloaderType.DEFAULT:
  default:
    return <div>Loading...</div>;
  }
}
