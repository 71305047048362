import { ResponseError, ResponseStatus } from "types/apiTypes";
import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";
import { FormTaxonomyData } from "types/formDataTypes";

import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { formatBEErrorToErrorSchema } from "helpers/formatErrorBEToFE";
import { relationsToBEFormat } from "helpers/relationsToBEFormat";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { taxonomyUrl } from "api/rootUrl";

import { useCreateRelationForTaxonomyItem } from "modules/TaxonomyModule/api/useCreateRelationForTaxonomyItem";
import {
  relationFieldName,
} from "modules/TaxonomyModule/CommonTaxonomyFormModule/const";
import { useShowTaxonomyError } from "modules/TaxonomyModule/CommonTaxonomyFormModule/hooks/useShowTaxonomyError";

const postCreateTaxonomyItem =
  (taxonomyClass: string) =>
    (data: Omit<TaxonomyItem, "relations">): Promise<ItemID> => {
      return axios
        .post(`${taxonomyUrl}${taxonomyClass.toLowerCase()}/`, data)
        .then((data) => data.data);
    };

export const useCreateTaxonomyItem = (taxonomyClass: string) => {
  const { showSuccess } = useMessage();
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const showError = useShowTaxonomyError();

  const { mutateAsync, error } = useMutation({
    mutationFn: postCreateTaxonomyItem(taxonomyClass),
    onSuccess: async () => {
      queryClient.clear();
      showSuccess(translations["item.messages.create.success"]);
    },
    onError: (error: AxiosError<ResponseError>, ...args) => {
      if (error.response?.status === ResponseStatus.BAD_REQUEST) {
        showError();
        return;
      }
      queryClient.defaultMutationOptions?.().onError?.(error, ...args);
    }
  });

  const createRelationForTaxonomyItem = useCreateRelationForTaxonomyItem();

  const createTaxonomyItem = useCallback(
    async (formData: FormTaxonomyData) => {
      const { [relationFieldName]: relationsFormData, ...mainFormData } =
        formData;

      const result = await mutateAsync(mainFormData);

      if (relationsFormData && Object.keys(relationsFormData).length) {
        await createRelationForTaxonomyItem(result, relationsToBEFormat(relationsFormData));
      }

      return result;
    },
    [createRelationForTaxonomyItem, mutateAsync]
  );

  const errorSchema = formatBEErrorToErrorSchema(error as AxiosError<ResponseError>);

  return { createTaxonomyItem, errorSchema };
};
