export const GenericTaxonomyClass = "Class";

export enum TaxonomyClass {
  ACTIVE_INGREDIENT = "ActiveIngredient",
  BIOLOGICAL = "Biological",
  BREED_SIZE = "BreedSize",
  CLINIC = "Clinic",
  CORPORATION = "Corporation",
  DIAGNOSTIC_COMPONENT = "DiagnosticComponent",
  DIAGNOSTIC_DEVICE = "DiagnosticDevice",
  DIAGNOSTIC_PANEL = "DiagnosticPanel",
  DIAGNOSTIC_TEST = "DiagnosticTest",
  DIAGNOSTIC_TEST_LOCATION = "DiagnosticTestLocation",
  DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS = "DiagnosticTestMatchingRequirements",
  DRUG_CLASS = "DrugClass",
  FLAVOR = "Flavor",
  FOOD_BRAND = "FoodBrand",
  FOOD_CLASS = "FoodClass",
  FOOD_FORM = "FoodForm",
  FOOD_PRODUCT = "FoodProduct",
  FOOD_PRODUCT_LINE = "FoodProductLine",
  MANUFACTURER = "Manufacturer",
  MARKET = "Market",
  MEDICAL_BRAND = "MedicalBrand",
  MEDICAL_PRODUCT = "MedicalProduct",
  OTC_ACTIVE_INGREDIENT = "OtcActiveIngredient",
  OTHER = "Other",
  PACK_COLOR = "PackColor",
  PACK_QUANTITY_MEASURE = "PackQuantityMeasure",
  PACK_SIZE_MEASURE = "PackSizeMeasure",
  PACK_UNIT_VOLUME_MEASURE = "PackUnitVolumeMeasure",
  PANEL_TYPE = "PanelType",
  PATIENT_WEIGHT_UNIT = "PatientWeightUnit",
  PHYSICAL_FORM = "PhysicalForm",
  PRESCRIPTION_OTC = "PrescriptionOTC",
  PRINCIPAL_GENERIC = "PrincipalGeneric",
  PROVIDER = "Provider",
  RETAIL_BRAND = "RetailBrand",
  RETAIL_CLASS = "RetailClass",
  RETAIL_PRODUCT = "RetailProduct",
  ROUTE_OF_ADMINISTRATION = "RouteOfAdministration",
  SERVICE_CLASS = "ServiceClass",
  SPECIES = "Species",
  STRENGTH_UNIT = "StrengthUnit",
  TRANSACTION_TYPE = "TransactionType",
  TREATMENT_INDICATION = "TreatmentIndication",
}
