import { Dataset } from "types/entities/Jobs";

import React from "react";
import { Link } from "react-router-dom";
import { Button, Space, Typography } from "antd";
import { getMatchingListPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { MatchingProgress } from "modules/MatchingModule/components/MatchingProgress";
import { checkDataset } from "modules/MatchingModule/helpers/useCheckDataset";

import { JobLinkProps } from "./JobLink.types";

export const JobLink = React.memo<JobLinkProps>((props) => {
  const { jobId, datasetName, isJobsManager } = props;
  const { isTransactions } = checkDataset(datasetName);
  const translations = useTranslations();

  const { title, buttonText } = React.useMemo(() => {
    const textMap = {
      [Dataset.TRANSACTIONS]: {
        title: translations["matching.dataset.transactions.title"],
        buttonText: translations["matching.dataset.transactions.button"]
      },
      [Dataset.SPECIES]: {
        title: translations["matching.dataset.species.title"],
        buttonText: translations["matching.dataset.species.button"]
      }
    }
    return textMap[datasetName];
  }, [datasetName, translations]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <Space direction="vertical">
          <Typography.Text strong>{title}</Typography.Text>
          <MatchingProgress jobId={jobId} datasetName={datasetName} />
        </Space>
        {!isJobsManager && (
          <Link to={getMatchingListPath(jobId, datasetName)}>
            <Button type={isTransactions ? "primary" : "default"} className="w-full">{buttonText}</Button>
          </Link>
        )}
      </div>
    </div>
  );
});
