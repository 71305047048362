import React from "react";

export function useActionOnAppearanceInViewport(
  ref: { current: HTMLDivElement | null },
  actionOnAppearance: () => void
) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (!isIntersecting && entry.isIntersecting) actionOnAppearance();
        setIsIntersecting(entry.isIntersecting);
      }),
    [actionOnAppearance, isIntersecting]
  );

  React.useEffect(() => {
    if (ref.current) {
      // timeout is here for the rare case when on specific resolution/zoom level a fluke happens and intersection
      // does not fire (about 5% of the time). timeout seems to handle it along with some div size tweaks
      const container = ref.current;
      setTimeout(() => observer.observe(container), 100);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
}
