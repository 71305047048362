import React from "react";
import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";

type TaxonomyUrlQueryServiceInMatchingContextType = {
  taxonomyUrlQueryService?: UrlQueryService;
};

const TaxonomyUrlQueryServiceInMatchingContext = React.createContext<TaxonomyUrlQueryServiceInMatchingContextType>({})

export const TaxonomyUrlQueryServiceInMatchingProvider = React.memo<{ children: JSX.Element }>(({ children }) => {
  const taxonomyUrlQueryService = useTaxonomyUrlQueryService();

  return (
    <TaxonomyUrlQueryServiceInMatchingContext.Provider value={{ taxonomyUrlQueryService }}>
      {children}
    </TaxonomyUrlQueryServiceInMatchingContext.Provider>
  );
});

export const useTaxonomyUrlQueryServiceInMatchingContext = () => React.useContext(TaxonomyUrlQueryServiceInMatchingContext);
