import { CodeType } from "types/entities/Code";

import { MarketCode } from "consts/markets";

import CanFlagImage from "assets/images/can-flag.svg";
import DeuFlagImage from "assets/images/deu-flag.svg";
import FraFlagImage from "assets/images/fra-flag.svg";
import GbrFlagImage from "assets/images/gbr-flag.svg";
import GerFlagImage from "assets/images/ger-flag.svg";
import UsaFlagImage from "assets/images/usa-flag.svg";

type CountryConfigType = {
  countryName: string;
  countryNameISO: string;
  currencyName: string;
  codeTypes: CodeType[];
  image: string;
  value: string;
  selectable: boolean;
};

export const countryCurrencyMap: CountryConfigType[] = [
  {
    value: MarketCode.USA,
    countryName: "USA",
    countryNameISO: MarketCode.USA,
    image: UsaFlagImage,
    currencyName: "USD",
    codeTypes: [CodeType.SKU],
    selectable: true,
  },
  {
    value: MarketCode.CAN,
    countryName: "Canada",
    countryNameISO: MarketCode.CAN,
    image: CanFlagImage,
    currencyName: "CAD",
    codeTypes: [],
    selectable: true,
  },
  {
    value: MarketCode.FRA,
    countryName: "France",
    countryNameISO: MarketCode.FRA,
    image: FraFlagImage,
    currencyName: "EUR",
    codeTypes: [],
    selectable: false,
  },
  {
    value: MarketCode.GER,
    countryName: "Germany",
    countryNameISO: MarketCode.GER,
    image: GerFlagImage,
    currencyName: "EUR",
    codeTypes: [],
    selectable: false,
  },
  {
    value: MarketCode.DEU,
    countryName: "Germany",
    countryNameISO: MarketCode.DEU,
    image: DeuFlagImage,
    currencyName: "EUR",
    codeTypes: [],
    selectable: false,
  },
  {
    value: MarketCode.GBR,
    countryName: "United Kingdom",
    countryNameISO: MarketCode.GBR,
    image: GbrFlagImage,
    currencyName: "GBP",
    codeTypes: [],
    selectable: false,
  }
];

export const getCountryConfig = (country: string): CountryConfigType => {
  return (
    countryCurrencyMap.find((countryConf) => countryConf.value.toLowerCase() === country.toLowerCase()) || {
      countryName: "",
      currencyName: "",
      countryNameISO: "",
      codeTypes: [],
      image: "",
      value: "",
      selectable: false,
    }
  );
};
