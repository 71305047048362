import React from "react";
import { getCountryConfig } from "helpers/countryHelpers";
import { useTranslations } from "translations/useTranslations";

import {
  useInformationSourceInSearchParams
} from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";
import {
  useMatchingInformationSourceContext
} from "modules/MatchingModule/MatchingInformationSourceModule/MatchingInformationSource.context";

export const MatchingInformationSourceLabel = React.memo(() => {
  const translations = useTranslations();
  const { countryValue } = useInformationSourceInSearchParams();
  const { selectedProviders } = useMatchingInformationSourceContext();

  const selectedValues = React.useMemo(() => [
    getCountryConfig(countryValue).countryNameISO,
    ...selectedProviders,
  ].filter(Boolean), [countryValue, selectedProviders]);

  return (
    <div>
      {translations["matching.information_source.title"]}:{" "}
      {selectedValues.join(", ")}
    </div>
  );
});
