import { TaxonomyItem } from "types/entities/Taxonomy";

export const getFiltersFromTaxonomyItemList = (taxonomyItemList: TaxonomyItem[]): { [key: string]: string[] } => {
  return taxonomyItemList.reduce<{ [relationKey: string]: string[]}>((acc, item) => {
    if (!item.relations) return acc;

    Object.entries(item.relations).forEach(([relationKey, relationValue]) => {
      const uniqueRelationIds = relationValue
        .map((relation) => relation.id)
        .filter(id => !acc[relationKey]?.includes(id));

      if (acc[relationKey]) {
        acc[relationKey].push(...uniqueRelationIds);
      } else {
        acc[relationKey] = uniqueRelationIds;
      }
    });

    return acc;
  }, {});
}
