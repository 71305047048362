import React from "react";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { queries } from "consts";

import { useIsFindInCatalogOpened } from "modules/MatchingModule/hooks/useIsFindInCatalogOpened";
import { MatchingGroupsModule } from "modules/MatchingModule/MatchingGroupsModule";
import { useMatchingTargetsDrawerContext
} from "modules/MatchingModule/MatchingListModule/components/MatchingTargetsDrawer/MatchingTargetsDrawer.context";
import { columnStickyPosition } from "modules/MatchingModule/MatchingListModule/consts/matchingColumnSizes";
import {
  useMatchingListModuleBottomPadding
} from "modules/MatchingModule/MatchingListModule/hooks/useMatchingListModuleBottomPadding";
import { MatchingTargetsModule } from "modules/MatchingModule/MatchingTargetsModule";

import { MatchingListColumnsProps } from "./MatchingListColumns.types";

export const MatchingListColumns = React.memo<MatchingListColumnsProps>((props) => {
  const minLG = useMediaQuery({ query: queries.lg });
  const isCatalogOpened = useIsFindInCatalogOpened();
  const { isDrawerOpen } = useMatchingTargetsDrawerContext();
  const paddingBottom = useMatchingListModuleBottomPadding(isDrawerOpen);

  return (
    <div className="relative flex justify-between items-start gap-x-4 grow">
      <div className="sticky w-1/2 grow -mx-6 md:mx-0" style={{
        paddingBottom,
        ...(minLG && { top: columnStickyPosition })
      }}>
        <MatchingGroupsModule />
      </div>
      {minLG && (
        <div
          className={clsx("sticky transition-all overflow-hidden", isCatalogOpened ? "w-1/2" : "w-[38%]")}
          style={{
            ...(minLG && { top: columnStickyPosition })
          }}
        >
          <MatchingTargetsModule />
        </div>
      )}
    </div>
  );
});
