import React from "react";
import { getCountryConfig } from "helpers/countryHelpers";
import { configurationServiceInstance } from "services/ConfigurationService";

export const TaxonomyItemCodeType = React.memo<{ type: string }>(({ type }) => {
  const config = configurationServiceInstance.getCodesConfig()[type];

  if (!config) return <>{type}</>;

  const title = config.title || type;
  const countries = config.countries || [];
  const icons = countries.map((country) => getCountryConfig(country).image).filter(Boolean);

  return (
    <div className="flex items-center gap-2">
      <span className="flex items-center">
        {icons.map((icon, i) => <img src={icon} className="-mr-1" key={i} alt="country-flag" />)}
      </span>
      <span>{countries.join(", ")} ({title})</span>
    </div>
  );
});
