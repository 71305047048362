import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import {
  getTaxonomyRelationTitleFromChain
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitleFromChain";
import { JSONSchema7 } from "json-schema";
import { configurationServiceInstance } from "services/ConfigurationService";

import { medicalProductFacetsKeys } from "modules/TaxonomyModule/consts/facetsKeys";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { TaxonomyItemCommonProperties } from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";

const prepareKeyName = (taxonomyClass: string, key: string) => {
  if (key.includes("/")) {
    return key.split("/").reduce((prevTaxonomyClass, nextKey) => {
      return configurationServiceInstance.getTaxonomyItemRelationName(prevTaxonomyClass, nextKey) || taxonomyClass;
    }, taxonomyClass);
  }
  return configurationServiceInstance.getTaxonomyItemRelationName(taxonomyClass, key) || taxonomyClass;
};

export const getColumnTitle = (
  taxonomyClass: string,
  keyName: string,
  titleKey: string | undefined = "",
  translations: Record<string, string>
) => {
  const keyForFindTitle = prepareKeyName(taxonomyClass, keyName);

  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(keyForFindTitle || taxonomyClass);

  if (keyName === TaxonomyItemCommonProperties.NAME) return titleKey || getTaxonomyClassTitle(taxonomyClass);
  if (keyName === medicalProductFacetsKeys[TaxonomyClass.ACTIVE_INGREDIENT]) return taxonomyItemConfig?.title || keyForFindTitle;

  return (
    translations[titleKey] ||
    (taxonomyItemConfig?.properties?.[keyForFindTitle || keyName] as JSONSchema7)?.title ||
    getTaxonomyRelationTitleFromChain(taxonomyClass, keyName) ||
    taxonomyItemConfig?.title ||
    keyForFindTitle
  );
};
