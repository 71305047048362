import { MatchStatus } from "types/entities/Match";

import React from "react";
import { Badge, theme } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";

import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

export const MatchingTargetsClassTabItem = React.memo<{ taxonomyClass: TaxonomyClass; showMatchToClass?: boolean }>(({ taxonomyClass, showMatchToClass }) => {
  const {
    targetItems: { suggestions, conflicts },
    matchGroupsList,
    selectedGroupIds,
    commonStatus
  } = useMatchingContext();
  const { token } = theme.useToken();

  const targetItemsQt = React.useMemo(() => {
    const conflictsIds = conflicts.map((item) => item.taxonomy_item_id);
    const suggestionsIds = suggestions[taxonomyClass.toLowerCase()]?.map((item) => item.taxonomy_item_id) || [];

    return commonStatus === MatchStatus.CONFLICT
      ? suggestionsIds.filter((id) => !conflictsIds.includes(id)).length
      : suggestionsIds.length;
  }, [commonStatus, conflicts, suggestions, taxonomyClass]);

  const showBadge = React.useMemo(() => {
    if (targetItemsQt) return true;
    if (!showMatchToClass) return false;

    const selectedGroups = getSelectedGroups(matchGroupsList, selectedGroupIds);
    return selectedGroups.some((group) => group.metadata_.prematch?.[GenericTaxonomyClass].includes(taxonomyClass));
  }, [matchGroupsList, selectedGroupIds, showMatchToClass, targetItemsQt, taxonomyClass])

  return (
    <div className="flex items-center gap-2">
      {configurationServiceInstance.getTaxonomyItemByName(taxonomyClass)?.title || taxonomyClass}
      {showBadge && <Badge count={targetItemsQt} color={token.colorPrimary}/>}
    </div>
  );
});
