import { MatchGroup } from "types/entities/Match";

export const getCommonStatus = (selectedGroups: MatchGroup[]) => {
  if (!selectedGroups.length) return null;
  if (
    selectedGroups.every(
      (group) => group.metadata_.match.status === selectedGroups[0].metadata_.match.status
    )
  ) {
    return selectedGroups[0].metadata_.match.status;
  }
  return null;
}
