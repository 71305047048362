import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import {
  jobsFeatureFlagsConfigByRolesUrl,
  matchingFeatureFlagsConfigByRolesUrl,
  taxonomyFeatureFlagsConfigByRolesUrl
} from "api/rootUrl";

import { FeatureFlagsConfigType } from "../featureFlags/featureFlags.types";
import { RolesNames } from "../featureFlags/roles.types";

const getFeatureFlagsConfigByRoles = (url: string) => (): Promise<Record<RolesNames, FeatureFlagsConfigType>> =>
  axios.get(url).then((response) => response.data);

export const useGetFeatureFlagsConfigByRoles = (role?: RolesNames) => {
  const { data: taxonomyData } = useQuery({
    queryKey: [QueryKeys.GET_FEATURE_FLAGS_CONFIG_BY_ROLE, QueryKeys.GET_TAXONOMY_FEATURE_FLAGS_CONFIG_BY_ROLE],
    queryFn: getFeatureFlagsConfigByRoles(taxonomyFeatureFlagsConfigByRolesUrl),
    enabled: Boolean(role),
  });

  const { data: matchingData } = useQuery({
    queryKey: [QueryKeys.GET_FEATURE_FLAGS_CONFIG_BY_ROLE, QueryKeys.GET_MATCHING_FEATURE_FLAGS_CONFIG_BY_ROLE],
    queryFn: getFeatureFlagsConfigByRoles(matchingFeatureFlagsConfigByRolesUrl),
    enabled: Boolean(role),
  });

  const { data: jobsData } = useQuery({
    queryKey: [QueryKeys.GET_FEATURE_FLAGS_CONFIG_BY_ROLE, QueryKeys.GET_JOBS_FEATURE_FLAGS_CONFIG_BY_ROLE],
    queryFn: getFeatureFlagsConfigByRoles(jobsFeatureFlagsConfigByRolesUrl),
    enabled: Boolean(role),
  });

  return React.useMemo<Record<RolesNames, FeatureFlagsConfigType> | undefined>(() => {
    if (!taxonomyData || !matchingData || !jobsData) return undefined;
    return {
      [RolesNames.DataEnrichmentSpecialist]: {
        ...taxonomyData?.[RolesNames.DataEnrichmentSpecialist],
        ...matchingData?.[RolesNames.DataEnrichmentSpecialist],
        ...jobsData?.[RolesNames.DataEnrichmentSpecialist],
      },
      [RolesNames.GlobalDataManager]: {
        ...taxonomyData?.[RolesNames.GlobalDataManager],
        ...matchingData?.[RolesNames.GlobalDataManager],
        ...jobsData?.[RolesNames.GlobalDataManager],
      }
    };
  }, [taxonomyData, matchingData, jobsData]);
};
