import React from "react";
import { Alert } from "antd";

import { useAlertsPropsMap } from "modules/MatchingModule/hooks/useAlertsPropsMap";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

import { MatchingTargetsDrawerTitleProps } from "./MatchingTargetsDrawerTitle.types";
import { MatchingTargetsDrawerToggleButton } from "./MatchingTargetsDrawerToggleButton";

export const MatchingTargetsDrawerSpecialTitle = React.memo<MatchingTargetsDrawerTitleProps>((props) => {
  const { isOpen, onToggle, height } = props;
  const { commonStatus } = useMatchingContext();
  const alertsPropsMap = useAlertsPropsMap();

  if (!commonStatus || !(commonStatus in alertsPropsMap)) return null;

  const { message, type, controls } = alertsPropsMap[commonStatus as keyof typeof alertsPropsMap];

  return (
    <Alert
      message={(
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <MatchingTargetsDrawerToggleButton isOpen={isOpen} onToggle={onToggle} />
            {message}
          </div>
          <div>
            {controls}
          </div>
        </div>
      )}
      type={type}
      style={{ height, paddingLeft: "24px", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
    />
  );
});
