import { FacetSectionConfigurationItem } from "modules/FacetsModule/FacetsModule.types";
import { facetConfigurationMap } from "modules/TaxonomyModule/consts/facetConfigurationMap";
import { generateFacetConfig } from "modules/TaxonomyModule/helpers/generateFacetConfig";

export const getFacetConfiguration: (taxonomyClass: string) => FacetSectionConfigurationItem[] = (taxonomyClass) => {
  if (!taxonomyClass) return [];

  for (const key in facetConfigurationMap) {
    if (key.toLowerCase() === taxonomyClass.toLowerCase()) return facetConfigurationMap[key];
  }

  return generateFacetConfig(taxonomyClass) || [];
}
