import { useCallback } from "react";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

export const useShowTaxonomyError = () => {
  const translations = useTranslations();
  const { showError } = useMessage();

  return useCallback(() => {
    showError(translations["item.form.errors.message.content"]);
  }, [showError, translations]);
};
