import React from "react";
import { ApiOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";

export const MatchingCreateMatchButton = React.memo(() => {
  const translations = useTranslations();

  const catalogItemCreateEnabledForRole = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_CREATE);

  if (!catalogItemCreateEnabledForRole) return null;

  return (
    <Button type="primary" htmlType="submit" icon={<ApiOutlined />} className="w-full">
      {translations["matching.target.buttons.create_match"]}
    </Button>
  );
});
