import React from "react";
import { Typography } from "antd";

export const MatchingListHeader = React.memo(({ title }: { title: JSX.Element | string }) => {
  return (
    <Typography.Title className="flex gap-3 items-center">
      {title}
    </Typography.Title>
  );
});
