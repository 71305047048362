import {
  InstructionResultForm,
  InstructionResultPartialType
} from "modules/GenerateByUIConfigurationModule/generateByUIConfiguration.types";

export const checkInstructionIsEmpty = (instruction: InstructionResultPartialType): boolean => {
  if (instruction.form === InstructionResultForm.SEPARATOR) {
    return !instruction.content.value;
  }

  if (instruction.form === InstructionResultForm.VALUE) {
    return !instruction.content.value;
  }

  if (instruction.form === InstructionResultForm.ARRAY) {
    return !instruction.content.length || instruction.content.every(
      item => !item.length || item.every(subItem => !subItem.value)
    );
  }

  if (instruction.form === InstructionResultForm.PAIR) {
    return !instruction.content.length || instruction.content.every(item => !item.value);
  }

  return false;
}
