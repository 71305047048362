import { TaxonomyItem } from "types/entities/Taxonomy";

import { getRelationsMapForMatching } from "helpers/expandHelpers/getRelationsKeysForMatching";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";

export const getRelationKeysMapByTaxonomyClass = (taxonomyItem?: TaxonomyItem): Record<string, string> => {
  if (!taxonomyItem) return {};

  const taxonomyItemClass = getItemClassFromItemId(taxonomyItem.id)[0];
  return getRelationsMapForMatching(taxonomyItemClass);
}
