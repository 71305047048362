import { Code, TrimmedCode } from "types/entities/Code";

import React from "react";

import { TaxonomyItemCodeBox } from "components/codeComponents/TaxonomyItemCodeBox";

export const CodeBoxesList = React.memo<{ codes: (Code | TrimmedCode)[] }>(({ codes }) => {
  const sortedCodes = codes.sort((a, b) => {
    const typeCompare = a.type.localeCompare(b.type);
    return typeCompare === 0 ? a.code.localeCompare(b.code) : typeCompare;
  });

  if (sortedCodes.length === 0) return null;

  return (
    <div className="flex gap-2 flex-wrap">
      {sortedCodes.map(({ type, code }) => (
        <div key={`${type}-${code}`} className="max-w-full">
          <TaxonomyItemCodeBox type={type} code={code} />
        </div>
      ))}
    </div>
  );
});
