import React from "react";
import { Collapse } from "antd";

import { useFacetsModuleContext } from "modules/FacetsModule/FacetsModule.context";
import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import { findAllValuesFromTree } from "modules/FacetsModule/helpers/buildTree/findAllValuesFromTree";
import { combineTree } from "modules/FacetsModule/helpers/combineTree";
import { useFacetTitle } from "modules/FacetsModule/hooks/useFacetTitle";
import { useFillItemsWithCache } from "modules/FacetsModule/hooks/useFillItemsWithCache";
import { useGetFacetsValues } from "modules/FacetsModule/hooks/useGetFacetsValues";
import { useInitialTreeWithEndpointData } from "modules/FacetsModule/hooks/useInitialTreeWithEndpointData";

import { TreeCheckboxFacet } from "../TreeCheckboxFacet";

import { FacetComponentWrapperProps } from "./FacetComponentWrapperProps.types";

export const FacetsSectionWrapperTree = React.memo<FacetComponentWrapperProps>(
  ({ facetSectionElement, isFacetOpen, ...panelProps }) => {
    const { facetsService, facetSectionChange } = useFacetsModuleContext();

    const title = useFacetTitle(facetSectionElement);

    const { key } = facetSectionElement;
    const taxonomyClass = "taxonomyClass" in facetSectionElement ? facetSectionElement.taxonomyClass : "";

    const { endpointData, initialTreeMap } = useInitialTreeWithEndpointData(taxonomyClass);
    const { data, isFacetsValuesLoading } = useGetFacetsValues(key, facetsService, isFacetOpen);

    const checkedKeys = facetsService.getValuesByKey(key);

    const newTreeValues = React.useMemo(() => {
      const treeValues = data?.value?.length ? (data as FacetsValueFlatTreeType)?.value : [];
      if (checkedKeys?.length) {
        for (const key of checkedKeys) {
          if (!treeValues) break;
          if (treeValues.find((value) => value.id === key)) continue;
          treeValues.push({ id: key, count: 0 });
        }
      }
      return treeValues;
    }, [checkedKeys, data]);
    const { filledValues: filledNewTreeValues, isLoading } = useFillItemsWithCache(
      newTreeValues,
      newTreeValues.length !== 0 && !newTreeValues[0]?.name,
      facetSectionElement
    );

    const treeStore = React.useRef<FacetsValueFlatTreeType["value"]>([]);

    treeStore.current = React.useMemo(() => {
      const { combinedElement: newTreeValues, uniqueElementsFromSecondArray: uniqueCheckedElementsFromPreviousTree } =
        combineTree(filledNewTreeValues, treeStore.current, facetsService, key);

      const listIdsForSaving = findAllValuesFromTree(initialTreeMap, newTreeValues);
      const uniqueCheckedIdsFromPreviousTree = uniqueCheckedElementsFromPreviousTree.map(({ id }) => id);

      return (
        (endpointData || [])
          .filter((el) => listIdsForSaving.includes(el.id))
          .map((el) => {
            const prev = newTreeValues.find((newEl) => newEl.id === el.id);
            return { ...el, count: uniqueCheckedIdsFromPreviousTree.includes(el.id) ? 0 : prev?.count || 0 };
          }) || []
      );
    }, [filledNewTreeValues, facetsService, key, initialTreeMap, endpointData]);

    const facetSectionChangeWrapper = React.useCallback(
      (values: string[]) => {
        facetSectionChange(key, values);
      },
      [facetSectionChange, key]
    );

    return (
      <Collapse.Panel {...panelProps} key={key} header={title}>
        <TreeCheckboxFacet
          treeData={treeStore.current}
          checkedKeys={checkedKeys}
          facetSectionChange={facetSectionChangeWrapper}
          isLoading={isFacetsValuesLoading || isLoading}
        />
      </Collapse.Panel>
    );
  }
);
