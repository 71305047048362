import { ResponseError, ResponseStatus } from "types/apiTypes";
import { ItemID } from "types/common";
import { TaxonomyItem } from "types/entities/Taxonomy";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { getUrlToItemByItemID } from "api/rootUrl";

import { useShowTaxonomyError } from "modules/TaxonomyModule/CommonTaxonomyFormModule/hooks/useShowTaxonomyError";

const postUpdateTaxonomyItemByClassAndId = (itemID: ItemID) => (data: Omit<TaxonomyItem, "relations">) => {
  return axios.put<TaxonomyItem>(getUrlToItemByItemID(itemID), data).then((response) => response.data);
};

export const useUpdateTaxonomyItemByClassAndId = (itemID: ItemID, onSuccessCallback: () => void) => {
  const queryClient = useQueryClient();
  const showError = useShowTaxonomyError();

  const { mutate, error } = useMutation({
    mutationFn: postUpdateTaxonomyItemByClassAndId(itemID),
    onSuccess: () => {
      queryClient.clear();
      onSuccessCallback();
    },
    onError: (error: AxiosError<ResponseError>, ...args) => {
      if (error.response?.status === ResponseStatus.BAD_REQUEST) {
        showError();
        return;
      }
      queryClient.defaultMutationOptions?.().onError?.(error, ...args);
    },
  });

  return { mutate, error };
};
