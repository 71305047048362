import { ResponseError } from "types/apiTypes";
import { ItemID } from "types/common";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { QueryKeys } from "api/queryKeys";
import { taxonomyApiUrl } from "api/rootUrl";

import { UseDeleteSynonymProps } from "./useDeleteSynonym.types";

const deleteSynonym = (synonymItemId: ItemID) => () => axios.delete(`${taxonomyApiUrl}/${synonymItemId}`);

export const useDeleteSynonym = (props: UseDeleteSynonymProps) => {
  const { synonymItemId, taxonomyItemId } = props;
  const queryClient = useQueryClient();

  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutateAsync, isLoading } = useMutation<AxiosResponse, AxiosError<ResponseError>>({
    mutationFn: deleteSynonym(synonymItemId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_LIST_SYNONYM_ITEMS, taxonomyItemId],
      });
      showSuccess(translations["synonym.messages.delete.success"]);
    },
  });

  return { deleteSynonym: mutateAsync, isLoading };
};
