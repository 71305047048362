import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Alert, Card, theme, Typography } from "antd";
import { breakpoints } from "consts";
import { getTaxonomyReadPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { useMatchedTaxonomyItem } from "modules/MatchingModule/api/useMatchedTaxonomyItem";
import { MatchingItemDetailsList } from "modules/MatchingModule/components/MatchingItemDetailsList";
import { MatchingTargetItemTitle } from "modules/MatchingModule/components/MatchingTargetItemTitle";
import { useCheckDataset } from "modules/MatchingModule/helpers/useCheckDataset";
import { MatchingTargetControls } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetControls";
import { TaxonomyItemAbstractTag } from "modules/TaxonomyModule/components/TaxonomyItemAbstractTag";

import { Preloader, PreloaderType } from "ui/Preloader";

import OpenInNewTabOutlined from "assets/images/OpenInNewTabOutlined.svg";

import { MatchingTargetItemProps } from "./MatchingTargetItem.types";

export const MatchingTargetItem = React.memo<MatchingTargetItemProps>(({
  matchTarget,
  isFinal,
  isLinkedToFindInCatalog = true
}) => {
  const translations = useTranslations();
  const betweenMDandXXL = useMediaQuery({ minWidth: breakpoints.lg, maxWidth: breakpoints.xxl });
  const itemID = matchTarget.taxonomy_item_id;

  const { components: { CatalogLink } } = useCatalogContext();

  const { isTransactions } = useCheckDataset();

  const { taxonomyItem, isLoading } = useMatchedTaxonomyItem(itemID);
  const { token } = theme.useToken();

  if (itemID && !taxonomyItem && !isLoading) {
    return <Alert type="error" message={translations["matching.error.no_item"]} description={itemID} />;
  }

  return (
    <Card size="small" style={{ backgroundColor: token.colorBgContainer }} bodyStyle={{ padding: 0 }}>
      <div className="flex gap-4" style={{ backgroundColor: token.colorFillAlter, padding: token.paddingSM }}>
        {isLoading ? (
          <Preloader type={PreloaderType.SKELETON} />
        ) : (
          <>
            <div className="grow flex gap-2 lg:block xxl:flex">
              <div className="grow">
                <div className="flex justify-between items-start gap-2 mb-2">
                  <div className="flex">
                    <Typography.Text strong className="block">
                      {taxonomyItem?.id && isLinkedToFindInCatalog ? (
                        <CatalogLink to={getTaxonomyReadPath(taxonomyItem.id)}>
                          <MatchingTargetItemTitle matchTarget={matchTarget} />
                        </CatalogLink>
                      ) : (
                        <MatchingTargetItemTitle matchTarget={matchTarget} />
                      )}
                    </Typography.Text>
                    {taxonomyItem?.id && (
                      <Link target="_blank" to={getTaxonomyReadPath(taxonomyItem.id)} className="flex ml-3">
                          <img src={OpenInNewTabOutlined} alt="open in new tab"
                            style={{ height: '22px' }} />
                      </Link>
                    )}
                  </div>
                  <TaxonomyItemAbstractTag isAbstract={taxonomyItem?.is_abstract} style={{ marginRight: 0 }} />
                </div>
                {isTransactions && (
                  <MatchingItemDetailsList
                    matchTarget={matchTarget}
                    horizontal={!betweenMDandXXL}
                    className="mb-2 flex gap-2 lg:block xxl:flex"
                  />
                )}
              </div>
              {!isFinal && <MatchingTargetControls itemID={matchTarget.taxonomy_item_id} score={matchTarget.score} />}
            </div>
          </>
        )}
      </div>
    </Card>
  );
});
