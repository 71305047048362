import React from "react";
import { Typography } from "antd";
import { useIsPath } from "router/hooks/useIsPath";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

export const CatalogLinkWithPrevent = React.memo<{
  name: string,
  href: string,
}>(({ name, href }) => {
  const { components: { CatalogLink } } = useCatalogContext();
  const { isInMatching } = useIsPath();

  if (isInMatching || !href) return <Typography.Text>{name}</Typography.Text>;
  return <CatalogLink to={href}>{name}</CatalogLink>;
});
