import { MatchStatus } from "types/entities/Match";

import React from "react";
import { Empty } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { MatchingTargetItem } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetItem";
import {
  MatchingTargetListTooltip
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetListTooltip";

export const MatchingTargetList = React.memo<{ activeTaxonomyClass: string }>(({ activeTaxonomyClass }) => {
  const translations = useTranslations();

  const { targetItems: { suggestions, conflicts }, commonStatus } = useMatchingContext();

  const activeTargetItems = React.useMemo(() => {
    const itemsFilteredByTaxonomyClass = suggestions[activeTaxonomyClass] || [];

    if (commonStatus !== MatchStatus.CONFLICT) return itemsFilteredByTaxonomyClass;

    return itemsFilteredByTaxonomyClass.filter(
      (item) => !conflicts.find(conflict => conflict.taxonomy_item_id === item.taxonomy_item_id)
    );
  }, [suggestions, activeTaxonomyClass, commonStatus, conflicts]);

  const sortedTargetItems = React.useMemo(
    () => activeTargetItems.sort((a, b) => b.score - a.score),
    [activeTargetItems]
  );

  if (!activeTargetItems.length) return <Empty className="pt-4" description={translations["matching.table.empty"]} />;

  return (
    <div className="pt-4">
      {sortedTargetItems.map(item => (
        <div key={item.taxonomy_item_id} className="mb-4">
          <MatchingTargetItem matchTarget={item} />
        </div>
      ))}
      <MatchingTargetListTooltip />
    </div>
  );
});
