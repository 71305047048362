import { Code } from "types/entities/Code";

import type { FormInstance } from "antd";

import {
  TaxonomyItemCodeFormValueKeys
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesModule.types";

export const getValidator = (getFieldValue: FormInstance["getFieldValue"], itemCodes: Code[] = [], codeId?: string) => () => {
  const newCode = getFieldValue(TaxonomyItemCodeFormValueKeys.CODE);
  const newType = getFieldValue(TaxonomyItemCodeFormValueKeys.TYPE);

  if (
    itemCodes?.find((code) =>
      code.code === newCode &&
      code.type === newType &&
      code.id !== codeId
    )
  ) {
    return Promise.reject();
  }

  return Promise.resolve();
}
