import { MatchStatus } from "types/entities/Match";

import React from "react";
import { DislikeOutlined, LikeOutlined, UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const MatchingTargetFinalControls = React.memo(() => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();

  const isApproveEnabled = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_APPROVE);
  const isRejectEnabled = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_REJECT);

  const { commonStatus, patchSelectedGroups, isMatchGroupsUpdating } = useMatchingContext();

  const isNotApproved = commonStatus !== MatchStatus.APPROVED;

  const handleApproveAndDisapprove = React.useCallback(async () => {
    await patchSelectedGroups({ status: isNotApproved ? MatchStatus.APPROVED : MatchStatus.MANUAL });
    showSuccess(isNotApproved ? translations["matching.messages.approve.success"] : translations["matching.messages.disapprove.success"]);
  }, [isNotApproved, patchSelectedGroups, showSuccess, translations]);

  const handleReject = React.useCallback(async () => {
    await patchSelectedGroups({ status: MatchStatus.REMATCH });
    showSuccess(translations["matching.messages.reject.success"]);
  }, [patchSelectedGroups, showSuccess, translations]);

  return (
    <div className="w-full">
      <div className="flex gap-2">
        {isApproveEnabled && (
          <Button
            className="grow basis-0"
            onClick={handleApproveAndDisapprove}
            loading={isMatchGroupsUpdating}
            icon={isNotApproved ? <LikeOutlined /> : <UndoOutlined />}
            type={isNotApproved ? "primary" : "text"}
          >
            {isNotApproved ? translations["matching.buttons.approve"] : translations["matching.buttons.disapprove"]}
          </Button>
        )}
        {isRejectEnabled && (
          <Button
            className="grow basis-0"
            onClick={handleReject}
            loading={isMatchGroupsUpdating}
            icon={<DislikeOutlined />}
          >
            {translations["matching.buttons.reject"]}
          </Button>
        )}
      </div>
    </div>
  );
});
