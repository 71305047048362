// Does not represent all possible properties

export enum TaxonomyItemCommonProperties {
  NAME = "name",
}

export enum TaxonomyItemPatientWeightProperties {
  MAX_WEIGHT_CAT = "maxWeightCat",
  MAX_WEIGHT_DOG = "maxWeightDog",
  MIN_WEIGHT_CAT = "minWeightCat",
  MIN_WEIGHT_DOG = "minWeightDog",
}

export enum FoodProductProperties {
  FOOD_BREED_SIZE = "food_breed_size",
  FOOD_PRODUCT = "foodProduct",
  GROUP = "group",
  LIFESTAGE = "lifestage",
  MARKETING_PRODUCT_LINE = "marketingProductLine",
  PACK_QUANTITY = "packQuantity",
  PACK_UNIT_VOLUME = "packUnitVolume",
  RANGE = "range",
  REPCATEGORY = "repcategory",
  REPORT_INCLUDE = "report_include",
  REP_BRAND = "rep_brand",
  REP_CLASS = "rep_class",
  REP_MANUFACTURER = "rep_manufacturer",
  REP_PRODLINE = "rep_prodline",
  SUB_RANGE = "sub_range",
}

export enum MedicalProductProperties {
  DURATION_OF_EFFECT = "durationOfEffect",
  PACK_SIZE = "packSize",
  REGISTRATION_NUMBER = "registrationNumber",
  REPCATEGORY = "repcategory",
  REPORT_INCLUDE = "report_include",
  REP_BRAND = "rep_brand",
  REP_CLASS = "rep_class",
  REP_MANUFACTURER = "rep_manufacturer",
  REP_ROA = "rep_roa",
  STRENGTH = "strength",
}

export enum RetailProductProperties {
  DURATION_OF_EFFECT = "durationOfEffect",
  PACK_SIZE = "packSize",
  PACK_UNIT_VOLUME = "packUnitVolume",
  REPCATEGORY = "repcategory",
  REP_BRAND = "rep_brand",
  REP_CLASS = "rep_class",
  REP_INCLUDE = "rep_include",
  REP_MANUFACTURER = "rep_manufacturer",
  REP_ROA = "rep_roa",
  STRENGTH = "strength",
}

export enum DiagnosticTestProperties {}
