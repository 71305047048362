import { MatchStatus } from "types/entities/Match";

import React from "react";
import { Alert } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useIsFindInCatalogOpened } from "modules/MatchingModule/hooks/useIsFindInCatalogOpened";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import {
  MatchingCreateRequestEmbedButton
} from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCreateRequestButton";

export const MatchingCatalogNotification = React.memo(() => {
  const translations = useTranslations();

  const { commonStatus } = useMatchingContext();
  const isCatalogOpened = useIsFindInCatalogOpened();
  const isItemCreationRequested = commonStatus === MatchStatus.ITEM_REQUEST;

  if (!isCatalogOpened || isItemCreationRequested) return null;

  return (
    <Alert
      type="info"
      banner
      message={(
        <div>
          {translations["matching.target.catalog.cant_find.tooltip"].split("{{button}}")[0]}
          <MatchingCreateRequestEmbedButton text={translations["matching.target.catalog.cant_find.button"]} />
          {translations["matching.target.catalog.cant_find.tooltip"].split("{{button}}")[1]}
        </div>
      )}
    />
  );
});
