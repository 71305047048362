import { Dataset } from "types/entities/Jobs";

export const SORT_KEY = "match_sort";

const sortOptions = {
  matchingResultAsc: {
    label: "Group by Matching Result",
    value: "matching_result:asc",
  },
  priceAvgAsc: {
    label: "Avg. price per unit: low to high",
    value: "price_avg:asc",
  },
  rowsCountDesc: {
    label: "Times met: high to low",
    value: "rows_count:desc",
  },
  descriptionAsc: {
    label: "Description: A-Z",
    value: "",
  },
  clinicCodeAsc: {
    label: "Clinic's ID: A-Z",
    value: "clinic_id:asc",
  },
  clinicIdAsc: {
    label: "Clinic's Code: A-Z",
    value: "clinic_code:asc",
  },
  updatedDateDesc: {
    label: "Updated date: newest to oldest",
    value: "date:desc",
  },
};

export const MatchingSortingOptionsMap = {
  [Dataset.TRANSACTIONS]: [
    sortOptions.matchingResultAsc,
    sortOptions.priceAvgAsc,
    sortOptions.rowsCountDesc,
    sortOptions.descriptionAsc,
    sortOptions.clinicCodeAsc,
    sortOptions.clinicIdAsc,
    sortOptions.updatedDateDesc,
  ],
  [Dataset.SPECIES]: [
    sortOptions.matchingResultAsc,
    sortOptions.descriptionAsc,
    sortOptions.updatedDateDesc
  ],
};
