import React from "react";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";
import { CommonTaxonomyItemReadSection } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/CommonTaxonomyItemReadSection";
import {
  DiagnosticTestTaxonomyItemReadSection
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/DiagnosticTestTaxonomyItemReadSection";
import { FoodProductTaxonomyItemReadSection } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/FoodProductTaxonomyItemReadSection";
import { MedicalProductTaxonomyItemReadSection } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/MedicalProductTaxonomyItemReadSection";
import { RetailProductTaxonomyItemReadSection } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemReadSections/RetailProductTaxonomyItemReadSection/RetailProductTaxonomyItemReadSection";

import { TaxonomyItemReadSectionProps } from "./TaxonomyItemReadSection.types";

export const TaxonomyItemReadSection: React.FC<TaxonomyItemReadSectionProps> = React.memo((props) => {
  const { taxonomyClass } = props;
  if (taxonomyClass === TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()) {
    return <MedicalProductTaxonomyItemReadSection {...props} />;
  }
  if (taxonomyClass === TaxonomyClass.FOOD_PRODUCT.toLowerCase()) {
    return <FoodProductTaxonomyItemReadSection {...props} />;
  }
  if (taxonomyClass === TaxonomyClass.RETAIL_PRODUCT.toLowerCase()) {
    return <RetailProductTaxonomyItemReadSection {...props} />;
  }
  if (taxonomyClass === TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase()) {
    return <DiagnosticTestTaxonomyItemReadSection {...props} />;
  }
  return <CommonTaxonomyItemReadSection {...props} />;
});
