import React from "react";
import { Button } from "antd";

import { useMatchingCreateRequestButton } from "./useMatchingCreateRequestButton";

export const MatchingCreateRequestEmbedButton = React.memo<{ text: string }>(({ text }) => {
  const { createRequest, showCreateRequestButton } = useMatchingCreateRequestButton();

  return (
    <Button size="small" type="link" style={{ padding: 0 }} onClick={createRequest} disabled={!showCreateRequestButton}>
      {text}
    </Button>
  );
});
