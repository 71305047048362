/**
 * Github issue https://github.com/remix-run/react-router/issues/8139
 * [V6] [Feature] Getting usePrompt and useBlocker back in the router #8139
 * Code taken from https://gist.github.com/chaance/2f3c14ec2351a175024f62fd6ba64aa6
 */

import * as React from "react";
import { useBlocker } from "react-router-dom";

// You can abstract `useBlocker` to use the browser's `window.confirm` dialog to
// determine whether or not the user should navigate within the current origin.
// `useBlocker` can also be used in conjunction with `useBeforeUnload` to
// prevent navigation away from the current origin.
//
// IMPORTANT: There are edge cases with this behavior in which React Router
// cannot reliably access the correct location in the history stack. In such
// cases the user may attempt to stay on the page but the app navigates anyway,
// or the app may stay on the correct page but the browser's history stack gets
// out of whack. You should test your own implementation thoroughly to make sure
// the tradeoffs are right for your users.
export function usePrompt(message: string, enabled: boolean) {
  let blocker = useBlocker(React.useCallback(() => (enabled ? !window.confirm(message) : false), [enabled, message]));

  React.useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  }, [blocker]);
}
