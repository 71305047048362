import { MatchStatus } from "types/entities/Match";

import React from "react";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const useMatchingCreateRequestButton = () => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();
  const { commonStatus, patchSelectedGroups } = useMatchingContext();

  const enabled = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_REQUEST_CREATE);

  const showCreateRequestButton = React.useMemo(() => commonStatus !== MatchStatus.ITEM_REQUEST && enabled, [commonStatus, enabled]);

  const createRequest = React.useCallback(async () => {
    await patchSelectedGroups({ status: MatchStatus.ITEM_REQUEST });
    showSuccess(translations["matching.messages.request_item.success"]);
  }, [patchSelectedGroups, showSuccess, translations]);
  
  return { showCreateRequestButton, createRequest }
}
