import { DeepPartial } from "types/common";
import { MatchGroup } from "types/entities/Match";

import React from "react";
import { useParams } from "react-router-dom";
import { MatchingParamsType } from "router/navigationTypes";

import { useMatchMultipleGroups } from "modules/MatchingModule/api/useMatchMultipleGroups";
import { getCommonStatus } from "modules/MatchingModule/helpers/getCommonStatus";
import { getPatchGroupData } from "modules/MatchingModule/helpers/getPatchGroupData";
import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMemoMatchGroups } from "modules/MatchingModule/helpers/useMemoMatchGroups";
import { useSelectGroups } from "modules/MatchingModule/hooks/useSelectGroups";
import { useMatchingTargetItems } from "modules/MatchingModule/MatchingListModule/hooks/useMatchingTargetItems";

import { MatchingContextType } from "./MatchingModule.types";

const initialState: MatchingContextType = {
  selectedGroupIds: [],
  setSelectedGroupIds: () => {},
  patchSelectedGroups: async () => {},
  undoLastMatch: () => {},
  matchGroupsList: [],
  setMatchGroupsList: () => {},
  targetItems: {
    suggestions: {},
    conflicts: [],
  },
}

export const MatchingContext = React.createContext<MatchingContextType>(initialState);

export const MatchingModuleProvider = React.memo<{ children: JSX.Element }>(({ children }) => {
  const { jobId = "", datasetName = "" } = useParams<MatchingParamsType>();

  const [matchGroupsList, setMatchGroupsList] = React.useState<MatchGroup[]>([]);

  const {
    selectedGroupIds,
    setSelectedGroupIds,
    clearSelectedGroupsAndPickNext,
    needScrollToSelected,
  } = useSelectGroups(matchGroupsList);

  const {
    updateMultipleGroups,
    isLoading: isMatchGroupsUpdating
  } = useMatchMultipleGroups({ jobId, datasetName, cb: clearSelectedGroupsAndPickNext });

  const { putToMemory, restoreFromMemory, dropMemory, canUndo } = useMemoMatchGroups();

  const targetItems = useMatchingTargetItems(matchGroupsList, selectedGroupIds);

  const commonStatus = React.useMemo(() => {
    const selectedGroups = getSelectedGroups(matchGroupsList, selectedGroupIds);
    return getCommonStatus(selectedGroups);
  }, [matchGroupsList, selectedGroupIds]);

  const patchSelectedGroups = React.useCallback<MatchingContextType["patchSelectedGroups"]>(
    async (props) => {
      const isLastGroupInListPatched = selectedGroupIds.some(id => id === matchGroupsList[matchGroupsList.length - 1]?.group_id);
      const patchData = selectedGroupIds.map<DeepPartial<MatchGroup>>((groupId) => {
        const currentGroup = matchGroupsList.find(({ group_id }) => group_id === groupId);
        return getPatchGroupData(groupId, props, currentGroup);
      });
      await updateMultipleGroups(patchData, matchGroupsList.length, isLastGroupInListPatched);

      const groups = getSelectedGroups(matchGroupsList, selectedGroupIds);
      putToMemory(groups);
    }, [matchGroupsList, putToMemory, selectedGroupIds, updateMultipleGroups]);

  const undoLastMatch = React.useCallback(() => {
    restoreFromMemory(matchGroupsList.length).then((restoredGroups) => {
      dropMemory();
      const newSelectedGroups = restoredGroups.map(({group_id}) => group_id);
      needScrollToSelected.current = true;
      setSelectedGroupIds(newSelectedGroups);
    });
  }, [restoreFromMemory, matchGroupsList.length, dropMemory, needScrollToSelected, setSelectedGroupIds]);

  return (
    <MatchingContext.Provider value={{
      selectedGroupIds,
      setSelectedGroupIds,
      needScrollToSelected,
      matchGroupsList,
      setMatchGroupsList,
      patchSelectedGroups,
      undoLastMatch,
      isMatchGroupsUpdating,
      targetItems,
      commonStatus,
      canUndo,
    }}>
      {children}
    </MatchingContext.Provider>
  );
});

export const useMatchingContext = (): MatchingContextType => {
  return React.useContext(MatchingContext);
};
