import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { theme, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { JobCreateButton } from "modules/JobsModule/components/JobCreateButton";
import { JobsLists } from "modules/JobsModule/JobsLists";

export const JobsModule = React.memo(() => {
  const translations = useTranslations();
  const jobsCreateEnabled = useFeatureFlag(FeatureFlagNames.JOBS_CREATE);

  const { token } = theme.useToken();

  return (
    <div>
      <div className="flex flex-row items-center justify-between gap-4">
        <Typography.Title className="flex gap-3 items-center">
          <CheckCircleOutlined width={40} height={40} style={{ color: token.colorPrimary }} />
          {translations["jobs.title"]}
        </Typography.Title>
        {jobsCreateEnabled && <JobCreateButton/>}
      </div>
      <JobsLists isJobManager={true} />
    </div>
  );
});
