import React from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { useFilterContext } from "context/FilterContext/Filter.context";

import { FacetSetItem } from "modules/FacetsModule/components/FacetSetItem";

import { FacetSetProps } from "./FacetSet.types";

export const FacetSet = (props: FacetSetProps) => {
  const { urlQueryService, onClearFacet, className } = props;
  const location = useLocation();
  const { facetSectionsConfiguration } = useFilterContext();

  const clearFacetSection = React.useCallback((key: string) => {
    onClearFacet(key);
  }, [onClearFacet]);

  const items = React.useMemo(() => {
    return facetSectionsConfiguration
      .sort((a, b) => a.meta?.isImmutable ? -1 : 1)
      .reduce<React.ReactNode[]>((acc, configuration) => {
        const queryValue = urlQueryService.getValueByKey(configuration.key) || configuration.value || [];
        const values = Array.isArray(queryValue) ? queryValue : queryValue.split(",");

        if (values.length) {
          acc.push(
            <FacetSetItem
              key={configuration.key}
              configuration={configuration}
              values={values}
              onClose={clearFacetSection}
            />
          );
        }

        return acc;
      }, []);
    // "location" dependency is here to trigger the effect on location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFacetSection, facetSectionsConfiguration, location, urlQueryService]);

  if (!items.length) return null;

  return (
    <div className={clsx(className, "flex flex-wrap gap-2")}>
      {items}
    </div>
  );
};
