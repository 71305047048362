import { ItemID } from "types/common";

import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import { DIAGNOSTIC_TEST_RELATION } from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { getCleanListValues } from "./getCleanListValues";

export const mapValuesBEtoFE = (values: Partial<Record<string, ItemID[]>>) => ({
  [TaxonomyClass.PANEL_TYPE]: getCleanListValues(values[DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE] || []),
  [TaxonomyClass.MANUFACTURER]: getCleanListValues(values[DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER] || []),
  [TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]: getCleanListValues(
    values[DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION] || []
  )[0],
});
