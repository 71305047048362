import { checkInstructionIsEmpty } from "./helpers/checkInstructionIsEmpty";
import { findAndRemoveRegExp } from "./helpers/findAndRemoveRegExp";

import { valueAndUnitPairs } from "./UIConfiguration/UIConfigurationPairs";
import { valueBetweenBracesRegExp } from "./generateByUIConfiguration.consts";
import {
  InstructionResultForm,
  InstructionResultPartialArrayType,
  InstructionResultPartialPairType,
  InstructionResultPartialSeparatorType,
  InstructionResultPartialType,
  InstructionResultPartialValueType,
} from "./generateByUIConfiguration.types";

const addStrToResult = (result: string, str: string) => {
  if (str.at(0)?.match(/[^,]/)) return `${result} ${str}`;
  return `${result}${str}`;
}

export const buildStringByTemplateWithInstructions = (
  template: string,
  instructions: InstructionResultPartialType[]
): string => {

  let result = "";

  instructions.reduce((string, instructionEl, idx) => {
    if (instructionEl.form === InstructionResultForm.SEPARATOR) {

      const prevElement = instructions[idx - 1];
      const nextElement = instructions[idx + 1];

      if (checkInstructionIsEmpty(prevElement) && checkInstructionIsEmpty(nextElement)) return string;
      if (checkInstructionIsEmpty(nextElement) && idx === instructions.length - 2) return string;

      const replaceValue = (instructionEl as InstructionResultPartialSeparatorType).content.value;
      result += replaceValue;
      return string.replace(instructionEl.template.withBrackets, replaceValue);
    }

    if (instructionEl.form === InstructionResultForm.VALUE) {
      const replaceValue = (instructionEl as InstructionResultPartialValueType).content.value;
      result = addStrToResult(result, replaceValue);
      return string.replace(instructionEl.template.withBrackets, replaceValue);
    }

    if (instructionEl.form === InstructionResultForm.ARRAY) {
      const { insideBrackets, withBrackets } = instructionEl.template;

      const [, stringForReplace] = findAndRemoveRegExp(insideBrackets, valueBetweenBracesRegExp);

      const generateValueForArray = (instructionEl as InstructionResultPartialArrayType).content;

      const replaceValue = generateValueForArray
        .map((valueArr) => valueArr.sort((a, b) => a.value.localeCompare(b.value)))
        .sort((a, b) => a[0].value.localeCompare(b[0].value))
        .reduce(
          (acc, values) =>
            acc + values.reduce((str, valueEl, i) => str.replace(stringForReplace[i][0], valueEl.value), insideBrackets),
          ""
        );
      result = addStrToResult(result, replaceValue);
      return string.replace(withBrackets, replaceValue);
    }

    if (instructionEl.form === InstructionResultForm.PAIR) {
      const { withBrackets } = instructionEl.template;
      const templateConfig = Object.values(valueAndUnitPairs).find(
        (valueAndUnitPairConfig) => valueAndUnitPairConfig.template === withBrackets
      );
      if (!templateConfig) return string.replace(withBrackets, "");

      const newPairContent = templateConfig.decider((instructionEl as InstructionResultPartialPairType).content);

      result = addStrToResult(result, newPairContent.map((el) => el.value).join(""));
      return string.replace(withBrackets, newPairContent.map((el) => el.value).join(""));
    }

    return string;
  }, template);

  return result;
};
