import { TaxonomyItemCommonProperties } from "modules/TaxonomyModule/consts";
import { RenderFunction } from "modules/TaxonomyModule/TaxonomyTableModule/helpers/getRenderFunction";

import { CustomNameColumn } from "./CustomNameColumn";

export const NAME_COLUMN_KEY = TaxonomyItemCommonProperties.NAME;
export const customNameColumn: { [key: string]: RenderFunction } = {
  [NAME_COLUMN_KEY]: (value, taxonomyItem) => {
    return <CustomNameColumn taxonomyItem={taxonomyItem} value={String(value)} />
  },
};
