import { SkeletonProps } from "antd";
import { SpinProps } from "antd/es/spin";

export enum PreloaderType {
  DEFAULT = "default",
  SPINNER = "spinner",
  SPINNER_BLOCK = "spinner-block",
  SKELETON = "skeleton",
  SKELETON_BUTTON = "skeleton-button",
}

export type PreloaderProps = Partial<SpinProps> & Partial<SkeletonProps> & {
  type?: PreloaderType;
}
