import { ItemID } from "types/common";
import { RelationsToBE } from "types/entities/Relations";

import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { getItemRelationsUrl } from "api/rootUrl";

const postCreateRelationForTaxonomyItem = ({ itemID, data }: { itemID: ItemID; data: RelationsToBE }) => {
  return axios.post<number>(getItemRelationsUrl(itemID), data).then((data) => data.data);
};

export const useCreateRelationForTaxonomyItem = () => {
  const { showSuccess } = useMessage();
  const translations = useTranslations();

  const { mutateAsync } = useMutation({
    mutationFn: postCreateRelationForTaxonomyItem,
    onSuccess: (_, { itemID }) => {
      showSuccess(translations["relation.messages.create.success"]);
    },
  });

  return useCallback(
    async (itemID: ItemID, data: RelationsToBE) => {
      await mutateAsync({ itemID, data });
    },
    [mutateAsync]
  );
};
