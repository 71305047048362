import React from "react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { ConfigProvider, message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { MainRouter } from "router";
import { getAuthRequest } from "services/AuthService";

import { ApiQueryClientProvider } from "api/ApiQueryClientProvider";

import { AuthModule } from "modules/AuthModule";
import { FeatureFlagProvider } from "modules/AuthModule/featureFlags/FeatureFlag.context";
import { InitialConfigurationLoader } from "modules/InitialConfigurationLoader";

import { antdThemeConfig } from "assets/styles/antdThemeConfig";

import "App.css";

export const MessageContext = React.createContext<MessageInstance | null>(null);

function App({ pca }: { pca: IPublicClientApplication }) {
  const [messageApi, contextHolder] = message.useMessage();

  const authRequest = React.useMemo(() => getAuthRequest(), []);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <MessageContext.Provider value={messageApi}>
          <ApiQueryClientProvider>
            <FeatureFlagProvider>
              <AuthModule>
                <ConfigProvider theme={antdThemeConfig}>
                  <>
                    {contextHolder}
                    <InitialConfigurationLoader MainRouter={MainRouter} />
                  </>
                </ConfigProvider>
              </AuthModule>
            </FeatureFlagProvider>
          </ApiQueryClientProvider>
        </MessageContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
