import { TaxonomyClasses } from "types/appConfig";

import React from "react";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import { getTaxonomyListPath } from "router/path";

import { CatalogItemProps } from "modules/CatalogModule/components/CatalogItem/CatalogItem.types";

export const useMainAndRestCatalogItems = (
  mainItemsCoverMap: Map<string, string>,
  items?: TaxonomyClasses
): { mainItems: CatalogItemProps[]; restItems: CatalogItemProps[] } => {
  return React.useMemo(() => {
    if (!items) return { mainItems: [], restItems: [] };

    const mainItems: CatalogItemProps[] = Array.from(mainItemsCoverMap).map(([key, cover]) => ({
      label: getTaxonomyClassTitle(key),
      href: getTaxonomyListPath(key),
      cover,
    }));

    const restItems: CatalogItemProps[] = Object.keys(items)
      .filter((key) => !mainItemsCoverMap.has(key))
      .map((key) => ({
        label: getTaxonomyClassTitle(key),
        href: getTaxonomyListPath(key),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return { mainItems, restItems };
  }, [items, mainItemsCoverMap]);
}
