import React from "react";
import { useLocation } from "react-router-dom";
import { useIsPath } from "router/hooks/useIsPath";

import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";

const CATALOG_KEY = "catalogLastListSearch";
const FIND_IN_CATALOG_KEY = "findInCatalogLastListSearch";

export const useLastListSearch = (save?: boolean) => {
  const location = useLocation();
  const urlQueryService = useTaxonomyUrlQueryService();
  const { isInMatching } = useIsPath();

  const getLastListSearch = () => {
    const lastListUrl = sessionStorage.getItem(isInMatching ? FIND_IN_CATALOG_KEY : CATALOG_KEY);
    return lastListUrl || "";
  }

  React.useEffect(() => {
    if (save) {
      sessionStorage.setItem(
        isInMatching ? FIND_IN_CATALOG_KEY : CATALOG_KEY,
        urlQueryService.getQueryStringWithoutOtherQueries()
      );
    }
  }, [isInMatching, location.search, save, urlQueryService])

  return { getLastListSearch };
}
