import React from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const MatchingTableUndoButton = React.memo(() => {
  const translations = useTranslations();
  const { canUndo, undoLastMatch, isMatchGroupsUpdating } = useMatchingContext();

  if (!canUndo) return null;

  return (
    <Button icon={<UndoOutlined/>} onClick={undoLastMatch} loading={isMatchGroupsUpdating} size="small" danger>
      {translations["matching.controls.undo"]}
    </Button>
  );
});
