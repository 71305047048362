import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { useTranslations } from "translations/useTranslations";

import { useUpdateSynonym } from "./api/useUpdateSynonym";

import { SynonymFormModule } from "modules/SynonymModule/SynonymFormModule/SynonymFormModule";
import { useDeleteSynonym } from "modules/SynonymModule/SynonymUpdateModule/api/useDeleteSynonym";

import { SynonymUpdateModuleProps } from "./SynonymUpdateModule.types";

export const SynonymUpdateModule = (props: SynonymUpdateModuleProps) => {
  const { onClose, taxonomyItemId, synonym } = props;

  const [taxonomyClass] = getItemClassFromItemId(taxonomyItemId);
  const [value, setValue] = React.useState(synonym?.value || "");
  const translations = useTranslations();

  const { updateSynonym, isLoading: isUpdating, errorMessage } = useUpdateSynonym({ taxonomyItemId, synonym });
  const { deleteSynonym, isLoading: isDeleting } = useDeleteSynonym({ taxonomyItemId, synonymItemId: synonym.id });

  const isBusy = isUpdating || isDeleting;

  const handleSave = React.useCallback(
    async (value: string, language: string, scope?: string) => {
      value ? await updateSynonym({ value, language, scope }) : await deleteSynonym();
      onClose();
    },
    [updateSynonym, deleteSynonym, onClose]
  );

  return (
    <SynonymFormModule
      taxonomyClass={taxonomyClass}
      synonym={synonym}
      onClose={onClose}
      onSubmit={handleSave}
      okText={translations["synonym.buttons.update"]}
      isBusy={isBusy}
      errorMessage={errorMessage}
      value={value}
      setValue={setValue}
    />
  );
};
