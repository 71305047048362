import { Code, TrimmedCode } from "types/entities/Code";

import { configurationServiceInstance } from "services/ConfigurationService";

export const getConflictingCode = (newCode: TrimmedCode, codes: Code[]): Code | null => {
  const config = configurationServiceInstance.getCodesConfig();

  if (!config[newCode.type]?.unique) return null;

  return codes.find(code => code.type === newCode.type) || null;
}
