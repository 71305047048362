import { configurationServiceInstance } from "services/ConfigurationService";

export const getTaxonomyRelationTitle = (taxonomyClass: string, relationName: string): string => {
  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  if (!taxonomyItemConfig) return relationName;

  const relationConfig = taxonomyItemConfig.meta.relations?.[relationName];
  const relatedTaxonomyClassConfig = configurationServiceInstance.getTaxonomyItemByName(relationConfig?.to || "");

  return relationConfig?.title || relatedTaxonomyClassConfig?.title || relationName;
};
