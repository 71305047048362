import { TaxonomyItem } from "types/entities/Taxonomy";

import type { Dayjs } from "dayjs";

export enum Source {
  MARKET = "market",
  CLINIC = "clinic",
}

export enum JobsFormValueKeys {
  NAME = "name",
  PERIOD = "period",
  MATCH = "match",
  _SOURCE = "source",
  MARKET = "market",
  PROVIDERS = "providers",
  _CLINICS_PROVIDERS = "clinicProviders",
  CLINICS = "clinics",
}

export type JobsCreateFormValues = {
  [JobsFormValueKeys.NAME]: string;
  [JobsFormValueKeys.PERIOD]: Array<Dayjs>;
  [JobsFormValueKeys.MATCH]: string[][];
  [JobsFormValueKeys._SOURCE]: Source;
  [JobsFormValueKeys.MARKET]: string;
  [JobsFormValueKeys.PROVIDERS]: Array<TaxonomyItem>;
  [JobsFormValueKeys._CLINICS_PROVIDERS]: Array<TaxonomyItem>;
  [JobsFormValueKeys.CLINICS]: Array<string>;
}
