import { ItemID } from "types/common";
import { Synonym, SynonymType } from "types/entities/Synonym";

import React from "react";
import clsx from "clsx";

import { SynonymListItem } from "modules/SynonymModule/SynonymListModule/components/SynonymListItem";

export const SynonymWildcardTable = React.memo<{ synonymType:SynonymType, taxonomyItemId: ItemID, items: Synonym[] }>(({ synonymType, taxonomyItemId, items }) => {
  return (
    <div>
      {items.map((synonym, i) => (
        <div
          key={synonym.id}
          className={clsx("py-2", i < items.length - 1 && "border-solid border-0 border-b border-b-black/5")}
        >
          <SynonymListItem synonym={synonym} synonymType={synonymType} taxonomyItemId={taxonomyItemId} />
        </div>
      ))}
    </div>
  );
});
