import React from "react";
import { theme } from "antd";

import { SelectCountry } from "../SelectCountry";
import { SelectProviders } from "../SelectProviders";

const wrapperClass = "grid grid-cols-[minmax(200px,_1fr)_repeat(2,_1fr)] md:grid-cols-[minmax(200px,_1fr)_repeat(3,_1fr)] lg:grid-cols-[minmax(200px,_1fr)_repeat(5,_1fr)] mb-[-1px] mr-[-1px]";
const containerClass = "px-4 flex justify-between min-h-[70px] p-4 border-0 border-r border-b border-solid";

export const MatchingInformationSourceContent = React.memo(() => {
  const { token } = theme.useToken();

  return (
    <div className="overflow-hidden">
      <div className={wrapperClass} style={{ backgroundColor: token.colorBgContainerDisabled }}>
        <SelectCountry containerClass={containerClass} />
        <SelectProviders containerClass={containerClass} />
      </div>
    </div>
  );
});
