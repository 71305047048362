import React from "react";
import { Typography } from "antd";
import { getCountryConfig } from "helpers/countryHelpers";
import { getFormattedPriceRange } from "helpers/priceHelpers";
import { useTranslations } from "translations/useTranslations";

import { useGetItemPricesWithCache } from "api/useGetItemPrices";

import { LabelledList } from "ui/LabelledList";
import { Preloader, PreloaderType } from "ui/Preloader";

import { PriceModuleProps } from "./PriceModule.types";

export const PriceModule = React.memo<PriceModuleProps>((props) => {
  const { taxonomyItemId } = props;

  const translation = useTranslations();

  const { itemPrices = [], isLoading } = useGetItemPricesWithCache(taxonomyItemId);

  const labelledItems = React.useMemo(() => {
    return itemPrices.map(({ price_min, price_max, country }) => {
      const { countryName, currencyName } = getCountryConfig(country);

      return {
        label: `${translation["item.price.label"]} (${countryName})`,
        value: getFormattedPriceRange(currencyName, price_min, price_max),
      };
    });
  }, [itemPrices, translation]);

  return (
    <div>
      <Typography.Title level={4}>{translation["item.price.title"]}</Typography.Title>
      {isLoading ? (
        <Preloader type={PreloaderType.SKELETON} />
      ) : (
        <LabelledList items={labelledItems} emptyMessage={translation["item.price.empty"]} />
      )}
    </div>
  );
});
