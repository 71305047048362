import { ResponseError } from "types/apiTypes";
import { ItemID } from "types/common";

import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { formatBEErrorToModals } from "helpers/formatErrorBEToFE";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { useGetTranslatedUiError } from "hooks/useGetTranslatedUiError";
import { useInteractiveMessage } from "hooks/useInteractiveMessage";
import { useMessage } from "hooks/useMessage";
import { getTaxonomyListPath } from "router/path";
import { useTranslations } from "translations/useTranslations";

import { getUrlToItemByItemID } from "api/rootUrl";

type ResultOfMutationDeleteTaxonomyItemById = { id: number };
const deleteTaxonomyItemById = (itemID: ItemID) => () => {
  return axios
    .delete<ResultOfMutationDeleteTaxonomyItemById>(
      getUrlToItemByItemID(itemID)
    )
    .then((data) => data.data);
};

export const useDeleteTaxonomyItemById = (
  itemID: ItemID
): {
  deleteTaxonomyItemById: () => Promise<ResultOfMutationDeleteTaxonomyItemById>;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSuccess } = useMessage();
  const { showInteractiveError } = useInteractiveMessage();
  const getTranslatedUiError = useGetTranslatedUiError();
  const translations = useTranslations();

  const { mutateAsync } = useMutation({
    mutationFn: deleteTaxonomyItemById(itemID),
    onSuccess: async () => {
      queryClient.clear();
      const [itemClass] = getItemClassFromItemId(itemID)
      navigate(getTaxonomyListPath(itemClass));
      showSuccess(translations["item.messages.delete.success"])
    },
    onError: (error, ...args) => {
      const formattedErrors = error ? formatBEErrorToModals(error as AxiosError<ResponseError>) : [];

      formattedErrors.forEach((formattedError) => {
        const translatedError = getTranslatedUiError(formattedError);
        if (typeof translatedError === "string") {
          queryClient.defaultMutationOptions?.().onError?.(formattedErrors, ...args);
        } else {
          showInteractiveError(translatedError);
        }
      });
    }
  });

  return { deleteTaxonomyItemById: mutateAsync };
};
