import React from "react";
import { debounce } from "helpers/debounce";

export const useSearch = (initialValue = "", minLength = 2) => {
  const [inputValue, setInputValue] = React.useState(initialValue);
  const [queryValue, setQueryValue] = React.useState(initialValue);

  const updateSearch = React.useMemo(
    () =>
      debounce((newValue: string) => {
        if (newValue.length === 0) setQueryValue("");
        if (newValue.length > minLength) setQueryValue(newValue);
      }),
    [minLength]
  );

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    updateSearch(newValue);
  }, [updateSearch]);

  return { handleChange, inputValue, queryValue };
}
