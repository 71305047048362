import { Dataset, JobStatus } from "types/entities/Jobs";

import React from "react";
import { Card, Divider, theme } from "antd";
import clsx from "clsx";

import { JobCardControl } from "modules/JobsModule/components/JobCardControl";
import { JobCardTitle } from "modules/JobsModule/components/JobCardTitle";
import { JobLink } from "modules/JobsModule/components/JobLink";

import { LabelledList } from "ui/LabelledList";

import { useJobCardListItems } from "./hooks/useJobCardListItems";
import { JobCardProps } from "./JobCard.types";

import styles from "./JobCard.module.scss";

export const JobCard = React.memo<JobCardProps>((props) => {
  const { job, isJobsManager } = props;
  const { token } = theme.useToken();

  const listItems = useJobCardListItems(job);

  const isGhost = job.status === JobStatus.CREATED || job.status === JobStatus.DELETED;

  return (
    <Card
      title={<JobCardTitle job={job} isJobManager={isJobsManager} />}
      style={isGhost ? { border: `1px dashed ${token.colorBorder}`, backgroundColor: token.colorBgLayout } : {}}
      headStyle={isGhost ? { borderBottomColor: token.colorSplit } : {}}
      className={clsx(styles.root)}
      extra={<JobCardControl job={job} isJobManager={isJobsManager} />}
    >
      {!isGhost && (
        <div className="flex flex-col gap-4 mb-4">
          <JobLink jobId={job.id} datasetName={Dataset.TRANSACTIONS} isJobsManager={isJobsManager} />
          <Divider style={{ margin: 0 }} />
          <JobLink jobId={job.id} datasetName={Dataset.SPECIES} isJobsManager={isJobsManager} />
        </div>
      )}
      <LabelledList items={listItems} />
    </Card>
  );
});
