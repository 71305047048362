import React from "react";
import { useParams } from "react-router-dom";
import { Select } from "antd";
import { MatchingParamsType } from "router/navigationTypes";
import { useTranslations } from "translations/useTranslations";

import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";
import { MatchingSortingOptionsMap, SORT_KEY } from "modules/MatchingModule/MatchingGroupsModule/consts";

export const MatchingSort = React.memo(() => {
  const translations = useTranslations();
  const { datasetName } = useParams<MatchingParamsType>();
  const urlQueryService = useMatchingUrlQueryService();

  const value = urlQueryService.getValueByKey(SORT_KEY) as string;

  const handleChange = React.useCallback(
    (newValue = "") => urlQueryService.setSearchParam(SORT_KEY, newValue),
    [urlQueryService]
  );

  if (!datasetName) return null;

  return (
    <Select
      options={MatchingSortingOptionsMap[datasetName]}
      onChange={handleChange}
      value={value || ""}
      className="w-full"
      placeholder={translations["matching.table.sort.placeholder"]}
    />
  );
});
