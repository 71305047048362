import { TaxonomyItem } from "types/entities/Taxonomy";

import { useTranslations } from "translations/useTranslations";

import { TreeNode } from "modules/JobsModule/JobsCreateFormModule/components/SelectInfoToMatch/SelectInfoToMatch.types";
import {
  _TAXONOMY_ITEM_COMMON_RELATION,
  FOOD_BRAND_RELATION,
  FOOD_PRODUCT_LINE_RELATION,
  MEDICAL_BRAND_RELATION,
  RETAIL_BRAND_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

type RelationMap = Map<string, TreeNode[]>;

const buildTreeNode = (item: TaxonomyItem): TreeNode => ({ label: item.name || item.id, value: item.id });

const buildRelationMap = (items: TaxonomyItem[], relationKey: string): { map: RelationMap, orphans: TreeNode[] } => {
  const map: RelationMap = new Map();
  const orphans: TreeNode[] = [];

  for (const item of items) {
    if (!item.relations?.[relationKey]?.length) {
      orphans.push(buildTreeNode(item));
      continue;
    }

    for (const relation of item.relations[relationKey]) {
      if (!map.has(relation.id)) map.set(relation.id, []);
      map.get(relation.id)?.push(buildTreeNode(item));
    }
  }

  return { map, orphans };
}

const getNodeWithChildren = (
  { node, maps }: {
    node: TreeNode;
    maps: { map: RelationMap; isRecursive?: boolean }[]
  }): TreeNode => {
  const treeNode = { ...node };
  node.children = [];

  for (const { map, isRecursive } of maps) {
    if (!map.has(treeNode.value)) continue;
    node.children.push(
      ...map.get(treeNode.value)?.map(
        (child) => isRecursive ? getNodeWithChildren({ node: child, maps }) : child
      ) || []
    );
  }

  return node;
}


export const useTreeBuilders = () => {
  const translations = useTranslations();
  const otherLabel = translations["jobs.form.info_to_match.other"];

  return {
    buildMedicalTree: (drugClasses: TaxonomyItem[], medicalBrands: TaxonomyItem[]): TreeNode[] => {
      const tree: TreeNode[] = [];

      const { map: drugClassToSelfMap, orphans: drugClassLvl1 } = buildRelationMap(drugClasses, _TAXONOMY_ITEM_COMMON_RELATION._ANY_TO_PARENT);
      const { map: drugClassToBrandMap, orphans: brandLvl1 } = buildRelationMap(medicalBrands, MEDICAL_BRAND_RELATION.TO_DRUG_CLASS);

      for (const drugClass of drugClassLvl1) {
        const treeNode = getNodeWithChildren({
          node: drugClass,
          maps: [
            { map: drugClassToSelfMap, isRecursive: true },
            { map: drugClassToBrandMap }
          ]
        });
        tree.push(treeNode);
      }

      tree.push({ label: otherLabel, value: "", children: brandLvl1 });

      return tree;
    },
    buildFoodTree: (foodClasses: TaxonomyItem[], foodBrands: TaxonomyItem[], foodProductLines: TaxonomyItem[]) => {
      const tree: TreeNode[] = [];

      const { map: foodClassToSelfMap, orphans: foodClassLvl1 } = buildRelationMap(foodClasses, _TAXONOMY_ITEM_COMMON_RELATION._ANY_TO_PARENT);
      const { map: foodClassToBrandMap, orphans: brandLvl1 } = buildRelationMap(foodBrands, FOOD_BRAND_RELATION.TO_FOOD_CLASS);
      const { map: foodBrandToProductLineMap, orphans: productLineLvl1 } = buildRelationMap(foodProductLines, FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND);

      for (const foodClass of foodClassLvl1) {
        const treeNode = getNodeWithChildren({
          node: foodClass,
          maps: [
            { map: foodClassToSelfMap, isRecursive: true },
            { map: foodClassToBrandMap, isRecursive: true },
            { map: foodBrandToProductLineMap }
          ]
        });
        tree.push(treeNode);
      }

      tree.push({ label: otherLabel, value: "", children: [...productLineLvl1, ...brandLvl1] });

      return tree;
    },
    buildRetailTree: (retailClasses: TaxonomyItem[], retailBrands: TaxonomyItem[]) => {
      const tree: TreeNode[] = [];

      const { map: retailClassToSelfMap, orphans: retailClassLvl1 } = buildRelationMap(retailClasses, _TAXONOMY_ITEM_COMMON_RELATION._ANY_TO_PARENT);
      const { map: retailClassToBrandMap, orphans: brandLvl1 } = buildRelationMap(retailBrands, RETAIL_BRAND_RELATION.TO_RETAIL_CLASS);

      for (const retailClass of retailClassLvl1) {
        const treeNode = getNodeWithChildren({
          node: retailClass,
          maps: [
            { map: retailClassToSelfMap, isRecursive: true },
            { map: retailClassToBrandMap }
          ]
        });
        tree.push(treeNode);
      }

      tree.push({ label: otherLabel, value: "", children: brandLvl1 });

      return tree;
    }
  }
}
