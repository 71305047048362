import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useTranslations } from "translations/useTranslations";

export const TaxonomyItemAbstractTag = React.memo<{ isAbstract?: boolean, style?: React.CSSProperties }>(({ isAbstract, style }) => {
  const translations = useTranslations();
  if (isAbstract === undefined || isAbstract === false) return null;

  return (
    <Tooltip title={translations["tag.abstract.tooltip"]} className="cursor-help">
      <Tag icon={<ExclamationCircleOutlined />} color="warning" style={style}>
        {translations["item.form.completeness.abstract.label"]}
      </Tag>
    </Tooltip>
  );
});
