import { ErrorSchema } from "@rjsf/utils";
import { useTranslations } from "translations/useTranslations";

export const useErrorSchemaWithTranslations = (
  errorSchema?: ErrorSchema,
  taxonomyName?: string,
): ErrorSchema | undefined => {
  const translations = useTranslations();

  if (!errorSchema) return;

  const translatedSchema: ErrorSchema = {};

  for (const errorSchemaKey in errorSchema) {
    const value = errorSchema[errorSchemaKey];
    if (!value?.__errors) continue;

    translatedSchema[errorSchemaKey] = {
      __errors: value.__errors.map((keyOrMsg) => {
        const errorText = translations[keyOrMsg] || keyOrMsg;

        return errorText.replace("{{taxonomyName}}", taxonomyName || "");
      }),
    } as ErrorSchema;
  }

  return translatedSchema;
}
