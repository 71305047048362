import { NodeTree } from "modules/FacetsModule/FacetsModule.types";

export const getChildrenOfTreeNode = (nodeTree: NodeTree): NodeTree[] => {
  if (!nodeTree.children || nodeTree.children.length === 0) return [];

  return [
    ...(nodeTree.children || []),
    ...(nodeTree.children || []).map((nodeTreeEl) => getChildrenOfTreeNode(nodeTreeEl)).flat(1),
  ];
};
