// Does not represent all possible relations
export enum _TAXONOMY_ITEM_COMMON_RELATION {
  _ANY_TO_PARENT = "hasParent",
}

export enum CLINIC_RELATION {
  TO_PROVIDER = "hasProvider"
}

export enum DIAGNOSTIC_TEST_RELATION {
  TO_DIAGNOSTIC_TEST_LOCATION = "hasDiagnosticTestLocation",
  TO_MANUFACTURER = "hasManufacturer",
  TO_PANEL_TYPE = "hasPanelType",
  TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS = "hasDiagnosticTestMatchingRequirements",
}

export enum FOOD_BRAND_RELATION {
  TO_FOOD_CLASS = "hasFoodClass",
  TO_MANUFACTURER = "hasManufacturer",
  TO_MARKET = "hasMarket",
}

export enum FOOD_PRODUCT_RELATION {
  TO_FLAVOR = "hasFlavor",
  TO_FOOD_FORM = "hasFoodForm",
  TO_FOOD_PRODUCT_LINE = "hasFoodProductLine",
  TO_PACK_COLOR = "hasPackColor",
  TO_PACK_QUANTITY_MEASURE = "hasPackQuantityMeasure",
  TO_PACK_UNIT_VOLUME_MEASURE = "hasPackUnitVolumeMeasure",
  TO_PRESCRIPTION_OTC = "hasPrescriptionOTC",
  TO_SPECIES = "targetSpecies",
  TO_TREATMENT_INDICATION = "hasTreatmentIndication",
}

export enum FOOD_PRODUCT_LINE_RELATION {
  TO_FOOD_BRAND = "hasFoodBrand",
}

export enum MANUFACTURER_RELATION {
  TO_CORPORATION = "hasCorporation",
}

export enum MEDICAL_BRAND_RELATION {
  TO_DRUG_CLASS = "hasDrugClass",
  TO_MANUFACTURER = "hasManufacturer",
  TO_MARKET = "hasMarket",
  TO_PRINCIPAL_GENERIC = "hasPrincipalGeneric",
  TO_PRIMARY_ACTIVE_INGREDIENT = "primaryActiveIngredient",
  TO_SECONDARY_ACTIVE_INGREDIENT = "secondaryActiveIngredient",
}

export enum MEDICAL_PRODUCT_RELATION {
  TO_FLAVOR = "hasFlavor",
  TO_MEDICAL_BRAND = "hasMedicalBrand",
  TO_PACK_COLOR = "hasPackColor",
  TO_PACK_SIZE_MEASURE = "hasPackSizeMeasure",
  TO_PATIENT_WEIGHT_UNIT = "hasPatientWeightUnit",
  TO_PHYSICAL_FORM = "hasPhysicalForm",
  TO_ROUTE_OF_ADMINISTRATION = "hasRouteOfAdministration",
  TO_SPECIES = "targetSpecies",
  TO_STRENGTH_UNIT = "hasStrengthUnit",
  TO_TREATMENT_INDICATION = "hasTreatmentIndication",
}

export enum PROVIDER_RELATION {
  TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS = "hasDiagnosticTestMatchingRequirements",
}

export enum RETAIL_BRAND_RELATION {
  TO_MANUFACTURER = "hasManufacturer",
  TO_MARKET = "hasMarket",
  TO_RETAIL_CLASS = "hasRetailClass",
  TO_PRIMARY_OTC_ACTIVE_INGREDIENT = "primaryOTCActiveIngredient",
  TO_SECONDARY_OTC_ACTIVE_INGREDIENT = "secondaryOTCActiveIngredient",
}

export enum RETAIL_PRODUCT_RELATION {
  TO_BREED_SIZE = "hasBreedSize",
  TO_PACK_SIZE_MEASURE = "hasPackSizeMeasure",
  TO_PACK_UNIT_VOLUME_MEASURE = "hasPackUnitVolumeMeasure",
  TO_PHYSICAL_FORM = "hasPhysicalForm",
  TO_RETAIL_BRAND = "hasRetailBrand",
  TO_ROUTE_OF_ADMINISTRATION = "hasRouteOfAdministration",
  TO_SPECIES = "targetSpecies",
  TO_STRENGTH_UNIT = "hasStrengthUnit",
}
