import { ItemID } from "types/common";
import { MatchStatus, MatchTargetFinal } from "types/entities/Match";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const useMatchItem = () => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();
  const { patchSelectedGroups, isMatchGroupsUpdating } = useMatchingContext();

  const match = React.useCallback(async (itemID: ItemID, taxonomyItem?: TaxonomyItem) => {
    const [className] = getItemClassFromItemId(itemID);

    const final: MatchTargetFinal = { taxonomy_item_id: itemID };

    if (className.toLowerCase() === TaxonomyClass.DIAGNOSTIC_TEST.toLowerCase() && taxonomyItem) {
      const relations: Record<string, string[]> = {};

      for (const relationKey in (taxonomyItem.relations || {})) {
        relations[relationKey] = taxonomyItem.relations?.[relationKey].map((item) => item.id) || [];
      }

      final.taxonomy_item_body = {
        class: TaxonomyClass.DIAGNOSTIC_TEST,
        relations,
      };
    }

    await patchSelectedGroups({ status: MatchStatus.MANUAL, final: [final] });
    showSuccess(translations["matching.messages.update.success"]);
  }, [patchSelectedGroups, showSuccess, translations]);

  return {
    isMatchGroupsUpdating,
    match
  };
}
