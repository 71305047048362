import { MatchGroup } from "types/entities/Match";

import React from "react";
import { Empty, Table } from "antd";
import clsx from "clsx";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { useActionOnAppearanceInViewport } from "hooks/useActionOnAppearanceInViewport";
import { useTranslations } from "translations/useTranslations";

import { useGetMatchingList } from "modules/MatchingModule/api/useGetMatchingList";
import {
  scrollIntoViewIfNotVisible
} from "modules/MatchingModule/MatchingGroupsModule/components/MatchingTable/helpers/scrollIntoView";
import { createColumns } from "modules/MatchingModule/MatchingGroupsModule/helpers/createColumns";
import { createRows } from "modules/MatchingModule/MatchingGroupsModule/helpers/createRows";
import { useInitialFacets } from "modules/MatchingModule/MatchingGroupsModule/hooks/useInitialFacets";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

import { Preloader, PreloaderType } from "ui/Preloader";
import { PaginationPreloader } from "ui/Preloader/PaginationPreloader";

import { MatchingTableRowExtension } from "../MatchingTableRowExtension";

import { useClickOnRow } from "./hooks/useClickOnRow";
import { useKeepSelectedGroupVisible } from "./hooks/useKeepSelectedGroupsVisible";
import { useListenForTextSelection } from "./hooks/useListenForTextSelection";

import styles from "./MatchingTable.module.scss";

const expandable = {
  expandedRowRender: (matchGroup: MatchGroup) => <MatchingTableRowExtension matchGroup={matchGroup} />,
};
const ROW_TRACKING_CLASS_NAME = "matching-table-row";
const ROW_DATA_ATTR = "data-row-key";
const HEADER_OFFSET = 45;

export const MatchingTable = React.memo(() => {
  const {
    setMatchGroupsList,
    selectedGroupIds,
    setSelectedGroupIds,
    needScrollToSelected,
    isMatchGroupsUpdating
  } = useMatchingContext();
  const translations = useTranslations();

  const isInitialFacetReady = useInitialFacets();
  const { allowedFilterParamsWithAdditionalKeys } = useFilterContext();

  const { matchGroupsList, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetMatchingList(
    allowedFilterParamsWithAdditionalKeys,
    isInitialFacetReady
  );

  const actionOnAppearanceInViewport = React.useCallback(
    () => !isFetchingNextPage && hasNextPage && fetchNextPage(),
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  );
  const divRef = React.useRef(null);
  useActionOnAppearanceInViewport(divRef, actionOnAppearanceInViewport);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const { isShift } = useListenForTextSelection();

  const columns = React.useMemo(() => createColumns(translations), [translations]);
  const rows = React.useMemo(() => createRows(matchGroupsList), [matchGroupsList]);

  const handleSelection = React.useCallback((selectedRowKeys: React.Key[]) => {
    setSelectedGroupIds(selectedRowKeys as number[]);
  }, [setSelectedGroupIds]);

  const handleClickOnRow = useClickOnRow();
  const onRow = React.useMemo(() => (matchGroup: MatchGroup) => ({
    onClick: (e: React.MouseEvent) => handleClickOnRow(e, matchGroup, handleSelection),
  }), [handleClickOnRow, handleSelection]);

  const rowSelection = React.useMemo(() => ({
    selectedRowKeys: selectedGroupIds,
    onChange: handleSelection
  }), [handleSelection, selectedGroupIds]);

  useKeepSelectedGroupVisible(scrollContainerRef, ROW_TRACKING_CLASS_NAME, HEADER_OFFSET);

  React.useEffect(() => {
    if (needScrollToSelected?.current) {
      const targetEl = document.querySelector<HTMLElement>(`[${ROW_DATA_ATTR}="${selectedGroupIds[0]}"]`);
      if (!scrollContainerRef.current || !targetEl) return;
      needScrollToSelected.current = false;
      scrollIntoViewIfNotVisible(scrollContainerRef.current, targetEl, HEADER_OFFSET);
    }
  }, [needScrollToSelected, selectedGroupIds])

  React.useEffect(() => {
    setMatchGroupsList(matchGroupsList);
  }, [matchGroupsList, setMatchGroupsList]);

  if (!rows?.length && (isLoading || !isInitialFacetReady || isMatchGroupsUpdating)) return <Preloader type={PreloaderType.SPINNER_BLOCK} />;
  if (!rows?.length) return <Empty className="mb-4" />;

  return (
    <>
      <div className="overflow-y-auto" ref={scrollContainerRef}>
        <div className={clsx(isShift && "select-none")}>
          <Table
            className={styles.table}
            loading={isMatchGroupsUpdating || (isLoading && !isFetchingNextPage)}
            columns={columns}
            dataSource={rows}
            rowSelection={rowSelection}
            onRow={onRow}
            expandable={expandable}
            rowClassName={`${ROW_TRACKING_CLASS_NAME} cursor-pointer`}
            pagination={false}
            size="middle"
            bordered
            sticky
          />
        </div>
        {isFetchingNextPage && <PaginationPreloader text={translations["matching.table.load_more"]}/>}
        <div ref={divRef} className="h-2 mb-2" />
      </div>
    </>
  );
});
