import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import { FacetsServiceType } from "modules/FacetsModule/FacetsService";

export const getUniqueElementsFromSecondArray = (
  aValues: FacetsValueFlatTreeType["value"],
  bValues: FacetsValueFlatTreeType["value"]
) => {
  const aValueIds = aValues.map(({ id }) => id);
  return bValues.filter((bItem) => !aValueIds.includes(bItem.id));
};

type CombinationResultType = {
  uniqueElementsFromSecondArray: FacetsValueFlatTreeType["value"];
  combinedElement: FacetsValueFlatTreeType["value"];
};

const combineUniqueElements = (
  aValues: FacetsValueFlatTreeType["value"],
  bValues: FacetsValueFlatTreeType["value"]
): CombinationResultType => {
  if (aValues.length === 0) return { uniqueElementsFromSecondArray: bValues, combinedElement: bValues };
  if (bValues.length === 0) return { uniqueElementsFromSecondArray: [], combinedElement: aValues };
  const uniqueElementsFromSecondArray = getUniqueElementsFromSecondArray(aValues, bValues);
  return {
    uniqueElementsFromSecondArray,
    combinedElement: aValues.concat(uniqueElementsFromSecondArray),
  };
};

export const combineTree = (
  newFacetsValues: FacetsValueFlatTreeType["value"],
  oldFacetsValues: FacetsValueFlatTreeType["value"],
  facetsService: FacetsServiceType,
  facetKey: string
): CombinationResultType => {
  const checkedFacetsValues = oldFacetsValues.length
    ? oldFacetsValues.filter((facetValue) => facetsService.getValuesByKey(facetKey).includes(facetValue.id))
    : [];

  return combineUniqueElements(newFacetsValues, checkedFacetsValues);
};
