import React from "react";
import { Form, Input } from "antd";
import { SPACE } from "consts/strings";
import { useTranslations } from "translations/useTranslations";

import {
  SelectCodeType
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/components/SelectCodeType";
import {
  getValidator
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/helpers/getValidator";

import { TaxonomyItemCodesTableEditableCellProps } from "./TaxonomyItemCodesTableEditableCell.types";

export const TaxonomyItemCodesTableEditableCell = React.memo<TaxonomyItemCodesTableEditableCellProps>(({
  codeId,
  editing,
  dataIndex,
  codes,
  children,
  ...restProps
}) => {
  const translations = useTranslations();

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator: getValidator(getFieldValue, codes, codeId),
              message: dataIndex === "code" ? translations["item.codes.error.duplicate"] : SPACE,
            }),
          ]}
        >
          {dataIndex === "type" ? <SelectCodeType /> : <Input/>}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
});
