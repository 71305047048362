import { PaginatedResponse } from "types/apiTypes";

import { getRelationWithExpandOnSelf } from "helpers/expandHelpers/getRelationWithExpandOnSelf";
import { configurationServiceInstance } from "services/ConfigurationService";

import { expandWithKeys, getUnlimitedPaginationParams } from "api/rootUrl";
import { useEndpoint } from "api/useEndpoint";

// TODO: add "expandToSelf" param
export const useEndpointByTaxonomyClass = <T extends Object>(
  taxonomyClass: string,
  isUnlimited: boolean = true,
  expand: boolean | string | string[] = true
) => {
  const configuration = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass.toLowerCase());
  const limitPart = isUnlimited && getUnlimitedPaginationParams();
  const expandPart = typeof expand === "boolean" ? expand && getRelationWithExpandOnSelf(taxonomyClass.toLowerCase()) : expandWithKeys(expand);
  const additionalPartsOfQuery = [limitPart, expandPart].filter(Boolean);

  const { data, isLoading, isError } = useEndpoint<PaginatedResponse<T> | T[]>(
    configuration?.meta.endpoint + (additionalPartsOfQuery.length ? `?${additionalPartsOfQuery.join("&")}` : "")
  );

  return { data: (data as PaginatedResponse<T>)?.data || (data as T[]), isLoading, isError };
};
