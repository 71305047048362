import { MatchStatus } from "types/entities/Match";

import { FacetsComponentsEnum, FacetSectionConfigurationStaticItem } from "modules/FacetsModule/FacetsModule.types";
import { GenericTaxonomyClass, TaxonomyClass } from "modules/TaxonomyModule/consts";

export const MATCHING_FACETS_KEY = "matchingFacets";

export const matchingFacetsKeys = {
  STATUS: "status",
  ACTIVE_INGREDIENT: TaxonomyClass.ACTIVE_INGREDIENT,
  BREAD_SIZE: TaxonomyClass.BREED_SIZE,
  CLASS: GenericTaxonomyClass,
  DIAGNOSTIC_COMPONENT: TaxonomyClass.DIAGNOSTIC_COMPONENT,
  DIAGNOSTIC_DEVICE: TaxonomyClass.DIAGNOSTIC_DEVICE,
  DIAGNOSTIC_PANEL: TaxonomyClass.DIAGNOSTIC_PANEL,
  DIAGNOSTIC_TEST_LOCATION: TaxonomyClass.DIAGNOSTIC_TEST_LOCATION,
  DRUG_CLASS: TaxonomyClass.DRUG_CLASS,
  FLAVOR: TaxonomyClass.FLAVOR,
  FOOD_BRAND: TaxonomyClass.FOOD_BRAND,
  FOOD_CLASS: TaxonomyClass.FOOD_CLASS,
  FOOD_FORM: TaxonomyClass.FOOD_FORM,
  FOOD_PRODUCT_LINE: TaxonomyClass.FOOD_PRODUCT_LINE,
  MANUFACTURER: TaxonomyClass.MANUFACTURER,
  MEDICAL_BRAND: TaxonomyClass.MEDICAL_BRAND,
  OTC_ACTIVE_INGREDIENT: TaxonomyClass.OTC_ACTIVE_INGREDIENT,
  OTHER: TaxonomyClass.OTHER,
  PACK_COLOR: TaxonomyClass.PACK_COLOR,
  PANEL_TYPE: TaxonomyClass.PANEL_TYPE,
  PHYSICAL_FORM: TaxonomyClass.PHYSICAL_FORM,
  RETAIL_BRAND: TaxonomyClass.RETAIL_BRAND,
  RETAIL_CLASS: TaxonomyClass.RETAIL_CLASS,
  ROUTE_OF_ADMINISTRATION: TaxonomyClass.ROUTE_OF_ADMINISTRATION,
  SERVICE_CLASS: TaxonomyClass.SERVICE_CLASS,
  SPECIES: TaxonomyClass.SPECIES,
  TRANSACTION_TYPE: TaxonomyClass.TRANSACTION_TYPE,
  TREATMENT_INDICATION: TaxonomyClass.TREATMENT_INDICATION,
};

const statusFacetConfig: FacetSectionConfigurationStaticItem = {
  key: matchingFacetsKeys.STATUS,
  titleTranslationKey: "matching.facets.item.status.title",
  type: FacetsComponentsEnum.FLAT_TREE,
  isStatic: true,
};

export const initialFacetKeysConfig = [
  matchingFacetsKeys.STATUS,
  matchingFacetsKeys.CLASS,
  matchingFacetsKeys.TRANSACTION_TYPE,
];

export const medicalProductFacetKeysConfig = [
  ...initialFacetKeysConfig,
  matchingFacetsKeys.DRUG_CLASS,
  matchingFacetsKeys.MANUFACTURER,
  matchingFacetsKeys.MEDICAL_BRAND,
  matchingFacetsKeys.ACTIVE_INGREDIENT,
  matchingFacetsKeys.PACK_COLOR,
  matchingFacetsKeys.FLAVOR,
  matchingFacetsKeys.PHYSICAL_FORM,
  matchingFacetsKeys.ROUTE_OF_ADMINISTRATION,
  matchingFacetsKeys.SPECIES,
  matchingFacetsKeys.TREATMENT_INDICATION,
];
export const foodProductFacetKeysConfig = [
  ...initialFacetKeysConfig,
  matchingFacetsKeys.FOOD_CLASS,
  matchingFacetsKeys.MANUFACTURER,
  matchingFacetsKeys.FOOD_BRAND,
  matchingFacetsKeys.FOOD_PRODUCT_LINE,
  matchingFacetsKeys.PACK_COLOR,
  matchingFacetsKeys.FLAVOR,
  matchingFacetsKeys.FOOD_FORM,
  matchingFacetsKeys.SPECIES,
  matchingFacetsKeys.TREATMENT_INDICATION,
];

export const retailProductFacetKeysConfig = [
  ...initialFacetKeysConfig,
  matchingFacetsKeys.RETAIL_CLASS,
  matchingFacetsKeys.RETAIL_BRAND,
  matchingFacetsKeys.PHYSICAL_FORM,
  matchingFacetsKeys.SPECIES,
  matchingFacetsKeys.BREAD_SIZE,
  matchingFacetsKeys.OTC_ACTIVE_INGREDIENT,
  matchingFacetsKeys.ROUTE_OF_ADMINISTRATION,
  matchingFacetsKeys.MANUFACTURER,
  matchingFacetsKeys.TRANSACTION_TYPE,
];

export const diagnosticFacetKeysConfig = [
  ...initialFacetKeysConfig,
  matchingFacetsKeys.PANEL_TYPE,
  matchingFacetsKeys.MANUFACTURER,
  matchingFacetsKeys.DIAGNOSTIC_COMPONENT,
  matchingFacetsKeys.DIAGNOSTIC_DEVICE,
  matchingFacetsKeys.DIAGNOSTIC_PANEL,
  matchingFacetsKeys.DIAGNOSTIC_TEST_LOCATION,
];

export const unknownFacetKeysConfig = [
  ...initialFacetKeysConfig,
  matchingFacetsKeys.SERVICE_CLASS,
  matchingFacetsKeys.MANUFACTURER,
  matchingFacetsKeys.PACK_COLOR,
  matchingFacetsKeys.FLAVOR,
  matchingFacetsKeys.PHYSICAL_FORM,
  matchingFacetsKeys.FOOD_FORM,
  matchingFacetsKeys.ROUTE_OF_ADMINISTRATION,
  matchingFacetsKeys.SPECIES,
  matchingFacetsKeys.TREATMENT_INDICATION,
  matchingFacetsKeys.OTHER,
];

export const facetSectionsConfigurationTransactions = [
  statusFacetConfig,
  {
    key: matchingFacetsKeys.CLASS,
    titleTranslationKey: "matching.facets.item.info_type.title",
    type: FacetsComponentsEnum.FLAT_TREE,
    isStatic: true,
  },
  {
    key: matchingFacetsKeys.DRUG_CLASS,
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.DRUG_CLASS,
  },
  {
    key: matchingFacetsKeys.FOOD_CLASS,
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.FOOD_CLASS,
  },
  {
    key: matchingFacetsKeys.RETAIL_CLASS,
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.RETAIL_CLASS,
  },
  {
    key: matchingFacetsKeys.SERVICE_CLASS,
    type: FacetsComponentsEnum.TREE,
    taxonomyClass: TaxonomyClass.SERVICE_CLASS,
  },
  {
    key: matchingFacetsKeys.PANEL_TYPE,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PANEL_TYPE,
  },
  {
    key: matchingFacetsKeys.MEDICAL_BRAND,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MEDICAL_BRAND,
  },
  {
    key: matchingFacetsKeys.FOOD_BRAND,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_BRAND,
  },
  {
    key: matchingFacetsKeys.FOOD_PRODUCT_LINE,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_PRODUCT_LINE,
  },
  {
    key: matchingFacetsKeys.RETAIL_BRAND,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.RETAIL_BRAND,
  },
  {
    key: matchingFacetsKeys.PHYSICAL_FORM,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PHYSICAL_FORM,
  },
  {
    key: matchingFacetsKeys.FOOD_FORM,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FOOD_FORM,
  },
  {
    key: matchingFacetsKeys.PACK_COLOR,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.PACK_COLOR,
  },
  {
    key: matchingFacetsKeys.SPECIES,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.SPECIES,
  },
  {
    key: matchingFacetsKeys.BREAD_SIZE,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.BREED_SIZE,
  },
  {
    key: matchingFacetsKeys.ROUTE_OF_ADMINISTRATION,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ROUTE_OF_ADMINISTRATION,
  },
  {
    key: matchingFacetsKeys.FLAVOR,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.FLAVOR,
  },
  {
    key: matchingFacetsKeys.ACTIVE_INGREDIENT,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.ACTIVE_INGREDIENT,
  },
  {
    key: matchingFacetsKeys.OTC_ACTIVE_INGREDIENT,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.OTC_ACTIVE_INGREDIENT,
  },
  {
    key: matchingFacetsKeys.TREATMENT_INDICATION,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.TREATMENT_INDICATION,
  },
  {
    key: matchingFacetsKeys.MANUFACTURER,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.MANUFACTURER,
  },
  {
    key: matchingFacetsKeys.DIAGNOSTIC_TEST_LOCATION,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_TEST_LOCATION,
  },
  {
    key: matchingFacetsKeys.DIAGNOSTIC_COMPONENT,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_COMPONENT,
  },
  {
    key: matchingFacetsKeys.DIAGNOSTIC_DEVICE,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_DEVICE,
  },
  {
    key: matchingFacetsKeys.DIAGNOSTIC_PANEL,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.DIAGNOSTIC_PANEL,
  },
  {
    key: matchingFacetsKeys.TRANSACTION_TYPE,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.TRANSACTION_TYPE,
  },
  {
    key: matchingFacetsKeys.OTHER,
    type: FacetsComponentsEnum.FLAT_TREE,
    taxonomyClass: TaxonomyClass.OTHER,
  }
];

export const facetSectionsConfigurationSpecies = [statusFacetConfig];

export const initialFacets = {
  [matchingFacetsKeys.STATUS]: [MatchStatus.NOMATCH, MatchStatus.CONFLICT, MatchStatus.MOVED, MatchStatus.REMATCH],
};
