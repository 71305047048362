import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getItemClassFromItemId } from "helpers/getItemClassFromItemId";
import { getTaxonomyCreatePath } from "router/path";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { MatchingCatalogLink } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCatalogLink";

import { MatchingDuplicateButtonProps } from "./MatchingDuplicateButton.types";

export const MatchingDuplicateButton = React.memo<MatchingDuplicateButtonProps>(({ itemID, className }) => {
  const translations = useTranslations();
  const enabledForRole = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_MATCH);

  const [taxonomyClass] = getItemClassFromItemId(itemID);
  const taxonomyItemConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

  if (!enabledForRole || !taxonomyItemConfig?.meta.creatable) return null;

  return (
    <MatchingCatalogLink className={className} to={getTaxonomyCreatePath(taxonomyClass, itemID)}>
      <Button icon={<CopyOutlined />} className="w-full">
        {translations["matching.target.buttons.duplicate"]}
      </Button>
    </MatchingCatalogLink>
  );
});
