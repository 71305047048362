import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { CatalogPage } from "pages/CatalogPage";
import { LogoPage } from "pages/LogaPage";
import { JobsRoutes } from "router/routes/JobsRoutes";
import { MatchingRoutes } from "router/routes/MatchingRoutes";

import { Layout } from "ui/Layout";

import { TaxonomyRoutes } from "./routes/TaxonomyRoutes";
import { catalogPath, logoPagePath, matchingPath, rootPath } from "./path";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path={rootPath} element={<Navigate replace to={matchingPath} />} />
      <Route path={logoPagePath} element={<LogoPage />} />
      <Route path={catalogPath} element={<CatalogPage />} />
      {JobsRoutes}
      {TaxonomyRoutes}
      {MatchingRoutes}
    </Route>
  )
);

export const MainRouter = () => {
  return <RouterProvider router={router} />;
};
