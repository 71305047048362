import React from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Routes } from "react-router-dom";
import { Alert } from "antd";
import { queries } from "consts";
import { TaxonomyRoutes } from "router/routes/TaxonomyRoutes";

import { CatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { CatalogContextType } from "modules/CatalogModule/CatalogModule.types";
import { useInformationSourceInSearchParams } from "modules/MatchingModule/MatchingInformationSourceModule/hooks/useInformationSourceInSearchParams";
import {
  useCatalogMandatoryFilters
} from "modules/MatchingModule/MatchingListModule/components/MatchingTargets/hooks/useCatalogMandatoryFilters";
import { useMatchingTargetsDrawerHeights } from "modules/MatchingModule/MatchingListModule/hooks/useMatchingTargetsDrawerHeights";
import { useModifiedMatchingPaths } from "modules/MatchingModule/MatchingListModule/hooks/useModifiedMatchingPaths";
import { MatchingCatalogDetailHeaderControls } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCatalogDetailHeaderControls";
import { MatchingCatalogLink } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCatalogLink";
import { MatchingCatalogListRowControls } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCatalogListRowControls";
import { MatchingCatalogNotification } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCatalogNotification";
import { MatchingCreateMatchButton } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingCreateMatchButton";
import { MatchingTargetList } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetList";
import { useMatchingTargetsClassTabsContext } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsClassTabs/MatchingTargetsClassTabs.context";
import { useMatchItem } from "modules/MatchingModule/MatchingTargetsModule/hooks/useMatchItem";
import { MatchingTargetsDiagnosticsModule } from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule";

import { useScrollToTop } from "./hooks/useScrollToTop";

const withCatalogRoutes = (WrappedComponent: Array<React.ReactElement>) => {
  return WrappedComponent.map((route) => {
    const { getKeyWithCatalog, getRouteWithCatalog } = useModifiedMatchingPaths();
    const updatedKey = getKeyWithCatalog(route.key || "");
    const updatedPath = getRouteWithCatalog(route.props.path);
    return React.cloneElement(route, { key: updatedKey, path: updatedPath });
  });
};

const defaultCatalogProviderValue: Pick<CatalogContextType, "getFacetsKey" | "components"> = {
  getFacetsKey: () => "taxonomyMatchingFacets",
  components: {
    CatalogLink: MatchingCatalogLink,
    ListRowControls: MatchingCatalogListRowControls,
    DetailHeaderControls: MatchingCatalogDetailHeaderControls,
    CreateFormControls: MatchingCreateMatchButton,
    ListNotification: MatchingCatalogNotification,
  },
};

export const MatchingTargets = React.memo(() => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { match } = useMatchItem();

  const minLG = useMediaQuery({ query: queries.lg });
  const { headDrawerHeight, mainDrawerHeight } = useMatchingTargetsDrawerHeights();
  const { countryValue } = useInformationSourceInSearchParams();
  const { isDiagnosticTabSelected, activeTaxonomyClass } = useMatchingTargetsClassTabsContext();

  const getFacetsPos = React.useCallback(() => {
    if (minLG) {
      return {
        top: 0,
        height: containerRef.current ? `${containerRef.current.clientHeight}px` : "80vh",
      };
    } else {
      return {
        top: headDrawerHeight,
        height: `${mainDrawerHeight}px`,
      };
    }
  }, [containerRef, headDrawerHeight, mainDrawerHeight, minLG]);

  const mandatoryAdditionalFilter = useCatalogMandatoryFilters();
  const catalogProviderValue = React.useMemo(
    () => ({
      ...defaultCatalogProviderValue,
      callbacks: {
        createItemCallback: match,
      },
      getFacetsPos,
      market: countryValue,
      mandatoryAdditionalFilter,
    }),
    [match, getFacetsPos, countryValue, mandatoryAdditionalFilter]
  );

  useScrollToTop(containerRef);

  return (
    <div ref={containerRef} className="lg:overflow-y-auto">
      <Alert.ErrorBoundary>
        <CatalogContext.Provider value={catalogProviderValue}>
          <Routes>
            <Route
              index
              element={
                isDiagnosticTabSelected
                  ? <MatchingTargetsDiagnosticsModule />
                  : <MatchingTargetList activeTaxonomyClass={activeTaxonomyClass} />
              }
            />
            {withCatalogRoutes(TaxonomyRoutes)}
          </Routes>
        </CatalogContext.Provider>
      </Alert.ErrorBoundary>
    </div>
  );
});
