import { MatchStatus } from "types/entities/Match";

import React from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMessage } from "hooks/useMessage";
import { useTranslations } from "translations/useTranslations";

import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";

export const MatchingCancelCreationRequestButton = React.memo(() => {
  const translations = useTranslations();
  const { showSuccess } = useMessage();
  const { patchSelectedGroups } = useMatchingContext();

  const cancelCreationRequest = React.useCallback(async () => {
    await patchSelectedGroups({ status: MatchStatus.NOMATCH });
    showSuccess(translations["matching.messages.request_item.cancel.success"]);
  }, [patchSelectedGroups, showSuccess, translations]);

  return (
    <Button type="text" icon={<UndoOutlined />} onClick={cancelCreationRequest} className="w-full">
      {translations["matching.buttons.request_item.cancel"]}
    </Button>
  );
});
