import { ItemID } from "types/common";

import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

export enum FacetsComponentsEnum {
  FLAT_TREE = "flatTree",
  TREE = "tree",
  RANGE = "range",
}

export type generateApiUrlType = (facetKey: string) => string;

type FacetSectionConfigurationItemCommon = {
  key: string;
  type: FacetsComponentsEnum;
  titleTranslationKey?: string;
  meta?: {
    isImmutable?: boolean;
    isHidden?: boolean;
  }
}

export type FacetSectionConfigurationStaticItem = FacetSectionConfigurationItemCommon & {
  titleTranslationKey: string;
  isStatic: true;
}

export type FacetSectionConfigurationRelationItem = FacetSectionConfigurationItemCommon & {
  taxonomyClass?: string;
}

export type FacetSectionConfigurationPropItem = FacetSectionConfigurationItemCommon & {
  taxonomyClassPropKey: string;
};

export type FacetSectionConfigurationItem =
  | FacetSectionConfigurationStaticItem
  | FacetSectionConfigurationRelationItem
  | FacetSectionConfigurationPropItem;

export type FacetSectionConfiguration = FacetSectionConfigurationItem[];

export type FacetsModuleType = {
  facetsKey: string;
  generateApiUrl: generateApiUrlType;
  urlQueryService: UrlQueryService;
  allowedKeys?: string[];
};

export type FacetsValueFlatTreeType = {
  type: string;
  value: { name?: string; id: ItemID; count: number }[];
};

export type FacetsValueRangeType = {
  type: string;
  value: [[number, number]];
};
export type FacetsValueElementType = FacetsValueFlatTreeType | FacetsValueRangeType;

export type FacetsValuesType<T> = {
  [facetSectionKey: string]: T;
};
export type FacetsValuesCommonType = FacetsValuesType<FacetsValueElementType>;
export type FacetsValuesFlatTreeType = FacetsValuesType<FacetsValueFlatTreeType>;
export type FacetsValuesRangeType = FacetsValuesType<FacetsValueRangeType>;

export type FacetSectionChangeType = (facetKey: string, facetValues: string[]) => void;

export type NodeTree = {
  title: string | JSX.Element;
  key: string;
  parent?: NodeTree | null;
  children?: NodeTree[];
  count?: number;
};
