import { Modal } from "antd";
import { useTranslations } from "translations/useTranslations";

export const useShowCompleteConfirm = (
  completeJob: () => Promise<{}>
) => {
  const translations = useTranslations();

  return () => {
    Modal.confirm({
      title: translations["jobs.complete.popup.title"],
      content: translations["jobs.complete.popup.content"],
      okText: translations["jobs.complete.popup.button.ok"],
      onOk: completeJob,
      onCancel() {},
    });
  }
};
