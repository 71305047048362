import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PopupRequest,
  PublicClientApplication
} from "@azure/msal-browser";
import { BASE_AUTHORITY, CLIENT_ID, SCOPE, TENANT_ID } from "services/AuthService/consts";

export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: `${BASE_AUTHORITY}/${TENANT_ID}`,
    knownAuthorities: [BASE_AUTHORITY],
  }
};

export const getAuthRequest = (): PopupRequest => {
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  const redirectUri = `${protocol}//${domain}${port ? ":" + port : ""}`;

  return {
    scopes: [`${CLIENT_ID}/${SCOPE}`],
    redirectUri
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
