import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { defaultTimeToLive } from "consts";

import { QueryKeys } from "api/queryKeys";
import { expandWithKeys, getUnlimitedPaginationParams, taxonomyUrl } from "api/rootUrl";

import { PaginationResultType } from "./useGetListOfTaxonomyByTaxonomyClass.types";

const getListOfAllTaxonomyByName = (taxonomyClass = "", expand?: string) => () =>
  axios
    .get<PaginationResultType>(
      `${taxonomyUrl}${taxonomyClass?.toLowerCase()}/?${getUnlimitedPaginationParams()}${expand ? `&${expand}` : ""}`)
    .then((res) => res.data);

export const useGetListOfAllTaxonomyByTaxonomyClassWithCache = (
  taxonomyClass: string,
  params?: { expand?: boolean | string | string[]; enabled?: boolean }
) => {
  const { expand, enabled: enabledParam = true } = params || {};
  const enabled = enabledParam && !!taxonomyClass;
  const expandString = expand ? expandWithKeys(typeof expand === "boolean" ? expand.toString() : expand) : "";

  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKeys.GET_LIST_TAXONOMY_ITEMS, taxonomyClass, expandString],
    queryFn: getListOfAllTaxonomyByName(taxonomyClass, expandString),
    enabled,
    ...defaultTimeToLive,
  });

  return { data: data?.data, isLoading: Boolean(isLoading && enabled), isError };
};
