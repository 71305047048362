import { MatchGroup } from "types/entities/Match";

import React from "react";
import { useParams } from "react-router-dom";
import { MatchingParamsType } from "router/navigationTypes";

import { useMatchMultipleGroups } from "modules/MatchingModule/api/useMatchMultipleGroups";

export const useMemoMatchGroups = () => {
  const { jobId = "", datasetName = "" } = useParams<MatchingParamsType>();
  const [lastMatchedGroups, setLastMatchedGroups] = React.useState<MatchGroup[]>([]);

  const { updateMultipleGroups } = useMatchMultipleGroups({ jobId, datasetName });

  const putToMemory = React.useCallback((groups: MatchGroup[]) => {
    setLastMatchedGroups(groups);
  }, []);

  const restoreFromMemory = React.useCallback(async (matchGroupsListSize: number) => {
    await updateMultipleGroups(lastMatchedGroups, matchGroupsListSize);
    return lastMatchedGroups;
  }, [lastMatchedGroups, updateMultipleGroups]);

  const dropMemory = React.useCallback(() => {
    setLastMatchedGroups([]);
  }, []);

  const canUndo = lastMatchedGroups.length > 0;

  return { putToMemory, restoreFromMemory, dropMemory, canUndo };
}
