import { TaxonomyItem, TaxonomyStatus } from "types/entities/Taxonomy";

import { CascaderProps } from "antd";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const getCurrentOptionTitleAndIgnored = (
  selectedData: (string | number)[],
  options: CascaderProps["options"] = [],
  drugClasses: TaxonomyItem[],
  foodClasses: TaxonomyItem[]
): { title: string; isIgnored?: boolean } => {
  const firstOption = options.find((el) => el.value === selectedData[0]);
  const lastOption = firstOption?.value === TaxonomyClass.MEDICAL_PRODUCT
    ? drugClasses.find(({ id }) => id === selectedData.at(-1))
    : foodClasses.find(({ id }) => id === selectedData.at(-1));

  return {
    title: lastOption?.name || firstOption?.label,
    isIgnored: lastOption?.status === TaxonomyStatus.ignored,
  };
}
