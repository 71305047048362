import { useState } from "react";
import { usePrompt } from "hooks/usePrompt";

import { useDiscardText } from "modules/JobsModule/JobsCreateFormModule/components/DiscardButton";

export const useDiscardPromptOnLeave = () => {
  const [shouldPrevent, setShouldPrevent] = useState(false);

  const { title, content } = useDiscardText();
  usePrompt(`${title}\n${content}`, shouldPrevent);

  return { setShouldPrevent };
};
