import { ItemID } from "types/common";

import React from "react";
import { Alert } from "antd";
import { useTranslations } from "translations/useTranslations";

export const NoNameError = React.memo<{ itemID?: ItemID }>(({ itemID }) => {
  const translations = useTranslations();

  return (
    <Alert type="error" message={`${translations["item.error.no_name"]}${itemID ? ` ${itemID}` : ""}`} showIcon />
  );
});
