import { RenderFunction } from "modules/TaxonomyModule/TaxonomyTableModule/helpers/getRenderFunction";

import { CustomSpeciesColumn } from "./CustomSpeciesColumn";

export const SPECIES_COLUMN_KEY = "species";
export const customSpeciesColumn: { [key: string]: RenderFunction } = {
  [SPECIES_COLUMN_KEY]: (_, taxonomyItem) => {
    return <CustomSpeciesColumn taxonomyItem={taxonomyItem} />;
  },
};
