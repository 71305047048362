import { UIConfigurationColumnsMapType } from "types/UIConfigurationColumnsTypes";

import { configurationServiceInstance } from "services/ConfigurationService";

import { expandWithKeys } from "api/rootUrl";

import { UIConfigurationTemplatesRead } from "modules/GenerateByUIConfigurationModule/UIConfiguration";

import { getFilteredRelationsKeys } from "./getFilteredRelationsKeys";
import { getKeysFromTemplate } from "./getKeysFromTemplate";

export const getRelationsKeysForTaxonomyTable = (
  taxonomyClass: string,
  UIConfigurationColumns: UIConfigurationColumnsMapType
) => {
  const configuration = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  if (!configuration) return "";

  // TODO: think about using getRelationsKeysForTaxonomyItemName for nameTemplate
  const nameTemplate = UIConfigurationTemplatesRead[taxonomyClass.toLowerCase()]?.name || "";
  const columnsTemplates = (UIConfigurationColumns[taxonomyClass.toLowerCase()] || [])
    .map((el) => el.templates || [])
    .flat(1);

  const columnsKeys = (UIConfigurationColumns[taxonomyClass.toLowerCase()] || []).map((el) => el.key || []).flat(1);

  const configurationTemplatesKeys = [...columnsTemplates, nameTemplate].map(getKeysFromTemplate).flat(1);

  const relationKeys = Object.keys(configuration.meta.relations || {});
  const allKeys = getFilteredRelationsKeys([...relationKeys, ...configurationTemplatesKeys, ...columnsKeys]);

  return expandWithKeys(Array.from(new Set(allKeys)));
};
