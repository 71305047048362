import { SynonymType } from "types/entities/Synonym";

import React from "react";
import type { TabsProps } from "antd";
import { Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { SynonymListModule } from "modules/SynonymModule/SynonymListModule/SynonymListModule";

import { TabsWrapper } from "components/TabsWrapper";

import { SynonymModuleProps } from "./SynonymModule.types";

const synonymTabLabels: Record<SynonymType, string> = {
  [SynonymType.EXACT]: "Exact matches",
  [SynonymType.WILDCARD]: "Synonyms",
  [SynonymType.EXCLUSION]: "Exclusions",
};

export const SynonymModule = React.memo<SynonymModuleProps>((props) => {
  const { taxonomyItemId, availableSynonymTypes } = props;

  const translations = useTranslations();

  const tabItems: TabsProps["items"] = React.useMemo(() => {
    if (!availableSynonymTypes?.length || !taxonomyItemId) return [];

    return availableSynonymTypes.map((synonymType) => {
      return {
        key: synonymType,
        label: synonymTabLabels[synonymType],
        children: <SynonymListModule taxonomyItemId={taxonomyItemId} synonymType={synonymType} />,
      };
    });
  }, [availableSynonymTypes, taxonomyItemId]);

  if (!availableSynonymTypes?.length) return null;

  return (
    <div>
      <Typography.Title level={4}>{translations["synonym.title"]}</Typography.Title>
      <TabsWrapper items={tabItems} />
    </div>
  );
});
