import { Key } from "react";
import { useLocation } from "react-router-dom";

import { CATALOG_PATH_SEPARATOR } from "modules/MatchingModule/consts/paths";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";

const combineUrlWithQuery = (url: string, query: string) => {
  return `${url}${url.includes("?") ? "&" : "?"}${query}`;
}

export const useModifiedMatchingPaths = () => {
  const { pathname } = useLocation();
  const urlQueryService = useMatchingUrlQueryService();

  const matchingSearchParams = urlQueryService.getQueryStringWithoutOtherQueries();
  const allSearchParams = urlQueryService.getAllSearchParams().toString();

  return {
    getKeyWithCatalog: (key: Key) => `${CATALOG_PATH_SEPARATOR}${key}`,
    getRouteWithCatalog: (route: string) => `${CATALOG_PATH_SEPARATOR}${route}`,
    getMatchingPathWithSearch: () => combineUrlWithQuery(pathname.split(CATALOG_PATH_SEPARATOR)[0], matchingSearchParams),
    getMatchingCatalogPathWithMatchingSearch: (to: string) => combineUrlWithQuery(
      `${pathname.split(CATALOG_PATH_SEPARATOR)[0]}/${CATALOG_PATH_SEPARATOR}${to}`,
      matchingSearchParams
    ).replace(/([^:]\/)\/+/g, "$1"),
    getMatchingCatalogPathWithAllSearch: (to: string) => combineUrlWithQuery(
      `${pathname.split(CATALOG_PATH_SEPARATOR)[0]}/${CATALOG_PATH_SEPARATOR}${to}`,
      allSearchParams
    ).replace(/([^:]\/)\/+/g, "$1")
  }
}
