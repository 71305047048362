import React from "react";
import { EMPTY } from "consts/strings";
import { useTranslations } from "translations/useTranslations";

import { DateAndUserProps } from "modules/MatchingModule/MatchingTargetSpecialModule/components/DateAndUser/DateAndUser.types";

import { SecondaryLabelledList } from "components/SecondaryLabelledList";

const getDateString = (dates: Date[]) => {
  if (!dates.length) return "";
  const numericDates = dates.map((date) => date.getTime());

  const minDate = new Date(Math.min(...numericDates));
  const maxDate = new Date(Math.max(...numericDates));

  const minDateString = minDate && minDate.toLocaleDateString();
  const maxDateString = maxDate && maxDate.toLocaleDateString();

  if (minDateString === maxDateString) return minDateString;

  return `${minDateString} - ${maxDateString}`;
}

export const DateAndUser = React.memo<DateAndUserProps>((props) => {
  const { users, dates } = props;
  const translations = useTranslations();

  const dateString = React.useMemo(() => getDateString(dates.map((date) => new Date(date))), [dates]);

  const items = React.useMemo(() => [
    {
      title: translations["matching.special.label.updated.date"],
      value: dateString || EMPTY,
    },
    {
      title: translations["matching.special.label.updated.user"],
      value: users.join(", ") || EMPTY,
    }
  ], [dateString, translations, users]);

  return <SecondaryLabelledList items={items} />;
});
