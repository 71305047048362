import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, theme } from "antd";
import { useTranslations } from "translations/useTranslations";

const { useToken } = theme;

export const DeleteSynonymButton = React.memo<{ handleDelete: () => void }>(({ handleDelete }) => {
  const translations = useTranslations();
  const { token } = useToken();

  return (
    <Popconfirm
      title={translations["synonym.delete.confirm.title"]}
      onConfirm={handleDelete}
      okText={translations["synonym.delete.confirm.button.ok"]}
      cancelText={translations["synonym.delete.confirm.button.cancel"]}
      placement="bottomRight"
      rootClassName="max-w-[200px]"
    >
      <Button type="text">
        <DeleteOutlined width={16} height={16} style={{ color: token.colorError }} />
      </Button>
    </Popconfirm>
  );
});
