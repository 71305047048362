import { getStringFromSearchParams } from "helpers/facetHelpers/getStringFromSearchParams";

import { FacetSectionConfiguration } from "./FacetsModule.types";

type SearchParamsArrayType = [string, string[]][];

export type FacetsServiceType = {
  setConfiguration: (newFacetSectionsConfiguration: FacetSectionConfiguration) => void;
  getFacetConfiguration: () => FacetSectionConfiguration;
  getFacetMap: () => { [facetKey: string]: string[] };
  setByKey: (facetKey: string, facetValues: string[]) => void;
  getValuesByKey: (facetKey: string) => string[];

  getArrayFromSearchParams: (searchParams: URLSearchParams) => SearchParamsArrayType;

  writeFromArrayToFacetMap: (searchParamsArray: SearchParamsArrayType) => void;
  getStringFromFacetMap: () => string;

  reset: () => void;
};

export const createFacetsService = (): FacetsServiceType => {
  const emptyValues: string[] = [];
  let facetMap = {} as { [facetKey: string]: string[] };

  let facetSectionsConfiguration: FacetSectionConfiguration = [];

  return {
    getFacetConfiguration: () => facetSectionsConfiguration,
    getFacetMap: () => facetMap,
    setByKey: (facetKey, facetValues) => {
      facetMap[facetKey] = facetValues;
    },
    getValuesByKey: (facetKey) => {
      return facetMap[facetKey] || emptyValues;
    },

    setConfiguration: (newFacetSectionsConfiguration: FacetSectionConfiguration) => {
      facetSectionsConfiguration = newFacetSectionsConfiguration;
    },

    getArrayFromSearchParams: (searchParams) => {
      const searchParamsArray: SearchParamsArrayType = [];
      const keys = facetSectionsConfiguration.map(({ key }) => key);
      searchParams.forEach((value, key) => {
        if (keys.length === 0 || keys.includes(key)) {
          const facetValues = value.split(",");
          searchParamsArray.push([key, facetValues]);
        }
      });

      return searchParamsArray;
    },

    writeFromArrayToFacetMap: (searchParamsArray) => {
      facetMap = searchParamsArray.reduce<{ [facetKey: string]: string[] }>((acc, [facetKey, facetValues]) => {
        acc[facetKey] = facetValues;
        return acc;
      }, {});
    },
    getStringFromFacetMap: () => {
      return getStringFromSearchParams(Object.entries(facetMap || {}));
    },

    reset: () => {
      facetMap = facetSectionsConfiguration.reduce((acc, { key }) => ({ ...acc, [key]: [] }), {});
    },
  };
};

export const FacetsManagerService = (() => {
  const storeOfFacetsServices: { [facetsKey: string]: FacetsServiceType } = {};
  return {
    getServiceByFacetsKey: (facetsKey: string) => {
      if (!storeOfFacetsServices[facetsKey]) {
        storeOfFacetsServices[facetsKey] = createFacetsService();
      }
      return storeOfFacetsServices[facetsKey];
    },
  };
})();
