import { TaxonomyStatus } from "types/entities/Taxonomy";

import React from "react";
import { Tag, Tooltip } from "antd";
import { useTranslations } from "translations/useTranslations";

const mapStatusToColor = {
  [TaxonomyStatus.deactivated]: { color: "default", textKey: "item.form.status.deactivated.label" },
  [TaxonomyStatus.ignored]: { color: "red", textKey: "item.form.status.ignored.label" },
};

const mapStatusToTooltipKey = {
  [TaxonomyStatus.deactivated]: "tag.deactivated.tooltip",
  [TaxonomyStatus.ignored]: "tag.ignored.tooltip",
};

export const TaxonomyItemStatusTag = React.memo<{
  status?: TaxonomyStatus;
  showTooltip?: boolean;
}>(({ status, showTooltip = true }) => {
  const translations = useTranslations();

  if (!status) return null;
  if (status === TaxonomyStatus.active) return null;

  const { color, textKey } = mapStatusToColor[status];

  const tag = <Tag color={color}>{translations[textKey]}</Tag>;

  return showTooltip ? <Tooltip title={translations[mapStatusToTooltipKey[status]]} className="cursor-help">{tag}</Tooltip> : tag;
});
