import React from "react";
import { configurationServiceInstance } from "services/ConfigurationService";

import { useGetConfiguration } from "./api/useGetConfiguration";

import { Preloader, PreloaderType } from "ui/Preloader";

export const InitialConfigurationLoader: React.FC<{
  MainRouter: () => JSX.Element
}> = ({ MainRouter }) => {
  const { isLoading } = useGetConfiguration();

  if (isLoading || !configurationServiceInstance.isConfigurationExist()) {
    return (
      <div className="h-screen grid place-content-center">
        <Preloader type={PreloaderType.SPINNER_BLOCK} />
      </div>
    );
  }
  return <MainRouter />;
};
