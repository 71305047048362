import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { TabsProps } from "antd";
import { useIsPath } from "router/hooks/useIsPath";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyItemCodesModule } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule";
import { TaxonomyItemDetailModule } from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemDetailModule";

export const useTabItems = (taxonomyClass: string, taxonomyItem?: TaxonomyItem) => {
  const translation = useTranslations();
  const { isInCatalog } = useIsPath();

  return React.useMemo(() => {
    const config = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);

    if (!taxonomyItem) return [];

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: translation["item.tabs.details"],
        children: <TaxonomyItemDetailModule taxonomyClass={taxonomyClass} taxonomyItem={taxonomyItem} />,
      },
    ];

    if (isInCatalog && config?.meta?.codes) {
      items.push({
        key: "2",
        label: translation["item.tabs.codes"],
        children: <TaxonomyItemCodesModule itemID={taxonomyItem.id} />,
      })
    }

    return items;
  }, [isInCatalog, taxonomyClass, taxonomyItem, translation]);
}
