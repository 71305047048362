import { InstructionResultPartialType, InstructionResultType } from "../generateByUIConfiguration.types";

export const setupOrder = (
  template: string,
  instructionResult: InstructionResultPartialType[]
): InstructionResultType[] => {
  return instructionResult
    .sort((a, b) => template.indexOf(a.template.withBrackets) - template.indexOf(b.template.withBrackets))
    .map((item, index) => ({ ...item, order: index }));
};
