import React, { useMemo, useRef } from "react";
import { Alert, Empty } from "antd";
import { ColumnsType } from "antd/es/table";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { useActionOnAppearanceInViewport } from "hooks/useActionOnAppearanceInViewport";
import { useTranslations } from "translations/useTranslations";

import { useGetListOfTaxonomyByTaxonomyClass } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { Preloader, PreloaderType } from "ui/Preloader";
import { PaginationPreloader } from "ui/Preloader/PaginationPreloader";

import { ListOfItems } from "components/ListOfItems";

import { useCreateColumns } from "./hooks/useCreateColumns";
import { ListOfTaxonomyProps } from "./TaxonomyTableModule.types";

export const TaxonomyTableModule: React.FC<ListOfTaxonomyProps> = React.memo(({ taxonomyClass, title }) => {
  const translations = useTranslations();

  const {
    components: { ListNotification },
  } = useCatalogContext();

  const { allowedFilterParams } = useFilterContext();
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetListOfTaxonomyByTaxonomyClass(
    taxonomyClass,
    allowedFilterParams
  );

  const actionOnAppearanceInViewport = React.useCallback(
    () => !isFetchingNextPage && !isLoading && hasNextPage && fetchNextPage(),
    [isFetchingNextPage, isLoading, hasNextPage, fetchNextPage]
  );
  const divRef = useRef(null);
  useActionOnAppearanceInViewport(divRef, actionOnAppearanceInViewport);

  const columns = useCreateColumns(taxonomyClass);

  const dataWithKeys = useMemo(() => (data || []).map((el) => ({ ...el, key: el.id })), [data]);

  if (isLoading)
    return (
      <div className="flex flex-1 justify-center items-center h-auto w-full">
        <Preloader type={PreloaderType.SPINNER_BLOCK} />
      </div>
    );

  if (!data || data.length === 0 || !taxonomyClass) {
    return <Empty className="mb-4" />;
  }

  return (
    <Alert.ErrorBoundary>
      <ListOfItems columns={columns as ColumnsType<Record<string, unknown>>} data={dataWithKeys} />
      <div ref={divRef} className="h-2 mb-2" />
      {isFetchingNextPage && (
        <PaginationPreloader text={translations["item.table.load_more"].replace("{{title}}", title)} />
      )}
      {ListNotification && <ListNotification />}
    </Alert.ErrorBoundary>
  );
});
