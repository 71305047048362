import { RelationsForm, RelationsToBE } from "types/entities/Relations";


export const relationsToBEFormat = (data: RelationsForm): RelationsToBE => {
  const dataEntries = Object.entries(data);
  return dataEntries
    .map(([relationType, relationToList]) =>
      relationToList.map((relationTo) => ({
        type: relationType,
        to: relationTo,
      }))
    )
    .reduce((acc, el) => [...acc, ...el], []);
};