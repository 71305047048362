import React from "react";
import { useTranslations } from "translations/useTranslations";

import { CommonDiscardButton } from "components/CommonDiscardButton";

import { DiscardButtonProps } from "./DiscardButton.types";

export const useDiscardText = (isCreating: boolean = false, taxonomyName: string) => {
  const translations = useTranslations();

  const creationFormTextKeys = {
    title: "item.form.discard.create.title",
    content: "item.form.discard.create.content",
    okText: "item.form.discard.create.button.ok",
  };

  const editFormTextKeys = {
    title: "item.form.discard.edit.title",
    content: "item.form.discard.edit.content",
    okText: "item.form.discard.edit.button.ok",
  };

  const translationKeys = isCreating ? creationFormTextKeys : editFormTextKeys;
  const title = translations[translationKeys.title].replace("{{taxonomyName}}", taxonomyName);
  const content = translations[translationKeys.content].replace("{{taxonomyName}}", taxonomyName);
  const okText = translations[translationKeys.okText];

  return {
    title,
    content,
    okText,
  };
};

export const DiscardButton = React.memo<DiscardButtonProps>(({ isCreating, taxonomyName, setShouldPrevent }) => {
  const { title, content, okText } = useDiscardText(isCreating, taxonomyName);

  return (
    <CommonDiscardButton
      button={{ text: okText }}
      popup={{ title, content, okText }}
      setShouldPrevent={setShouldPrevent}
    />
  );
});
