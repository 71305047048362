import React from "react";
import {
  getTaxonomyClassPropTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import { useTranslations } from "translations/useTranslations";

import { TaxonomyClass } from "modules/TaxonomyModule/consts";
import { SubTitle } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/SubTitle";
import { TaxonomyItemReadRow } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadRow";
import {
  getRestPropsAndRelations
} from "modules/TaxonomyModule/TaxonomyItemReadModule/helpers/getRestPropsAndRelations";

import { OtherTaxonomyItemReadSectionProps } from "./OtherTaxonomyItemReadSection.types";

export const OtherTaxonomyItemReadSection = React.memo<OtherTaxonomyItemReadSectionProps>(({
  taxonomyClass,
  taxonomyItem,
  requiredPropsKeys,
  requiredRelationsKeys,
  fieldsToHide,
}) => {
  const translations = useTranslations();

  const { restProps } = getRestPropsAndRelations(
    taxonomyClass,
    taxonomyItem,
    requiredPropsKeys,
    requiredRelationsKeys
  );

  return (
    <>
      <SubTitle text={translations["item.details.subtitle.other"]} />
      {Object.entries(restProps)
        .filter(([key]) => !fieldsToHide?.includes(key))
        .map(([key, value]) => (
          <TaxonomyItemReadRow
            key={key}
            title={getTaxonomyClassPropTitle(TaxonomyClass.MEDICAL_PRODUCT, key)}
            value={value}
          />
        ))
      }
    </>
  );
});
