import React from "react";

const TIME_TO_REACTIVATE = 1 * 60 * 60 * 1000; // 1 hour

const getMinutesLeft = (completedDateString?: string) => {
  if (!completedDateString) return 0;

  const currentDate = new Date();
  const completedDate = new Date(completedDateString);
  const timezoneOffset = currentDate.getTimezoneOffset();

  return Math.floor((TIME_TO_REACTIVATE - (currentDate.getTime() - completedDate.getTime())) / 1000 / 60) - timezoneOffset;
}

export const useTimeTillCompleted = (completedDateString?: string) => {
  const [minutesLeft, setMinutesLeft] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newMinutesLeft = getMinutesLeft(completedDateString);
      setMinutesLeft(newMinutesLeft);
      if (newMinutesLeft <= 0) clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [completedDateString]);

  return minutesLeft;
}
