import { ResponseError } from "types/apiTypes";

import { AxiosError } from "axios";
import { useTranslations } from "translations/useTranslations";

const getDefaultError = (translations: Record<string, string>, code?: number) => {
  switch (code) {
  case 500:
    return translations["error.default.500"];
  default:
    return translations["error.default"];
  }
}

export const useResponseErrorMessage = (): (error: AxiosError<ResponseError>) => string => {
  const translations = useTranslations();

  return (error) => {
    const code = error?.response?.status;
    const defaultMessage = getDefaultError(translations, code);
    if (typeof error?.response?.data.detail !== "string") return defaultMessage;
    return error.response.data.detail || defaultMessage;
  }
}
