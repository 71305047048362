import { ItemID } from "types/common";

import React from "react";
import { EMPTY } from "consts/strings";
import { getCountryConfig } from "helpers/countryHelpers";
import { getFormattedPriceRange } from "helpers/priceHelpers";
import { getTaxonomyReadPath } from "router/path";

import { useGetItemPricesWithCache } from "api/useGetItemPrices";
import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { Preloader, PreloaderType } from "ui/Preloader";

import { CatalogLinkWithPrevent } from "components/CatalogLinkWithPrevent";

export const CustomPriceColumn = React.memo<{ itemID: ItemID }>(({ itemID }) => {
  const countries = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.MARKET);
  const { itemPrices, isLoading } = useGetItemPricesWithCache(itemID);
  const { market } = useCatalogContext();

  const filteredItemPrices = React.useMemo(() => !market
    ? itemPrices
    : itemPrices?.filter(({ country }) => country === market), [itemPrices, market]
  );

  const sortedFormattedPrices = React.useMemo(() => {
    if (!filteredItemPrices) return [];
    return filteredItemPrices
      .sort((a, b) => a.country.localeCompare(b.country))
      .map(({ price_min, price_max, country }) => {
        const { countryNameISO, currencyName } = getCountryConfig(country);
        const countryItem = countries?.data?.find((item) => item.name === country);
        const priceRange = getFormattedPriceRange(currencyName, price_min, price_max);

        if (!countryItem) {
          return (
            <span key={country}>
              <span>{countryNameISO}:</span>
              {" "}
              {priceRange}
            </span>
          );
        } else {
          return (
            <span key={country}>
              <CatalogLinkWithPrevent href={getTaxonomyReadPath(countryItem.id)} name={countryNameISO} />:
              {" "}
              {priceRange}
            </span>
          )
        }
      });
  }, [countries?.data, filteredItemPrices]);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={{ rows: 2 }} title={false} />;

  return (
    <div className="flex flex-col">
      {sortedFormattedPrices.length === 0
        ? EMPTY
        : sortedFormattedPrices.map((value, index) => (
          <div key={index}>{value}</div>
        ))}
    </div>
  );
});
