import { Job } from "types/entities/Jobs";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { QueryKeys } from "api/queryKeys";
import { getJobUrl } from "api/rootUrl";

const getJob = (jobId: string) => () =>
  axios
    .get<Job>(getJobUrl(jobId))
    .then((data) => data.data);

export const useGetJob = (jobId = "") => {
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [QueryKeys.GET_JOB_ITEM, jobId],
    queryFn: getJob(jobId),
    enabled: !!jobId,
  });

  return { job: data, isLoading, isFetching, isError };
}
