import { RenderFunction } from "modules/TaxonomyModule/TaxonomyTableModule/helpers/getRenderFunction";

import { CustomPriceColumn } from "./CustomPriceColumn";

export const PRICE_TITLE_KEY = "item.table.column.prices";
export const PRICE_COLUMN_KEY = "prices";
export const customPriceColumn: { [key: string]: RenderFunction } = {
  [PRICE_COLUMN_KEY]: (_, taxonomyItem) => {
    return <CustomPriceColumn itemID={taxonomyItem.id} />
  },
};
