import { ThemeConfig } from "antd/es/config-provider/context";

export const antdThemeConfig: ThemeConfig = {
  token: {
    // Below are colour variables taken from kynetec.com palette.
    colorLink: "#007dc3", // --c-quaternary
    colorPrimary: "#264283", // --c-primary
    borderRadius: 6,
    wireframe: false,
    colorInfo: "#007dc3", // --c-quaternary-40. Using of followings doesn't work: --c-info-40, --c-info-60
    colorBgLayout: "#ebf1ff", // --c-primary-80
    colorText: "#17284f", // --c-primary-0
    colorError: "#cc0000", // --c-error-60
    colorWarning: "#ffa100", // --c-warning-60
    colorSuccess: "#9bac93", // --c-secondary-40
    colorBgBase: "#f5f8ff", // --c-primary-90
    colorTextBase: "#17284f", // --c-primary-0
    sizeStep: 4,
    sizeUnit: 4,
    colorTextSecondary: "#264283", // --c-primary-10
    colorTextTertiary: "#405b9b", // --c-primary-20
    colorTextQuaternary: "#9ea5cd", // --c-primary-40
    colorPrimaryBgHover: "#BBCDF2", // A custom colour out of Kynetec palette. May use "--c-primary-60: #ccd9e7;" instead, but it's way too grey.
    colorPrimaryBg: "#D6E4FF", // --c-primary-70
    colorBgSpotlight: "#000",
  },
  components: {
    Tag: {
      colorFillQuaternary: "#F5F8FF", // --c-primary-90
      colorInfo: "#003b5c", // --c-quaternary-0
      colorSuccess: "#174f27", // --c-secondary-0
      colorWarning: "#8f5a00", // --c-warning-40
      colorError: "#5e1313", // --c-error-40
    },
  },
};
