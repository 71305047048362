import { useParams } from "react-router-dom";
import { getTaxonomyClassPropTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassPropTitle";
import { getTaxonomyClassTitle } from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyClassTitle";
import {
  getTaxonomyRelationTitleFromChain
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitleFromChain";
import { ListOfTaxonomyParamsType } from "router/navigationTypes";
import { useTranslations } from "translations/useTranslations";

import {
  FacetSectionConfigurationItem, FacetSectionConfigurationPropItem, FacetSectionConfigurationRelationItem,
  FacetSectionConfigurationStaticItem
} from "modules/FacetsModule/FacetsModule.types";

const getStaticFacetTitle = (
  facetSectionElement: FacetSectionConfigurationStaticItem,
  translations: Record<string, string>
) => {
  return translations[facetSectionElement.titleTranslationKey] || facetSectionElement.key;
}

const getRelationFacetTitle = (
  facetSectionElement: FacetSectionConfigurationRelationItem,
  rootTaxonomyClass: string,
) => {
  const { taxonomyClass = "" } = facetSectionElement;

  const taxonomyClassName = taxonomyClass ? getTaxonomyClassTitle(taxonomyClass) : "";
  const relationTitle = getTaxonomyRelationTitleFromChain(rootTaxonomyClass, facetSectionElement.key);

  return relationTitle || taxonomyClassName || facetSectionElement.key;
}

const getPropFacetTitle = (
  facetSectionElement: FacetSectionConfigurationPropItem,
  rootTaxonomyClass: string,
) => {
  const { taxonomyClassPropKey } = facetSectionElement;

  return taxonomyClassPropKey
    ? getTaxonomyClassPropTitle(rootTaxonomyClass, taxonomyClassPropKey)
    : facetSectionElement.key;
}

export const useFacetTitle = (facetSectionElement: FacetSectionConfigurationItem) => {
  const translations = useTranslations();
  const { taxonomyClass: rootTaxonomyClass = "" } = useParams<ListOfTaxonomyParamsType>();

  if ("isStatic" in facetSectionElement && facetSectionElement.isStatic)
    return getStaticFacetTitle(facetSectionElement, translations);

  if ("taxonomyClass" in facetSectionElement) {
    return facetSectionElement.key.toLowerCase() === facetSectionElement.taxonomyClass?.toLowerCase()
      ? getTaxonomyClassTitle(facetSectionElement.taxonomyClass)
      : getRelationFacetTitle(facetSectionElement, rootTaxonomyClass);
  }

  if ("taxonomyClassPropKey" in facetSectionElement)
    return getPropFacetTitle(facetSectionElement, rootTaxonomyClass);

  return facetSectionElement.key;
}
