import { useState } from "react";
import { usePrompt } from "hooks/usePrompt";

import { useDiscardText } from "../components/DiscardButton";

export const useDiscardPromptOnLeave = (isCreating: boolean = false, taxonomyClass: string) => {
  const [shouldPrevent, setShouldPrevent] = useState(false);

  const { title, content } = useDiscardText(isCreating, taxonomyClass);
  usePrompt(`${title}\n${content}`, shouldPrevent);

  return { setShouldPrevent };
};
