import React from "react";
import { theme, Typography } from "antd";
import clsx from "clsx";

import { SecondaryLabelledListProps } from "./SecondaryLabelledList.types";

export const SecondaryLabelledList = React.memo<SecondaryLabelledListProps>(({ items, horizontal, className }) => {
  const { token } = theme.useToken();

  return (
    <div className={className}>
      {items.map(({ title, titleAttr, value, valueTitle }) => (
        <div
          key={title}
          className={clsx("flex basis-0", !horizontal && "gap-2 items-baseline", horizontal && "flex-col grow")}
        >
          <div title={titleAttr || title} className={clsx(!horizontal && "basis-1/3 overflow-hidden max-w-[200px]")}>
            <Typography.Text
              style={{ fontSize: token.fontSizeSM, lineHeight: token.lineHeightSM }}
              type="secondary"
              className="block max-w-full uppercase overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {title}
            </Typography.Text>
          </div>
          <div title={valueTitle} className={clsx(!horizontal && "basis-2/3 grow")}>
            <Typography.Text className="break-normal">{value}</Typography.Text>
          </div>
        </div>
      ))}
    </div>
  );
});
