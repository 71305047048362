import { TaxonomyItem } from "types/entities/Taxonomy";

import { ColumnsType } from "antd/es/table";
import { useIsPath } from "router/hooks/useIsPath";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { getColumnsByConfiguration } from "../helpers/getColumnsByConfiguration";
import { getColumnTitle } from "../helpers/getColumnTitle";
import { getRenderFunction } from "../helpers/getRenderFunction";
import { useMapKeysToComponents } from "../helpers/useMapKeysToComponents";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";

import { CODE_COLUMN_KEY, CODE_TITLE_KEY } from "../components/CustomCodeColumn";
import { NAME_COLUMN_KEY } from "../components/CustomNameColumn/consts";
import { PRICE_COLUMN_KEY, PRICE_TITLE_KEY } from "../components/CustomPriceColumn/consts";
import { CustomWithTemplate } from "../components/CustomWithTemplate";
import { DEFAULT_LIST_OF_COLUMNS, UIConfigurationColumns } from "../UIConfigurationColumns";

const getFixedStatus = (keyName: string, leftFixedColumnName: string): { fixed?: "left" | "right" } => {
  if ([leftFixedColumnName].includes(keyName)) return { fixed: "left" };
  return {};
};

const getColWidth = (keyName: string): number => {
  if (keyName === NAME_COLUMN_KEY) return 120;
  return 96;
}

const useColumns = (taxonomyClass = "", leftFixedColumnName: string): ColumnsType<TaxonomyItem> => {
  const translations = useTranslations();
  const mapKeyToComponents = useMapKeysToComponents();

  const hasPrices = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass)?.meta.prices;
  const hasCodes = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass)?.meta.codes;

  const columnsConfigs = [
    ...(UIConfigurationColumns[taxonomyClass.toLowerCase()] ||
    getColumnsByConfiguration(taxonomyClass, leftFixedColumnName) ||
    DEFAULT_LIST_OF_COLUMNS)
  ];

  if (hasPrices) columnsConfigs.splice(1, 0, { title: PRICE_TITLE_KEY, key: PRICE_COLUMN_KEY });
  if (hasCodes) columnsConfigs.splice(3, 0, { key: CODE_COLUMN_KEY, title: CODE_TITLE_KEY });

  return columnsConfigs.map((columnConfig) => {
    const { key, title: titleKey = "", templates, customColumnKey } = columnConfig;

    const renderFunction = getRenderFunction(customColumnKey || key, mapKeyToComponents);
    const title = getColumnTitle(taxonomyClass, key, titleKey, translations);
    return {
      key,
      title,
      dataIndex: key,
      render: templates
        ? (_, taxonomyItem) => {
          return <CustomWithTemplate taxonomyItem={taxonomyItem} templates={templates} />;
        }
        : renderFunction,
      width: getColWidth(key),
      ...getFixedStatus(key, leftFixedColumnName),
    };
  });
};

const DEFAULT_LEFT_FIXED_COLUMN_NAME = NAME_COLUMN_KEY;
export const useCreateColumns = (
  taxonomyClass: string | undefined,
  leftFixedColumnName: string = DEFAULT_LEFT_FIXED_COLUMN_NAME
): ColumnsType<TaxonomyItem> => {
  const { components: { ListRowControls } } = useCatalogContext();
  const { isInMatching } = useIsPath();

  const columns = useColumns(taxonomyClass, leftFixedColumnName);

  if (!taxonomyClass) return [];

  if (ListRowControls) {
    const controlsColumn: ColumnsType<TaxonomyItem>[0] = {
      title: "",
      dataIndex: "id",
      align: "center",
      key: "_table_controls",
      width: 50,
      fixed: isInMatching ? "left" : "right",
      render: (itemID: string, taxonomyItem) => <ListRowControls itemID={itemID} taxonomyItem={taxonomyItem} />,
    };

    isInMatching ? columns.splice(1, 0, controlsColumn) : columns.push(controlsColumn);
  }

  return columns;
};
