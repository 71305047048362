import { ItemID } from "types/common";
import { TrimmedCode } from "types/entities/Code";

import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Typography } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { useCreateItemCode } from "./api/useCreateItemCode";
import { useGetItemCodes } from "api/useGetItemCodes";

import {
  SelectCodeType
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/components/SelectCodeType";
import {
  getConflictingCode
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/helpers/getConflictingCode";
import {
  getValidator
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/helpers/getValidator";
import {
  useShowCodeOverwriteConfirm
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/hooks/useShowCodeOverwriteConfirm";
import {
  TaxonomyItemCodeFormValueKeys, TaxonomyItemCodeFormValues
} from "modules/TaxonomyModule/TaxonomyItemReadModule/TaxonomyItemCodesModule/TaxonomyItemCodesModule.types";

export const TaxonomyItemCodesCreateModule = React.memo<{ itemID: ItemID }>(({ itemID }) => {
  const translations = useTranslations();
  const [form] = Form.useForm();

  const codes = configurationServiceInstance.getCodesConfig();
  const { itemCodes, isLoading: isCodesLoading } = useGetItemCodes(itemID);

  const showOverwriteModalConfirm = useShowCodeOverwriteConfirm();

  const { createCode, isLoading } = useCreateItemCode(itemID);

  const handleCreate = React.useCallback(async (newCode: TrimmedCode) => {
    await createCode(newCode);
    form.resetFields([TaxonomyItemCodeFormValueKeys.CODE]);
  }, [createCode, form]);

  const handleValuesChange = React.useCallback(() => {
    if (form.getFieldsError().some(({ errors }) => errors.length)) form.validateFields();
  }, [form]);

  const handleSubmit = React.useCallback(async (values: TaxonomyItemCodeFormValues) => {
    const newCode: TrimmedCode = {
      code: values[TaxonomyItemCodeFormValueKeys.CODE].trim(),
      type: values[TaxonomyItemCodeFormValueKeys.TYPE],
    }

    const conflictingCode = getConflictingCode(newCode, itemCodes || []);
    if (conflictingCode) {
      showOverwriteModalConfirm(conflictingCode, newCode, () => handleCreate(newCode));
      return;
    }

    handleCreate(newCode);
  }, [handleCreate, itemCodes, showOverwriteModalConfirm]);

  return (
    <Card>
      <div className="flex gap-4">
        <Typography.Title level={4} style={{ margin: 0 }}>{translations["item.codes.new.title"]}</Typography.Title>
        <Form
          form={form}
          className="grow"
          layout="inline"
          requiredMark={false}
          initialValues={{
            [TaxonomyItemCodeFormValueKeys.TYPE]: Object.keys(codes)[0],
          }}
          validateTrigger="onSubmit"
          onValuesChange={handleValuesChange}
          onFinish={handleSubmit}
        >
          <Form.Item
            name={TaxonomyItemCodeFormValueKeys.CODE}
            label={translations["item.codes.new.input_code.label"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator: getValidator(getFieldValue, itemCodes),
                message: translations["item.codes.error.duplicate"],
              }),
            ]}
            style={{ flex: 1 }}
          >
            <Input placeholder={translations["item.codes.new.input_code.placeholder"]} />
          </Form.Item>
          <Form.Item
            name={TaxonomyItemCodeFormValueKeys.TYPE}
            label={translations["item.codes.new.select_type.label"]}
            rules={[
              ({ getFieldValue }) => ({
                validator: getValidator(getFieldValue, itemCodes),
              }),
            ]}
          >
            <SelectCodeType />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            loading={isLoading || isCodesLoading}
          >
            {translations["item.codes.new.submit"]}
          </Button>
        </Form>
      </div>
    </Card>
  );
});
