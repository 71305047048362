import { MatchGroup } from "types/entities/Match";

import React from "react";

import { mapValuesBEtoFE } from "../helpers/mapValuesBEtoFE";

import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { MatchingTargetsDiagnosticsFormValues } from "../MatchingTargetsDiagnosticsModule.types";

const getInitialFormState = (selectedGroups: MatchGroup[]) => {
  const values: Record<string, Set<string>> = {
    [TaxonomyClass.PANEL_TYPE]: new Set(),
    [TaxonomyClass.MANUFACTURER]: new Set(),
    [TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]: new Set(),
  };

  selectedGroups.forEach((group) => {
    Object.keys(values).forEach((key) => {
      group.metadata_.prematch?.[key as TaxonomyClass]?.forEach((value) => {
        values[key].add(value);
      });
    });
  });
  return {
    [TaxonomyClass.PANEL_TYPE]: Array.from(values[TaxonomyClass.PANEL_TYPE]),
    [TaxonomyClass.MANUFACTURER]:
      values[TaxonomyClass.MANUFACTURER].size === 1 ? values[TaxonomyClass.MANUFACTURER].values().next().value : null,
    [TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]:
      values[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION].size === 1
        ? values[TaxonomyClass.DIAGNOSTIC_TEST_LOCATION].values().next().value
        : "",
  };
};

export const useInitialFormValues = (): MatchingTargetsDiagnosticsFormValues => {
  const {
    matchGroupsList,
    selectedGroupIds,
    targetItems: { final },
  } = useMatchingContext();

  return React.useMemo(() => {
    const selectedGroups = getSelectedGroups(matchGroupsList, selectedGroupIds);
    if (final?.taxonomy_item_body?.relations) return mapValuesBEtoFE(final?.taxonomy_item_body?.relations);

    return getInitialFormState(selectedGroups);
  }, [final?.taxonomy_item_body?.relations, matchGroupsList, selectedGroupIds]);
};
