import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import {
  MedicalProductProperties,
  RetailProductProperties
} from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";
import {
  DIAGNOSTIC_TEST_RELATION,
  FOOD_BRAND_RELATION,
  FOOD_PRODUCT_LINE_RELATION,
  FOOD_PRODUCT_RELATION,
  MANUFACTURER_RELATION,
  MEDICAL_BRAND_RELATION,
  MEDICAL_PRODUCT_RELATION, RETAIL_BRAND_RELATION, RETAIL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

// TODO: remove facet keys maps in the future (be aware that they are mentioned in README)

export const diagnosticTestFacetsKeys = {
  [TaxonomyClass.DIAGNOSTIC_TEST_LOCATION]: DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_LOCATION,
  [TaxonomyClass.DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS]: DIAGNOSTIC_TEST_RELATION.TO_DIAGNOSTIC_TEST_MATCHING_REQUIREMENTS,
  [TaxonomyClass.MANUFACTURER]: DIAGNOSTIC_TEST_RELATION.TO_MANUFACTURER,
  [TaxonomyClass.PANEL_TYPE]: DIAGNOSTIC_TEST_RELATION.TO_PANEL_TYPE,
};

export const foodProductFacetsKeys = {
  [TaxonomyClass.CORPORATION]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}/${FOOD_BRAND_RELATION.TO_MANUFACTURER}/${MANUFACTURER_RELATION.TO_CORPORATION}`,
  [TaxonomyClass.FLAVOR]: FOOD_PRODUCT_RELATION.TO_FLAVOR,
  [TaxonomyClass.FOOD_BRAND]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}`,
  [TaxonomyClass.FOOD_CLASS]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}/${FOOD_BRAND_RELATION.TO_FOOD_CLASS}`,
  [TaxonomyClass.FOOD_FORM]: FOOD_PRODUCT_RELATION.TO_FOOD_FORM,
  [TaxonomyClass.FOOD_PRODUCT_LINE]: FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE,
  [TaxonomyClass.MANUFACTURER]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}/${FOOD_BRAND_RELATION.TO_MANUFACTURER}`,
  [TaxonomyClass.MARKET]: `${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}/${FOOD_PRODUCT_LINE_RELATION.TO_FOOD_BRAND}/${FOOD_BRAND_RELATION.TO_MARKET}`,
  [TaxonomyClass.PRESCRIPTION_OTC]: FOOD_PRODUCT_RELATION.TO_PRESCRIPTION_OTC,
  [TaxonomyClass.SPECIES]: FOOD_PRODUCT_RELATION.TO_SPECIES,
  [TaxonomyClass.TREATMENT_INDICATION]: FOOD_PRODUCT_RELATION.TO_TREATMENT_INDICATION,
};

export const medicalProductFacetsKeys = {
  DURATION_OF_EFFECT: MedicalProductProperties.DURATION_OF_EFFECT,
  SECONDARY_ACTIVE_INGREDIENT: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_SECONDARY_ACTIVE_INGREDIENT}`,
  [TaxonomyClass.ACTIVE_INGREDIENT]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_PRIMARY_ACTIVE_INGREDIENT}`,
  [TaxonomyClass.CORPORATION]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_MANUFACTURER}/${MANUFACTURER_RELATION.TO_CORPORATION}`,
  [TaxonomyClass.DRUG_CLASS]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_DRUG_CLASS}`,
  [TaxonomyClass.FLAVOR]: MEDICAL_PRODUCT_RELATION.TO_FLAVOR,
  [TaxonomyClass.MANUFACTURER]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_MANUFACTURER}`,
  [TaxonomyClass.MARKET]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_MARKET}`,
  [TaxonomyClass.MEDICAL_BRAND]: MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND,
  [TaxonomyClass.PACK_COLOR]: MEDICAL_PRODUCT_RELATION.TO_PACK_COLOR,
  [TaxonomyClass.PHYSICAL_FORM]: MEDICAL_PRODUCT_RELATION.TO_PHYSICAL_FORM,
  [TaxonomyClass.PRINCIPAL_GENERIC]: `${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_PRINCIPAL_GENERIC}`,
  [TaxonomyClass.ROUTE_OF_ADMINISTRATION]: MEDICAL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION,
  [TaxonomyClass.SPECIES]: MEDICAL_PRODUCT_RELATION.TO_SPECIES,
  [TaxonomyClass.TREATMENT_INDICATION]: MEDICAL_PRODUCT_RELATION.TO_TREATMENT_INDICATION,
};

export const retailProductFacetsKeys = {
  DURATION_OF_EFFECT: RetailProductProperties.DURATION_OF_EFFECT,
  SECONDARY_OTC_ACTIVE_INGREDIENT: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_SECONDARY_OTC_ACTIVE_INGREDIENT}`,
  [TaxonomyClass.ACTIVE_INGREDIENT]: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_PRIMARY_OTC_ACTIVE_INGREDIENT}`,
  [TaxonomyClass.BREED_SIZE]: RETAIL_PRODUCT_RELATION.TO_BREED_SIZE,
  [TaxonomyClass.MANUFACTURER]: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_MANUFACTURER}`,
  [TaxonomyClass.MARKET]: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_MARKET}`,
  [TaxonomyClass.PHYSICAL_FORM]: RETAIL_PRODUCT_RELATION.TO_PHYSICAL_FORM,
  [TaxonomyClass.RETAIL_BRAND]: RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND,
  [TaxonomyClass.RETAIL_CLASS]: `${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}/${RETAIL_BRAND_RELATION.TO_RETAIL_CLASS}`,
  [TaxonomyClass.ROUTE_OF_ADMINISTRATION]: RETAIL_PRODUCT_RELATION.TO_ROUTE_OF_ADMINISTRATION,
  [TaxonomyClass.SPECIES]: RETAIL_PRODUCT_RELATION.TO_SPECIES,
};
