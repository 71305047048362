import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { getConvertedValuesString } from "helpers/getConvertedValuesString";
import { useIsPath } from "router/hooks/useIsPath";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { generateStringByTemplate } from "modules/GenerateByUIConfigurationModule";
import { buildJsxComponentsByTemplate } from "modules/GenerateByUIConfigurationModule/buildJsxComponentsByTemplate";
import { getIsResultByTemplateExist } from "modules/GenerateByUIConfigurationModule/helpers/getIsResultByTemplateExist";

export const ItemReadRowByTemplate = React.memo<{
  template: string;
  taxonomyItem: Partial<TaxonomyItem> | undefined;
}>(({ template, taxonomyItem }) => {
  const { market } = useCatalogContext();
  const { isInMatching } = useIsPath();

  if (!taxonomyItem || !template) return <>—</>;
  const isResultByTemplateExist = getIsResultByTemplateExist(template, taxonomyItem);
  if (!isResultByTemplateExist) return <>—</>;

  const content = buildJsxComponentsByTemplate(template, taxonomyItem);
  const stringContent = generateStringByTemplate(template, taxonomyItem);
  const convertedString = market ? getConvertedValuesString(stringContent, market) : "";

  return (
    <div className="flex flex-row flex-wrap">
      {isInMatching ? `${stringContent} ${convertedString ? `(${convertedString})` : ""}` : content}
    </div>
  );
});
