import { TaxonomyConfiguration } from "types/appConfig";

import React from "react";
import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { configurationServiceInstance } from "services/ConfigurationService";

import { getIsDeepRelation } from "../../helpers/getIsDeepRelation";

import { DeepTaxonomyItemRelation } from "../DeepTaxonomyItemRelation";
import { ItemReadRowValue } from "../ItemReadRowValue";
import { TaxonomyItemReadRow } from "../TaxonomyItemReadRow/TaxonomyItemReadRow";
import { TaxonomyItemWithRelationToSelf } from "../TaxonomyItemWithRelationToSelf";

import { TaxonomyItemReadRelationProps } from "./TaxonomyItemReadRelation.types";

const getHasRelationOnYourself = (taxonomyConfiguration?: TaxonomyConfiguration, taxonomyClass?: string) => {
  if (!taxonomyConfiguration || !taxonomyClass) return false;

  const firstRelationName = Object.keys(taxonomyConfiguration.meta.relations || {})[0];

  const relationTaxonomyClass = configurationServiceInstance.getTaxonomyItemRelationName(
    taxonomyClass,
    firstRelationName
  );

  return relationTaxonomyClass === taxonomyClass;
};

export const TaxonomyItemReadRelation: React.FC<TaxonomyItemReadRelationProps> = ({
  relationName,
  relationItems,
  parentTaxonomyClass,
  nestingDepth,
}) => {
  const relationTaxonomyClass = configurationServiceInstance.getTaxonomyItemRelationName(
    parentTaxonomyClass,
    relationName
  );
  const relationConfiguration = configurationServiceInstance.getTaxonomyItemByName(relationTaxonomyClass || "");

  const isDeepRelation = getIsDeepRelation(relationConfiguration);

  const hasRelationOnYourself = getHasRelationOnYourself(relationConfiguration, relationTaxonomyClass);

  if (!relationConfiguration) return null;
  if (!relationTaxonomyClass) return null;

  const title = getTaxonomyRelationTitle(parentTaxonomyClass, relationName) || relationConfiguration.title || relationTaxonomyClass || "";

  if (hasRelationOnYourself) {
    return (
      <TaxonomyItemWithRelationToSelf
        title={title}
        taxonomyClass={relationTaxonomyClass}
        relationItems={relationItems}
      />
    );
  }

  if (isDeepRelation)
    return (
      <DeepTaxonomyItemRelation
        title={title}
        relationItems={relationItems}
        relationTaxonomyClass={relationTaxonomyClass}
        nestingDepth={nestingDepth}
      />
    );

  return <TaxonomyItemReadRow title={title} value={<ItemReadRowValue taxonomyItem={relationItems} />} />;
};
