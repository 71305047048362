import React from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import clsx from "clsx";
import { useTranslations } from "translations/useTranslations";

import { MatchingFacets } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingFacets";
import { MatchingSearch } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingSearch";
import { MatchingSort } from "modules/MatchingModule/MatchingGroupsModule/components/MatchingSort";

import { MatchingTableControlsProps } from "./MatchingTableControls.types";

export const MatchingTableControls = React.memo<MatchingTableControlsProps>(({ className }) => {
  const translations = useTranslations();
  const [isFacetsOpen, setIsFacetsOpen] = React.useState(false);

  const openFacets = React.useCallback(() => setIsFacetsOpen(true), []);
  const closeFacets = React.useCallback(() => setIsFacetsOpen(false), []);

  return (
    <>
      <div className={clsx("flex justify-between gap-4", className)}>
        <Button icon={<FilterOutlined />} onClick={openFacets}>{translations["matching.table.facets.button"]}</Button>
        <div className="grow"><MatchingSort /></div>
        <div className="grow"><MatchingSearch /></div>
      </div>
      <MatchingFacets isOpen={isFacetsOpen} onClose={closeFacets} />
    </>
  );
});
