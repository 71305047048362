import React, { useCallback } from "react";
import { useFilterContext } from "context/FilterContext/Filter.context";

import { getFacetsUrl, getTaxonomyUrlForItem } from "api/rootUrl";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { FacetsModule } from "modules/FacetsModule";
import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";

export const FacetContainer = React.memo<{
  taxonomyClass: string;
}>(({ taxonomyClass }) => {
  const { getFacetsKey } = useCatalogContext();
  const urlQueryService = useTaxonomyUrlQueryService();

  const { getAllowedFilterParamsWithExcludedKeys } = useFilterContext();

  const generateApiUrl = useCallback(
    (facetKey: string) => {
      const url = getTaxonomyUrlForItem(taxonomyClass);
      const allowedFilterParams = getAllowedFilterParamsWithExcludedKeys([facetKey]);
      return getFacetsUrl(url, facetKey) + (allowedFilterParams ? `&${allowedFilterParams}` : "");
    },
    [getAllowedFilterParamsWithExcludedKeys, taxonomyClass]
  );

  return (
    <FacetsModule
      facetsKey={getFacetsKey(taxonomyClass)}
      generateApiUrl={generateApiUrl}
      urlQueryService={urlQueryService}
    />
  );
});
