import React, { useCallback, useMemo } from "react";
import { getAllowedFilterParams } from "helpers/facetHelpers/getAllowedFilterParams";
import { UrlQueryService } from "services/UrlQueryService/UrlQueryService";

import { FacetSectionConfiguration, FacetSectionConfigurationItem } from "modules/FacetsModule/FacetsModule.types";

type FilterContextType = {
  allowedKeys: string[];
  allowedFilterParams: string;
  allowedFilterParamsWithAdditionalKeys: string;
  getAllowedFilterParamsWithExcludedKeys: (excludedKeys: string[]) => string;
  facetSectionsConfiguration: (FacetSectionConfigurationItem & { value?: string })[];
};

export const FilterContext = React.createContext<FilterContextType>({
  allowedKeys: [],
  allowedFilterParams: "",
  allowedFilterParamsWithAdditionalKeys: "",
  getAllowedFilterParamsWithExcludedKeys: () => "",
  facetSectionsConfiguration: [],
});

const filterAndJoin = (...content: string[]) => content.filter(Boolean).join("&");

export const FilterProviders = React.memo<{
  children: JSX.Element;
  allowedKeys: string[];
  additionalKeys: string[];
  urlQueryService: UrlQueryService;
  facetSectionsConfiguration: FacetSectionConfiguration;
  mandatoryFilterString?: string;
    }>(
      ({
        children,
        allowedKeys,
        additionalKeys,
        urlQueryService,
        facetSectionsConfiguration,
        mandatoryFilterString = "",
      }) => {
        const allowedFilterParams = getAllowedFilterParams(urlQueryService, allowedKeys);
        const allowedFilterParamsWithAdditionalKeys = getAllowedFilterParams(urlQueryService, [
          ...allowedKeys,
          ...additionalKeys,
        ]);

        const getAllowedFilterParamsWithExcludedKeys = useCallback(
          (excludedKeys: string[]) => {
            const allowedSearchParamsStringWitExcludedKeys = getAllowedFilterParams(
              urlQueryService,
              allowedKeys.filter((key) => !excludedKeys.includes(key))
            );
            return filterAndJoin(allowedSearchParamsStringWitExcludedKeys, mandatoryFilterString);
          },
          [allowedKeys, mandatoryFilterString, urlQueryService]
        );

        const value = useMemo<FilterContextType>(
          () => ({
            allowedKeys,
            allowedFilterParams: filterAndJoin(allowedFilterParams, mandatoryFilterString),
            allowedFilterParamsWithAdditionalKeys: filterAndJoin(allowedFilterParamsWithAdditionalKeys, mandatoryFilterString),
            getAllowedFilterParamsWithExcludedKeys,
            facetSectionsConfiguration,
          }),
          [
            allowedKeys,
            allowedFilterParams,
            mandatoryFilterString,
            allowedFilterParamsWithAdditionalKeys,
            getAllowedFilterParamsWithExcludedKeys,
            facetSectionsConfiguration
          ]
        );
        return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
      });

export const useFilterContext = () => {
  return React.useContext(FilterContext);
};
