import React from "react";
import { Checkbox, Form, Select, Space } from "antd";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { useGetListOfAllTaxonomyByTaxonomyClassWithCache } from "api/useGetListOfTaxonomyByTaxonomyClass";

import {
  useProviderRequirements
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/hooks/useProviderRequirements";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

import { Preloader, PreloaderType } from "ui/Preloader";

const MAX_ITEMS_FOR_CHECKBOXES = 5;

export const PanelTypeSelector = React.memo<{ required?: boolean }>(({ required }) => {
  const { getTaxonomyItemByName } = configurationServiceInstance;
  const translations = useTranslations();
  const { providerRequirements } = useProviderRequirements();

  const { data: panelTypes = [], isLoading } = useGetListOfAllTaxonomyByTaxonomyClassWithCache(TaxonomyClass.PANEL_TYPE);

  const filteredPanelTypes = React.useMemo(() => {
    const panelTypeRequirements = providerRequirements?.[TaxonomyClass.PANEL_TYPE];

    if (!panelTypeRequirements || typeof panelTypeRequirements === "boolean") return panelTypes;

    return panelTypes.filter((item) => panelTypeRequirements.includes(item.id));
  }, [panelTypes, providerRequirements]);

  const options = React.useMemo(() => {
    return filteredPanelTypes.map((item) => ({ value: item.id, label: item.name }));
  }, [filteredPanelTypes]);

  if (isLoading) return <Preloader type={PreloaderType.SKELETON} paragraph={false} />;

  return (
    <Form.Item
      name={TaxonomyClass.PANEL_TYPE}
      label={getTaxonomyItemByName(TaxonomyClass.PANEL_TYPE)?.title || TaxonomyClass.PANEL_TYPE}
      rules={[{ required }]}
    >
      {filteredPanelTypes.length <= MAX_ITEMS_FOR_CHECKBOXES ? (
        <Checkbox.Group>
          <Space direction="vertical">
            {options.map(({ value, label }) => (
              <Checkbox key={value} value={value}>{label}</Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      ) : (
        <Select
          mode="multiple"
          options={options}
          placeholder={translations["matching.diagnostics.form.panel.placeholder"]}
        />
      )}
    </Form.Item>
  );
});
