import { configurationServiceInstance } from "services/ConfigurationService";

import { FacetsComponentsEnum, FacetSectionConfigurationItem } from "modules/FacetsModule/FacetsModule.types";
import { defaultFacetSectionConfiguration } from "modules/TaxonomyModule/consts/facetConfigurations";

export const generateFacetConfig = (taxonomyClass: string): FacetSectionConfigurationItem[] => {
  const config = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass);
  const facetConfig: FacetSectionConfigurationItem[] = [...defaultFacetSectionConfiguration];

  const relations = config?.meta?.relations || {};

  for (let relation in relations) {
    facetConfig.push({
      key: relation,
      type: FacetsComponentsEnum.FLAT_TREE,
      taxonomyClass: relations[relation].to,
    })
  }

  return facetConfig;
}
