import React from "react";
import { theme, Typography } from "antd";
import { useTranslations } from "translations/useTranslations";

import { MatchingTargetsActions } from "modules/MatchingModule/MatchingTargetsModule/components/MatchingTargetsActions";

import { MatchingTargetsDrawerTitleProps } from "./MatchingTargetsDrawerTitle.types";
import { MatchingTargetsDrawerToggleButton } from "./MatchingTargetsDrawerToggleButton";

export const MatchingTargetsDrawerCommonTitle = React.memo<MatchingTargetsDrawerTitleProps>((props) => {
  const { isOpen, onToggle, height } = props;
  const { token } = theme.useToken();
  const translations = useTranslations();

  return (
    <div
      className="flex items-center justify-between gap-2 px-6"
      style={{
        height,
        borderBottom: `1px solid ${token.colorBorder}`
      }}
    >
      <div className="flex items-center gap-2">
        <MatchingTargetsDrawerToggleButton isOpen={isOpen} onToggle={onToggle} />
        <Typography.Text strong>{translations["matching.target.list.title"]}</Typography.Text>
      </div>
      <MatchingTargetsActions />
    </div>
  );
});
