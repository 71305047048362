import React from "react";
import { Collapse } from "antd";

import { TreeCheckboxFacet } from "modules/FacetsModule/components/TreeCheckboxFacet";
import { useFacetsModuleContext } from "modules/FacetsModule/FacetsModule.context";
import { FacetsValueFlatTreeType } from "modules/FacetsModule/FacetsModule.types";
import { combineTree } from "modules/FacetsModule/helpers/combineTree";
import { useFacetTitle } from "modules/FacetsModule/hooks/useFacetTitle";
import { useFillItemsWithCache } from "modules/FacetsModule/hooks/useFillItemsWithCache";
import { useGetFacetsValues } from "modules/FacetsModule/hooks/useGetFacetsValues";

import { FacetComponentWrapperProps } from "./FacetComponentWrapperProps.types";

export const FacetsSectionWrapperFlatTree = React.memo<FacetComponentWrapperProps>(
  ({ facetSectionElement, isFacetOpen, ...panelProps }) => {
    const { facetsService, facetSectionChange } = useFacetsModuleContext();

    const title = useFacetTitle(facetSectionElement);

    const { key } = facetSectionElement;
    const treeStore = React.useRef<FacetsValueFlatTreeType["value"]>([]);

    const { data, isFacetsValuesLoading } = useGetFacetsValues(facetSectionElement.key, facetsService, isFacetOpen);

    const checkedKeys = facetsService.getValuesByKey(key);

    const newTreeValues = React.useMemo(() => {
      const treeValues = data?.value?.length ? (data as FacetsValueFlatTreeType)?.value : [];
      if (checkedKeys?.length) {
        for (const key of checkedKeys) {
          if (!treeValues) break;
          if (treeValues.find((value) => value.id === key)) continue;
          treeValues.push({ id: key, count: 0 });
        }
      }

      return treeValues;
    }, [checkedKeys, data]);
    const { filledValues, isLoading } = useFillItemsWithCache(
      newTreeValues,
      newTreeValues.length !== 0 && !newTreeValues[0]?.name,
      facetSectionElement
    );

    treeStore.current = React.useMemo(() => {
      const { uniqueElementsFromSecondArray: uniqueCheckedElementsFromPreviousTree, combinedElement } = combineTree(
        filledValues,
        treeStore.current,
        facetsService,
        facetSectionElement.key
      );
      const uniqueCheckedIdsFromPreviousTree = uniqueCheckedElementsFromPreviousTree.map(({ id }) => id);

      return combinedElement.map((el) => (uniqueCheckedIdsFromPreviousTree.includes(el.id) ? { ...el, count: 0 } : el));
    }, [facetSectionElement.key, facetsService, filledValues]);

    const facetSectionChangeWrapper = React.useCallback(
      (values: string[]) => {
        facetSectionChange(facetSectionElement.key, values);
      },
      [facetSectionChange, facetSectionElement.key]
    );

    return (
      <Collapse.Panel {...panelProps} key={facetSectionElement.key} header={title}>
        <TreeCheckboxFacet
          isFlatTree
          treeData={treeStore.current}
          checkedKeys={checkedKeys}
          facetSectionChange={facetSectionChangeWrapper}
          isLoading={isFacetsValuesLoading || isLoading}
        />
      </Collapse.Panel>
    );
  }
);
