import { EMPTY, SPACE } from "consts/strings";

import {
  FoodProductProperties,
  MedicalProductProperties,
  RetailProductProperties,
  TaxonomyItemPatientWeightProperties
} from "modules/TaxonomyModule/consts/TaxonomyClassPropertiesNames";
import {
  FOOD_PRODUCT_RELATION,
  MEDICAL_PRODUCT_RELATION,
  RETAIL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { VALUE_FOR_NULL } from "../generateByUIConfiguration.consts";
import { ContentForm, ContentType } from "../generateByUIConfiguration.types";

const separator = { value: SPACE, form: ContentForm.TEXT };
const valuePairSeparator = { value: `${SPACE}-${SPACE}`, form: ContentForm.TEXT };
const empty = { value: EMPTY, form: ContentForm.TEXT };

export const deciderFunc = (pairValues: ContentType[]) => {
  const [measurement, unit] = pairValues;
  if (String(measurement.value) === "0" && unit.value && unit.to) {
    return [{ ...measurement, value: "?" }, separator, unit];
  }

  if (String(measurement.value) === "0" && !unit.value) {
    return [{ ...measurement, value: "?" }];
  }

  if (String(measurement.value) === VALUE_FOR_NULL && unit.value && unit.to) {
    return [{ ...measurement, value: "—" }, separator, unit];
  }

  if (measurement.value && !unit.value) {
    return [measurement, separator, { value: "—", form: ContentForm.TEXT }];
  }

  if (measurement.value && unit.value) {
    return [measurement, separator, unit];
  }

  return [{ value: "", form: ContentForm.TEXT }];
};

const speciesDecider = (pairValues: ContentType[]) => {
  const [min, max, unit] = pairValues;

  if (!min || !max || !unit) return [];

  const minIsTrue = !!Number(min.value);
  const minIsZero = min.value === "0";
  const minIsEmpty = !min.value;

  const maxIsTrue = !!Number(max.value);
  const maxIsZero = max.value === "0";
  const maxIsEmpty = !max.value;

  const hasUnit = !!unit.value;

  if (minIsTrue && maxIsTrue && hasUnit) {
    return [min, valuePairSeparator, max, separator, unit];
  }

  if (minIsTrue && maxIsTrue && !hasUnit) {
    return [min, valuePairSeparator, max, separator, empty];
  }

  if (minIsZero && maxIsTrue && hasUnit) {
    return [{ value: "<", form: ContentForm.TEXT }, separator, max, separator, unit];
  }

  if (minIsTrue && maxIsZero && hasUnit) {
    return [{ value: ">", form: ContentForm.TEXT }, separator, min, separator, unit];
  }

  if (minIsZero && maxIsTrue && !hasUnit) {
    return [{ value: "<", form: ContentForm.TEXT }, separator, max, separator, empty];
  }

  if (minIsTrue && maxIsZero && !hasUnit) {
    return [{ value: ">", form: ContentForm.TEXT }, separator, min, separator, empty];
  }

  if (minIsZero && maxIsZero && hasUnit) {
    return [{ value: "?", form: ContentForm.TEXT }, separator, unit];
  }

  if (minIsZero && maxIsZero && !hasUnit) {
    return [{ value: "?", form: ContentForm.TEXT }];
  }

  if (minIsEmpty && maxIsTrue && hasUnit) {
    return [empty, valuePairSeparator, max, separator, unit];
  }

  if (minIsTrue && maxIsEmpty && hasUnit) {
    return [min, valuePairSeparator, empty, separator, unit];
  }

  if (minIsEmpty && maxIsTrue && !hasUnit) {
    return [empty, valuePairSeparator, max, separator, empty];
  }

  if (minIsTrue && maxIsEmpty && !hasUnit) {
    return [min, valuePairSeparator, empty, separator, empty];
  }

  if (minIsEmpty && maxIsEmpty && hasUnit) {
    return [empty, separator, unit];
  }

  return [];
};

export const valueAndUnitPairs = {
  medicalProductStrength: {
    decider: deciderFunc,
    template: `[[{${MedicalProductProperties.STRENGTH}} {${MEDICAL_PRODUCT_RELATION.TO_STRENGTH_UNIT}}]]`,
  },
  medicalProductPackSize: {
    decider: deciderFunc,
    template: `[[{${MedicalProductProperties.PACK_SIZE}} {${MEDICAL_PRODUCT_RELATION.TO_PACK_SIZE_MEASURE}}]]`,
  },
  foodProductPackQuantity: {
    decider: deciderFunc,
    template: `[[{${FoodProductProperties.PACK_QUANTITY}} {${FOOD_PRODUCT_RELATION.TO_PACK_QUANTITY_MEASURE}}]]`,
  },
  foodProductPackUnitVolume: {
    decider: deciderFunc,
    template: `[[{${FoodProductProperties.PACK_UNIT_VOLUME}} {${FOOD_PRODUCT_RELATION.TO_PACK_UNIT_VOLUME_MEASURE}}]]`,
  },
  retailProductStrength: {
    decider: deciderFunc,
    template: `[[{${RetailProductProperties.STRENGTH}} {${RETAIL_PRODUCT_RELATION.TO_STRENGTH_UNIT}}]]`,
  },
  retailProductPackSize: {
    decider: deciderFunc,
    template: `[[{${RetailProductProperties.PACK_SIZE}} {${RETAIL_PRODUCT_RELATION.TO_PACK_SIZE_MEASURE}}]]`,
  },
  retailProductPackUnitVolume: {
    decider: deciderFunc,
    template: `[[{${RetailProductProperties.PACK_UNIT_VOLUME}} {${RETAIL_PRODUCT_RELATION.TO_PACK_UNIT_VOLUME_MEASURE}}]]`,
  },
  cat: {
    decider: speciesDecider,
    template: `[[{${TaxonomyItemPatientWeightProperties.MIN_WEIGHT_CAT}} - {${TaxonomyItemPatientWeightProperties.MAX_WEIGHT_CAT}} {${MEDICAL_PRODUCT_RELATION.TO_PATIENT_WEIGHT_UNIT}}]]`,
  },
  dog: {
    decider: speciesDecider,
    template: `[[{${TaxonomyItemPatientWeightProperties.MIN_WEIGHT_DOG}} - {${TaxonomyItemPatientWeightProperties.MAX_WEIGHT_DOG}} {${MEDICAL_PRODUCT_RELATION.TO_PATIENT_WEIGHT_UNIT}}]]`,
  },
};
