import { ItemID } from "types/common";
import { Synonym, SynonymType } from "types/entities/Synonym";

import { useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { combinePages, getNextPageParam } from "helpers/paginationHelpers";

import { QueryKeys } from "api/queryKeys";
import { getPaginationParams, getSynonymUrlWithParams } from "api/rootUrl";

export type SynonymsPaginationResult = { data: Synonym[]; nextPage: number };

const getSynonymItems = (taxonomyItemId: ItemID, synonymType: SynonymType, searchQuery?: string) => {
  return ({ pageParam }: QueryFunctionContext) =>
    axios
      .get<SynonymsPaginationResult>(getSynonymUrlWithParams(taxonomyItemId, synonymType, getPaginationParams(pageParam), searchQuery))
      .then(({ data }) => data);
};

export const useGetSynonymItems = (taxonomyItemId: ItemID, synonymType: SynonymType, searchQuery?: string) => {
  const { data, isLoading, isError, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } = useInfiniteQuery({
    queryKey: [QueryKeys.GET_LIST_SYNONYM_ITEMS, taxonomyItemId, synonymType, searchQuery],
    queryFn: getSynonymItems(taxonomyItemId, synonymType, searchQuery),
    enabled: !!taxonomyItemId,
    getNextPageParam: getNextPageParam,
  });

  return {
    hasNextPage,
    synonymItems: useMemo(() => combinePages<Synonym>(data?.pages), [data]),
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  };
};
