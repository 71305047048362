import { MarketCode } from "consts/markets";

enum Unit {
  LB = "lb",
  KG = "kg",
}

const conversionRates: Partial<Record<Unit, Partial<Record<Unit, number>>>> = {
  [Unit.LB]: { [Unit.KG]: 0.453592 },
  [Unit.KG]: { [Unit.LB]: 2.20462 }
}

const conversionsByCountry: Partial<Record<MarketCode, { from: Unit; to: Unit }[]>> = {
  [MarketCode.USA]: [{ from: Unit.KG, to: Unit.LB }],
  [MarketCode.CAN]: [{ from: Unit.LB, to: Unit.KG }],
}

const getRegexp = (unit: string) => new RegExp(`([\\d\\.]*)([^\\w]*)([\\d\\.]*)\\s*${unit}`);
const calcRate = (num: number, rate: number): number => Math.round(num * rate * 100) / 100;

export const getConvertedValuesString = (str: string, market: MarketCode) => {
  const conversions = conversionsByCountry[market];
  if (!conversions) return "";

  const conversion = conversions.find(({ from }) => str.toLowerCase().includes(from.toLowerCase()));
  if (!conversion) return "";

  const { from, to } = conversion;
  const rate = conversionRates[from]?.[to];
  if (!rate) return "";

  const match = str.toLowerCase().replace(/,/g, ".").match(getRegexp(from.toLowerCase()));
  if (!match) return "";

  const [, min, separator, max] = match;
  const [convertedMin, convertedMax] = [min, max].map((num) => num ? calcRate(+num, rate) : "");

  return `${[convertedMin, separator, convertedMax].join(" ")} ${to}`.replace(/\s+/g, " ").trim();
}
