import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";

import { InlineSelectProps } from "./InlineSelect.types";

export const InlineSelect = React.memo<InlineSelectProps>(({
  selectedItemId,
  items = [],
  placeholder,
  onSelect = () => {},
}) => {
  const selectedItemLabel = items.find((item) => selectedItemId === item.value)?.label;
  const menuItems = React.useMemo<MenuProps["items"]>(
    () => items.map((item) => ({ key: `${item.value}`, label: item.label })),
    [items]
  );

  return (
    <div className="inline-block">
      <Dropdown
        trigger={["click"]}
        menu={{
          items: menuItems,
          selectable: true,
          onSelect: ({ key }) => onSelect(key),
        }}
      >
        <Button type="link" size="small">{selectedItemLabel || placeholder} <EditOutlined /></Button>
      </Dropdown>
    </div>
  );
});
