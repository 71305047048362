import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { useShowDeleteItemPromiseConfirm } from "modules/TaxonomyModule/hooks/useShowDeleteItemPromiseConfirm";
import { useDeleteTaxonomyItemById } from "modules/TaxonomyModule/TaxonomyItemReadModule/api/useDeleteTaxonomyItemById";

import { Preloader, PreloaderType } from "ui/Preloader";

import { DeleteTaxonomyItemButtonType } from "./DeleteTaxonomyItemButton.types";

export const DeleteTaxonomyItemButton = React.memo<DeleteTaxonomyItemButtonType>(
  ({ itemID = "", type = "default", className }) => {
    const translations = useTranslations();
    const { deleteTaxonomyItemById } = useDeleteTaxonomyItemById(itemID);
    const catalogItemDeleteEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_ITEM_DELETE);

    const showDeletePromiseConfirm = useShowDeleteItemPromiseConfirm(itemID, deleteTaxonomyItemById);

    const handleClick = React.useCallback(() => {
      showDeletePromiseConfirm()
    }, [showDeletePromiseConfirm]);

    if (!catalogItemDeleteEnabled) return null;
    if (!itemID) return <Preloader type={PreloaderType.SKELETON_BUTTON} />;

    return (
      <Button type={type} onClick={handleClick} icon={<DeleteOutlined />} className={className} danger>
        {translations["item.button.delete"]}
      </Button>
    );
  }
);
