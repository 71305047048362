import { medicalProductFacetsKeys } from "modules/TaxonomyModule/consts/facetsKeys";
import { TaxonomyClass } from "modules/TaxonomyModule/consts/TaxonomyClassNames";
import {
  FOOD_PRODUCT_RELATION,
  MEDICAL_BRAND_RELATION,
  MEDICAL_PRODUCT_RELATION,
  RETAIL_PRODUCT_RELATION
} from "modules/TaxonomyModule/consts/TaxonomyClassRelationsNames";

import { valueAndUnitPairs } from "./UIConfigurationPairs";

export const propertiesForHiding = ["status", "is_abstract"];

export type UIConfigurationType = {
  read: Record<string, Record<string, string>>;
  form: Record<string, Record<string, string | string[]>>;
};

export const UIConfigurationTemplatesForm = {
  common: {
    lastFields: ["status", "is_abstract"],
  },
  [TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()]: {
    description: "item.form.field.medical_brand.description",
    firstFields: [MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND],
  },
  [TaxonomyClass.RETAIL_PRODUCT.toLowerCase()]: {
    firstFields: [RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND],
  },
  [TaxonomyClass.FOOD_PRODUCT.toLowerCase()]: {
    firstFields: [FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE],
  }
} as Record<string, Record<string, string | string[]>>;

export const UIConfigurationTemplatesRead = {
  [TaxonomyClass.MEDICAL_PRODUCT.toLowerCase()]: {
    name: `{${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}} [{${MEDICAL_PRODUCT_RELATION.TO_PHYSICAL_FORM}}, ] {${MEDICAL_PRODUCT_RELATION.TO_PACK_COLOR}} ${valueAndUnitPairs.medicalProductStrength.template} ${valueAndUnitPairs.medicalProductPackSize.template}`,
    activeIngredient: `[{${medicalProductFacetsKeys[TaxonomyClass.ACTIVE_INGREDIENT]}}, ] ${valueAndUnitPairs.medicalProductStrength.template}`,
    secondaryActiveIngredient: `[{${MEDICAL_PRODUCT_RELATION.TO_MEDICAL_BRAND}/${MEDICAL_BRAND_RELATION.TO_SECONDARY_ACTIVE_INGREDIENT}}, ]`,
  },
  [TaxonomyClass.FOOD_PRODUCT.toLowerCase()]: {
    name: `{${FOOD_PRODUCT_RELATION.TO_FOOD_PRODUCT_LINE}} {${FOOD_PRODUCT_RELATION.TO_PACK_COLOR}} [{${FOOD_PRODUCT_RELATION.TO_SPECIES}}, ] [{${FOOD_PRODUCT_RELATION.TO_FLAVOR}}, ] ${valueAndUnitPairs.foodProductPackQuantity.template}{{ - }}${valueAndUnitPairs.foodProductPackUnitVolume.template}`,
  },
  [TaxonomyClass.RETAIL_PRODUCT.toLowerCase()]: {
    name: `{${RETAIL_PRODUCT_RELATION.TO_RETAIL_BRAND}} [{${RETAIL_PRODUCT_RELATION.TO_PHYSICAL_FORM}}, ] ${valueAndUnitPairs.retailProductStrength.template} ${valueAndUnitPairs.retailProductPackSize.template} - ${valueAndUnitPairs.retailProductPackUnitVolume.template}`,
  },
} as Record<string, Record<string, string>>;

export const UIConfigurationTemplates: UIConfigurationType = {
  form: UIConfigurationTemplatesForm,
  read: UIConfigurationTemplatesRead,
};
