import React from "react";

type MatchingTargetsDrawerContextType = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MatchingTargetsDrawerContext = React.createContext<MatchingTargetsDrawerContextType>({
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
});

export const MatchingTargetsDrawerContextProvider = React.memo<{ children: JSX.Element }>(({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <MatchingTargetsDrawerContext.Provider value={{ isDrawerOpen, setIsDrawerOpen }}>
      {children}
    </MatchingTargetsDrawerContext.Provider>
  );
});

export const useMatchingTargetsDrawerContext = () => React.useContext(MatchingTargetsDrawerContext);
