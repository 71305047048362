import React from "react";
import { useTranslations } from "translations/useTranslations";

import { useDiscardText } from "modules/JobsModule/JobsCreateFormModule/components/DiscardButton/useDiscardText";

import { CommonDiscardButton } from "components/CommonDiscardButton";

export const DiscardButton = React.memo<{
  setShouldPrevent: React.Dispatch<React.SetStateAction<boolean>>
}>(({ setShouldPrevent }) => {
  const translations = useTranslations();
  const { title, content, okText } = useDiscardText();

  return (
    <CommonDiscardButton
      setShouldPrevent={setShouldPrevent}
      button={{ text: translations["jobs.form.button.discard"] }}
      popup={{ title, content, okText }}
    />
  );
})
