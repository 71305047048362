import { TaxonomyConfiguration } from "types/appConfig";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React from "react";
import { Card, Space, Typography } from "antd";
import clsx from "clsx";
import { useIsPath } from "router/hooks/useIsPath";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { propertiesForHiding } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { SynonymModule } from "modules/SynonymModule/SynonymModule/SynonymModule";
import { PriceModule } from "modules/TaxonomyModule/PriceModule";
import {
  TaxonomyItemHistoryCard
} from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemHistoryCard";
import { TaxonomyItemReadSection } from "modules/TaxonomyModule/TaxonomyItemReadModule/components/TaxonomyItemReadSection/TaxonomyItemReadSection";

export const TaxonomyItemDetailModule = React.memo<{ taxonomyClass: string; taxonomyItem?: TaxonomyItem }>(({ taxonomyClass, taxonomyItem }) => {
  const catalogViewChangelogEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_VIEW_CHANGELOG);
  const { isInCatalog, isInMatching } = useIsPath();

  const translation = useTranslations();

  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;

  if (!taxonomyItem) return null;

  return (
    <div className={clsx("grid grid-cols-1 md:grid-cols-2 gap-6", isInMatching && "lg:grid-cols-1 xl:grid-cols-2")}>
      <div>
        <Card>
          <Typography.Title level={4}>{translation["item.details.title"]}</Typography.Title>
          <TaxonomyItemReadSection fieldsToHide={propertiesForHiding} taxonomyClass={taxonomyClass} taxonomyItem={taxonomyItem} />
        </Card>
      </div>
      <Space direction="vertical" size="large">
        {isInCatalog && taxonomyConfig?.meta?.synonyms?.length && (
          <Card>
            <SynonymModule taxonomyItemId={taxonomyItem.id} availableSynonymTypes={taxonomyConfig.meta.synonyms} />
          </Card>
        )}
        {!!taxonomyConfig?.meta?.prices && (
          <Card>
            <PriceModule taxonomyItemId={taxonomyItem.id} />
          </Card>
        )}
        {taxonomyItem && catalogViewChangelogEnabled && <TaxonomyItemHistoryCard taxonomyItem={taxonomyItem} />}
      </Space>
    </div>
  );
});
