import React, { useCallback } from "react";
import { Collapse } from "antd";

import { RangeFacet } from "modules/FacetsModule/components/RangeFacet";
import { useFacetsModuleContext } from "modules/FacetsModule/FacetsModule.context";
import { FacetsValueRangeType } from "modules/FacetsModule/FacetsModule.types";
import { useFacetTitle } from "modules/FacetsModule/hooks/useFacetTitle";
import { useGetFacetsValues } from "modules/FacetsModule/hooks/useGetFacetsValues";

import { FacetComponentWrapperProps } from "./FacetComponentWrapperProps.types";

export const FacetsSectionWrapperRange = React.memo<FacetComponentWrapperProps>(
  ({ facetSectionElement, isFacetOpen, ...panelProps }) => {
    const { facetsService, facetSectionChange } = useFacetsModuleContext();

    const title = useFacetTitle(facetSectionElement);

    const { data } = useGetFacetsValues(facetSectionElement.key, facetsService, isFacetOpen);
    const [min, max] = (data as FacetsValueRangeType)?.value?.[0] || [0, 0];

    const facetSectionChangeCallback = useCallback(
      (values: [number, number]) => {
        facetSectionChange(
          facetSectionElement.key,
          values.map((el) => String(el))
        );
      },
      [facetSectionChange, facetSectionElement.key]
    );
    return (
      <Collapse.Panel {...panelProps} key={facetSectionElement.key} header={title}>
        <RangeFacet
          min={Number(min)}
          max={Number(max)}
          facetValues={facetsService.getValuesByKey(facetSectionElement.key) as [string, string] | []}
          facetSectionChange={facetSectionChangeCallback}
        />
      </Collapse.Panel>
    );
  }
);
