import {
  EnabledClassesAndItemsForDiagnosticsMatching
} from "modules/MatchingModule/MatchingTargetsModule/MatchingTargetsDiagnosticsModule/MatchingTargetsDiagnosticsModule.types";
import { TaxonomyClass } from "modules/TaxonomyModule/consts";

export const checkRequirementsConflict = (requirements: EnabledClassesAndItemsForDiagnosticsMatching[]): boolean => {
  if (requirements.every(requirement => requirement === null)) return false;
  if (requirements.some(requirement => requirement === null)) return true;

  return !requirements.filter(Boolean).every((requirement, index) => {
    if (index === 0) return true;

    const hasIdenticalValues = Object.entries(requirement).every(([key, value]) => {
      const firstRequirementValue = requirements[0][key as TaxonomyClass];
      if (Array.isArray(value) && Array.isArray(firstRequirementValue)) {
        return value.every((item) => firstRequirementValue.includes(item));
      }
      return firstRequirementValue === value;
    });

    const hasIdenticalValuesQt = Object.keys(requirement).length === Object.keys(requirements[0]).length;

    return hasIdenticalValues && hasIdenticalValuesQt;
  });
}
