import { Job } from "types/entities/Jobs";

import React from "react";
import { EMPTY } from "consts/strings";
import { formatDate } from "helpers/formatDate";
import { useTranslations } from "translations/useTranslations";

import { getProvidersAndClinicsFromJob } from "../helpers/getProvidersAndClinicsFromJob";

import { LabelledListProps } from "ui/LabelledList";
import { Preloader, PreloaderType } from "ui/Preloader";

import { useItemsToMatch } from "./useItemsToMatch";

export const useJobCardListItems = (job: Job) => {

  const { itemsToMatch, isLoading: isItemsToMatchLoading } = useItemsToMatch(job.filter_by_prematch.map(({ value }) => value));

  const translations = useTranslations();

  return React.useMemo<LabelledListProps["items"]>(() => {
    const from = translations["jobs.card.dates.from"];
    const to = translations["jobs.card.dates.to"];

    const { providers, clinics } = getProvidersAndClinicsFromJob(job);

    return [
      {
        label: translations["jobs.card.dates"],
        value: `${from} ${formatDate(job.date_from)} ${to} ${formatDate(job.date_to)}`
      },
      {
        label: translations["jobs.card.info_to_match"],
        value: isItemsToMatchLoading
          ? <Preloader type={PreloaderType.SKELETON} paragraph={false} />
          : itemsToMatch.join(", ") || translations["jobs.card.info_to_match.all"],
        tooltip: itemsToMatch.join(", "),
        nowrap: true,
      },
      {
        label: translations["jobs.card.markets"],
        value: job.market || EMPTY
      },
      {
        label: translations["jobs.card.providers"],
        value: providers.join(", ")?.toUpperCase() || EMPTY
      },
      {
        label: translations["jobs.card.clinics"],
        value: clinics.join(", ") || translations["jobs.card.clinics.all"],
        tooltip: clinics.join(", "),
        nowrap: true
      },
      {
        label: translations["jobs.card.created"],
        value: formatDate(job.created_dts)
      },
    ] as LabelledListProps["items"];
  }
  , [itemsToMatch, isItemsToMatchLoading, job, translations]);
}
